<h2 mat-dialog-title>User Activity - Form Fields Details</h2>
<mat-dialog-content class="mat-typography">
  <div class="audit-details">
    <ul>
      <li class="item">
        <span>Session ID</span>
        <span>{{ otherFields.session_id}} </span>
      </li>
      <li class="item">
        <span>Detection IP</span>
        <span>{{ otherFields.ip_address}} </span>
      </li>
      <!-- <li class="item">
        <span>User type</span>
        <span>{{ formFields.type }}</span>
      </li>
      <li class="item">
        <span>Clinic</span>
        <span>{{ formFields.clinic }}</span>
      </li> -->
      <li
        class="item"
        *ngFor="let item of allFields"
      >
        <span>{{ item.label }}</span>
        <span>{{ item.value | json }}</span>
      </li>
    </ul>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button
    color="primary"
    mat-button
    class="my-button my-outline mr-1"
    mat-dialog-close
  >Close</button>
</mat-dialog-actions>
