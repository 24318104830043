import { stringify } from "@angular/compiler/src/util";

export class BasicFilters {
  // basic
}

export class ClinicUserRequestFilters {
  filterSearch: string;
  filterUserName: string;
  filterClinicName: string;
  filterEmail: string;
  filterPhone: string;
  filterAddress: string;
  filterCreatedFrom: string;
  filterCreatedTo: string;

  constructor() {
    this.filterSearch = '';
    this.filterUserName = '';
    this.filterClinicName = '';
    this.filterEmail = '';
    this.filterPhone = '';
    this.filterAddress = '';
    this.filterCreatedFrom = '';
    this.filterCreatedTo = '';
  }
}

export class ClinicUserListFilters {
  filterSearch: string;
  filterUserName: string;
  filterClinicName: string;
  filterCreatedFrom: string;
  filterCreatedTo: string;
  filterLastActiveFrom: string;
  filterLastActiveTo: string;
  filterAccountType: string;

  constructor() {
    this.filterSearch = '';
    this.filterUserName = '';
    this.filterClinicName = '';
    this.filterCreatedFrom = '';
    this.filterCreatedTo = '';
    this.filterLastActiveFrom = '';
    this.filterLastActiveTo = '';
    this.filterAccountType = '';
  }
}

export class ClinicFilters {
  filterSearch: string;
  filterClinicName: string;
  filterClinicEmail: string;
  filterCreatedFrom: string;
  filterCreatedTo: string;
  filterLastActiveFrom: string;
  filterLastActiveTo: string;

  constructor() {
    this.filterSearch = '';
    this.filterClinicName = '';
    this.filterClinicEmail = '';
    this.filterCreatedFrom = '';
    this.filterCreatedTo = '';
    this.filterLastActiveFrom = '';
    this.filterLastActiveTo = '';
  }
}

export class ClinicRequestsFilters {
  filterSearch: string;
  filterClinicName: string;
  filterClinicEmail: string;
  filterClinicPhone: string;
  filterClinicAddress: string;
  filterCreatedFrom: string;
  filterCreatedTo: string;

  constructor() {
    this.filterSearch = '';
    this.filterClinicName = '';
    this.filterClinicEmail = '';
    this.filterClinicPhone = '';
    this.filterClinicAddress = '';
    this.filterCreatedFrom = '';
    this.filterCreatedTo = '';
  }
}

export class CaseFilters {
  filterSearch: string;
  filterLibrary: string;
  filterBiopsy: string;
  filterAOICaptured: string;
  filterSpecies: string;
  filterUseCase: string;
  filterDiagnosis: string;
  filterGender: string;
  filterReproductiveStatus: string;
  filterImageType: string;
  filterOtherImageType: string;
  filterAOIType: string;
  filterCaseStatus: string;
  filterBreed: string;
  filterAge: string;
  filterImageModality: string;
  filterCaseType: string;
  filterMachineManufacturer: string;
  filterMachineModel: string;
  filterBodyPart: string;
  filterClient: string;
  filterPatient: string;
  filterDateFrom: string;
  filterDateTo: string;

  constructor() {
    this.filterSearch = '';
    this.filterLibrary = '';
    this.filterBiopsy = '';
    this.filterAOICaptured = '';
    this.filterSpecies = '';
    this.filterUseCase = '';
    this.filterDiagnosis = '';
    this.filterGender = '';
    this.filterReproductiveStatus = '';
    this.filterImageType = '';
    this.filterOtherImageType = '';
    this.filterAOIType = '';
    this.filterCaseStatus = '';
    this.filterBreed = '';
    this.filterAge = '';
    this.filterImageModality = '';
    this.filterCaseType = '';
    this.filterMachineManufacturer = '';
    this.filterMachineModel = '';
    this.filterBodyPart = '';
    this.filterClient = '';
    this.filterPatient = '';
    this.filterDateFrom = '';
    this.filterDateTo = '';
  }
}


export class ClientFilters {
  filterSearch: string;
  filterClientId: string;
  filterName: string;
  filterNumberPatient: string;
  filterPatientName: string;
  filterPatientId: string;
  filterCreated: string;
  filterUpdated: string;
  filterDateFrom: string;
  filterDateTo: string;

  constructor() {
    this.filterSearch = '';
    this.filterClientId = '';
    this.filterName = '';
    this.filterNumberPatient = '';
    this.filterPatientName = '';
    this.filterPatientId = '';
    this.filterCreated = '';
    this.filterUpdated = '';
    this.filterDateFrom = '';
    this.filterDateTo = '';
  }
}


export class PatientsFilters {
  filterSearch: string;
  filterClientId: string;
  filterClientName: string;
  filterPatientName: string;
  filterPatientId: string;
  filterCreated: string;
  filterUpdated: string;
  filterDateFrom: string;
  filterDateTo: string;

  constructor() {
    this.filterSearch = '';
    this.filterClientId = '';
    this.filterClientName = '';
    this.filterPatientName = '';
    this.filterPatientId = '';
    this.filterCreated = '';
    this.filterUpdated = '';
    this.filterDateFrom = '';
    this.filterDateTo = '';
  }
}

export class UserActivityFilters {
  filterSearch: string;
  filterUserType: string;
  filterFormStep: string;
  filterDateFrom: string;
  filterDateTo: string;

  constructor() {
    this.filterSearch = '';
    this.filterUserType = '';
    this.filterFormStep = '';
    this.filterDateFrom = '';
    this.filterDateTo = '';
  }
}
