<div
  class="main-img-placeholder"
  *ngIf="outputImages.length === 0 && !loading"
>
  <span class="material-icons-outlined"> image_not_supported </span>
  <p><strong>Visual algorithm doesn't exist</strong></p>
</div>

<app-image-viewer
  *ngIf="outputImages.length !== 0 && !loading"
  [images]="outputImages"
  [idContainer]="'second'"
  (onNext)="onChangeImage($event)"
  (onPrevious)="onChangeImage($event)"
  [loadOnInit]="true"
  [imageTypeName]="helperService.transformAlgorithm(outputImagesObj[outputIndex]?.algorithm)"
  (viewerInstance)="handleActive($event)"
></app-image-viewer>
