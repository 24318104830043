export enum CreditCardTypesEnum {
  visa = 'visa',
  master = 'master',
  americanExpress = 'americanexpress',
  default = 'creditcard',
  visaIcon = 'visa.png',
  masterIcon = 'mastercard.png',
  americanExpressIcon = 'americanexpress.png',
  defaultIcon = 'creditcard.png',
}
