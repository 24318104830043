import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterService } from '@services/register.service';

@Component({
  selector: 'app-register-layout',
  templateUrl: './register-layout.component.html',
  styleUrls: ['./register-layout.component.scss'],
})
export class RegisterLayoutComponent implements OnInit {
  tryForFreeInfo: boolean = false;

  subscription: Subscription[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private registerService: RegisterService,
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      /**
       * * on change route
       * * if /register route is active set 'try for free' screen on side
       */
      this.activatedRoute.url.subscribe((url) => {
        if (this.router.url === '/register') {
          this.tryForFreeInfo = true;
        }
      })
    );
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.forEach((subscription) => subscription.unsubscribe());
  }

  demo() {
    const form = this.registerService.registerFormGroup;
    this.router.navigate(['demo', {
      first_name: form.get('first_name').value,
      last_name: form.get('last_name').value,
      email: form.get('email').value
    }]);
  }
}
