import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-download-dialog',
  templateUrl: './download-dialog.component.html',
  styleUrls: ['./download-dialog.component.scss']
})
export class DownloadDialogComponent implements OnInit {

  xml: boolean = false;
  original: boolean = false;
  downloadingInProgress = false;
  loggedRole: string;

  @Input() loading: boolean;
  @Input() downloadingProgress: boolean;
  @Input() caseTypeId: any;
  @Output() downloadEvent = new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
    const ls = JSON.parse(localStorage.getItem('currentUser'));
    if (ls) {
      this.loggedRole = ls.user.role;
    }

    if (this.caseTypeId === 4) {
      this.xml = true;
      this.original = true;
    }

    if (this.caseTypeId === 2) {
      this.original = true;
    }
  }


  download(type: string, selectAll: boolean): void {
    const value = {
      type,
      selectAll,
      xml: this.xml,
      original: this.original
    }
    this.downloadingInProgress = true;
    this.downloadEvent.emit(value);
  }

}
