<div id="personal-data-weekly-monthly">
  <div class="grid grid-col-2">
    <mat-form-field appearance="outline">
      <mat-label>Clinic</mat-label>
      <mat-select
        disableOptionCentering
        placeholder="Client"
        name="Client"
        [(ngModel)]="filters.filterClinic"
        (selectionChange)="onChangeClinic($event)"
        [disabled]="currentUser.role !== role.ADMIN"
      >
        <mat-option>
          <ngx-mat-select-search
            [formControl]="clinicFilterCtrl"
            placeholderLabel="Client first name"
            noEntriesFoundLabel="no matching found"
          >
          </ngx-mat-select-search>
        </mat-option>

        <mat-option
          *ngFor="let clinic of filteredClinic | async"
          [value]="clinic.id"
        >
          {{ clinic.name }}
        </mat-option>
      </mat-select>

      <button
        name="filterClinic"
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        *ngIf="filters.filterClinic && currentUser.role === role.ADMIN"
        (click)="onClear($event, 'filterClinic')"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>User</mat-label>
      <mat-select
        disableOptionCentering
        placeholder="Clinic user"
        name="Clinic user"
        [(ngModel)]="filters.filterUser"
        (selectionChange)="onChangeUser($event)"
      >
        <mat-option>
          <ngx-mat-select-search
            [formControl]="userFilterCtrl"
            placeholderLabel="Clinic user"
            noEntriesFoundLabel="no matching found"
          >
          </ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let user of filteredUser | async" [value]="user.id">
          {{ user.first_name }}
          {{ user.middle_name }}
          {{ user.last_name }}
        </mat-option>
      </mat-select>

      <button
        name="filterUser"
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        *ngIf="filters.filterUser"
        (click)="onClear($event, 'filterUser')"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="inner-section-header justify-content-between">
    <div class="inner-section-wrapper">
      <div class="inner-section-icon">
        <span class="material-icons-outlined"> donut_small </span>
      </div>

      <div class="inner-section-title">Weekly data</div>
    </div>

    <div class="inner-section-cases">
      {{ data?.weeklyData?.newCases }} new cases this week
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="grid grid-col-2 mt-2">
    <div class="col flex-1 pr-2">
      <div class="card-item">
        <p class="m-0">Weekly cases</p>
        <p class="green m-0">
          {{
            utilService.formatNumberWithPercentage(
              data?.weeklyData?.numberOfCasesIncrease
            )
          }}
        </p>
      </div>
      <div class="card-item">
        <p class="m-0">Bening cases</p>
        <p class="green m-0">
          {{
            utilService.formatNumberWithPercentage(
              data?.weeklyData?.numberOfBenignCasesIncrease
            )
          }}
        </p>
      </div>
      <div class="card-item">
        <p class="m-0">New clients</p>
        <p class="green m-0">
          {{
            utilService.formatNumberWithPercentage(
              data?.weeklyData?.numberOfClientsIncrease
            )
          }}
        </p>
      </div>
    </div>

    <div class="col flex-1">
      <div class="card-item">
        <p class="m-0">Normal cases</p>
        <p class="green m-0">
          {{
            utilService.formatNumberWithPercentage(
              data?.weeklyData?.numberOfNormalCasesIncrease
            )
          }}
        </p>
      </div>
      <div class="card-item">
        <p class="m-0">Malignant cases</p>
        <p class="green m-0">
          {{
            utilService.formatNumberWithPercentage(
              data?.weeklyData?.numberOfMalignantCasesIncrease
            )
          }}
        </p>
      </div>
      <div class="card-item">
        <p class="m-0">New patients</p>
        <p class="green m-0">
          {{
            utilService.formatNumberWithPercentage(
              data?.weeklyData?.numberOfPatientsIncrease
            )
          }}
        </p>
      </div>
    </div>
  </div>

  <div class="inner-section-header justify-content-between">
    <div class="inner-section-wrapper">
      <div class="inner-section-icon">
        <span class="material-icons-outlined"> donut_small </span>
      </div>

      <div class="inner-section-title">Monthly data</div>
    </div>
    <div class="inner-section-cases">
      {{ data?.monthlyData?.newCases }} new cases this month
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="grid grid-col-2 mt-2">
    <div class="col flex-1 pr-2">
      <div class="card-item card-item-purple">
        <p class="m-0">Monthly cases</p>
        <p class="purple m-0">
          {{
            utilService.formatNumberWithPercentage(
              data?.monthlyData?.numberOfPatientsIncrease
            )
          }}
        </p>
      </div>
      <div class="card-item card-item-purple">
        <p class="m-0">Bening cases</p>
        <p class="purple m-0">
          {{
            utilService.formatNumberWithPercentage(
              data?.monthlyData?.numberOfBenignCasesIncrease
            )
          }}
        </p>
      </div>
      <div class="card-item card-item-purple">
        <p class="m-0">New clients</p>
        <p class="purple m-0">
          {{
            utilService.formatNumberWithPercentage(
              data?.monthlyData?.numberOfClientsIncrease
            )
          }}
        </p>
      </div>
    </div>

    <div class="col flex-1">
      <div class="card-item card-item-purple">
        <p class="m-0">Normal cases</p>
        <p class="purple m-0">
          {{
            utilService.formatNumberWithPercentage(
              data?.monthlyData?.numberOfNormalCasesIncrease
            )
          }}
        </p>
      </div>
      <div class="card-item card-item-purple">
        <p class="m-0">Malignant cases</p>
        <p class="purple m-0">
          {{
            utilService.formatNumberWithPercentage(
              data?.monthlyData?.numberOfMalignantCasesIncrease
            )
          }}
        </p>
      </div>
      <div class="card-item card-item-purple">
        <p class="m-0">New patients</p>
        <p class="purple m-0">
          {{
            utilService.formatNumberWithPercentage(
              data?.monthlyData?.numberOfPatientsIncrease
            )
          }}
        </p>
      </div>
    </div>
  </div>
</div>
