import { useCaseNameEnum } from './../../enums/useCaseNameEnum';
import { refImagesListView } from './dummy-ref-images-by-case';
import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnInit,
  QueryList,
  ViewChildren,
  ElementRef,
  ViewChild,
  Inject,
  TemplateRef,
  ViewContainerRef,
  OnDestroy,
} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { interval, Subscription, Observable } from 'rxjs';

import { Role } from '@enums/role';
import { DownloadTable } from '@models/downloadTable';

import { CaseService } from '@services/case.service';
import { GraphStatsService } from '@services/graph-stats.service';
import { SpinnerService } from '@services/spinner.service';
import { ToastService } from '@services/toast.service';

import { FullSizeContainerComponent } from '@shared/components/full-size-container/full-size-container.component';
import { SendtomailDialogComponent } from './sendtomail-dialog/sendtomail-dialog.component';
import { UtilService } from '@services/util.service';
import { ProcessedScorePositions } from '@enums/ProcessedScorePositions';
import { ImageViewerService } from '@services/image-viewer.service';
import { throttleTime } from 'rxjs/operators';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { ReferentImagesService } from '@services/referent-images.service';

declare var cornerstone: any;
declare var cornerstoneWADOImageLoader: any;
declare var dicomParser: any;
let dicomDataArray: any[] = [];

export interface Images {
  algorithm: string;
  body_part: string;
  body_view: string;
  case_id: number;
  created_at: string;
  id: number;
  image_modality: string;
  image_url: string;
  name: string;
  patient_id: number;
  transaction_id: number;
  stdev: number;
  updated_at: string;
}

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent implements OnInit, OnDestroy {
  id: number;
  caseData: any;
  isImagesDCM: boolean[] = [];
  getServerFile: any;
  loadingDCM: any[] = [];
  downloadImagesList: any[] = [];
  disableDownloadBtn = true;
  objectKeys = Object.keys;
  transactionId: string;
  caseTransactionId: string;
  downloadAllLink = '';
  loadingDownload = false;
  isImgLoaded: any[] = [];
  isOutputImageLoaded: any[] = [];
  isOriginalImageLoaded: any[] = [];
  loadingPage = false;
  graphStatsData: any[] = [];
  caseTypeName: any = '';
  caseTypeId: number = null;
  checkboxList: any[] = [];
  referenceDisabled: boolean = false;

  activatedStatesLength: any;

  // output images array
  outputImageArray: any[] = [];
  referenceImages: any[] = [];
  originalImage: any = '';
  algorithmsImageArray: any[] = [];
  currentOutputIndex: any = null;
  selectedIndexImg: any = null;
  additionalInfoDetails: any = null;
  refCaseIndex = 0;

  imageIndex = 0;
  outputSplenicMass: any[] = [];
  imagesList: any[] = [];

  // Files for download
  setXMLtoDownload = false;
  setAOItoDownload = false;
  setOriginaltoDownload = false;
  trigger = false;

  // User Active Role
  activeUserRole: any;
  role = Role;

  // Case Details
  caseDetails: any[] = [];
  previousDisabled = true;
  nextDisabled = true;

  // tooltip
  zoomOutputTooltip = 'Click to use magnifying glass';
  zoomOriginalTooltip = 'Click to use magnifying glass';

  // filterVIew
  displayOriginalPreview = false;
  displayOutputPreview = false;
  displayReferencePreview = false;
  layoutCounter: any = 3;
  previewImage: any;

  // current values
  activeAOIType = 0;
  activeAlgorithm = 0;
  loadingState: boolean = false;
  displayStates = false;
  displayStateType: string = '';

  // Original Image
  originalArray: any[] = [];

  disabledNoData: boolean = false;

  // ref case
  refCaseSelected: string = '1';
  isReferenceActive: boolean = false;
  isOriginalActive: boolean = true;
  isOutputActive: boolean = true;

  noProcessingData: any[] = [];

  activeAlgorithmReference: string = '';
  currentActive: any = '';

  // * Focus mode
  focusModeActive: boolean = false;

  /**
   * * Error images
   */
  errorImages: any[] = [];

  // * Enums
  ProcessedScorePositions = ProcessedScorePositions;

  subscription: Subscription[] = [];
  selection = new SelectionModel<any>(true, []);

  @ViewChildren('allTheseThings') things: QueryList<any>;
  @ViewChild('imagePreviewModal') imagePreviewModal: any;
  @ViewChild('original_image_container') originalImageContainer: any;
  @ViewChild('output_image_container') outputImageContainer: any;
  @ViewChildren('outputWrapper') outputWrapper: any;
  @ViewChild('content', { read: TemplateRef }) contentRef: TemplateRef<any>;
  @ViewChild('outlet', { read: ViewContainerRef }) outletRef: ViewContainerRef;

  constructor(
    private http: CaseService,
    public dialog: MatDialog,
    private toast: ToastService,
    private caseService: CaseService,
    private router: Router,
    private gsService: GraphStatsService,
    private spinner: SpinnerService,
    public utilService: UtilService,
    public dialogRef: MatDialogRef<ViewComponent>,
    private ivService: ImageViewerService,
    private referentImagesService: ReferentImagesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    const ls = JSON.parse(localStorage.getItem('currentUser'));
    if (ls) {
      this.activeUserRole = ls.user.role;
    }

  }

  ngOnInit(): void {
    dicomDataArray = [];

    cornerstoneWADOImageLoader.external.cornerstone = cornerstone;

    this.id = this.data.id;
    this.selectedIndexImg = null;
    this.resetImages();
    this.initData();
    this.setActiveViewScreens(true, true, true);

    this.setOriginalImages();

    this.subscription.push(this.selection.changed.subscribe((event: any) => {
      if (event.added.length !== 0) {
        const el: ElementRef = event.added[0]._elementRef;

        this.addToDownloadList(
          event.added[0].id,
          el.nativeElement.dataset.name,
          el.nativeElement.dataset.transaction_id,
          el.nativeElement.dataset.index
        );
      } else {
        const el: ElementRef = event.removed[0]._elementRef;

        this.removeFromDownloadList(
          event.removed[0].id,
          el.nativeElement.dataset.name
        );
      }
      if (this.downloadImagesList.length !== 0) {
        this.disableDownloadBtn = false;
      } else {
        this.disableDownloadBtn = true;
      }
    }));

    this.subscription.push(
      this.referentImagesService.index.subscribe({
        next: (event: any) => {
          if (this.caseData) {
            this.fetchReferenceImages();
            this.activeAlgorithmReference = event.name;
          }
        }
      })
    )

    this.subscription.push(this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // this.initData();
      }
    }));

    this.ivService.refreshImageEmitter
      .pipe(
        throttleTime(10)
      )
      .subscribe(
        {
          next: (event) => {
            const imageCurrentDim = event.instance?._state?.imageCurrentDim;

            if (imageCurrentDim) {
              if (this.currentActive === 'first') {
                this.ivService.second.updateImage(event);

              } else if (this.currentActive === 'second') {
                this.ivService.first.updateImage(event);
              }
            }
          }
        }
      )
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.forEach(subscription => subscription.unsubscribe());
  }

  focusMode() {
    this.focusModeActive = !this.focusModeActive;
    const elem = <any>document.documentElement;
    const doc = <any>document;

    if (this.focusModeActive) {
      /* View in fullscreen */
      if (elem?.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem?.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem?.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }

      this.dialogRef.addPanelClass('no-padding-container')
    } else {
      /* Close fullscreen */
      if (doc?.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc?.webkitExitFullscreen) { /* Safari */
        doc.webkitExitFullscreen();
      } else if (doc?.msExitFullscreen) { /* IE11 */
        doc.msExitFullscreen();
      }

      this.isOutputActive = true;
      this.isOriginalActive = true;

      this.dialogRef.removePanelClass('no-padding-container')
    }
  }

  closeModal() {
    const doc = <any>document;

    this.isOutputActive = true;
    this.isOriginalActive = true;

    if (this.focusModeActive) {
      /* Close fullscreen */
      if (doc?.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc?.webkitExitFullscreen) { /* Safari */
        doc.webkitExitFullscreen();
      } else if (doc?.msExitFullscreen) { /* IE11 */
        doc.msExitFullscreen();
      }
    }

    this.dialogRef.close();
  }

  handleCurrentActive(event) {
    this.currentActive = event;
  }

  /** On change reference image */
  onChangeRefImage(event): void {
    /**
     * * On Change Reference Image
     */
  }

  copyToClipboard() {
    if (this.disabledNoData) {
      return;
    }
    this.utilService.copyToClipboard(location.href).subscribe({
      next: () => {
        this.toast.showInfo('The link to the page has been copied', 'Clipboard');
      }
    })
  }

  setOriginalImages(): any {
    this.originalArray = [];
    this.caseData?.images.forEach((original) => {
      this.originalArray.push({
        original_image: original.original_images,
        original_image_thumbnail: original.original_images_thumbnail,
        name: original.name,
        output: original,
      });
    });
  }

  setActiveViewScreens(original, output, ref): void {
    original
      ? (this.displayOriginalPreview = false)
      : (this.displayOriginalPreview = true);
    output
      ? (this.displayOutputPreview = false)
      : (this.displayOutputPreview = true);
    ref
      ? (this.displayReferencePreview = false)
      : (this.displayReferencePreview = true);

    this.checkboxList[0] = original;
    this.checkboxList[1] = output;
    this.checkboxList[2] = ref;
  }

  downloadEventEmit(data): void {
    this.setXMLtoDownload = data.xml;
    this.setOriginaltoDownload = data.original;

    if (data.type === 'regular') {
      this.onDownload(data.selectAll);
    } else if (data.type === 'email') {
      this.sendToMail(data.selectAll);
    }
  }

  parseTypeofCase(typeId): void {
    if (typeId === 1) {
      this.caseTypeName = 'Normal Use Case';
    } else if (typeId === 2) {
      this.caseTypeName = 'Image Upload Only';
    } else if (typeId === 3) {
      this.caseTypeName = 'Quick Process';
    }
  }

  onOpenComparison(name?: string): void {
    this.displayStates = !this.displayStates;
    if (name) {
      this.displayStateType = name;
    }
  }

  initData(): void {
    this.loadingPage = true;

    this.spinner.showSpinner('output');
    this.loadingState = true;

    this.http.getCaseById(this.id).subscribe(
      (response: any) => {
        this.loadingState = false;
        this.spinner.hideSpinner('output');

        if (!response) {
          this.disabledNoData = true;
        }

        if (response != null) {
          this.caseData = response;
          this.noProcessingData = response;
          this.caseTypeId = response.case_type_id;

          this.loadingPage = false;
          if (response.images.length !== 0) {
            //   // values for ACL Use Case
            //   this.loadDCMFile(response.images);
            this.setLoadingImages(response.images[0].processed_images);
          }
          this.currentOutputIndex = 0;
          this.triggerImageChangeInit();

          this.setImagesList(this.caseData.images);
          this.parseTypeofCase(response.case_type_id);

          this.fetchReferenceImages();

          this.activeAlgorithmReference = response.images[0].processed_images.at(0)?.algorithm;

          // * Set init eval type for reference case select field
          if (response.images[0].processed_images.at(0)?.result) {
            const firstEvalType = this.utilService.getEvalType(response.images[0].processed_images.at(0)?.result);
            this.setReferenceImageSelect(firstEvalType);
          }
        }

        this.outletRef.createEmbeddedView(this.contentRef);
      },
      (err) => {
        this.spinner.hideSpinner('output');
        this.toast.showError('Oops something went wrong.', 'Server Error!');
        this.loadingPage = false;
        this.loadingState = false;
        this.disabledNoData = true;

        if (err === 'Forbidden') {
          this.router.navigate(['/']);
        }
      }
    );
  }

  prevAOIType(event): void {
    const len = this.outputImageArray.length;
    if (len > 1 && this.activeAOIType > 0) {
      this.activeAOIType--;
    }
    return;
  }

  /**
   *
   * @param event Event
   * @param index index for aoi image
   */
  handleError(event, index) {
    const { type } = event;

    if (type === 'error') {
      this.errorImages[index] = event;
    }
  }

  handleReferenceState(event) {
    this.referenceDisabled = true;
    this.ivService.refreshImageSetter = true;
    this.outletRef.clear();
    this.outletRef.createEmbeddedView(this.contentRef);

    setTimeout(() => { this.referenceDisabled = false; }, 2000);
  }

  fetchReferenceImages(): void {
    const algorithmId = this.caseData.algorithm?.id;

    this.subscription.push(
      this.referentImagesService.getAll({
        algorithm_id: algorithmId,
      }).subscribe((resp) => {
        const result = [];
        const remake = this.referentImagesService.groupByAlgorithmName(resp);

        remake.forEach((v, i) => {
          const refAlgorithm = this.referentImagesService.groupByImageTypeName(v.value);
          result.push(refAlgorithm);
        });

        this.referenceImages = result[0];
      }, (error) => {
        console.log(error);
      })
    )
  }

  toggleFullScreen(imgSrc?, event?: any): void {
    let imageSrc;

    if (imgSrc) {
      imageSrc = imgSrc;
    } else {
      console.warn('reference image was changed - view.component.ts:457 - please update this part.');
    }

    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      srcFullImage: imageSrc,
    };

    dialogConfig.panelClass = 'full-screen-container';
    dialogConfig.backdropClass = 'backdropBackground';

    this.dialog.open(FullSizeContainerComponent, dialogConfig);
  }

  activatePreviewAOIType(index: number): void {
    const temp = this.outputImageArray[this.activeAOIType]?.url;

    this.activeAOIType = index;
  }

  nextAOIType(event): void {
    const len = this.outputImageArray.length;
    if (len - 1 > this.activeAOIType) {
      this.activeAOIType++;
    }
    return;
  }

  triggerImageChangeInit(): void {
    const imageDataObject = this.caseData.images
      ? this.caseData.images[0]
      : this.caseData.images_no_processing[0];
    this.triggerImageChange(imageDataObject, 0);

    if (
      this.checkboxList.filter((val) => val).length < 3 &&
      this.originalImageContainer
    ) {
      this.originalImageContainer.nativeElement.classList.remove('normal_size');
    } else if (
      this.checkboxList.filter((val) => val).length > 2 &&
      this.originalImageContainer
    ) {
      this.originalImageContainer.nativeElement.classList.add('normal_size');
    }
  }

  triggerImageChange(currentCaseData, index, trigger = ''): any {
    if (this.selectedIndexImg === index) {
      return;
    }
    this.resetImages();
    this.currentOutputIndex = 0;
    this.selectedIndexImg = index;
    this.setTransactionId(currentCaseData.transaction_id);

    let outputImages;
    if (this.caseTypeId === 2) {
      outputImages = currentCaseData.original_aoi_images ? currentCaseData.original_aoi_images : [currentCaseData.original_images];
    } else {
      outputImages = currentCaseData.processed_images;
    }
    this.setOutputImages(outputImages);
    // }

    if (
      currentCaseData.algorithm === useCaseNameEnum.SpleenMass &&
      currentCaseData.cases.case_type_id === 2
    ) {
      this.setOriginalImage(currentCaseData, currentCaseData.name, true);
    } else {
      this.setOriginalImage(currentCaseData, currentCaseData.name, false);
    }

    this.setAlgorithmsForImage(currentCaseData.processed_images);
    this.setDetails(this.caseData, 0);
    this.setInfoDetails(currentCaseData);
    this.setLoadingImages(currentCaseData.processed_images);
    this.activatedStatesLength = this.checkboxList.filter((val) => val).length;
  }

  checkProportionSize(): any {
    return this.checkboxList.filter((val) => val).length > 2;
  }

  resetImages(): void {
    this.originalImage = {
      url: null,
      name: null,
      thumbnail: null,
    };
    this.outputImageArray = [];
    this.isOutputImageLoaded[this.currentOutputIndex] = false;
    this.isOriginalImageLoaded[this.currentOutputIndex] = false;
  }

  setAlgorithmsForImage(imageData): void {
    this.algorithmsImageArray = [];
    if (!imageData) {
      return;
    }
    imageData.forEach((value, index) => {
      this.algorithmsImageArray.push(value.algorithm);
    });
  }

  setTransactionId(id): void {
    this.caseTransactionId = id;
  }

  setOriginalImage(data, name, aoi?: boolean): void {
    this.originalImage = {
      original_thumbnail: data.original_images_thumbnail,
      original_full: data.original_images,
      url: aoi ? data.original_aoi_images : data.original_images,
      name,
      thumbnail: aoi
        ? data.original_aoi_images
        : data.original_images_thumbnail,
    };
  }

  outputImageError(index): void {
    this.outputImageArray[index].thumbnail =
      this.outputImageArray[index].original_thumbnail;
    this.outputImageArray[index].url =
      this.outputImageArray[index].original_full;
  }

  setImagesList(images): void {
    this.imagesList = [];

    images.forEach((img) => {
      this.imagesList.push({
        image: img.original_images,
        thumbnail: img.original_images_thumbnail,
        ml: null,
        stdev: null,
        name: img.name,
        output: img,
      });
      // img.original_aoi_images.forEach((aoi) => {

      // });
    });
  }

  originalImageError(): void {
    this.originalImage.url = this.originalImage.original_full;
    this.originalImage.thumbnail = this.originalImage.original_thumbnail;
  }

  switchOutput(image: any, index): void {
    let outputImages;
    console.log('case type', this.caseTypeId);

    if (this.caseTypeId === 2) {
      outputImages = image.output.original_aoi_images ? image.output.original_aoi_images : [image.output.original_images];
    } else {
      outputImages = image.output.processed_images;
    }

    this.setOutputImages(outputImages);
    this.imageIndex = index;
    this.trigger = !this.trigger;


    // * Set eval type in reference case select field after change output image
    if (outputImages.at(0)?.result) {
      const selectedEvalType = this.utilService.getEvalType(outputImages.at(0).result);
      this.setReferenceImageSelect(selectedEvalType);
    }
  }

  /**
   * * Set value for reference case depends on eval type
   */
  setReferenceImageSelect(type: any) {
    if (type === 'NORMAL') {
      this.refCaseSelected = '1';
    } else if (type === 'BENIGN') {
      this.refCaseSelected = '2';
    } else if (type === 'MALIGNANT') {
      this.refCaseSelected = '3';
    }
  }

  setOutputImages(images): any {
    this.outputImageArray = [];
    this.activeAOIType = 0;

    if (!images) {
      return;
    }

    if (this.caseTypeId === 2) {
      this.outputImagesWithAOI(images);
    } else {
      this.outputImagesProcessed(images);
    }
  }

  outputImagesProcessed(images: any[]): any {
    images.forEach((value, index) => {
      if (value.algorithm === useCaseNameEnum.SpleenMass) {
        this.outputImageArray.push({
          original_thumbnail: value.original_images_thumbnail,
          original_full: value.original_images,
          url: value.original_aoi_images,
          name: value.name,
          thumbnail: value.original_aoi_images,
          result: value.result,
        });
      } else {
        this.outputImageArray.push({
          original_thumbnail: value.original_images_thumbnail,
          original_full: value.original_images,
          url: value.url,
          name: value.key,
          thumbnail: value.thumbnail,
          result: value.result,
        });
      }
    });
  }

  outputImagesWithAOI(images: any[]): any {
    images.forEach((value, index) => {
      this.outputImageArray.push({
        url: value.image,
        name: value.key,
        thumbnail: value.thumbnail,
      });
    });
  }

  prepareGraphData(image: any): any {
    return this.gsService.getGraphData(image.stdev);
  }

  proccesedML(MLValue): any {
    if (MLValue && MLValue !== undefined) {
      return MLValue.toLowerCase();
    }
    return '';
  }

  /**
   *
   * @param data set of date for details
   * in case where we have normal case its always 0 index otherwise its index for image upload only image
   * @param index current index in caseData array for normal cases it's 0 , in image upload only we need to parse index.
   */
  setDetails(data, index): void {
    this.caseDetails[index] = data;
  }

  setImageDetails(index): void {
    //
  }

  setInfoDetails(data): void {
    // this.additionalInfoDetails = {
    //   bodypart: data.body_part.name,
    //   algorithm: data.algorithm
    // }
  }

  getRandomNum(): any {
    return Math.floor(Math.random() * (100 - 90) + 90);
  }

  selectedFilterView(target, event): void {
    if (event.checked) {
      switch (target) {
        case 'original':
          this.displayOriginalPreview = false;
          this.layoutCounter++;
          break;
        case 'output':
          this.displayOutputPreview = false;
          this.layoutCounter++;
          break;
        case 'reference':
          this.displayReferencePreview = false;
          this.layoutCounter++;
          break;
      }
      if (this.checkboxList.filter((val) => val).length > 2) {
        this.originalImageContainer.nativeElement.classList.add('normal_size');
        this.outputImageContainer.nativeElement.classList.add('normal_size');
      }
    }

    if (!event.checked) {
      switch (target) {
        case 'original':
          this.displayOriginalPreview = true;
          this.layoutCounter--;
          break;
        case 'output':
          this.displayOutputPreview = true;
          this.layoutCounter--;
          break;
        case 'reference':
          this.displayReferencePreview = true;
          this.layoutCounter--;
          break;
      }
      if (this.checkboxList.filter((val) => val).length < 3) {
        this.originalImageContainer.nativeElement.classList.remove(
          'normal_size'
        );
        this.outputImageContainer.nativeElement.classList.remove('normal_size');
      }
    }
  }

  previousAlgorithmFilter(): void {
    const maxIndex = this.algorithmsImageArray.length - 1;
    if (this.currentOutputIndex === maxIndex) {
      return;
    }
    this.currentOutputIndex++;
  }

  nextAlgorithmFilter(): void {
    const minIndex = 0;
    if (this.currentOutputIndex === minIndex) {
      return;
    }
    this.currentOutputIndex--;
  }
  previousReferenceFilter(): void { }

  nextReferenceFilter(): void { }

  /**
   *
   * @param src Image path
   */
  openImageInModal(src): void {
    this.imagePreviewModal.nativeElement.style.display = 'block';
    this.previewImage = src;
  }

  closePreviewModal(modalElement: HTMLElement): void {
    modalElement.style.display = 'none';
    this.previewImage = '';
  }

  setLoadingImages(data): void {
    // if (data.length > 1) {
    //   this.nextDisabled = false;
    //   this.previousDisabled = false;
    // }
    // data.forEach((element, index) => {
    //   this.isImgLoaded[index] = false;
    // })
  }

  isImageLoaded(index): void {
    this.isImgLoaded[index] = true;
  }

  isOriginalImgLoaded(index): void {
    this.isOriginalImageLoaded[this.currentOutputIndex] = true;
  }

  isOutputImgLoaded(index): void {
    this.isOutputImageLoaded[index] = true;
  }

  openDialog(link: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      url: link,
    };

    // this.dialog.open(DialogContentComponent, dialogConfig);
  }

  removeFromDownloadList(id: number, name: string): void {
    const index = this.downloadImagesList.indexOf(name, 0);
    if (index > -1) {
      this.downloadImagesList.splice(index, 1);
    }
  }

  addToDownloadList(
    id: number,
    name: string,
    transactionId,
    index: number
  ): void {
    this.downloadImagesList.push(name);
    this.transactionId = transactionId;
  }

  openPreviewDialog(link: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      url: link,
    };

    // this.dialog.open(LightboxPreviewComponent, dialogConfig);
  }

  sendToMail(selectAll = false): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'modal-primary';
    dialogConfig.minWidth = '400px';
    dialogConfig.minHeight = '150px';

    const dialogRef = this.dialog.open(SendtomailDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.onSendMail(result, selectAll);
      }
    });
  }

  loadDCMFile(images: Images[]): void {
    const allowedTypes = ['jpeg', 'png', 'jpg'];
    images.forEach((image: Images, index) => {
      this.graphStatsData.push(this.gsService.getGraphData(image.stdev));

      const imageURLobj: any = image.image_url;
      for (const imageURL in imageURLobj) {
        if (imageURL) {
          const imageName = imageURLobj[imageURL].name.split('.');
          const imageExtension = imageName.pop();

          if (imageExtension === 'dcm') {
            this.isImagesDCM.push(true);

            setTimeout(() => {
              const element: HTMLElement = document.getElementById(
                'dicomImage' + index
              );
              cornerstone.enable(element);

              const imageUrl = 'wadouri:' + image.image_url;
              this.loadAndViewImage(imageUrl, index);
            });
          } else if (allowedTypes.includes(imageExtension)) {
            this.isImagesDCM.push(false);
          }
        }
      }
    });
  }

  openDCMDialog(value, index): any {
    if (dicomDataArray.length === 0) {
      return false;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      dicomData: { dcm: dicomDataArray[index].data, img: value },
    };

    // this.dialog.open(DicomInformationComponent, dialogConfig);
  }

  loadAndViewImage(imageId, index): any {
    // this.caseService.testDCM().subscribe(res => {
    //   this.getServerFile = res;
    // });
    const element = document.getElementById('dicomImage' + index);
    cornerstone.loadAndCacheImage(imageId).then(
      (image) => {
        const viewport = cornerstone.getDefaultViewportForImage(element, image);
        cornerstone.displayImage(element, image, viewport);
        // if(this.loaded === false) {
        //     cornerstoneTools.mouseInput.enable(element);
        //     cornerstoneTools.mouseWheelInput.enable(element);
        //     cornerstoneTools.wwwc.activate(element, 1); // ww/wc is the default tool for left mouse button
        //     cornerstoneTools.pan.activate(element, 2); // pan is the default tool for middle mouse button
        //     cornerstoneTools.zoom.activate(element, 4); // zoom is the default tool for right mouse button
        //     cornerstoneTools.zoomWheel.activate(element); // zoom is the default tool for middle mouse wheel

        //     cornerstoneTools.imageStats.enable(element);
        //     this.loaded = true;
        // }
        dicomDataArray[index] = image;

        function getPixelRepresentation(): any {
          const value = image.data.uint16('x00280103');
          if (value === undefined) {
            return;
          }
          return value + (value === 0 ? ' (unsigned)' : ' (signed)');
        }

        function getPlanarConfiguration(): any {
          const value = image.data.uint16('x00280006');
          if (value === undefined) {
            return;
          }
          return value + (value === 0 ? ' (pixel)' : ' (plane)');
        }
      },
      (err) => {
        this.toast.showError(
          'Image is currently unavailable. Please try again later',
          'Image not found!'
        );
        return false;
      }
    );
  }

  getAllImagesData(): void { }

  prepareToDownload(emails?: any, selectAll?: boolean): any {
    const dataSingle = this.caseData.images[this.imageIndex];
    const caseData = this.caseData;
    const dataArray = this.caseData.images;
    let download: DownloadTable;
    const fd = new FormData();

    if (emails && emails !== '') {
      const toEmail = emails
        .split(',')
        .map((email) => fd.append(`email[]`, email));
    }

    if (selectAll) {
      if (dataArray.length !== 0) {
        dataArray.forEach((data) => {
          download = {
            transaction_id: data.transaction_id,
            image_name: data.name,
            body_part: caseData.body_part?.name,
            case_type_id: caseData.case_type_id,
          };
          // set original
          download.original_image = true;
          download.xml = true;

          if (data.processed_images.length !== 0) {
            download.key = [];
            data.processed_images.forEach((processed) => {
              download.key.push(processed.key);
            });
          }

          fd.append(`images[]`, JSON.stringify(download));
        });
      }
    } else {
      download = {
        transaction_id: dataSingle.transaction_id,
        image_name: dataSingle.name,
        body_part: caseData.body_part?.name,
        case_type_id: caseData.case_type_id,
      };

      // set original
      if (this.setOriginaltoDownload) {
        download.original_image = true;
      }

      // set XML
      if (this.setXMLtoDownload) {
        download.xml = true;
      }

      // set key for images
      if (dataSingle.processed_images.length !== 0) {
        const processedArray = dataSingle.processed_images;
        download.key = [];
        processedArray.forEach((processed) => {
          download.key.push(processed.key);
        });
      }

      fd.append(`images[]`, JSON.stringify(download));
    }

    return { formData: fd, caseData };
  }
  /** On download selections */
  onDownload(selectAll = false): void {
    this.loadingDownload = true;
    const download = this.prepareToDownload(null, selectAll);
    const caseID = download.caseData.id;

    this.caseService.downloadSelected(download.formData).subscribe(
      (res: any) => {
        this.loadingDownload = false;
        this.downloadAllLink = res.link;
        window.open(res.link, 'download_window');
        this.toast.showSuccess(
          `Download of case (${ caseID }) was completed successfully. If you have any problem with downloading you can download manualy on <a href="${ res.link }" target="_blank" download>Link.</a>`,
          `Download finished`
        );
      },
      (err) => {
        this.loadingDownload = false;
        this.toast.showError(err, 'Zip not created!');
      }
    );
  }

  onSendMail(email, selectAll = false): void {
    this.loadingDownload = true;
    const download = this.prepareToDownload(email, selectAll);

    this.caseService.downloadSelected(download.formData).subscribe(
      (res: any) => {
        this.loadingDownload = false;
        this.toast.showSuccess('Please check your email', 'Mail sent.');
      },
      (err) => {
        this.loadingDownload = false;
        this.toast.showError(err, 'Mail not sent!');
      }
    );
  }

  onStartZoom(side: string, tooltip, state?: boolean): void {
    tooltip.show();
    if (side === 'output' && !state) {
      this.zoomOutputTooltip = 'Use scroll to adjust the zoom level';
    } else if (side === 'original' && !state) {
      this.zoomOriginalTooltip = 'Use scroll to adjust the zoom level';
    } else {
      this.zoomOriginalTooltip = 'Click to use magnifying glass';
      this.zoomOutputTooltip = 'Click to use magnifying glass';
    }
  }

  dumpFile(file): void {
    // let fileUrl = file.replace('wadouri:', '');
    // let newFile = new File(file, 'newdcm.dcm');

    const reader = new FileReader();
    reader.onload = (rfile: any) => {
      const arrayBuffer: any = reader.result;

      // Here we have the file data as an ArrayBuffer.  dicomParser requires as input a
      // Uint8Array so we create that here
      const byteArray = new Uint8Array(arrayBuffer);

      const kb = byteArray.length / 1024;
      const mb = kb / 1024;
      const byteStr = mb > 1 ? mb.toFixed(3) + ' MB' : kb.toFixed(0) + ' KB';
      document.getElementById('statusText').innerHTML =
        '<span class="glyphicon glyphicon-cog"></span>Status: Parsing ' +
        byteStr +
        ' bytes, please wait..';

      // set a short timeout to do the parse so the DOM has time to update itself with the above message
      setTimeout(() => {
        let dataSet;
        // Invoke the paresDicom function and get back a DataSet object with the contents
        try {
          const start = new Date().getTime();

          dataSet = dicomParser.parseDicom(byteArray);
          // Here we call dumpDataSet to update the DOM with the contents of the dataSet

          const end = new Date().getTime();
          const time = end - start;
          if (dataSet.warnings.length > 0) {
            /**
             * * Handle Error
             */
          } else {
            const pixelData = dataSet.elements.x7fe00010;
            if (pixelData) {
              // $('#status').removeClass('alert-warning alert-info alert-danger').addClass('alert-success');
              // $('#statusText').html('Status: Ready (file of size '+ byteStr + ' parsed in ' + time + 'ms)');
            } else {
              // $('#status').removeClass('alert-warning alert-info alert-danger').addClass('alert-success');
              // $('#statusText').html('Status: Ready - no pixel data found (file of size ' + byteStr + ' parsed in ' + time + 'ms)');
            }
          }
        } catch (err) {
          /**
           * * Handle Error
           */
        }
      }, 30);
    };

    reader.readAsArrayBuffer(file);
  }


}
