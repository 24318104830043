import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-newsfeed',
  templateUrl: './card-newsfeed.component.html',
  styleUrls: ['./card-newsfeed.component.scss']
})
export class CardNewsfeedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
