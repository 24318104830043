import { takeUntil } from 'rxjs/operators';
import { Subscription, ReplaySubject, Subject } from 'rxjs';
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ControlContainer,
  FormGroup,
  FormGroupDirective,
  Validators,
  FormControl,
} from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-register-account-type-form',
  templateUrl: './register-account-type-form.component.html',
  styleUrls: ['./register-account-type-form.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class RegisterAccountTypeFormComponent implements OnInit, OnDestroy {
  filteredClinic: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  protected onDestroy = new Subject<void>();

  clinicFilterCtrl: FormControl = new FormControl();

  subscription: Subscription[] = [];

  clinics: any[] = [];

  @Input() form: FormGroup;

  @Input() AccOwner: any;

  @Input() set clinicsList(value) {
    this.clinics = value;
    this.filteredClinic.next(value.slice());
  }

  @Output() hideBilling = new EventEmitter<boolean>(false);

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.subscription.push(
      this.form.get('type').valueChanges.subscribe((type) => {
        if (type === this.AccOwner.yes) {
          this.hideBilling.emit(false);
          this.form.get('clinic_id').disable();
          this.form.get('clinic').enable();
        } else if (type === this.AccOwner.no) {
          this.hideBilling.emit(true);
          this.form.get('clinic').disable();
          this.form.get('clinic_id').enable();
        }

        this.changeDetector.detectChanges();
      })
    );

    // listen for search field value changes CLIENTS
    this.subscription.push(
      this.clinicFilterCtrl.valueChanges
        .pipe(takeUntil(this.onDestroy))
        .subscribe(() => {
          this.filterClient();
        })
    );
  }

  onClear() {}

  // Clinic filter logic
  protected filterClient(): any {
    if (!this.clinics.length) {
      return;
    }

    // get the search keyword
    let search = this.clinicFilterCtrl.value;

    if (!search) {
      this.filteredClinic.next(this.clinics.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the clinic
    this.filteredClinic.next(
      this.clinics.filter(
        (clinic) =>
          clinic.name.toLowerCase().indexOf(search) > -1 ||
          clinic.email.toLowerCase().indexOf(search) > -1
      )
    );
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.forEach((subscription) => subscription.unsubscribe());
  }
}
