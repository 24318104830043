import { Role } from '@enums/role';
import { takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { ClinicFilters } from '@models/filters';
import { DropdownCommonService } from '@services/dropdown-common.service';
import { DashboardStatisticsService } from '@services/dashboard-statistics.service';
import { UtilService } from '@services/util.service';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'app-card-personal-data-weekly-monthly',
  templateUrl: './card-personal-data-weekly-monthly.component.html',
  styleUrls: ['./card-personal-data-weekly-monthly.component.scss'],
})
export class CardPersonalDataWeeklyMonthlyComponent implements OnInit {
  filters = {
    filterClinic: '',
    filterUser: '',
  };
  dropdownClinic: any[] = [];
  dropdownUser: any[] = [];

  /**
   * * user
   */
  currentUser;
  role = Role;

  clinicFilterCtrl: FormControl = new FormControl();
  userFilterCtrl: FormControl = new FormControl();
  protected onDestroy = new Subject<void>();
  filteredClinic: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  filteredUser: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  subscription: Subscription[] = [];

  @Input() data: any;

  constructor(
    private dcService: DropdownCommonService,
    private dashStatistics: DashboardStatisticsService,
    public utilService: UtilService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    /**
     * * Check & set before init data
     */
    this.currentUser = this.authService.currentUserValue.user;

    if (this.currentUser && this.currentUser.role !== this.role.ADMIN) {
      this.filters.filterClinic = this.currentUser?.clinic_id;
    }

    // listen for search field value changes client
    this.subscription.push(
      this.clinicFilterCtrl.valueChanges
        .pipe(takeUntil(this.onDestroy))
        .subscribe(() => {
          this.listFilterClinic();
        })
    );

    // listen for search field value changes client
    this.subscription.push(
      this.userFilterCtrl.valueChanges
        .pipe(takeUntil(this.onDestroy))
        .subscribe(() => {
          this.listFilterUser();
        })
    );

    // * OnSubject Emit Value
    this.subscription.push(
      this.dcService.clinicsDropdown$.subscribe(
        (resp) => {
          this.dropdownClinic = resp;
          this.listFilterClinic();
        },
        (error) => {
          this.dropdownClinic = [];
        }
      )
    );
    // * onUserSubject Emit value
    this.subscription.push(
      this.dcService.usersDropdown$.subscribe(
        (resp) => {
          this.dropdownUser = resp;
          this.listFilterUser();
        },
        (error) => {
          this.dropdownUser = [];
        }
      )
    );

    // * Load data
    if (!this.data) {
      this.dashStatistics.startLoading();

      this.subscription.push(
        this.dashStatistics.getStatistics().subscribe((res) => {
          this.dashStatistics.storeStatistics(res);
          this.dashStatistics.stopLoading();
        })
      );
    }
  }

  onChangeClinic(event) {
    const { value } = event;
    this.dcService.loadUsersByClinicIdDropdown(value);
    this.loadStatistics();
  }

  onChangeUser(event) {
    this.loadStatistics();
  }

  loadStatistics() {
    const filters = {};
    if (this.filters.filterClinic !== '') {
      Object.assign(filters, { clinic_id: this.filters.filterClinic });
    }
    if (this.filters.filterUser !== '') {
      Object.assign(filters, { user_id: this.filters.filterUser });
    }

    this.subscription.push(
      this.dashStatistics.getStatistics(filters).subscribe((res) => {
        this.dashStatistics.storeStatistics(res);
      })
    );
  }

  onClear(event, field) {
    // in case we have mat select field
    if (event) {
      event.stopPropagation();
    }

    if (field === 'filterClinic') {
      this.dropdownUser = [];
      this.listFilterUser();
    }

    this.filters[field] = '';
    this.loadStatistics();
  }

  // Clinic filter
  protected listFilterClinic(): any {
    if (!this.dropdownClinic) {
      return;
    }
    // get the search keyword
    let search = this.clinicFilterCtrl.value;

    if (!search) {
      this.filteredClinic.next(this.dropdownClinic.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the client
    this.filteredClinic.next(
      this.dropdownClinic.filter(
        (client) => client.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  // User filter
  protected listFilterUser(): any {
    if (!this.dropdownUser) {
      return;
    }
    // get the search keyword
    let search = this.userFilterCtrl.value;

    if (!search) {
      this.filteredUser.next(this.dropdownUser.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the client
    this.filteredUser.next(
      this.dropdownUser.filter(
        (client) => client.name.toLowerCase().indexOf(search) > -1
      )
    );
  }
}
