import { AoiTypeToolsComponent } from './aoi-type-tools.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AoiTypeToolsRoutingModule } from './aoi-type-tools-routing.module';
import { MaterialModule } from '@modules/material/material.module';


@NgModule({
  declarations: [
    AoiTypeToolsComponent
  ],
  imports: [
    CommonModule,
    AoiTypeToolsRoutingModule,
    MaterialModule,
  ],
  exports: [
    AoiTypeToolsComponent
  ]
})
export class AoiTypeToolsModule { }
