<div class="billing-form">
  <div class="general">
    <h2>Billing address</h2>

    <mat-form-field appearance="outline">
      <mat-label>First name</mat-label>
      <input
        matInput
        value=""
        formControlName="billingFirstName"
        maxlength="60"
        name="billingFirstName"
      />
      <mat-error>
        <app-field-errors [formField]="form.get('billingFirstName')">
        </app-field-errors>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Last name</mat-label>
      <input
        matInput
        value=""
        formControlName="billingLastName"
        maxlength="60"
        name="billingLastName"
      />
      <mat-error>
        <app-field-errors [formField]="form.get('billingLastName')">
        </app-field-errors>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Address</mat-label>
      <input
        matInput
        value=""
        formControlName="billingAddress"
        maxlength="60"
        name="billingAddress"
      />
      <mat-error>
        <app-field-errors [formField]="form.get('billingAddress')">
        </app-field-errors>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="card-details">
    <h2>Debit card info</h2>

    <mat-form-field appearance="outline">
      <mat-label>Card number</mat-label>
      <input
        matInput
        type="text"
        formControlName="billingCardNumber"
        name="billingCardNumber"
        [mask]="activeMask"
      />
      <mat-error>
        <app-field-errors [formField]="form.get('billingCardNumber')">
        </app-field-errors>
      </mat-error>
    </mat-form-field>

    <div class="billing-card-group">
      <mat-form-field appearance="outline">
        <mat-label>MM / YY</mat-label>
        <input
          matInput
          value=""
          formControlName="billingExpireDate"
          maxlength="60"
          name="billingExpireDate"
          mask="M0/00"
        />
        <mat-error>
          <app-field-errors [formField]="form.get('billingExpireDate')">
          </app-field-errors>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>CVV</mat-label>
        <input
          matInput
          value=""
          formControlName="billingCVV"
          name="billingCVV"
          [mask]="activeCCVMask"
        />
        <mat-error>
          <app-field-errors [formField]="form.get('billingCVV')">
          </app-field-errors>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
