import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MaterialModule } from '@modules/material/material.module';
import { CasesSubmitDialogComponent } from './cases-submit-dialog.component';



@NgModule({
  declarations: [
    CasesSubmitDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MaterialModule,
  ],
  exports: [
    CasesSubmitDialogComponent
  ]
})
export class CasesSubmitDialogModule { }
