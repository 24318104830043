<div class="inner-header card p-2 mb-2">
  <div class="inner-header-actions">
    <div class="inner-header-title d-flex align-items-center mb-1">
      <div class="page-icon"><span class="petview-training"></span></div>
      <h3>Audit Logs</h3>
    </div>
  </div>
  <!-- <mat-divider></mat-divider> -->
  <!-- <div class="inner-header-filters">

    <div class="group mt-2">
      <div class="mw-75">
        <mat-form-field appearance="outline" class="full-width mt-0 pb-0">
          <mat-label>Search</mat-label>
          <span matPrefix>
            <mat-icon>search</mat-icon>
          </span>
          <input matInput placeholder="Search" [(ngModel)]="filterSearch">
        </mat-form-field>
      </div>

      <div class="col-5 d-flex align-items-center min-width-auto">
        <button mat-button (click)="accordion.toggle()" class="my-outline my-button ml-1">Filter <span
            class="petview-filter"></span></button>

        <button mat-raised-button type="submit" color="primary" (click)="onSearch()" class="my-button ml-1">Search <span
            class="petview-search"></span></button>
      </div>
    </div>

    <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0">

        <div class="group align-items-start">
          <div class="mw-75">
            <div class="group_row">
              <code>Empty</code>
            </div>

          </div>
          <div class="col-5">
            <button mat-button (click)="resetFilters()" class="my-link my-button ml-1">Reset
              filter</button>
          </div>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div> -->
</div>

<div id="audit-logs" class="relative">
  <ngx-spinner bgColor="red" name="table">
    <p style="font-size: 20px; color: #000">Loading...</p>
  </ngx-spinner>

  <table mat-table [dataSource]="dataSource" matSort>
    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Audit ID</th>
      <td mat-cell *matCellDef="let row">{{ row.id }}</td>
    </ng-container>

    <!-- Timestamp Column -->
    <ng-container matColumnDef="timestamp">
      <th mat-header-cell *matHeaderCellDef>Timestamp</th>
      <td mat-cell *matCellDef="let row">
        {{ row.created_at | date: "medium" }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="event_type">
      <th mat-header-cell *matHeaderCellDef>Event type</th>
      <td mat-cell *matCellDef="let row">{{ row.event }}</td>
    </ng-container>

    <!-- Subject Column -->
    <ng-container matColumnDef="subject">
      <th mat-header-cell *matHeaderCellDef>Subject</th>
      <td mat-cell *matCellDef="let row">{{ row.auditable_type }}</td>
    </ng-container>

    <!-- URL Column -->
    <ng-container matColumnDef="url">
      <th mat-header-cell *matHeaderCellDef>URL</th>
      <td mat-cell *matCellDef="let row">
        <span matTooltip="{{ row.url }}">
          {{
            row.url.length > 30
              ? (row.url | slice: 0:15) + "..." + (row.url | slice: 27)
              : row.url
          }}</span
        >
      </td>
    </ng-container>

    <!-- User Column -->
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef>User</th>
      <td mat-cell *matCellDef="let row">{{ row.user?.name }}</td>
    </ng-container>

    <!-- User Column -->
    <ng-container matColumnDef="user_email">
      <th mat-header-cell *matHeaderCellDef>User email</th>
      <td mat-cell *matCellDef="let row">{{ row.user?.email }}</td>
    </ng-container>

    <!-- Browser Column -->
    <ng-container matColumnDef="browser">
      <th mat-header-cell *matHeaderCellDef>Browser</th>
      <td mat-cell *matCellDef="let row">
        <span matTooltip="{{ row.user_agent }}">
          {{ (row?.user_agent | slice: 0:12) + "..." }}
        </span>
      </td>
    </ng-container>

    <!-- IP Address Column -->
    <ng-container matColumnDef="ip">
      <th mat-header-cell *matHeaderCellDef>IP</th>
      <td mat-cell *matCellDef="let row">{{ row.ip_address }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell class="actions-cell-audits" *matCellDef="let row">
        <button
          matTooltip="View details"
          matTooltipPosition="above"
          (click)="onDetailsView(row)"
          type="button"
          rel="tooltip"
          mat-icon-button
          color="primary"
        >
          <span class="petview-search"></span>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [length]="total" [pageSize]="pageSize"></mat-paginator>
</div>
