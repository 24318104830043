import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  constructor(public spinner: NgxSpinnerService) {}

  showSpinner(name: string): void {
    this.spinner.show(name, {
      bdColor: 'rgba(255,255,255,.84)',
      size: 'small',
      color: 'mat-color($petview-primary)',
      type: 'square-jelly-box',
      fullScreen: false,
    });
  }

  hideSpinner(name: string): void {
    this.spinner.hide(name);
  }
}
