import { AoiType } from '@models/AoiType';
import { BehaviorSubject } from 'rxjs';
import { Injectable, ElementRef, QueryList } from '@angular/core';
import { fabric } from 'fabric'; 
import { FabricPolylineConfig } from '@models/fabricConfig';
import { SCInstance } from '@models/shapeObject';
import { Point } from '@models/aoi';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawingHelpersService {
  canvas: any;
  isDown = false;
  isDragging = false;
  isZoomActive = false;
  selectedShape = '';
  setAsDirty = false;
  lastPosX = 0;
  lastPosY = 0;
  origX: any = 0;
  origY: any = 0;
  borderValue: any = 3;
  rectangle: any;
  polygonMake: any = null;
  polygonPoints: any = [];
  lines: any = [];
  lineCounter: any = 0;
  polyVarX: any = 0;
  polyVarY: any = 0;
  shapeObject: SCInstance = new SCInstance();
  selectedShapeCopy: string = '';
  maxNumberOfShapes: number = 1;
  showApprovedTools: boolean = true;
  toolButtonsSelection: QueryList<ElementRef> = null;

  borderLimit: any = new BehaviorSubject<any>(false);
  borderLimitState = this.borderLimit.asObservable();
  onSelectTool: any = new Subject<any>();

  constructor() { }

  reset() {
    this.canvas = null;
    this.isDown = false;
    this.isDragging = false;
    this.isZoomActive = false;
    this.selectedShape = '';
    this.setAsDirty = false;
    this.lastPosX = 0;
    this.lastPosY = 0;
    this.origX = 0;
    this.origY = 0;
    this.borderValue = 3;
    this.rectangle = null;
    this.polygonMake = null;
    this.polygonPoints = [];
    this.lines = [];
    this.lineCounter = 0;
    this.polyVarX = 0;
    this.polyVarY = 0;
    this.shapeObject = new SCInstance();
    this.selectedShapeCopy = '';
    this.maxNumberOfShapes = 1;
    this.showApprovedTools = true;
    this.toolButtonsSelection = null;
  }

  setSelectTool(shape) {
    this.onSelectTool.next(shape);
  }

  setMaxNumberOfShapes(index): void {
    switch (index) {
      case 0:
      case 1:
      case 3:
        this.maxNumberOfShapes = 1;
        break;
      case 2:
        this.maxNumberOfShapes = 4;
        break;
      default:
        this.maxNumberOfShapes = 1;
    }
  }

  makeRoof(roofPoints): any {
    const left = this.findLeftPaddingForRoof(roofPoints);
    const top = this.findTopPaddingForRoof(roofPoints);

    roofPoints.push(new Point(roofPoints[0].x, roofPoints[0].y));

    const roof = new fabric.Polyline(roofPoints, new FabricPolylineConfig(this.borderValue));
    roof.set({
      left,
      top,
    });
    return roof;
  }

  doubleClick(): void {
    this.lines.forEach((value, index, ar) => {
      this.canvas.remove(value);
    });
    // canvas.remove(lines[lineCounter - 1]);
    try {
      this.polygonMake = this.makeRoof(this.polygonPoints);
      this.canvas.add(this.polygonMake);
    } catch (e) { }

    // clear arrays
    this.polygonPoints = [];
    this.lines = [];
    this.lineCounter = 0;

    this.selectedShape = '';
    this.selectedShapeCopy = '';

    this.canvas.off('mouse:down');
    this.canvas.off('mouse:move');
    this.canvas.off('mouse:up');

    this.canvas.forEachObject((obj) => {
      obj.selectable = true;
    });

    this.canvas.requestRenderAll();
  }

  /**
   * 
   * @param shapeObjectData shape data
   * @returns polygon coords
   * @description calculation for new polygon shape object
   */
  polygonShapeCalcNew(shapeObjectData): any {
    const newShapeObject: any = {
      dimensions: [],
      shape: ''
    };
    newShapeObject.dimensions = [];
    const matrix = shapeObjectData.calcTransformMatrix();
    shapeObjectData.get('points')
      .map((p) => {
        return new fabric.Point(
          p.x - shapeObjectData.pathOffset.x,
          p.y - shapeObjectData.pathOffset.y);
      })
      .map((p, index) => {
        const keyX = 'x' + index;
        const keyY = 'y' + index;
        const objectX = {};
        const objectY = {};
        objectX[keyX] = fabric.util.transformPoint(p, matrix).x;
        newShapeObject.dimensions.push(objectX);
        objectY[keyY] = fabric.util.transformPoint(p, matrix).y;
        newShapeObject.dimensions.push(objectY);
      });
    newShapeObject.dimensions.splice(-4, 4);
    newShapeObject.shape = 'polygon';
  
    return newShapeObject;
  }

  /**
   * 
   * @param shapeObjectData shape data
   * @returns coords for circle shape object
   * @description calculation coords for new circle shape object
   */
  circleShapeCalcNew(shapeObjectData): any {
    const newShapeObject: any = {
      dimensions: [],
      shape: ''
    };
  
    newShapeObject.dimensions = [];
    newShapeObject.dimensions.push({ x0: shapeObjectData.left });
    newShapeObject.dimensions.push({ y0: shapeObjectData.top });
    newShapeObject.dimensions.push({ r: shapeObjectData.getRadiusX() });
    newShapeObject.shape = 'circle';
  
    return newShapeObject;
  }
  
  /**
   * 
   * @param shapeObjectData shape data
   * @returns coords for rectangle shape object
   * @description calculation coords for new rectangle shape
   */
  rectangleShapeCalcNew(shapeObjectData): any {
    const newShapeObject: any = {
      dimensions: [],
      shape: ''
    };
    newShapeObject.dimensions = [];
    newShapeObject.dimensions = [
      { x0: shapeObjectData.aCoords.tl.x },
      { y0: shapeObjectData.aCoords.tl.y },
      { x1: shapeObjectData.aCoords.tr.x },
      { y1: shapeObjectData.aCoords.tr.y },
      { x2: shapeObjectData.aCoords.bl.x },
      { y2: shapeObjectData.aCoords.bl.y },
      { x3: shapeObjectData.aCoords.br.x },
      { y3: shapeObjectData.aCoords.br.y }
    ];
    newShapeObject.shape = 'rectangle';
    return newShapeObject;
  }

  findLeftPaddingForRoof(roofPoints): any {
    let result = 999999;
    for (let i = 0; i < this.lineCounter; i++) {
      if (roofPoints[i].x < result) {
        result = roofPoints[i].x;
      }
    }
    return Math.abs(result);
  }

  /**
   * 
   * @param event Canvas Event
   * @returns false
   * @description checking shape limit position inside canvas
   */
  checkBorderLimit(event) {
    /** checking logic */
    if (!this.canvas.backgroundImage) {
      return;
    }
  
    if (event.target.aCoords.br.x > this.canvas.backgroundImage.aCoords.br.x || event.target.aCoords.br.y > this.canvas.backgroundImage.aCoords.br.y) {
      this.borderLimit.next(true);
      return;
    }
  
    for (let o in event.target.aCoords) {
      if (event.target.aCoords[o].x < 0 || event.target.aCoords[o].y < 0) {
        this.borderLimit.next(true);
        return;
      }
    }
  
    this.borderLimit.next(false);
  }

  findTopPaddingForRoof(roofPoints): any {
    let result = 999999;
    for (let f = 0; f < this.lineCounter; f++) {
      if (roofPoints[f].y < result) {
        result = roofPoints[f].y;
      }
    }
    return Math.abs(result);
  }

  /**
   *
   * @param range if parse "all" delete all objects on canvas
   * @param preventConflict prevent conflict with setAsDirty state
   */
   deleteSelection(range: string = '', preventConflict = true): void {
    if (range === 'all') {
      this.canvas.remove(...this.canvas.getObjects());
    } else {
      this.canvas.remove(this.canvas.getActiveObject());
    }

    if (preventConflict) {
      this.setAsDirty = true;
    }
  }

  /**
   * @description clear canvas coords
   */
  clearCoords(): void {
    this.lines.forEach((value, index, ar) => {
      this.canvas.remove(value);
    });

    // clear arrays
    this.polygonPoints = [];
    this.lines = [];
    this.lineCounter = 0;

    this.selectedShape = '';
    this.selectedShapeCopy = '';

    this.canvas.off('mouse:down');
    this.canvas.off('mouse:move');
    this.canvas.off('mouse:up');
    this.canvas.off('mouse:wheel');
  }

   /**
   *
   * @param element - HTML Element #element dekorator
   */
    setHighlightSelection(shape: string): void {
      this.canvas.off('mouse:wheel');
      // removeHighlightSelection();
      // this.selectedShape.setValue(shape);
      // el.classList.add('active');
    }
  
    /**
     * @description remove all hightlighted selected tools
     */
    removeHighlightSelection(): void {
      if(this.toolButtonsSelection) {
        this.toolButtonsSelection.forEach((button: any) => {
          button.nativeElement.classList.remove('active');
        })
      }
    }

    /**
   * 
   * @param preventConflitSetAsDitry boolean flag
   */
    removeAllAOIMarker(preventConflitSetAsDitry = true): void {
      this.clearCoords();
      this.deleteSelection('all', preventConflitSetAsDitry);
      this.removeHighlightSelection();
    }

    /**
     * 
     * @param array Array with AOI Type objects
     * @returns casted array in AoiType array
     */
    convertToAOITypeDataObject(array: any[]) {
      return array.map((aoiObject: AoiType) => {
        return new AoiType(aoiObject);
      });
    }
  
}
