import { DrawingImageListComponent } from './drawing-image-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MaterialModule } from '@modules/material/material.module';



@NgModule({
  declarations: [
    DrawingImageListComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatTabsModule,
    MatSliderModule,
    MatTooltipModule,
    MaterialModule,
  ],
  exports: [
    DrawingImageListComponent
  ]
})
export class DrawingImageListModule { }
