import { UtilClass } from "./UtilClass";

export class AoiType {
  id?: number;
  label?: string;
  data?: any;
  isActive?: boolean;
  hidden?: boolean;
  valid?: boolean;
  thumbnail?: any;
  description?: string;

  constructor(obj?: any) {
    if(!UtilClass.isNullOrUndefined(obj)) {
      Object.assign(this, obj);
    }
  }
}