<div
  class="main-img-placeholder"
  *ngIf="referenceImagesArray.length === 0"
>
  <span class="material-icons-outlined"> image_not_supported </span>
  <p><strong>Reference image doesn't exist</strong></p>
</div>

<app-image-viewer
  *ngIf="referenceImagesArray.length !== 0"
  [images]="referenceImagesArray"
  [idContainer]="'reference'"
  [loadOnInit]="true"
  [imageTypeName]="helperService.transformAlgorithm(activeAlgorithmReference) + ', ' + (referenceCaseName | titlecase)"
></app-image-viewer>
