import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService {

  constructor(
    public router: Router,
    public auth: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    // decode the token to get its payload
    if (this.auth.isAuthenticated(route)) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
