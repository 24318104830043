<div class="general">
  <div
    class="general-user-card dash-link"
    style="background-image: url('../../../assets/images/shapre.png')"
  >
    <div class="d-flex justify-content-between">
      <h3>Welcome, {{ user?.first_name }}</h3>
      <div>
        <span class="light-gray">{{ date | date: "mediumDate" }}</span><br />
        <span class="light-gray">
          {{ date | date: "shortTime" }}
          <small>(local)</small>
        </span>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-end">
      <div class="d-flex">
        <div class="general-user-card-group mr-1">
          <h2
            class="mb-0 infinityIcon"
            *ngIf="
              user?.plan?.name === pricingPlanEnum.PER_CASE ||
              user?.role === role.TRIAL ||
              user?.role === role.ADMIN
            "
          >
            <span class="material-icons-outlined"> all_inclusive </span>
          </h2>

          <div *ngIf="
              user?.plan?.name !== pricingPlanEnum.PER_CASE &&
              user?.role !== role.ADMIN &&
              user?.role !== role.TRIAL
            ">
            <h2 class="mb-0">
              {{ user?.availableCases }}
            </h2>
          </div>
          <span *ngIf="user?.availableCases === 1">Case left</span>
          <span *ngIf="user?.availableCases !== 1">Cases left</span>
        </div>

        <div
          class="general-user-card-group"
          *ngIf="user?.role !== role.ADMIN && user?.role !== role.TRIAL"
        >
          <h2 class="mb-0">{{ user?.plan?.name }}</h2>
          <span>Pricing Plan</span>
        </div>

        <div
          class="general-user-card-group"
          *ngIf="user?.role === role.TRIAL"
        >
          <h2 class="mb-0">{{ user?.trialLeftDays }}</h2>
          <span>Trial days left</span>
        </div>
      </div>

      <a
        [routerLink]="['/profile/plan/edit']"
        *ngIf="user?.role !== role.TRIAL && user?.role !== role.ADMIN"
      >Change plan</a>
    </div>
  </div>
  <ul class="general-menu">
    <li
      *ngIf="user.role !== role.WOODY_BREAST_USER"
      class="dash-link br-15"
    >
      <a
        [routerLink]="['/cases/quick']"
        class=""
      >
        <div class="d-flex align-items-center justify-content-between dash-link-content">
          <span>Process image</span>
          <span
            class="material-icons-outlined"
            (click)="openMoreDetails(3, 'Process image', $event)"
          >
            info
          </span>
          <span class="petview-view_data dash-icons"></span>
        </div>
      </a>
    </li>
    <li
      *ngIf="user.role !== role.WOODY_BREAST_USER"
      class="dash-link br-15"
    >
      <a [routerLink]="['/cases/normal']">
        <div class="d-flex align-items-center justify-content-between dash-link-content">
          <span>Process Image - with Client & Patient Info</span>
          <span
            class="material-icons-outlined"
            (click)="openMoreDetails(1, 'Process Image - with Client & Patient Info', $event)"
          >
            info
          </span>
          <span class="petview-create_new dash-icons"></span>
        </div>
      </a>
    </li>
    <li
      *ngIf="user.role !== role.WOODY_BREAST_USER"
      class="dash-link br-15"
    >
      <a [routerLink]="['/cases/no-processing']">
        <div class="d-flex align-items-center justify-content-between dash-link-content">
          <span>Upload Images for Research</span>
          <span
            class="material-icons-outlined"
            (click)="openMoreDetails(2, 'Upload Images for Research', $event)"
          >
            info
          </span>
          <span class="petview-upload_data dash-icons"></span>
        </div>
      </a>
    </li>
    <!-- <li
      *ngIf="user.role === role.WOODY_BREAST_USER || user.role === role.ADMIN"
      class="dash-link br-15"
    >
      <a [routerLink]="['/cases/woody-breast']">
        <div
          class="
            d-flex
            align-items-center
            justify-content-between
            dash-link-content
          "
        >
          <span>Woody Breast</span>
          <span class="petview-upload_data dash-icons"></span>
        </div>
      </a>
    </li> -->
  </ul>
</div>
