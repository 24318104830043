<div
  (mouseover)="setActive()"
  (mouseout)="setActive(null)"
>
  <div
    id="{{idContainer}}"
    class="image-gallery-2"
  >
    <div class="image-container"></div>
    <div class="inline-icon">
      <div
        *ngIf="infoBtn"
        class="view-image-information view-image-header-item"
        (click)="openInformation($event)"
      >
        <span>Information</span>
      </div>

      <div class="image-viewer-image-type">{{ indexImagemAtual }} / {{totalImagens}}</div>

      <div class="image-viewer-image-type">{{imageTypeName}}</div>

      <!-- <div>
      <a
        class="image-viewer-tooltip"
        (click)="showPDFOnly()"
        *ngIf="showPDFOnlyOption"
      >
        <span
          *ngIf="enableTooltip"
          class="tooltiptext filterTooltip"
        >
          <span>{{showPDFOnlyLabel}}:</span>
          <i class="material-icons">{{showOnlyPDF ? 'check':'close'}}</i>
        </span>
        <i class="material-icons footer-icon">picture_as_pdf</i>
      </a>
    </div> -->

      <div
        *ngIf="showOptions"
        class="options-image-viewer"
      >
        <a
          class="image-viewer-tooltip"
          *ngIf="zoomInButton"
          (click)="zoomIn()"
          (mouseover)="atualizarCorHoverIn($event)"
          (mouseout)="atualizarCorHoverOut($event)"
        >
          <span
            [matTooltip]="zoomInTooltipLabel"
            *ngIf="enableTooltip"
            class="material-icons-outlined view-image-header-icon"
          >
            zoom_in
          </span>
        </a>
        <a
          class="image-viewer-tooltip"
          *ngIf="zoomOutButton"
          (click)="zoomOut()"
          (mouseover)="atualizarCorHoverIn($event)"
          (mouseout)="atualizarCorHoverOut($event)"
        >
          <span
            [matTooltip]="zoomOutTooltipLabel"
            *ngIf="enableTooltip"
            class="material-icons-outlined view-image-header-icon"
          >
            zoom_out
          </span>
        </a>

        <a
          class="image-viewer-tooltip"
          *ngIf="rotate"
          (click)="rotacionarDireita()"
          (mouseover)="atualizarCorHoverIn($event)"
          (mouseout)="atualizarCorHoverOut($event)"
        >
          <span
            [matTooltip]="rotateRightTooltipLabel"
            *ngIf="enableTooltip"
            class="material-icons-outlined view-image-header-icon"
          >
            rotate_right
          </span>
        </a>
        <a
          class="image-viewer-tooltip"
          *ngIf="rotate"
          (click)="rotacionarEsquerda()"
          (mouseover)="atualizarCorHoverIn($event)"
          (mouseout)="atualizarCorHoverOut($event)"
        >
          <span
            [matTooltip]="rotateLeftTooltipLabel"
            *ngIf="enableTooltip"
            class="material-icons-outlined view-image-header-icon"
          >
            rotate_left
          </span>
        </a>
        <a
          class="image-viewer-tooltip"
          *ngIf="resetZoom"
          (click)="resetarZoom()"
          (mouseover)="atualizarCorHoverIn($event)"
          (mouseout)="atualizarCorHoverOut($event)"
        >
          <span
            [matTooltip]="resetZoomTooltipLabel"
            *ngIf="enableTooltip"
            class="material-icons-outlined view-image-header-icon"
          >
            zoom_in_map
          </span>
        </a>
        <a
          class="image-viewer-tooltip"
          *ngIf="fullscreen"
          (click)="mostrarFullscreen()"
          (mouseover)="atualizarCorHoverIn($event)"
          (mouseout)="atualizarCorHoverOut($event)"
        >
          <span
            [matTooltip]="fullscreenTooltipLabel"
            *ngIf="enableTooltip"
            class="material-icons-outlined view-image-header-icon"
          >
            open_in_full
          </span>
        </a>
        <a
          class="image-viewer-tooltip"
          target="_blank"
          href="{{stringDownloadImagem}}"
          download="{{defaultDownloadName}} {{indexImagemAtual}}.png"
          *ngIf="download"
          (mouseover)="atualizarCorHoverIn($event)"
          (mouseout)="atualizarCorHoverOut($event)"
        >
          <span
            [matTooltip]="isURlImagem() ? openInNewTabTooltipLabel :
          downloadTooltipLabel"
            *ngIf="enableTooltip"
            class="material-icons-outlined view-image-header-icon"
          >
            {{isURlImagem() ?
            'open_in_browser' : 'file_download'}}
          </span>
        </a>
      </div>
    </div>
    <i
      [ngClass]="{ 'disabled': indexImagemAtual === 1 }"
      *ngIf="images.length > 1"
      class="material-icons prev"
      (click)="imagemAnterior()"
    >keyboard_arrow_left</i>
    <i
      [ngClass]="{ 'disabled': indexImagemAtual === totalImagens }"
      *ngIf="images.length > 1"
      class="material-icons next"
      (click)="proximaImagem()"
    >keyboard_arrow_right</i>
    <!-- <div
    class="footer-info"
  >
    <span id="current"></span>/<span class="total"></span>
  </div> -->
  </div>
</div>
