import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FloatingPopupService {
  $popupSetup = new BehaviorSubject({ event: null, data: null });
  pupupSetupSubject = this.$popupSetup.asObservable();

  constructor() {}

  // createPopup() {}

  show(event) {
    this.setPopupSetup = event;
  }

  hide() {}

  get popupSubject(): Observable<any> {
    return this.pupupSetupSubject as Observable<any>;
  }

  set setPopupSetup(value) {
    this.$popupSetup.next(value);
  }
}
