import {
  Component,
  Input,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent implements OnInit, OnChanges {
  flags = [];

  minNumberOfChars: number = 6;

  labels = [
    'contains at least one lower character',
    'contains at least one upper character',
    'contains at least one digit character',
    // 'contains at least one special character',
    'contains at least six characters',
  ];

  @Input() password: string;

  @Output() passwordState = new EventEmitter(false);

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.flags = this.checkStrength(this.password);
    this.passwordState.emit(this.flags);
  }

  checkStrength(p: string) {
    const regex = /[$-/:-?{-~!"^_@#`\[\]]/g; // regex for spec chars
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    // const symbols = regex.test(p);
    const min = p.length > this.minNumberOfChars;

    const flags = [lowerLetters, upperLetters, numbers, min];

    return flags;
  }
}
