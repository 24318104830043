import { BehaviorSubject } from 'rxjs';
import { CanvasObject } from './../models/CanvasHelperClass';
import { Injectable } from '@angular/core';
import { fabric } from 'fabric';
import { DrawingHelpersService } from './drawing-helpers.service';
import { AOIShapeCoords } from '@models/AOIShapeCoords';

@Injectable({
  providedIn: 'root'
})
export class CanvasHelperService {
  canvasObject: CanvasObject = new CanvasObject();
  imageChangedEvent: any = '';
  AOICoordinates: any = new BehaviorSubject<any>(null);

  constructor(
    private drawingHelper: DrawingHelpersService
  ) {}

  /**
   * @description render image in canvas
   */
  renderImageInCanvas(): void {
    fabric.Image.fromURL(this.imageChangedEvent, (img) => {
      img.set({ left: 0, top: 0 });

      this.canvasObject.img.height = img.height;
      this.canvasObject.img.width = img.width;
      this.canvasObject.backgroundImage = img;

      const canvasAspect = this.canvasObject.container.elementWidth / this.canvasObject.container.elementHeight;
      const imageAspect = img.width / img.height;


      this.drawingHelper.canvas.setWidth(this.canvasObject.container.elementWidth);
      this.drawingHelper.canvas.setHeight(this.canvasObject.container.elementHeight);
      if (canvasAspect > imageAspect) {
        img.scaleToHeight(this.canvasObject.container.elementHeight);
      } else {
        img.scaleToWidth(this.canvasObject.container.elementWidth);
      }

      this.drawingHelper.canvas.setBackgroundImage(img, this.drawingHelper.canvas.renderAll.bind(this.drawingHelper.canvas), {
        // scaleX: 1,
        // scaleY: 1
        // scaleX: canvas.width / img.width,
        // scaleY: canvas.height / img.height
      });

      const scaleX = this.drawingHelper.canvas.backgroundImage.width * this.drawingHelper.canvas.backgroundImage.scaleX / this.canvasObject.backgroundImage.width;
      const scaleY = this.drawingHelper.canvas.backgroundImage.height * this.drawingHelper.canvas.backgroundImage.scaleY / this.canvasObject.backgroundImage.height;

      this.canvasObject.dimensions.width = this.drawingHelper.canvas.width; // sus?
      this.canvasObject.dimensions.height = this.drawingHelper.canvas.height; // sus?

      var objects = this.drawingHelper.canvas.getObjects();
      for (var i in objects) {
        objects[i].scaleX = 1;
        objects[i].scaleY = 1;
        objects[i].left = objects[i].left * scaleX;
        objects[i].top = objects[i].top * scaleY;
        objects[i].setCoords();
      }
      this.drawingHelper.canvas.requestRenderAll();
      this.drawingHelper.canvas.calcOffset();
    });
  }

  /**
   * 
   * @param event Canvas event for objected
   * @description function for checking border limit inside canvas
   */
  canvasModified(event): any {
    const obj = event.target;
    const aCoords = obj.aCoords;

    this.drawingHelper.shapeObject.setData(obj);
    this.drawingHelper.shapeObject.setShape(obj.get('type'));
    /**
     * @description setovanje granica za pomeranje AOI shape u okviru AOI editora // trenutno ne radi
     */
    const topLeft = new AOIShapeCoords(aCoords.tl.x, aCoords.tl.y);
    const topRight = new AOIShapeCoords(aCoords.tr.x, aCoords.tr.y);
    const bottomLeft = new AOIShapeCoords(aCoords.bl.x, aCoords.bl.y);
    const bottomRight = new AOIShapeCoords(aCoords.br.x, aCoords.br.y);

    this.AOICoordinates.next([
      topLeft,
      topRight,
      bottomLeft,
      bottomRight
    ]);

    // check if object is out of bounds
    this.drawingHelper.checkBorderLimit(event);
    this.drawingHelper.setAsDirty = true;
  }

  /**
   * 
   * @param event Shape Object
   * @description creating shape object in canvas
   */
  objectCreated(event): void {
    const obj = event.target;
    /**
     * Remove hightlight selection after shape object is created
     */
    this.drawingHelper.removeHighlightSelection();    
    /**
     * Set shape object data and dirty state
     */
    this.drawingHelper.shapeObject.setActive(true);
    this.drawingHelper.shapeObject.setData(obj);
    this.drawingHelper.setAsDirty = true;

    /** end checking logic */
    this.drawingHelper.checkBorderLimit(event);
  }

  /**
   * @param newWidth canvas Width
   * @param newHeight canvas Height
   * @decription canvas resolution change
   */
  GetCanvasAtResoution(newWidth, newHeight) {
    if (this.drawingHelper.canvas.width != newWidth) {
      const scaleMultiplierX = newWidth / this.drawingHelper.canvas.width;
      const scaleMultiplierY = newHeight / this.drawingHelper.canvas.height;

      const imgCanvasWidth = this.canvasObject.backgroundImage.scaleX * newWidth;
      const imgCanvasHeight = this.canvasObject.backgroundImage.scaleY * newHeight;

      const scaleX = newWidth / imgCanvasWidth;
      const scaleY = newHeight / imgCanvasHeight;

      var objects = this.drawingHelper.canvas.getObjects();
      for (var i in objects) {
        objects[i].scaleX = objects[i].scaleX * scaleX;
        objects[i].scaleY = objects[i].scaleY * scaleY;
        objects[i].left = objects[i].left * scaleX;
        objects[i].top = objects[i].top * scaleY;
        objects[i].setCoords();
      }

      this.drawingHelper.canvas.discardActiveObject();
      this.drawingHelper.canvas.setWidth(this.drawingHelper.canvas.getWidth() * scaleMultiplierX);
      this.drawingHelper.canvas.setHeight(this.drawingHelper.canvas.getHeight() * scaleMultiplierY);

      var obj = this.drawingHelper.canvas.backgroundImage;
      if (obj) {
        this.canvasObject.backgroundImage.scaleX = 1;
        this.canvasObject.backgroundImage.scaleY = 1;
      }

      this.drawingHelper.canvas.requestRenderAll();
      this.drawingHelper.canvas.calcOffset();
    }
  }

}
