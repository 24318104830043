import { AuditsService } from './../_services/audits.service';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';


export class UserActivityDataSource implements DataSource<any> {

  private userActivitySubject = new BehaviorSubject<any[]>([]);

  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private auditsService: AuditsService) {

  }

  loadUserActivity(
    pageIndex,
    filterSearch
  ): void {

    this.loadingSubject.next(true);

    this.auditsService.findUserActivity(
      filterSearch,
      pageIndex
    ).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe((userActivity: any) => {
        const data = userActivity.map((element: any) => {
          const formFields = JSON.parse(element.form_fields);
          return { ...element, ...formFields, plan: formFields.plan }
        });

        this.userActivitySubject.next(data);
      });

  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.userActivitySubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.userActivitySubject.complete();
    this.loadingSubject.complete();
  }

}
