import { KEY_CODE } from '@enums/keyboardCodes';
import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  title = 'Are you sure you want to delete?';
  content = 'Please confirm that you want to delete.';
  confirmTxt = 'Yes';
  confirmColor: any = 'primary';
  onlyConfirmation = false;
  onlyCancelation = false;
  cancelationTxt = 'Cancel';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
  ) {
    this.dialogRef.disableClose = true;
    this.title = data?.title ? data?.title : this.title;
    this.content = data?.content ? data?.content : this.content;
    this.confirmTxt = data?.confirmTxt ? data?.confirmTxt : this.confirmTxt;
    this.confirmColor = data?.confirmColor ? data?.confirmColor : this.confirmColor;
    this.onlyConfirmation = data?.onlyConfirmation ? data?.onlyConfirmation : false;
    this.onlyCancelation = data?.onlyCancelation ? data?.onlyCancelation : false;
    this.cancelationTxt = data?.cancelationTxt ? data?.cancelationTxt : this.cancelationTxt;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: any): void {
    if (event.keyCode === KEY_CODE.ENTER) {
      this.closeDialog(true);
    }
    if (event.keyCode === KEY_CODE.ESC) {
      this.closeDialog(false);
    }
  }

  ngOnInit(): void { }

  closeDialog(param): void {
    this.dialogRef.close(param);
  }
}
