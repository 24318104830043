import { pricingPlanEnum } from './../../enums/pricingPlanEnum';
import {
  ControlContainer,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { HelpersService } from '@services/helpers.service';

@Component({
  selector: 'app-register-pricing-plan',
  templateUrl: './register-pricing-plan.component.html',
  styleUrls: ['./register-pricing-plan.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class RegisterPricingPlanComponent implements OnInit {
  // * Enum
  pricingPlanEnum = pricingPlanEnum;

  activePlan: number;
  plan: any;
  start: string;

  discountAmount: any = null;

  discountExpireDate: any = null;

  @Input() form: FormGroup;

  constructor(
    public authService: AuthenticationService,
    public helperService: HelpersService
  ) {
    this.activePlan = authService.currentUserValue?.user?.plan?.id;
    this.plan = authService.currentUserValue?.user?.plan;
    this.start = authService.currentUserValue?.user?.trial_start_date;
  }

  ngOnInit(): void {
    this.authService.currentUser.subscribe({
      next: (resp) => {
        if (resp) {
          this.activePlan = resp?.user?.plan?.id;
          this.discountExpireDate = resp?.user?.clinic?.discount_expiry_date;
          this.discountAmount = resp?.user?.clinic?.discount;
        }
      },
    });
  }
}
