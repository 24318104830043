import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'app-sendtomail-dialog',
  templateUrl: './sendtomail-dialog.component.html',
  styleUrls: ['./sendtomail-dialog.component.scss'],
})
export class SendtomailDialogComponent implements OnInit {
  emailGroup: FormGroup;
  additionalEmails: any[] = [];
  emailList: any[] = [];
  isFocused: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SendtomailDialogComponent>,
    private authService: AuthenticationService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.emailGroup = this.fb.group({
      newEmail: ['', [Validators.email, Validators.maxLength(190)]],
    });

    // add account user email at first index
    this.emailList.push(this.authService.currentUserValue.user.email);
    this.additionalEmails.push(this.authService.currentUserValue.user.email);
  }

  addNewEmail(): void {
    const inputEmail = this.emailGroup.controls.newEmail.value;

    if (this.emailList.indexOf(inputEmail) !== -1) {
      this.emailGroup.controls.newEmail.setErrors({});
      return;
    }
    this.emailList.push(inputEmail);
    this.additionalEmails.push(inputEmail);
    // reset field
    this.emailGroup.controls.newEmail.setValue('');
  }

  onRemoveEmail(email: any): void {
    if (this.emailList.indexOf(email) !== -1) {
      this.emailList.splice(this.emailList.indexOf(email), 1);
    }

    if (this.additionalEmails.indexOf(email) !== -1) {
      this.additionalEmails.splice(this.additionalEmails.indexOf(email), 1);
    }
  }

  onCheckEmail(event): any {
    const state = event.checked;
    const value = event.source.value;

    if (state) {
      this.additionalEmails.push(value);
    } else {
      this.additionalEmails.splice(this.additionalEmails.indexOf(value), 1);
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  clearInput(event): any {
    this.emailGroup.controls.newEmail.setValue('');
  }

  onSubmit(): void {
    this.dialogRef.close(this.additionalEmails.join(','));
  }
}
