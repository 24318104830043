import { ProcessedScorePositions } from '@enums/ProcessedScorePositions';
import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from '@services/util.service';

@Component({
  selector: 'app-processed-results-subcategory',
  templateUrl: './processed-results-subcategory.component.html',
  styleUrls: ['./processed-results-subcategory.component.scss'],
})
export class ProcessedResultsSubcategoryComponent implements OnInit {
  ProcessedScorePositions = ProcessedScorePositions;
  @Input() data: any;
  @Input() position: string;

  constructor(public utilService: UtilService) {}

  ngOnInit(): void {}
}
