import { useCaseNameEnum } from './../../../enums/useCaseNameEnum';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PercentageFormatPipe } from '@pipes/percentage-format.pipe';
import { GraphStatsService } from '@services/graph-stats.service';
import { UtilService } from '@services/util.service';
import { refImagesListView } from '../dummy-ref-images-by-case';

@Component({
  selector: 'app-view-reference-cases',
  templateUrl: './view-reference-cases.component.html',
  styleUrls: ['./view-reference-cases.component.scss'],
})
export class ViewReferenceCasesComponent implements OnInit {
  activeAOIType: number;

  refType: any = 'normal';

  outputImageArray: any[] = [];

  refImages = refImagesListView;

  aoiImageArray: any[] = [];

  imageIndex: number;

  refIndex = 0;

  trigger = false;

  activeAlgorithmReference: string = '';

  @Input() data: any;

  @Input() algorithm: any;

  @Output() changeState = new EventEmitter<any>();

  constructor(public utilService: UtilService) {}

  ngOnInit(): void {
    this.activeAOIType = 0;
    this.refIndex = 0;
    this.imageIndex = 0;
    this.setOutputImages(this.data.processed_images);
    this.setAOIImages(this.data.original_aoi_images);
    this.activeAlgorithmReference = this.utilService.getRefAlgo(this.algorithm);
  }

  onChangeState(): void {
    this.changeState.emit();
  }

  prevRef(disabled): void {
    if (disabled) {
      return;
    }
    this.refIndex--;
  }
  nextRef(disabled): void {
    if (disabled) {
      return;
    }
    this.refIndex++;
  }

  prevAOIType(event): void {
    const len = this.outputImageArray.length;
    if (len > 1 && this.activeAOIType > 0) {
      this.activeAOIType--;
    }
    return;
  }

  nextAOIType(event): void {
    const len = this.outputImageArray.length;
    if (len - 1 > this.activeAOIType) {
      this.activeAOIType++;
      this.nextAOIType(event);
    }
    return;
  }

  onSelectImage(index: number): void {
    this.imageIndex = index;
  }

  getRefImageSrc() {
    if (this.activeAlgorithmReference) {
      return this.refImages[this.activeAlgorithmReference][this.refType][
        this.refIndex
      ];
    }

    return null;
  }

  setAOIImages(images): any {
    this.aoiImageArray = [];
    images.forEach((aoi) => {
      this.aoiImageArray.push({
        aoi_image: aoi.image,
        aoi_image_thumbnail: aoi.thumbnail,
        ml: aoi?.ml,
        stdev: aoi?.stdev,
      });
    });
  }

  setOutputImages(images): any {
    this.outputImageArray = [];
    if (!images) {
      return;
    }

    images.forEach((value, index) => {
      if (value.algorithm === useCaseNameEnum.SpleenMass) {
        this.outputImageArray.push({
          processed_image: value.url,
          processed_image_thumbnail: value.thumbnail,
        });
      } else {
        this.outputImageArray.push({
          processed_image: value.url,
          processed_image_thumbnail: value.thumbnail,
        });
      }
    });
  }
}
