<form [formGroup]="forgotPasswordForm">
  <ng-container>

    <app-register-password-form
      [form]="forgotPasswordForm"
      (onFinish)="onSubmitNewPassword()"
    >

      <!-- Additional Fields -->

      <!-- Email -->
      <mat-form-field
        class="full-width"
        appearance="outline"
      >

        <mat-label>Email</mat-label>

        <input
          matInput
          value=""
          formControlName="email"
          name="email"
        />

        <mat-error>
          <app-field-errors [formField]="forgotPasswordForm.get('email')">
          </app-field-errors>
        </mat-error>

      </mat-form-field>
      <!-- Additional Fields -->

    </app-register-password-form>
  </ng-container>

</form>
