import { Injectable } from '@angular/core';
import { forkJoin, of, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Breed } from '@models/breed';
import { CaseType } from '@models/cases';
import { Client } from '@models/clients';
import { ImageModalityDropdown } from '@models/imageModality';
import { ImageTypeDropdown } from '@models/imageType';
import { Patient } from '@models/patients';
import { SpeciesDropdown } from '@models/species';
import { UseCase } from '@models/useCase';
import { BodyPart } from '@models/bodyPart';
import { AlteredStatus } from '@models/alteredStatus';
import { DropdownService } from './dropdown.service';

@Injectable({
  providedIn: 'root',
})
export class DropdownCommonService {
  hasLoaded = false;
  alteredStatus: AlteredStatus[];
  bodyPart: BodyPart[];
  breed: Breed[];
  caseType: CaseType[];
  clients: Client[];
  imageModality: ImageModalityDropdown[];
  imageType: ImageTypeDropdown[];
  patients: Patient[];
  species: SpeciesDropdown[];
  useCase: UseCase[];

  // * Clinic dropdown
  private clinicsDropdownSubject = new BehaviorSubject<any[]>([]);
  public clinicsDropdown$ = this.clinicsDropdownSubject.asObservable();

  // * Users dropdown
  private usersDropdownSubject = new BehaviorSubject<any[]>([]);
  public usersDropdown$ = this.usersDropdownSubject.asObservable();

  constructor(private dropdownService: DropdownService) {}

  loadClinicDropdown() {
    this.dropdownService.getAllClinics().subscribe(
      (resp) => {
        this.clinicsDropdownSubject.next(resp);
      },
      (error) => {
        /**
         * * Handle Error
         */
      }
    );
  }

  loadUsersByClinicIdDropdown(clinicId: number) {
    this.dropdownService.getUsersByClinicId(clinicId).subscribe(
      (resp) => {
        this.usersDropdownSubject.next(resp);
      },
      (error) => {
        /**
         * * Handle Error
         */
      }
    );
  }

  loadAll() {
    if (this.hasLoaded) {
      return of([]);
    }

    return forkJoin([this.dropdownService.getAllDropdown()]).pipe(
      map((resp: any) => {
        resp = resp[0];
        this.alteredStatus = resp.altered_status;
        this.bodyPart = resp.body_part;
        this.breed = resp.breed;
        this.caseType = resp.case_type;
        this.clients = resp.clients;
        this.imageModality = resp.image_modality;
        this.imageType = resp.image_type;
        this.patients = resp.patients;
        this.species = resp.species;
        this.useCase = resp.usecase;
        this.hasLoaded = true;
      })
    );
  }
}
