<div id="pricing-plan">
  <mat-radio-group
    formControlName="plan"
    class="pricing-plan-group"
  >
    <mat-radio-button
      [value]="pricingPlanEnum.PER_CASE_ID"
      [disabled]="activePlan === pricingPlanEnum.PER_CASE_ID"
    >
      <span class="material-icons-outlined active"> check_circle_outline </span>
      <span class="material-icons-outlined inactive">
        radio_button_unchecked
      </span>

      <div class="plan-card-details">
        <div class="plan-card-title">
          Per case
          <div
            class="tag"
            *ngIf="discountAmount"
          >-{{ discountAmount }}%</div>
        </div>
        <li class="plan-card-subtitle">payment will be per case</li>
      </div>

      <div class="plan-card-price">
        <div
          class="plan-card-discount"
          *ngIf="discountAmount"
        >
          <div class="plan-card-discount-regular">$19</div>
          <div>
            {{ helperService.calculateDiscountedPrice(discountAmount, 19) }}
          </div>
        </div>
        <div
          class="plan-card-discount"
          *ngIf="!discountAmount"
        >$19</div>
        <small>/ per case </small>
      </div>
    </mat-radio-button>
    <mat-radio-button
      [value]="pricingPlanEnum.BASE_ID"
      [disabled]="activePlan === pricingPlanEnum.BASE_ID"
    >
      <span class="material-icons-outlined active"> check_circle_outline </span>
      <span class="material-icons-outlined inactive">
        radio_button_unchecked
      </span>

      <div class="plan-card-details">
        <div class="plan-card-title">
          Base
          <div
            class="tag"
            *ngIf="discountAmount"
          >-{{ discountAmount }}%</div>
        </div>
        <li class="plan-card-subtitle">Includes 10 cases</li>
        <li class="plan-card-subtitle">$15 per case if exceed 10</li>
      </div>

      <div class="plan-card-price">
        <div
          class="plan-card-discount"
          *ngIf="discountAmount"
        >
          <div class="plan-card-discount-regular">$150</div>
          <div>
            {{ helperService.calculateDiscountedPrice(discountAmount, 150) }}
          </div>
        </div>
        <div
          class="plan-card-discount"
          *ngIf="!discountAmount"
        >$150</div>
        <small>/ Month</small>
      </div>
    </mat-radio-button>
    <mat-radio-button
      [value]="pricingPlanEnum.POPULAR_ID"
      [disabled]="activePlan === pricingPlanEnum.POPULAR_ID"
    >
      <span class="material-icons-outlined active"> check_circle_outline </span>
      <span class="material-icons-outlined inactive">
        radio_button_unchecked
      </span>

      <div class="plan-card-details">
        <div class="plan-card-title">
          Popular
          <div
            class="tag"
            *ngIf="discountAmount"
          >-{{ discountAmount }}%</div>
        </div>
        <li class="plan-card-subtitle">Includes 20 cases</li>
        <li class="plan-card-subtitle">$13 per case if exceed 20</li>
      </div>

      <div class="plan-card-price">
        <div
          class="plan-card-discount"
          *ngIf="discountAmount"
        >
          <div class="plan-card-discount-regular">$250</div>
          <div>
            {{ helperService.calculateDiscountedPrice(discountAmount, 250) }}
          </div>
        </div>
        <div
          class="plan-card-discount"
          *ngIf="!discountAmount"
        >$250</div>
        <small>/ Month</small>
      </div>
    </mat-radio-button>
    <mat-radio-button
      [value]="pricingPlanEnum.ENTERPRISE_ID"
      [disabled]="activePlan === pricingPlanEnum.ENTERPRISE_ID"
    >
      <span class="material-icons-outlined active"> check_circle_outline </span>
      <span class="material-icons-outlined inactive">
        radio_button_unchecked
      </span>

      <div class="plan-card-details">
        <div class="plan-card-title">
          Enterprise
          <div
            class="tag"
            *ngIf="discountAmount"
          >-{{ discountAmount }}%</div>
        </div>
        <li class="plan-card-subtitle">Includes 30 cases</li>
        <li class="plan-card-subtitle">$11 per case if exceed 30</li>
      </div>

      <div class="plan-card-price">
        <div
          class="plan-card-discount"
          *ngIf="discountAmount"
        >
          <div class="plan-card-discount-regular">$350</div>
          <div>
            {{ helperService.calculateDiscountedPrice(discountAmount, 350) }}
          </div>
        </div>
        <div
          class="plan-card-discount"
          *ngIf="!discountAmount"
        >$350</div>
        <small>/ Month</small>
      </div>
    </mat-radio-button>

    <mat-radio-button
      [value]="-1"
      [disabled]="plan || start"
    >
      <span class="material-icons-outlined active"> check_circle_outline </span>
      <span class="material-icons-outlined inactive">
        radio_button_unchecked
      </span>

      <div class="plan-card-details">
        <div class="plan-card-title">
          Free Trial
          <div
            class="tag"
            *ngIf="discountAmount"
          >-{{ discountAmount }}%</div>
        </div>
        <li class="plan-card-subtitle">Demo version</li>
        <li class="plan-card-subtitle">Try creating cases and processed with real data</li>
      </div>

      <div class="plan-card-price free-trail">
        <div
          class="plan-card-discount"
          *ngIf="discountAmount"
        >
          <div class="plan-card-discount-regular">$350</div>
          <div>
            {{ helperService.calculateDiscountedPrice(discountAmount, 350) }}
          </div>
        </div>
        <div class="plan-card-discount">FREE
        </div>
      </div>
    </mat-radio-button>
  </mat-radio-group>

  <small *ngIf="discountExpireDate">Your discount is valid till
    {{ discountExpireDate | date: "mediumDate" }}</small>
</div>
