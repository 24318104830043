import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ReferentImagesService {

  outputIndex = new BehaviorSubject(null);
  public index = this.outputIndex.asObservable();

  constructor(
    private http: HttpService
  ) { }


  getAll(params): Observable<any> {
    let q = null;
    if (params) {
      q = new URLSearchParams(params).toString();
    }

    return this.http.get(`/referent-images?${ q }`);
  }

  create(data: any): Observable<any> {
    return this.http.postFile('/referent-images', data);
  }

  delete(id: any) {
    return this.http.delete(`/referent-images?ids=${ id }`);
  }

  /**
   * * Helpers
   */

  public groupByAlgorithmName(response) {
    let output = [];
    response.forEach(function (item, index) {
      var existing = output.filter(function (v, i) {
        return v.algorithm.name == item.algorithm.name;
      });
      if (existing.length) {
        var existingIndex = output.indexOf(existing[0]);
        output[existingIndex].value = output[existingIndex].value.concat(item);
      } else {
        if (typeof item.algorithm.name == 'string')
          item.value = [{ ...item }];

        output.push(item);
      }
    });
    return output;
  }

  public groupByImageTypeName(response) {
    let output = [];
    response.forEach(function (item) {
      var existing = output.filter(function (v, i) {
        return v.image_type.name == item.image_type.name;
      });
      if (existing.length) {
        var existingIndex = output.indexOf(existing[0]);
        output[existingIndex].value = output[existingIndex].value.concat(item);
      } else {
        if (typeof item.image_type.name == 'string')
          item.value = [{ ...item }];
        output.push(item);
      }
    });
    return output;
  }

  public groupByRefAlgorithmName(response) {
    let output = [];
    response.forEach(function (item) {
      var existing = output.filter(function (v, i) {
        return v.referent_algorithm == item.referent_algorithm;
      });

      if (existing.length) {
        var existingIndex = output.indexOf(existing[0]);
        output[existingIndex].value = output[existingIndex].value.concat(item);
      } else {
        if (typeof item.referent_algorithm == 'string')
          item.value = [{ ...item }];
        output.push(item);
      }
    });
    return output;
  }
}
