<mat-radio-group
  aria-label="Select an option"
  formControlName="type"
  class="account-type-group"
>
  <mat-radio-button [value]="AccOwner.yes">Yes, I'm a primary account owner</mat-radio-button>
  <mat-radio-button [value]="AccOwner.no">No, I'm not a primary account owner</mat-radio-button>
</mat-radio-group>

<ng-container *ngIf="form.get('type').value === AccOwner.yes">
  <div class="account-type-register">
    <h3 class="text-center">Please, type a clinic name</h3>

    <mat-form-field
      appearance="outline"
      class="full-width"
    >
      <mat-label>Clinic Name</mat-label>
      <input
        matInput
        value=""
        formControlName="clinic"
        name="clinic"
        maxlength="60"
      />
      <mat-error>
        <app-field-errors [formField]="form.get('clinic')"> </app-field-errors>
      </mat-error>
    </mat-form-field>
  </div>
</ng-container>

<ng-container *ngIf="form.get('type').value === AccOwner.no">
  <div class="account-type-register">
    <h3 class="text-center">Please, type an email or clinic name</h3>

    <mat-form-field
      appearance="outline"
      class="input-full-width"
    >
      <mat-label>Clinic name</mat-label>

      <mat-select
        disableOptionCentering
        placeholder="Clinic name"
        name="clinic"
        formControlName="clinic_id"
        ngDefaultControl
      >
        <mat-option>
          <ngx-mat-select-search
            [formControl]="clinicFilterCtrl"
            placeholderLabel="Clinic name"
            noEntriesFoundLabel="no matching found"
          >
          </ngx-mat-select-search>
        </mat-option>

        <mat-option
          *ngFor="let clinic of filteredClinic | async"
          [value]="clinic.id"
        >
          {{ clinic?.name }} -
          <span class="secondary-text">{{
            clinic?.email ? clinic?.email : "no email"
            }}</span>
        </mat-option>
      </mat-select>

      <button
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        *ngIf="form.get('clinic_id').value !== ''"
        (click)="form.get('clinic_id').setValue(''); $event.stopPropagation()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
</ng-container>
