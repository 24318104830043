<!-- Full screen image -->
<div id="full_screen">
  <div class="content">
    <div
      class="material-icons-outlined icon"
      (click)="onClose()"
      matTooltip="Close"
    >
      <span>close</span>
    </div>
    <img
      [src]="data.srcFullImage"
      alt=""
    />
  </div>
</div>
