import { Subscription } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { Role } from '@enums/role';

@Component({
  selector: 'app-case-details-dialog',
  templateUrl: './case-details-dialog.component.html',
  styleUrls: ['./case-details-dialog.component.scss'],
})
export class CaseDetailsDialogComponent implements OnInit {
  caseTypeId: number;

  currentUser: any;

  imageData: any;

  Role = Role;

  subscription: Subscription[] = [];

  @Input() patientClientData: any;
  @Input() loading: any;
  @Input() index: any;

  @Input() set image(data) {
    this.imageData = data;
  }

  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.caseTypeId = this.patientClientData?.case_type_id;

    this.subscription.push(
      this.authService.currentUser.subscribe({
        next: (value) => {
          this.currentUser = value;
        },
      })
    );
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.forEach((subscription) => subscription.unsubscribe());
  }
}
