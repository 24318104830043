import { ProcessedScorePositions } from '@enums/ProcessedScorePositions';
import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { useCaseNameEnum } from '@enums/useCaseNameEnum';

@Component({
  selector: 'app-graph-stats',
  templateUrl: './graph-stats.component.html',
  styleUrls: ['./graph-stats.component.scss'],
})
export class GraphStatsComponent implements OnInit, OnChanges {
  ProcessedScorePositions = ProcessedScorePositions;

  @Input() data: any;
  @Input() indicatorSection: any;
  @Input() triggerChange: any;
  @Input() position: string;
  @Input() useCaseSuffix: string;

  fontColor = '#fff';
  private colors = {
    green: '#e5f7ea',
    red: '#ffe5e5',
    orange: 'orangered',
    unknown: '#e9edf3',
  };
  color = '';
  selectedColor = '';

  constructor() {}

  ngOnInit(): void {
    /**
     * TODO privremeno
     *
     *
     * ! U HTML promeniti broj 95
     */

    /**
     * TODO ==============
     */

    this.selectVariables();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.triggerChange) {
      // Do your logic here
      this.selectVariables();
    }
  }

  selectVariables(): void {
    /**
     * * Experimental Use Cases
     */
    if (
      this.useCaseSuffix === useCaseNameEnum.CCL ||
      this.useCaseSuffix === useCaseNameEnum.Bone
    ) {
      this.color = this.colors.unknown;
      this.selectedColor = 'unknown';
      this.fontColor = '#000';
      return;
    }

    if (
      this.data.info.positive.min < this.data.info.current &&
      this.data.info.positive.max > this.data.info.current
    ) {
      this.color = this.colors.green;
      this.selectedColor = 'green';
      this.fontColor = '#00ac2e';
    } else if (
      this.data.info.negative.min < this.data.info.current &&
      this.data.info.negative.max > this.data.info.current
    ) {
      this.color = this.colors.red;
      this.selectedColor = 'red';
      this.fontColor = '#b80000';
    } else {
      this.color = this.colors.unknown;
      this.selectedColor = 'unknown';
      this.fontColor = '#000';
    }
  }

  mappingNames(color: string): string {
    /**
     * * Experimental Use Cases
     */
    if (
      this.useCaseSuffix === useCaseNameEnum.CCL ||
      this.useCaseSuffix === useCaseNameEnum.Bone
    ) {
      return 'n/a';
    }

    if (color === 'red') {
      // return 'malignant';
      return 'abnormal';
    } else if (color === 'green') {
      return 'normal';
    } else if (color === 'orange') {
      // return 'benign';
      return 'abnormal';
    } else if (color === 'unknown') {
      return 'unknown';
    }
  }
}
