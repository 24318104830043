<div
  id="sign-in-wrapper"
  class="d-flex align-items-center"
>
  <div class="w-50 h-100 relative d-flex flex-center">
    <div class="overlay-blue"></div>
    <div class="login-background"></div>

    <div class="content">
      <div class="content-header"></div>

      <div class="content-main">
        <strong class="content-title">Re-inventing Image Analysis</strong>

        <div>
          <strong>Current Use Cases:</strong>
          <ul>
            <li>Liver - beta</li>
            <li>Lymph Node - beta</li>
            <li>Spleen - beta</li>
            <li>Bone Visualization - Visual only Experimental</li>
          </ul>

          <strong>Coming Soon</strong>
          <ul>
            <li>Cranial Cruciate Ligament Tear - Visual only Experimental</li>
          </ul>
        </div>

        <button
          (click)="demo()"
          mat-stroked-button
          type="button"
          class="my-button my-outline my-outline-white mt-2 my-button-scaleup"
        >
          Try for free
        </button>
      </div>

      <div class="content-footer">
        Trusted by the the world's most notable companies
      </div>
    </div>
  </div>

  <div class="w-50">
    <img
      src="../../../assets/images/petview_logo_svg.svg"
      id="logo"
      alt="logo"
    />

    <div class="card w-50 m-auto p-2">
      <h2 class="mb-0">Sign in to PetView</h2>
      <small>Enter your email address below</small>
      <hr class="mb-1" />
      <form
        class="login-form"
        [formGroup]="loginForm"
      >
        <div class="login-form-inputs">
          <mat-form-field appearance="outline">
            <mat-label>E-mail</mat-label>
            <input
              matInput
              value=""
              formControlName="email"
              name="email"
              autocomplete="on"
            />
            <mat-error *ngIf="loginForm.controls.email.invalid">{{
              getErrorMessage()
              }}</mat-error>
          </mat-form-field>

          <div *ngIf="passwordState">
            <mat-form-field appearance="outline">
              <mat-label>Password</mat-label>
              <input
                matInput
                [type]="hide ? 'password' : 'text'"
                formControlName="password"
                name="password"
                autocomplete="new-password"
                #passwordInput
              />
              <mat-error *ngIf="loginForm.controls.password.invalid">{{
                getErrorMessage()
                }}</mat-error>
            </mat-form-field>
          </div>

          <!-- Checkbox -->
          <div class="mb-1">
            <!-- (change)="authenticationService.updateRememberMeStatus($event.checked)" -->
            <mat-checkbox
              formControlName="remember"
              color="primary"
            >Remember me</mat-checkbox>
          </div>
        </div>

        <button
          mat-raised-button
          color="primary"
          (click)="onLogin()"
          class="my-button"
          [disabled]="loading"
        >
          Login
        </button>
      </form>
      <div class="d-flex justify-content-between signup">
        <span class="d-flex flex-column align-items-start">
          <p>
            Don’t have an account?
            <a
              href=""
              class="color-primary"
              routerLink="/register"
            >Sign Up</a>
          </p>
          <a
            routerLink="/request-reset"
            class="color-secondary"
          >Forgot password?
          </a>
        </span>
      </div>
    </div>

    <div class="login-footer">
      <p class="color-secondary text-center">Powered by ImagoSystems</p>
    </div>
  </div>
</div>
