<div
  class="inner-header mb-0"
  [ngClass]="{'focus-mode-active' : focusModeActive}"
  mat-dialog-title
>
  <div class="inner-header-actions mb-1">
    <div class="inner-header-title d-flex align-items-center">

      <div
        class="page-icon cursor-pointer"
        (click)="focusMode()"
        [matTooltip]="!focusModeActive ? 'Focus mode' : 'Exit focus mode'"
      >
        <span
          class="material-icons-outlined"
          *ngIf="!focusModeActive"
        >
          fullscreen
        </span>
        <span
          class="material-icons-outlined"
          *ngIf="focusModeActive"
        >
          close_fullscreen
        </span>
      </div>

      <div class="page-icon">
        <span class="custom-question-icon">?</span>
      </div>

      <div
        class="page-icon cursor-pointer"
        matTooltip="Copy page link"
        (click)="copyToClipboard()"
      >
        <span class="material-icons-outlined">
          content_copy
        </span>
      </div>

      <div class="page-icon"><span class="petview-folder"></span></div>
      <h3 *ngIf="!loadingState">
        Case {{ data?.id }}
        <span *ngIf="caseTypeId === 1">: {{ caseData?.algorithm?.name }} /
          {{ caseData?.client?.first_name }}
          {{ caseData?.client?.last_name }}
          {{ caseData?.client?.middle_name }} / {{ caseData?.patient?.name }} /
          {{ caseData?.patient?.patient_type.name }} -
          {{ caseData?.patient?.breed?.name }}</span>
      </h3>
    </div>
    <div class="inner-header-buttons flex-center gap-1">
      <div class="view-header-reference">
        <mat-form-field appearance="outline">
          <mat-label>Reference case</mat-label>
          <mat-select
            disableOptionCentering
            [(ngModel)]="refCaseSelected"
            (ngModelChange)="onChangeRefImage($event)"
            placeholder="Reference case"
            [disabled]="!isReferenceActive"
          >
            <mat-option value="1">Normal image</mat-option>
            <mat-option value="2">Benign image</mat-option>
            <mat-option value="3">Malignant image</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="view-header-checkbox">
        <mat-checkbox
          color="primary"
          class="mr-2"
          *ngIf="focusModeActive"
          [(ngModel)]="isOriginalActive"
          (change)="handleReferenceState($event)"
          [checked]="isOriginalActive"
        >
          Original
        </mat-checkbox>

        <mat-checkbox
          color="primary"
          class="mr-2"
          *ngIf="focusModeActive"
          [(ngModel)]="isOutputActive"
          (change)="handleReferenceState($event)"
          [checked]="isOutputActive"
        >
          Output
        </mat-checkbox>

        <mat-checkbox
          color="primary"
          [(ngModel)]="isReferenceActive"
          (change)="handleReferenceState($event)"
          [checked]="isReferenceActive"
          [disabled]="referenceDisabled || disabledNoData"
        >
          Reference
        </mat-checkbox>
      </div>

      <span class="relative">
        <button
          mat-button
          color="primary"
          [matMenuTriggerFor]="downloadMenu"
          class="my-button my-link"
          [disabled]="disabledNoData"
        >
          Download
        </button>

        <mat-menu #downloadMenu="matMenu">
          <app-download-dialog
            (downloadEvent)="downloadEventEmit($event)"
            [loading]="loadingState"
            [downloadingProgress]="loadingDownload"
            [caseTypeId]="caseTypeId"
          ></app-download-dialog>
        </mat-menu>
      </span>
      <span
        class="relative"
        *ngIf="caseTypeId !== 4"
      >
        <button
          mat-button
          color="primary"
          class="my-button my-link"
          [matMenuTriggerFor]="detailsMenu"
          [disabled]="disabledNoData"
        >
          Case details
        </button>
        <mat-menu #detailsMenu="matMenu">
          <app-case-details-dialog
            *ngIf="caseTypeId !== 2"
            [patientClientData]="caseData"
            [image]="caseData?.images[imageIndex]"
            [loading]="loadingState"
            [index]="imageIndex"
          >
          </app-case-details-dialog>
          <app-case-upload-data-details-dialog
            *ngIf="caseTypeId === 2"
            [uploadDataCaseData]="caseData?.images[imageIndex]"
            [loading]="loadingState"
          >
          </app-case-upload-data-details-dialog>
        </mat-menu>
      </span>
      <button
        mat-raised-button
        color="primary"
        class="my-button"
        (click)="closeModal()"
      >
        Exit
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
</div>
<mat-dialog-content [ngClass]="{ 'no-padding': !displayStates, 'focus-mode-active' : focusModeActive }">
  <ngx-spinner name="output">
    <p style="font-size: 20px; color: #000">Loading...</p>
  </ngx-spinner>

  <div
    *ngIf="disabledNoData"
    class="d-flex justify-content-center flex-column align-items-center custom-no-data"
  >
    <span class="material-icons-outlined">
      cloud_off
    </span>
    <p>No data</p>
  </div>

  <div *ngIf="!disabledNoData">
    <app-view-reference-cases
      *ngIf="!loadingState && displayStates && displayStateType === 'reference'"
      [data]="caseData?.images[imageIndex]"
      [algorithm]="this.caseData?.algorithm?.suffix"
      (changeState)="onOpenComparison()"
    >
    </app-view-reference-cases>

    <!-- <div class="ref-cases" *ngIf="caseTypeId !== 2">

            </div> -->

    <div class="view-main-container">
      <ngx-spinner name="output">
        <p style="font-size: 20px; color: #000">Loading...</p>
      </ngx-spinner>

      <div
        class="images-list"
        *ngIf="caseTypeId !== 4"
      >
        <div class="image-from-cases">
          <div
            class="image-from-case-card"
            *ngIf="imagesList.length === 0"
          >
            <div class="main-img-placeholder">
              <span class="material-icons-outlined"> image_not_supported </span>
              <p><strong>No images</strong></p>
            </div>
          </div>

          <div
            class="image-from-case-card"
            [ngClass]="{ active: i === imageIndex }"
            *ngFor="let aoi of imagesList; let i = index"
            (click)="switchOutput(aoi, i)"
          >
            <div class="buttons-group">
              <div
                matTooltip="Full screen"
                class="material-icons-outlined view-image-header-icon"
                *ngIf="errorImages.length === 0 && errorImages[i] === undefined"
                (click)="
                    toggleFullScreen(
                      aoi.output.processed_original_images?.url,
                      $event
                    )
                  "
              >
                <span> open_in_full </span>
              </div>
            </div>
            <div
              class="thumbnail"
              *ngIf="errorImages.length === 0 && errorImages[i] === undefined"
            >
              <img
                [src]="aoi.output?.processed_original_images?.thumbnail"
                alt="thumbnail"
                (error)="handleError($event, i)"
              />
            </div>

            <div
              class="main-img-placeholder"
              *ngIf="
                  (errorImages.length !== 0 && errorImages[i] !== undefined) ||
                  !aoi.output.processed_original_images?.url
                "
            >
              <span class="material-icons-outlined"> image_not_supported </span>
              <p><strong>No image</strong></p>
            </div>

            <app-processed-results
              *ngIf="caseTypeId !== 2"
              [data]="caseData?.images[i]"
              [usecaseType]="caseData?.algorithm"
              [index]="i"
              [imgIndex]="imageIndex"
              [position]="ProcessedScorePositions.IMAGE"
            >
            </app-processed-results>
          </div>
        </div>
      </div>

      <div
        class="images-list"
        *ngIf="caseTypeId === 4"
      >
        <strong>Images from case</strong>

        <div
          class="image-from-cases"
          [ngClass]="{ 'flex-wrap': caseTypeId === 4 }"
        >
          <div
            class="image-from-case-card"
            [ngClass]="{ active: i === imageIndex }"
            *ngFor="let aoi of imagesList; let i = index"
            (click)="switchOutput(aoi, i)"
          >
            <span *ngIf="aoi.name?.indexOf('.jpg') > -1">
              <div class="buttons-group">
                <div
                  class="petview-full_size"
                  (click)="toggleFullScreen(aoi.image, $event)"
                ></div>
              </div>
              <img
                [src]="aoi.image"
                alt="thumbnail"
              />
            </span>
            <img
              *ngIf="aoi.name?.indexOf('.jpg') === -1"
              src="./../../../assets/others/spreadsheet.svg"
              alt="thumbnail"
            />
          </div>
        </div>
      </div>

      <div
        class="preview-container"
        *ngIf="caseTypeId !== 4"
      >
        <div class="main-preview">
          <div class="view-main-images">
            <ng-container #outlet>
            </ng-container>
            <ng-template #content>
              <div
                class="view-image-wrap"
                *ngIf="isOriginalActive"
              >
                <!-- <app-processed-results
                    *ngIf="caseTypeId !== 2"
                    [data]="caseData?.images[imageIndex]"
                    [index]="imageIndex"
                    [imgIndex]="imageIndex"
                    [position]="ProcessedScorePositions.DETAILED"
                  >
                  </app-processed-results> -->

                <div class="view-image-fixed-height">
                  <!-- Original Images send to processing -->
                  <app-view-original-images
                    [data]="caseData?.images[imageIndex]"
                    [imageIndex]="imageIndex"
                    [caseType]="caseTypeId"
                    [images]="caseData?.images"
                    [loading]="loadingState"
                    [activeUserRole]="activeUserRole"
                    (toggleFullScreen)="toggleFullScreen($event)"
                    (isActive)="handleCurrentActive($event)"
                  >
                  </app-view-original-images>
                </div>
              </div>

              <!-- Output processed images -->
              <div
                class="view-image-wrap text-right"
                *ngIf="isOutputActive"
              >
                <div class="view-image-fixed-height">
                  <app-view-output-image
                    [images]="caseData?.images[imageIndex]"
                    [handleChangeReference]="isReferenceActive"
                    (toggleFullScreen)="toggleFullScreen($event)"
                    (isActive)="handleCurrentActive($event)"
                  >
                  </app-view-output-image>
                </div>
              </div>
            </ng-template>

            <!-- Reference Images -->
            <div
              class="view-image-wrap text-right"
              *ngIf="isReferenceActive && caseTypeId !== 2"
            >
              <div class="view-image-fixed-height">
                <app-view-reference-image
                  [activeAlgorithmReference]="activeAlgorithmReference"
                  [referenceImages]="referenceImages"
                  [refCaseSelected]="refCaseSelected"
                  [refCaseIndex]="refCaseIndex"
                  (toggleFullScreen)="toggleFullScreen($event)"
                  (isActive)="handleCurrentActive('')"
                >
                </app-view-reference-image>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</mat-dialog-content>
