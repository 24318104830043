import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpService) { }

  me(): Observable<any> {
    return this.http.get(`/auth/me`);
  }

  getUsers(search): any {
    return this.http.getTable('/users', search, 1);
  }

  getById(id: number): any {
    return this.http.get('/users/' + id);
  }

  getCasesUsers(): any {
    return this.http.get('/users/dropdown');
  }

  deleteUser(ids: number[]): any {
    return this.http.delete('/users?ids=' + ids);
  }

  register(data): any {
    return this.http.post('/register', data);
  }

  sendInvites(data): any {
    return this.http.post(`/invite`, data);
  }

  sendResetLinkOnMail(data): any {
    return this.http.post(`/accounts/password/change`, data);
  }

  resetForgotPassword(data): any {
    return this.http.post(`/accounts/password/reset`, data);
  }

  registerInvited(data, token) {
    return this.http.post(`/register/invite-user/${ token }`, data);
  }

  registerPlan(data) {
    return this.http.post(`/accounts/choose-plan`, data);
  }

  updateUser(id, data): any {
    return this.http.put(`/users/${ id }`, data);
  }

  updateFCMToken(token): any {
    return this.http.post('/updatetoken', token);
  }

  checkEmail(data): any {
    return this.http.post('/register/check-email', data);
  }

  checkEmailTrial(data): any {
    return this.http.post('/register/check-email-trial-user', data);
  }

  verifyAccount(data) {
    return this.http.post(`/accounts/verifyAccount`, data);
  }

  loginEmailChecking(data) {
    return this.http.post(`/login/check-email`, data);
  }

  updateCreditCard(data) {
    return this.http.post(`/payments/payment-customer-profile`, data);
  }

  updatePlan(data) {
    return this.http.post(`/payments/payment-pricing-plan/update`, data);
  }

  cancelSubscription() {
    return this.http.post(`/users/cancel-subscription`, {});
  }
}
