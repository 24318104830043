import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  NgZone,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { useCaseNameEnum } from '@enums/useCaseNameEnum';
import { HelpersService } from '@services/helpers.service';
import { ReferentImagesService } from '@services/referent-images.service';

@Component({
  selector: 'app-view-output-image',
  templateUrl: './view-output-image.component.html',
  styleUrls: ['./view-output-image.component.scss'],
})
export class ViewOutputImageComponent implements OnInit {
  // outputImageArray: any[] = [];
  outputImages: any[] = [];
  allImages: any[] = [];
  outputIndex: number = 0;
  outputImagesObj: any[] = [];

  // TODO this is set to 0 because there is only 1 image return as processed
  processedImageIndex: number = 0;

  // @Input() imageIndex: number;
  @Input() set images(value) {
    this.allImages = value;
    this.setOutputImages(value);
  };
  @Input() loading: boolean;
  @Input() set handleChangeReference(value: boolean) {
    this.cdr.detectChanges();
  }

  @Output() toggleFullScreen = new EventEmitter();
  @Output() isActive = new EventEmitter();

  @ViewChild('zoom') zoom: any;

  constructor(
    private cdr: ChangeDetectorRef,
    public helperService: HelpersService,
    private referentImageService: ReferentImagesService,
  ) { }

  ngOnInit(): void {
    this.setOutputImages(this.allImages);
  }

  handleActive(event): void {
    this.isActive.emit(event);
  }

  onFullScreen() {
    this.toggleFullScreen.emit(
      this.outputImages[this.processedImageIndex]
    );
  }

  onChangeImage(event) {
    this.outputIndex = event - 1;
    const name = this.outputImagesObj[this.outputIndex]?.algorithm;
    this.referentImageService.outputIndex.next({ index: event, name: name });
  }

  handleZoom() {
    this.zoom.zoomContainer.nativeElement.click();
  }

  setOutputImages(images): any {
    this.outputImages = [];

    if (!images.processed_images) {
      return;
    }

    images.processed_images.forEach((value, index) => {
      this.outputImages.push(value.url);
      this.outputImagesObj.push(value);
    });
  }
}
