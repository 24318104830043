import { Component, Input, OnInit } from '@angular/core';
import { DashboardStatisticsService } from '@services/dashboard-statistics.service';
import { Statistics } from '@models/Statistics';

@Component({
  selector: 'app-card-personal-data-extended',
  templateUrl: './card-personal-data-extended.component.html',
  styleUrls: ['./card-personal-data-extended.component.scss'],
})
export class CardPersonalDataExtendedComponent implements OnInit {
  data: Statistics;

  constructor(private dashStatistics: DashboardStatisticsService) {}

  ngOnInit(): void {
    // * Test statistics data loading and store
    // ? Read
    this.dashStatistics.readStatistics().subscribe(
      (statistics) => {
        this.data = statistics;
      },
      (error) => {
        /**
         * * Handle Error
         */
      }
    );
  }
}
