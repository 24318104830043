<div class="cards">
  <div class="cards-header">
    <strong>Newsfeed</strong> <span class="cards-header-actions"><a href="">View more<span class="material-icons">
          keyboard_arrow_right
        </span></a></span>
  </div>
  <mat-divider></mat-divider>


  <div class="cards-body">
    <div class="cards-body-news-item d-flex align-items-center justify-content-between">
      <div>
        <p class="cards-body-news-item-title">Scanning tumor for a mixed race</p>
        <p class="cards-body-news-item-date m-0">03.07.2020.</p>
      </div>
      <a href="" class="white-link">Details</a>
    </div>

    <div class="cards-body-news-item d-flex align-items-center justify-content-between">
      <div>
        <p class="cards-body-news-item-title">Scar tissues with XV20 scans</p>
        <p class="cards-body-news-item-date m-0">13.07.2020.</p>
      </div>
      <a href="" class="white-link">Details</a>
    </div>

    <div class="cards-body-news-item d-flex align-items-center justify-content-between">
      <div>
        <p class="cards-body-news-item-title">New x-ray for the golden retriever...</p>
        <p class="cards-body-news-item-date m-0">13.07.2020.</p>
      </div>
      <a href="" class="white-link">Details</a>
    </div>
  </div>
</div>
