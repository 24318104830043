<h3>Do you want to invite other users to your account?</h3>

<ng-container>

  <div
    [formGroup]="form"
    id="register-account-type-invites"
  >
    <div
      *ngFor="let control of formArray.controls; let i = index"
      formArrayName="invites"
    >
      <div
        [formGroupName]="i"
        class="d-flex"
      >
        <mat-form-field
          appearance="outline"
          class="input-full-width"
        >
          <mat-label>Email address</mat-label>
          <input
            matInput
            value=""
            formControlName="email"
            name="email"
          />
          <mat-error>
            <app-field-errors [formField]="control.get('email')">
            </app-field-errors>
          </mat-error>
        </mat-form-field>

        <div class="action-buttons">
          <button
            mat-button
            matSuffix
            mat-icon-button
            (click)="addEmail(); $event.stopPropagation()"
          >
            <mat-icon>add</mat-icon>
          </button>

          <button
            *ngIf="formArray.controls.length > 1"
            mat-button
            matSuffix
            mat-icon-button
            (click)="removeEmail(i); $event.stopPropagation()"
          >
            <mat-icon>remove</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
