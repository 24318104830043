import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { CardPersonalDataExtendedComponent } from './card-personal-data/card-personal-data-extended/card-personal-data-extended.component';
import { Subscription } from 'rxjs';

// * Services
import { DashboardStatisticsService } from '@services/dashboard-statistics.service';
import { DropdownCommonService } from '@services/dropdown-common.service';
import { UserService } from '@services/user.service';
import { AuthenticationService } from '@services/authentication.service';

// * Models
import { Statistics } from '@models/Statistics';

// * Enums
import { Role } from '@enums/role';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  user: any;
  role = Role;
  statisticsData: Statistics | any = {};
  loading: boolean = false;

  subscription: Subscription[] = [];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private dashStatistics: DashboardStatisticsService,
    private dcService: DropdownCommonService,
    private userService: UserService,
    private authService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.loadComponent();
    const ls = JSON.parse(localStorage.getItem('currentUser'));

    if (ls) {
      this.user = ls.user;
    }

    // * Load Clinics Dropdown List
    this.dcService.loadClinicDropdown();

    // * Get All Stats
    this.loadStatistics();

    // * Loading
    this.loadingState();

    // * Loading available cases
    this.userService
      .me()
      .subscribe((user) => {
        this.authService.currentUserToLocalStorage = user;
      });

    // * Subscribe on statistics data
    this.subscription.push(
      this.dashStatistics.statisticsData.subscribe(
        (resp) => {
          this.statisticsData = resp;
        },
        (error) => {
          /**
           * * Handle Error
           */
        }
      )
    );
  }

  loadStatistics() {
    this.dashStatistics.startLoading();

    this.subscription.push(
      this.dashStatistics.getStatistics().subscribe((res) => {
        this.dashStatistics.storeStatistics(res);
        this.dashStatistics.stopLoading();
      })
    );
  }

  loadComponent(): void {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        CardPersonalDataExtendedComponent
      );

    // const componentRef = viewContainerRef.createComponent<any>(componentFactory);
  }

  loadingState() {
    this.subscription.push(
      this.dashStatistics.loadingSubject.subscribe(
        (resp) => {
          this.loading = resp;
        },
        (error) => {
          this.loading = false;
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription) => subscription.unsubscribe());
  }
}
