import { FormGroup, FormControl, Validators, FormArray, ControlContainer, FormGroupDirective } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-register-account-type-invites',
  templateUrl: './register-account-type-invites.component.html',
  styleUrls: ['./register-account-type-invites.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ]
})
export class RegisterAccountTypeInvitesComponent implements OnInit {

  @Input() form: FormGroup;

  @Input() formArray: FormArray;

  @Output() onAddEmail = new EventEmitter();

  @Output() onRemoveEmail = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  addEmail() {
    this.onAddEmail.emit();
  }

  removeEmail(index) {
    this.onRemoveEmail.emit(index);
  }

}
