import { CreditCardTypesEnum } from './../enums/CreditCardTypesEnum';
import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HelpersService {
  americanCardMask = '0000 000000 00000';
  otherCardMask = '0000 0000 0000 0000';
  activeMask: string = '0000 0000 0000 0000'; // default mask
  activeCCVMask: string = '000';

  constructor() { }

  /**
   * args? define is need index or id
   * @param args true -> return index
   */
  getTabIndex(tabName): number {
    switch (tabName) {
      case 'FOV':
        return 0;
      case 'Spleen':
        return 1;
      case 'Liver':
        return 1;
      case 'Lymph Node':
        return 1;
      case 'Lesion':
        return 2;
      case 'ROI':
        return 3;
      default:
        return -1;
    }
  }

  getTabId(tabName): number {
    switch (tabName) {
      case 'FOV':
        return 1;
      case 'Spleen':
        return 2;
      case 'Liver':
        return 2;
      case 'Lymph Node':
        return 2;
      case 'Lesion':
        return 3;
      case 'ROI':
        return 4;
    }
  }

  /**
   * * Map algorithm name
   */
  transformAlgorithm(algName: string) {
    switch (algName) {
      case 'GS_ACL_v2': return 'Grayscale Enhancement';
      case 'HIST_EQUAL_INV': return 'Inverse-Normalized';
    }
  }

  /**
   * Password checking
   */
  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.password_confirmation.value;

    return pass === confirmPass ? null : { mismatchedPasswords: true };
  }

  atLeastOneValidator(...fields: string[]) {
    return (fg: FormGroup): ValidationErrors | null => {
      return fields.some((fieldName) => {
        const field = fg.get(fieldName).value;
        if (typeof field === 'number')
          return field && field >= 0 ? true : false;
        if (typeof field === 'string')
          return field && field.length > 0 ? true : false;
      })
        ? null
        : ({
          atLeastOne: 'At least one field has to be provided.',
        } as ValidationErrors);
    };
  }

  errorResponse(errors) {
    let errorMessages = '';
    for (const key in errors) {
      if (Array.isArray(errors[key])) {
        errorMessages += errors[key].join(`, `);
      }
    }
    return errorMessages;
  }

  convertCCV(cardType) {
    if (cardType == '3') {
      return '0000';
    }
    return '000';
  }

  convertBillingExpireDate(date) {
    const m = date.slice(0, 2);
    const y = date.slice(2);
    return `20${ y }-${ m }`;
  }

  convertMask(cardType) {
    /**
     * 3 - american
     * 2 - mastercard (2017)
     * 5 - mastercard
     * 4 - visa
     * 6 - discover
     */

    switch (cardType) {
      case '3':
        return this.americanCardMask;
      case '2':
      case '5':
      case '4':
      case '6':
        return this.otherCardMask;
      default:
        return this.otherCardMask;
    }
  }

  isYAxisTickActive(data) {
    return !data
      .map((timeInterval) =>
        timeInterval.series.every((serie) => serie.value === 0)
      )
      .every((b) => b);
  }

  getCreditCardTypeName(type) {
    switch (type) {
      case '3':
        return CreditCardTypesEnum.americanExpress;
      case '5':
        return CreditCardTypesEnum.master;
      case '2':
      case '4':
      case '6':
        return CreditCardTypesEnum.visa;
      default:
        return CreditCardTypesEnum.default;
    }
  }

  getCreditCardLogo(type) {
    if (type === CreditCardTypesEnum.visa) {
      return CreditCardTypesEnum.visaIcon;
    }
    if (type === CreditCardTypesEnum.master) {
      return CreditCardTypesEnum.masterIcon;
    }
    if (type === CreditCardTypesEnum.americanExpress) {
      return CreditCardTypesEnum.americanExpressIcon;
    }
    return CreditCardTypesEnum.defaultIcon;
  }

  calculateDiscountedPrice(discount, price) {
    return `$${ Math.round(price - (price / 100) * discount) }`;
  }

  getAccountTypeStepLabel(stepIndex: number): string {
    if (stepIndex === 0) {
      return 'account_type';
    }
    if (stepIndex === 1) {
      return 'pricing_plan';
    }
    if (stepIndex === 2) {
      return 'billing';
    }
    if (stepIndex === 3) {
      return 'finish';
    }

    return '';
  }

  /**
   *
   * @returns Simple hex generate key
   */
  genCryptoHexKey() {
    const segmentActive = Math.random().toString(36).slice(2);
    const segmentDrive = Math.random().toString(36).slice(2);
    const segmentFinish = Math.random().toString(36).slice(2);

    return `${ segmentActive }${ segmentDrive }${ segmentFinish }`;
  }
}
