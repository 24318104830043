<div class="slider" [hidden]="indicatorSection">
  <div
    id="green"
    [ngStyle]="{
      'left.%': data.info?.positive.min,
      'width.%': data.info?.positive.max - data.info?.positive.min
    }"
  >
    <span>{{ data.info?.positive.min }}</span>
    <span>{{ data.info?.positive.max }}</span>
  </div>
  <div id="current" [ngStyle]="{ 'left.%': data.info?.current }">
    <div id="num" [ngStyle]="{ backgroundColor: color }">
      {{ data.info?.current }}
    </div>
    <div id="indicator"></div>
  </div>
  <div
    id="red"
    [ngStyle]="{
      'left.%': data.info?.negative.min,
      'width.%': data.info?.negative.max - data.info?.negative.min
    }"
  >
    <span>{{ data.info?.negative.min }}</span>
    <span>{{ data.info?.negative.max }}</span>
  </div>
</div>

<div
  class="number-indicator"
  [ngClass]="{
    detailed: position === ProcessedScorePositions.DETAILED,
    image_layout: position === ProcessedScorePositions.IMAGE,
    table_layout: position === ProcessedScorePositions.TABLE
  }"
  [hidden]="!indicatorSection"
>
  <span *ngIf="position === ProcessedScorePositions.DETAILED">State</span>
  <span class="name" [ngStyle]="{ backgroundColor: color, color: fontColor }">
    {{ mappingNames(selectedColor) | processedImageLabel: position }}
  </span>
  <span *ngIf="position === ProcessedScorePositions.DETAILED">Score</span>
  <span class="value" [ngStyle]="{ backgroundColor: color, color: fontColor }">
    <!-- {{ data.info?.current | roundNumber }} -->
    95
  </span>
</div>
