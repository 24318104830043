import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agreement-subscription-trial',
  templateUrl: './agreement-subscription-trial.component.html',
  styleUrls: ['./agreement-subscription-trial.component.scss'],
})
export class AgreementSubscriptionTrialComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<AgreementSubscriptionTrialComponent>
  ) {}

  ngOnInit(): void {}

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
