import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderModule } from '@shared/header/header.module';
import { NavbarModule } from '@shared/navbar/navbar.module';
import { AoiToolModule } from '@app/aoi-tool/aoi-tool.module';
import { CasesSubmitDialogModule } from '@app/cases/cases-submit-dialog/cases-submit-dialog.module';
import { LoginModule } from '@app/login/login.module';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [
    LoginModule,
    HeaderModule,
    NavbarModule,
    AoiToolModule,
    CasesSubmitDialogModule,
  ],
})
export class FeatureComponentsModule {}
