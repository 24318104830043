<div class="card m-auto p-2 demo-form">
  <h2 class="mb-0">Create account</h2>
  <small>Create an account and continue using our customer experience.</small>
  <hr class="mb-1" />

  <div class="demo-fields">
    <div class="d-flex demo-name-group">
      <!-- FistName -->
      <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input
          matInput
          value=""
          formControlName="first_name"
          name="first_name"
          maxlength="60"
        />
        <mat-error>
          <app-field-errors [formField]="form.get('first_name')">
          </app-field-errors>
        </mat-error>
      </mat-form-field>

      <!-- Last Name -->
      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input
          matInput
          value=""
          formControlName="last_name"
          name="last_name"
          maxlength="60"
        />
        <mat-error>
          <app-field-errors [formField]="form.get('last_name')">
          </app-field-errors>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Email -->
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>

      <input
        matInput
        value=""
        formControlName="email"
        name="email"
        maxlength="60"
      />

      <mat-error>
        <app-field-errors [formField]="form.get('email')"></app-field-errors>
      </mat-error>
    </mat-form-field>

    <!-- Checkbox -->
    <mat-checkbox
      formControlName="subscribe"
      color="primary"
    >Subscribe to our newsletter</mat-checkbox>

    <!-- Terms of use -->
    <mat-checkbox
      formControlName="termsOfUse"
      color="primary"
    >I agree to the
      <a
        class="text-primary text-underline"
        (click)="openTermsOfUse($event)"
      >Terms of Use</a>
    </mat-checkbox>

    <!-- Privacy & Policy -->
    <mat-checkbox
      formControlName="privacyPolicy"
      color="primary"
    >I agree to the
      <a
        class="text-primary text-underline"
        (click)="openPrivacyPolicy($event)"
      >Privacy Policy</a>
    </mat-checkbox>

    <!-- Subscription -->
    <mat-checkbox
      formControlName="subscriptionAgreement"
      color="primary"
    >I agree to the
      <a
        class="text-primary text-underline"
        (click)="openSubscriptionAgreement($event)"
      >Subscription Agreement</a>
    </mat-checkbox>
  </div>

  <div class="d-flex justify-content-between">
    <div>
      <button
        mat-raised-button
        color="primary"
        type="button"
        class="my-button"
        [disabled]="
              form.get('first_name').invalid ||
              form.get('last_name').invalid ||
              form.get('email').invalid ||
              form.get('subscriptionAgreement').invalid ||
              form.get('termsOfUse').invalid ||
              form.get('privacyPolicy').invalid ||
              loading
            "
        (click)="passwordStep()"
      >
        Continue
      </button>
      <span class="ml-1 mr-1">or</span>
      <button
        mat-raised-button
        color="primary"
        type="button"
        class="my-button"
        [disabled]="
              form.get('first_name').invalid ||
              form.get('last_name').invalid ||
              form.get('email').invalid ||
              form.get('subscriptionAgreement').invalid ||
              form.get('termsOfUse').invalid ||
              form.get('privacyPolicy').invalid ||
              loading
            "
        (click)="passwordStepDemoAccount()"
      >
        Try for free
      </button>
    </div>

    <a
      mat-button
      [routerLink]="['login']"
      class="my-link my-button"
    >Back to login</a>
  </div>
</div>
