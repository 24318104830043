import { Role } from '@enums/role';

import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastService } from '@services/toast.service';
import { UserService } from '@services/user.service';
import { AuthenticationService } from '@services/authentication.service';
import * as Rollbar from 'rollbar';
import { RollbarService } from '@helpers/rollbarErrorHandler';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  hide = true;

  loginForm: FormGroup;

  loading = false;

  returnUrl: string;

  passwordState: boolean = false;

  role = Role;

  @ViewChild('passwordInput') set passwordInput(ElementRef: ElementRef) {
    if (ElementRef) {
      ElementRef.nativeElement.focus();
      this.changeDetector.detectChanges();
    }
  }

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public authenticationService: AuthenticationService,
    private userService: UserService,
    private toast: ToastService,
    private changeDetector: ChangeDetectorRef,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    // if (this.authenticationService.currentUserValue) {
    //   this.router.navigate(['/']);
    // }
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(190)],
      ],
      password: ['', [Validators.required, Validators.maxLength(190)]],
      remember: [false]
    });

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';

    this.loginForm.get('email').valueChanges.subscribe((emit) => {
      this.passwordState = false;
      this.loginForm.get('password').setValue('');
    });


  }

  get f(): any {
    return this.loginForm.controls;
  }

  getErrorMessage(): string {
    return this.loginForm.controls.email.hasError('required')
      ? 'You must enter a value'
      : this.loginForm.controls.email.hasError('email')
        ? 'Not a valid email'
        : '';
  }

  demo() {
    this.router.navigate(['demo']);
  }

  onLogin() {
    if (this.loginForm.get('email').invalid) {
      return;
    }

    if (this.passwordState) {
      this.onSubmit();
      return;
    }

    this.userService
      .loginEmailChecking({ email: this.loginForm.get('email').value })
      .subscribe(
        (resp) => {
          this.passwordState = true;
        },
        (error) => {
          this.rollbar.error('On check email', error);
          this.passwordState = false;
          this.loginForm.get('email').setErrors(Validators.email);
          this.toast.showError(error, 'Error');
        }
      );
  }

  onSubmit(): any {
    // stop if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService
      .login(this.f.email.value, this.f.password.value, this.f.remember.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.loading = false;

          if (
            !data.user.primaryOwner &&
            !data.user.billingInfo &&
            data.user.role === Role.IN_PROCESS &&
            data.user.created_by_admin
          ) {
            this.router.navigate(['/clinic-account-type']);
            return;
          }

          if (data.user.role === this.role.IN_PROCESS) {
            this.router.navigate(['/account-type']);
            return;
          }

          this.router.navigate([this.returnUrl]);
          this.toast.showSuccess('', 'Welcome');
        },
        (err) => {
          this.loading = false;

          if (err.redirectUrl) {
            this.router.navigate(['/account-type']);

            if (err.user && err.user.access_token) {
              localStorage.setItem('currentUser', JSON.stringify(err.user));
              this.authenticationService.currentUserSubject.next(err.user);
            }

            return;
          }

          this.toast.showError(err, 'Error');
          this.rollbar.error('On Login Error', err);
          this.loginForm.reset();
        }
      );
  }
}
