<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="text-center"><span [innerHTML]="content"></span></mat-dialog-content>
<mat-dialog-actions>
  <ng-container *ngIf="onlyCancelation">
    <button
      mat-button
      mat-dialog-close
      class="my-outline my-button"
    >
      {{ cancelationTxt }}
    </button>
  </ng-container>

  <ng-container *ngIf="!onlyCancelation">
    <button
      *ngIf="!onlyConfirmation"
      mat-button
      mat-dialog-close
      class="my-outline my-button"
    >
      Discard
    </button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button
      *ngIf="confirmColor === 'primary'"
      mat-button
      mat-raised-button
      [mat-dialog-close]="true"
      cdkFocusInitial
      class="my-button"
      color="primary"
    >
      {{ confirmTxt }}
    </button>
    <button
      *ngIf="confirmColor === 'warn'"
      mat-button
      mat-raised-button
      [mat-dialog-close]="true"
      cdkFocusInitial
      class="my-button"
      color="warn"
    >
      {{ confirmTxt }}
    </button>
  </ng-container>
</mat-dialog-actions>
