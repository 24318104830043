import { Component, OnInit, Input } from '@angular/core';
import { SpinnerService } from '@services/spinner.service';
import { UtilService } from '@services/util.service';

@Component({
  selector: 'app-card-clinic-performance',
  templateUrl: './card-clinic-performance.component.html',
  styleUrls: ['./card-clinic-performance.component.scss'],
})
export class CardClinicPerformanceComponent implements OnInit {
  @Input() data: any;
  @Input() set loading(value) {
    if (value) {
      this.spinner.showSpinner('clinicPerformance');
    } else {
      this.spinner.hideSpinner('clinicPerformance');
    }
  }

  constructor(
    public utilService: UtilService,
    private spinner: SpinnerService
  ) {}

  ngOnInit(): void {}
}
