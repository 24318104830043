import { InformationOfTypeDialogComponent } from '@shared/components/information-of-type-dialog/information-of-type-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { useCaseNameEnum } from './../../../enums/useCaseNameEnum';
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  ViewChild,
} from '@angular/core';
import { PercentageFormatPipe } from '@pipes/percentage-format.pipe';
import { GraphStatsService } from '@services/graph-stats.service';
import { UtilService } from '@services/util.service';

@Component({
  selector: 'app-view-original-images',
  templateUrl: './view-original-images.component.html',
  styleUrls: ['./view-original-images.component.scss'],
})
export class ViewOriginalImagesComponent implements OnInit {
  activeAOIType: number;

  refType: any;

  originalArray: any[] = [];

  originalImageIndex: number;

  trigger = false;

  zoomActive: boolean = false;

  ngxDisplay: any;

  @ViewChild('zoom') zoom: any;


  @Input() activeUserRole: string;

  @Input() data: any;

  @Input() images: any;

  @Input() imageIndex: number;

  @Input() loading: boolean;

  @Input() public caseType: any;


  @Output() changeState = new EventEmitter<any>();

  @Output() toggleFullScreen = new EventEmitter();

  @Output() isActive = new EventEmitter();

  constructor(public utilService: UtilService, public dialog: MatDialog) { }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }

  ngOnInit(): void {
    this.activeAOIType = 0;
    this.originalImageIndex = this.imageIndex;
    this.setOriginalImages(this.images);
  }

  handleActive(event): void {
    this.isActive.emit(event);
  }

  ngxZoomPos(event) { }

  onFullScreen() {
    this.toggleFullScreen.emit(
      this.originalArray[this.imageIndex]?.original_image
    );
  }

  handleZoom() {
    this.zoomActive = !this.zoomActive;
    this.zoom.zoomContainer.nativeElement.click();
  }

  openInformation(event): void {
    // open info dialog about use case
    event.stopPropagation();
    event.preventDefault();

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      content: `
        <div class="text-left">
          <h4 class="text-left">mdlversion:</h4>
          <p>${ this.data.mdlversion }</p>
          <h4 class="text-left">algversion:</h4>
          <p>${ this.data.algversion }</p>
        </div>
      `,
      data: {
        name: 'Information',
        content: `<i>We currently have no information</i>`,
      },
    };
    dialogConfig.width = '90%';
    dialogConfig.panelClass = 'modal-primary';

    this.dialog.open(InformationOfTypeDialogComponent, dialogConfig);
  }

  onChangeState(): void {
    this.changeState.emit();
  }

  setOriginalImages(images): any {
    this.originalArray = [];

    if (images) {
      images.forEach((original) => {
        // this.originalArray.push({
        //   original_image: original.original_images,
        //   original_image_thumbnail: original.original_images_thumbnail,
        //   name: original.name,
        //   output: original,
        // });
        /**
         * * NEW Object 'processed_original_images'
         */

        if (
          original.processed_original_images &&
          original.processed_original_images.length !== 0
        ) {
          this.originalArray.push({
            original_image: original.processed_original_images.url,
            original_image_thumbnail:
              original.processed_original_images.thumbnail,
            name: original.name,
            output: original,
          });
        }
      });
    }
  }
}
