import { filter } from 'rxjs/operators';
import { BasicDialogComponent } from '@shared/components/basic-dialog/basic-dialog.component';
import { AuthenticationService } from '@services/authentication.service';
import { Subscription } from 'rxjs';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ControlContainer,
  FormGroupDirective,
  FormArray,
} from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper, MatStepperIntl } from '@angular/material/stepper';
import { UserService } from '@services/user.service';
import { ToastService } from '@services/toast.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HelpersService } from '@services/helpers.service';

@Component({
  selector: 'app-register-clinic-account-type-stepper',
  templateUrl: './register-clinic-account-type-stepper.component.html',
  styleUrls: ['./register-clinic-account-type-stepper.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class RegisterClinicAccountTypeStepperComponent implements OnInit {
  // values for primary account owner
  AccOwner = {
    yes: 'clinic_admin',
    no: 'user',
  };

  loading: boolean = false;

  accountType: FormGroup;

  billingForm: FormGroup;

  pricingForm: FormGroup;

  invitesForm: FormGroup;

  hideBilling: boolean = false;

  clinicsList: any[] = [];

  subscription: Subscription[] = [];

  @ViewChild('stepper') myStepper: MatStepper;

  constructor(
    private fb: FormBuilder,
    private _matStepperIntl: MatStepperIntl,
    private userService: UserService,
    private authService: AuthenticationService,
    private toast: ToastService,
    private dialog: MatDialog,
    private helperService: HelpersService
  ) { }

  /**
   * * Lifecycles
   */
  ngOnInit(): void {
    /**
     * Clear option text label
     */
    this._matStepperIntl.optionalLabel = '';

    this.invitesForm = this.fb.group({
      invites: this.fb.array([
        this.fb.group({
          email: ['', [Validators.email, Validators.maxLength(190)]],
        }),
      ]),
    });

    this.pricingForm = this.fb.group({
      plan: ['', Validators.required],
    });

    this.billingForm = this.fb.group({
      billingFirstName: ['', [Validators.required, Validators.maxLength(190)]],
      billingLastName: ['', [Validators.required, Validators.maxLength(190)]],
      billingCardNumber: ['', Validators.required],
      billingAddress: ['', [Validators.required, Validators.maxLength(190)]],
      billingExpireDate: ['', Validators.required],
      billingCVV: ['', Validators.required],
      creditCardType: [''],
    });
  }

  /**
   * * Events
   */
  submitBilling(): void {
    // set converted expire date for credit card in correct format
    this.loading = true;

    /**
     * * trim space billing card number
     */
    this.billingForm
      .get('billingCardNumber')
      .setValue(
        this.billingForm.get('billingCardNumber').value.replace(/\s/g, '')
      );

    const data = {
      ...this.billingForm.getRawValue(),
      ...this.pricingForm.getRawValue(),
      billingExpireDate: this.helperService.convertBillingExpireDate(
        this.billingForm.get('billingExpireDate').value
      ),
      type: 'clinic_admin',
      clinic: this.authService.currentUserValue.user?.clinic_id,
    };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(BasicDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => { });

    this.userService.registerPlan(data).subscribe(
      (resp) => {
        setTimeout(() => {
          this.loading = false;
          this.myStepper.next();
          dialogRef.close();
          this.toast.showSuccess('The payment was completed successfully', '');
        }, 1000);
      },
      (error) => {
        this.loading = false;
        dialogRef.close();
        this.toast.showError(error.message, error);
      }
    );
  }

  /**
   * * Helpers
   */
  clinicName(clinicId) {
    return this.clinicsList.find((clinic) => clinic.id == clinicId)?.name;
  }

  get invitesArray() {
    return this.invitesForm.get('invites') as FormArray;
  }

  get fieldsInvalid() {
    return this.accountType.get('type').value === this.AccOwner.yes
      ? this.accountType.get('clinic').invalid
      : this.accountType.get('clinic_id').invalid;
  }

  /** Add email to array */
  onAddEmail() {
    this.invitesArray.push(this.fb.group({ email: ['', Validators.email] }));
  }

  /** Remove email from array */
  onRemoveEmail(index) {
    this.invitesArray.removeAt(index);
  }

  /**
   * Submit for clinic admin, including invites
   */
  submitWithInvites() {
    this.loading = true;

    // * remove empty fields for email
    let invitedEmails = this.invitesForm.getRawValue();
    invitedEmails = invitedEmails.invites.filter((invite) => invite.email && invite.email !== '');

    this.userService.sendInvites({ invites: invitedEmails }).subscribe(
      (resp) => {
        this.loading = false;
        this.toast.showSuccess('Invitations has been sent successfully', '');
        this.authService.logout();
      },
      (error) => {
        this.loading = false;
        this.toast.showError(error.message, '');
      }
    );
  }
}
