import { Observable, BehaviorSubject } from 'rxjs';
import { Statistics } from './../models/Statistics';
import { Injectable } from '@angular/core';
import { Role } from '../enums/role';
import { AuthenticationService } from './authentication.service';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root',
})
export class RequestsStatsService {
  role = Role;

  constructor(
    private authService: AuthenticationService,
    private notificationService: NotificationsService
  ) {}

  // * Fetch number of new requests for ****-requests table
  loadRequestsNotification() {
    if (
      this.authService.currentUserValue.user.role === this.role.CLINIC_ADMIN ||
      this.authService.currentUserValue.user.role === this.role.ADMIN
    ) {
      this.notificationService.getRequestsNumberStatus().subscribe((resp) => {
        Object.assign(this.notificationService.requestsNotification, resp);
      });
    }
  }
}
