<div class="cards relative">
  <ngx-spinner name="clinicPerformance">
    <p style="font-size: 20px; color: #000">Loading Performance...</p>
  </ngx-spinner>

  <div class="cards-header d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center w-100">
      <strong>Clinic Performanse</strong>
      <span class="cards-header-actions"
        ><a href=""
          >View more<span class="material-icons">
            keyboard_arrow_right
          </span></a
        ></span
      >
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="cards-body">
    <div
      *ngFor="let performance of data?.slice(0, 3); let i = index"
      class="cards-body-news-item d-flex align-items-center justify-content-between"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <p class="cards-body-news-item-title">{{ performance.name }}</p>
          <p class="cards-body-news-item-date m-0">
            {{ performance.casesCount }} cases
          </p>
        </div>
      </div>
      <div class="color-primary text-center">
        <div>
          <strong
            >{{
              utilService.formatNumberWithPercentage(
                performance.weeklyData.numberOfCasesIncrease
              )
            }}
          </strong>
        </div>
        <strong>Weekly cases</strong>
      </div>
      <div class="color-primary text-center">
        <div>
          <strong class="block">{{
            utilService.formatNumberWithPercentage(
              performance.monthlyData.numberOfCasesIncrease
            )
          }}</strong>
        </div>
        <strong>Monthly patients</strong>
      </div>
    </div>

    <!-- <div class="cards-body-news-item d-flex align-items-center justify-content-between">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <p class="cards-body-news-item-title">PetView Clinic</p>
          <p class="cards-body-news-item-date m-0">23 cases</p>
        </div>
      </div>
      <div class="color-primary text-center">
        <div><strong>+2%</strong></div>
        <strong>Weekly cases</strong>
      </div>
      <div class="color-primary text-center">
        <div><strong class="block">+22%</strong></div>
        <strong>Monthly patients</strong>
      </div>
    </div>

    <div class="cards-body-news-item d-flex align-items-center justify-content-between">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <p class="cards-body-news-item-title">HopeForPaws Clinic</p>
          <p class="cards-body-news-item-date m-0">22 cases</p>
        </div>
      </div>
      <div class="color-primary text-center">
        <div><strong>+3%</strong></div>
        <strong>Weekly cases</strong>
      </div>
      <div class="color-primary text-center">
        <div><strong class="block">+8%</strong></div>
        <strong>Monthly patients</strong>
      </div>
    </div> -->
  </div>
</div>
