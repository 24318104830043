import { ActivatedRoute, Router } from '@angular/router';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { HelpersService } from '@services/helpers.service';
import { UserService } from '@services/user.service';
import { ToastService } from '@services/toast.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  // viewProviders: [
  //   {
  //     provide: ControlContainer,
  //     useExisting: FormGroupDirective,
  //   },
  // ]
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;

  token: string = '';

  constructor(
    private fb: FormBuilder,
    private helperService: HelpersService,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group(
      {
        password: ['', Validators.required],
        password_confirmation: ['', Validators.required],
        token: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
      },
      { validator: this.helperService.checkPasswords }
    );

    /** Set token & email from params */
    this.forgotPasswordForm.patchValue({
      token: this.route.snapshot.paramMap.get('token'),
      email: this.route.snapshot.queryParams['email'],
    });

    this.forgotPasswordForm.updateValueAndValidity();
  }

  onSubmitNewPassword() {
    this.userService
      .resetForgotPassword(this.forgotPasswordForm.getRawValue())
      .subscribe(
        (resp) => {
          this.toast.showSuccess('Your password has been set successfuly.', '');
          this.router.navigate(['login']);
        },
        (error) => {
          this.toast.showError(error.message, '');
        }
      );
  }
}
