import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  storage = JSON.parse(localStorage.getItem('currentUser'));

  constructor() { }

  public isAuthenticated(route: ActivatedRouteSnapshot): boolean {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    // route.data.roles = undefined / false
    if (token) {

      if (route.data.roles && route.data.roles.indexOf(token.user.role) === -1) {
        return false;
      }

      return true;
    }
    // Check whether the token is expired and return
    // true or false
    // return !this.jwtHelper.isTokenExpired(token);
    return false;
  }
}
