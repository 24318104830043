import { AuthenticationService } from '@services/authentication.service';
import { BasicDialogComponent } from '@shared/components/basic-dialog/basic-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
  FormArray,
} from '@angular/forms';
import { HelpersService } from '@services/helpers.service';
import { DropdownService } from '@services/dropdown.service';
import { ToastService } from '@services/toast.service';
import { MatStepper, MatStepperIntl } from '@angular/material/stepper';
import { UserService } from '@services/user.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuditsService } from '@services/audits.service';
import { UserActivityLog } from '@models/UserActivityLog';
import { IDeactivateComponent } from '@app/guards/deactivate-activity-guard.service';
import { RegisterService } from '@services/register.service';
import { debounceTime } from 'rxjs/operators';
import { UtilService } from '@services/util.service';

@Component({
  selector: 'app-register-account-type-stepper',
  templateUrl: './register-account-type-stepper.component.html',
  styleUrls: ['./register-account-type-stepper.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class RegisterAccountTypeStepperComponent implements OnInit {
  // values for primary account owner
  AccOwner = {
    yes: 'clinic_admin',
    no: 'user',
  };

  loading: boolean = false;

  accountType: FormGroup;

  billingForm: FormGroup;

  pricingForm: FormGroup;

  invitesForm: FormGroup;

  emitNewState = new Subject();

  user: any;

  currentStep: number;

  sessionId: string;

  completedSteps: any[] = [];

  hideBilling: boolean = false;
  isTrialPlan: boolean = false;

  clinicsList: any[] = [];

  subscription: Subscription[] = [];

  @ViewChild('stepper') myStepper: MatStepper;

  constructor(
    private fb: FormBuilder,
    private dropdownService: DropdownService,
    private userService: UserService,
    private toast: ToastService,
    private _matStepperIntl: MatStepperIntl,
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthenticationService,
    private helperService: HelpersService,
    private auditsService: AuditsService,
    private utilService: UtilService,
    private registerService: RegisterService,
  ) { }

  clinicName(clinicId) {
    return this.clinicsList.find((clinic) => clinic.id == clinicId)?.name;
  }

  get invitesArray() {
    return this.invitesForm.get('invites') as FormArray;
  }

  get fieldsInvalid() {
    return this.accountType.get('type').value === this.AccOwner.yes
      ? this.accountType.get('clinic').invalid
      : this.accountType.get('clinic_id').invalid;
  }

  ngOnInit(): void {
    /**
     * Clear option text label
     */
    this._matStepperIntl.optionalLabel = '';

    // set session id
    this.sessionId = this.helperService.genCryptoHexKey();

    this.user = this.authService.currentUserValue;

    this.accountType = this.fb.group({
      type: ['', Validators.required],
      clinic_id: ['', Validators.required],
      clinic: ['', [Validators.required, Validators.maxLength(190)]],
    });

    this.invitesForm = this.fb.group({
      invites: this.fb.array([
        this.fb.group({
          email: ['', [Validators.required, Validators.email, Validators.maxLength(190)]],
        }),
      ]),
    });

    this.pricingForm = this.fb.group({
      plan: ['', Validators.required],
    });

    this.billingForm = this.fb.group({
      billingFirstName: ['', [Validators.required, Validators.maxLength(190)]],
      billingLastName: ['', [Validators.required, Validators.maxLength(190)]],
      billingCardNumber: ['', Validators.required],
      billingAddress: ['', [Validators.required, Validators.maxLength(190)]],
      billingExpireDate: ['', Validators.required],
      billingCVV: ['', Validators.required],
      creditCardType: [''],
    });

    this.subscription.push(
      this.dropdownService.getAllClinics().subscribe(
        (resp) => {
          this.clinicsList = resp;
        },
        (error) => {
          this.toast.showError(
            'Error: ',
            "Clinics can't be loaded, Please try again later."
          );
        }
      )
    );

    this.emitNewState.pipe(
      debounceTime(100)
    ).subscribe(() => {

      let pricingPlan = {
        plan: this.utilService.pricingPlans(this.pricingForm.getRawValue())
      }

      const data: UserActivityLog = {
        session_id: this.sessionId,
        email: this.user.user.email,
        route: 'account-type',
        // form_step: this.myStepper,
        form_fields: {
          ...this.accountType.getRawValue(),
          ...pricingPlan,
          ...this.invitesForm.value,
          // billing: this.billingForm.value
        }
      }

      this.auditsService.addUserActivityLog(data)
    })
  }

  setBillingStepper(event) {
    this.hideBilling = event;
  }

  onSelectPlan(event) {
    this.hideBilling = event;
    if (event) {

    }
  }

  dismiss(element: HTMLElement) {
    element.style.display = 'none';
  }

  selectionChange(event) {
    /**
     * * Selection Change Event Handler
     */
  }

  /**
   * Submit for doctor / no invites
   */
  onSubmitRequest() {
    this.loading = true;

    const data = {
      ...this.accountType.getRawValue(),
      ...this.pricingForm.getRawValue(),
    };

    // * Set type as trial_user for FREE plan
    if (this.pricingForm.get('plan').value === -1) {
      data.type = 'trial_user';
    }

    this.userService.registerPlan(data).subscribe(
      (resp) => {
        this.loading = false;
        this.authService.logout();
        this.toast.showSuccess('Thank you for finish registration', 'Registration Completed');
      },
      (error) => {
        this.loading = false;
        this.toast.showError(error.message, 'Error');
      }
    );
  }

  /** Add email to array */
  onAddEmail() {
    this.invitesArray.push(this.fb.group({ email: ['', Validators.email] }));
  }

  /** Remove email from array */
  onRemoveEmail(index) {
    this.invitesArray.removeAt(index);
  }

  /**
   * Handle Changing step
   */
  onChangeStep(event) {
    const { selectedIndex } = event;

    const isCompleted = this.completedSteps.indexOf(selectedIndex);

    let pricingPlan = {
      plan: this.utilService.pricingPlans(this.pricingForm.getRawValue())
    }

    // if (isCompleted === -1) {

    const data: UserActivityLog = {
      session_id: this.sessionId,
      email: this.user.user.email,
      route: 'account-type',
      form_step: this.helperService.getAccountTypeStepLabel(selectedIndex),
      form_fields: {
        ...this.accountType.getRawValue(),
        ...pricingPlan,
        ...this.invitesForm.value,
        // billing: this.billingForm.value
      }
    }

    this.auditsService.addUserActivityLog(data).subscribe({ next: (res) => { } });

    this.completedSteps.push(selectedIndex);
  }

  /**
   * Submit for clinic admin, including invites
   */
  submitWithInvites() {
    this.loading = true;

    // * remove empty fields for email
    let invitedEmails = this.invitesForm.getRawValue();
    invitedEmails = invitedEmails.invites.filter((invite) => invite.email && invite.email !== '');

    this.userService.sendInvites({ invites: invitedEmails }).subscribe(
      (resp) => {
        this.loading = false;
        this.toast.showSuccess('Invites have been sent successfuly', '');
        this.authService.logout();
      },
      (error) => {
        this.loading = false;
        this.toast.showError(error.message, '');
      }
    );
  }

  public userActivityRecorded(
    component: IDeactivateComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): any {
    this.emitNewState.next();

    setTimeout(() => { return true; }, 1000)
  };

  /**
   * Submit billing
   */
  submitBilling() {
    // set converted expire date for credit card in correct format
    this.loading = true;

    /**
     * * trim space billing card number
     */
    this.billingForm
      .get('billingCardNumber')
      .setValue(
        this.billingForm.get('billingCardNumber').value.replace(/\s/g, '')
      );

    const data = {
      ...this.accountType.getRawValue(),
      ...this.billingForm.getRawValue(),
      ...this.pricingForm.getRawValue(),
      billingExpireDate: this.helperService.convertBillingExpireDate(
        this.billingForm.get('billingExpireDate').value
      ),
    };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(BasicDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => { });

    this.userService.registerPlan(data).subscribe(
      (resp) => {
        setTimeout(() => {
          this.loading = false;
          this.myStepper.next();
          dialogRef.close();
          this.toast.showSuccess('Credit Card informations added successfully', 'Payment details');
        }, 1000);
      },
      (error) => {
        this.loading = false;
        dialogRef.close();
        this.billingForm.get('billingCardNumber').setErrors({ 'incorrect': true });
        this.billingForm.get('billingExpireDate').setErrors({ 'incorrect': true });
        this.billingForm.get('billingCVV').setErrors({ 'incorrect': true });
        this.toast.showError('Credit Card is not valid or informations is not complete. Please check and try again.', 'Error occures');
      }
    );
  }
}
