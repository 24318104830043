import { map } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-personal-data-common-cases',
  templateUrl: './card-personal-data-common-cases.component.html',
  styleUrls: ['./card-personal-data-common-cases.component.scss'],
})
export class CardPersonalDataCommonCasesComponent implements OnInit {
  pieData: any;

  @Input() set data(value) {
    if (value) {
      this.pieData = this.formatToPieData(value);
    }
  }

  constructor() {}

  ngOnInit(): void {}

  formatToPieData(data) {
    const usecaseArray = data.usecaseData;
    return usecaseArray?.map((c: any) => {
      return { name: c.usecaseName, value: c.numberOfCases };
    });
  }
}
