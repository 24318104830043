// import { MaterialModule } from './../../modules/material/material.module';
// import { MaterialModule } from 'app/modules/material/material.module';
import { DrawingImageListModule } from './drawing-image-list/drawing-image-list.module';
import { DrawingComponent } from './drawing.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@modules/material/material.module';
import { AoiTypeToolsModule } from '../aoi-type-tools/aoi-type-tools.module';
import { DrawingRoutingModule } from './drawing-routing.module';


@NgModule({
  declarations: [
    DrawingComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    DrawingImageListModule,
    AoiTypeToolsModule,
    DrawingRoutingModule
  ],
  exports: [
    DrawingComponent
  ]
})
export class DrawingModule { }
