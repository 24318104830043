import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './../../modules/material/material.module';
import { PasswordStrengthModule } from './../../shared/form-elements/password-strength/password-strength.module';
import { FieldErrorsModule } from './../../shared/form-elements/field-errors/field-errors.module';
import { RegisterPasswordFormComponent } from './register-password-form.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [RegisterPasswordFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FieldErrorsModule,
    PasswordStrengthModule,
  ],
  exports: [RegisterPasswordFormComponent]
})
export class RegisterPasswordFormModule { }
