<!-- List of Images -->
<div class="list-images">
  <div class="images-group">
    <div
      class="single"
      [ngClass]="{ active: i === selectedIndex, completed: imagesStatus[i] }"
      (click)="onSelectImage(allData, i)"
      *ngFor="let allData of images.allImages; let i = index"
    >
      <img [src]="allData.preview" alt="" />
      <div
        class="d-flex justify-content-between align-items-center"
        [ngClass]="{ completed: imagesStatus[i] }"
      >
        <span
          class="name"
          [matTooltip]="allData?.fullData?.value?.original_image_name"
          [matTooltipDisabled]="
            allData?.fullData?.value?.original_image_name?.length < 15
          "
        >
          {{
            allData?.fullData?.value?.original_image_name?.length > 15
              ? (allData?.fullData?.value?.original_image_name | slice: 0:8) +
                ".." +
                (allData?.fullData?.value?.original_image_name
                  | slice
                    : allData?.fullData?.value?.original_image_name?.length - 7
                    : allData?.fullData?.value?.original_image_name?.length)
              : allData?.fullData?.value?.original_image_name
          }}
        </span>
        <div class="status">
          <mat-icon *ngIf="!imagesStatus[i]" class="material-icons"
            >check_box_outline_blank
          </mat-icon>
          <span *ngIf="imagesStatus[i]" class="petview-check_1"></span>
        </div>
      </div>
    </div>
  </div>
</div>
