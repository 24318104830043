import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plurals',
})
export class PluralsPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (args[0] === 1) {
      return value;
    }
    return `${value}s`;
  }
}
