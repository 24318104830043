import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaterialModule } from '@modules/material/material.module';
import { AuditLogsDetailsComponent } from './audit-logs-details/audit-logs-details.component';
import { AuditLogsComponent } from './audit-logs.component';



@NgModule({
  declarations: [
    AuditLogsComponent,
    AuditLogsDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxSpinnerModule,
    MatTooltipModule,
    MaterialModule,
    MatPaginatorModule,
  ], 
  exports: [
    AuditLogsComponent,
    AuditLogsDetailsComponent
  ]
})
export class AuditLogsModule { }
