import { FieldErrorsModule } from '@shared/form-elements/field-errors/field-errors.module';
import { RegisterPasswordFormModule } from './../../register/register-password-form/register-password-form.module';
import { ForgotPasswordComponent } from './forgot-password.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@modules/material/material.module';



@NgModule({
  declarations: [
    ForgotPasswordComponent
  ],
  imports: [
    CommonModule,
    RegisterPasswordFormModule,
    MaterialModule,
    FieldErrorsModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ForgotPasswordComponent
  ]
})
export class ForgotPasswordModule { }
