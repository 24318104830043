import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageViewerService {
  private $refreshImageSubject = new BehaviorSubject(false);
  readonly refreshImage = this.$refreshImageSubject.asObservable();

  private $moveSnapSubject = new BehaviorSubject(false);
  readonly moveSnap = this.$moveSnapSubject.asObservable();

  private $moveImageSubject = new BehaviorSubject(false);
  readonly moveImage = this.$moveImageSubject.asObservable();

  first: any = null;
  second: any = null;

  // zoomPercentage: number = 0;
  // cordX: number = 0;
  // cordY: number = 0;

  constructor() { }

  get refreshImageEmitter(): Observable<any> {
    return this.$refreshImageSubject.asObservable();
  }

  set refreshImageSetter(value: boolean) {
    this.$refreshImageSubject.next(value);
  }

  get moveSnapEmitter(): Observable<any> {
    return this.$moveImageSubject.asObservable();
  }

  set moveSnapSetter(value: any) {
    this.$moveImageSubject.next(value);
  }

  get moveImageEmitter(): Observable<any> {
    return this.$moveSnapSubject.asObservable();
  }

  set moveImageSetter(value: any) {
    this.$moveSnapSubject.next(value);
  }

  updateImageViewZoom(zoom: any, x: any, y: any) {
    // this.zoomPercentage = zoom;
    // this.cordX = x;
    // this.cordY = y;
  }
}
