export enum pricingPlanEnum {
  PER_CASE = 'percase', // Per case
  BASE = '150', // 150$ / Month
  POPULAR = '250', // 250$ / Month
  ENTERPRISE = '350', // 350$ / Month

  PER_CASE_ID = 1,
  BASE_ID = 2,
  POPULAR_ID = 3,
  ENTERPRISE_ID = 4,
}
