<ng-container
  *ngIf="formField.invalid || formField?.dirty || formField?.touched"
>
  <span class="field-error" *ngIf="formField?.hasError('required')">
    This is a required field.
  </span>
  <span class="field-error" *ngIf="formField?.hasError('email')">
    Email format is invalid.
  </span>
  <span class="field-error" *ngIf="formField?.hasError('emailTaken')">
    Email already exist.
  </span>
  <span class="field-error" *ngIf="formField?.hasError('minlength')">
    Too short.
  </span>
  <span class="field-error" *ngIf="formField?.hasError('maxlength')">
    Too much characters limit is 190.
  </span>
  <span class="field-error" *ngIf="formField?.hasError('mismatchedPasswords')">
    Passwords are mismatched.
  </span>
  <span class="field-error" *ngIf="formField?.hasError('noWhitespaceRequired')">
    No spaces allowed.
  </span>
  <span class="field-error" *ngIf="formField?.hasError('passwordRule')">
    Password is not strong enough.
  </span>
  <span class="field-error" *ngIf="formField?.hasError('digitCharacterRule')">
    Must have at least one number.
  </span>
  <span class="field-error" *ngIf="formField?.hasError('notDate')">
    Not a valid date.
  </span>
  <span class="field-error" *ngIf="formField?.hasError('atLeastOne')">
    At least one field has to be provided.
  </span>
</ng-container>
