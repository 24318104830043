import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterReferenceImage'
})
export class FilterReferenceImagePipe implements PipeTransform {

  transform(value: any[], compare: any, referentName: string): any[] {
    if (value && value.length !== 0) {
      return value.filter(ref => ref.image_type.id == compare)[0]?.value?.filter(img => img.referent_algorithm === referentName).map((value) => value.url);
    }
    return [];
  }

}
