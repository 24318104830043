import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { UserService } from '@services/user.service';
import { ToastService } from '@services/toast.service';

@Component({
  selector: 'app-request-reset-password',
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['./request-reset-password.component.scss'],
})
export class RequestResetPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;

  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmit() {
    this.loading = true;
    this.userService
      .sendResetLinkOnMail(this.forgotPasswordForm.getRawValue())
      .subscribe(
        (resp) => {
          this.loading = false;
          this.toast.showSuccess(
            'Please check your email address for further instrucations how to reset your password',
            'Password reset link has been sent.'
          );
        },
        (error) => {
          this.loading = false;
          this.toast.showError(error, 'Error');
        }
      );
  }
}
