import { NotificationsService } from './../_services/notifications.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AuthenticationService } from './../_services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private notify: NotificationsService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        catchError(err => {
          if (err.status === 401) {
            const error = err.error.message || err.statusText;
            this.authenticationService.logout();
            return throwError(error);
            // location.reload(true);
          } else if (err.status === 400) {
            const error = err.error.message || err.statusText;
            this.authenticationService.logout();
            if (error === 'Credentials not match.') {
              return throwError(error);
            }
          }

          const error = err.error.message || err;

          return throwError(error);
        })
      );
  }
}
