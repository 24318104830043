import { DrawingModule } from './drawing/drawing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MaterialModule } from '@modules/material/material.module';
import { HintDialogComponent } from './hint-dialog/hint-dialog.component';
import { AoiTypeToolsModule } from './aoi-type-tools/aoi-type-tools.module';
import { DrawingImageListModule } from './drawing/drawing-image-list/drawing-image-list.module';
import { DrawingRoutingModule } from './drawing/drawing-routing.module';


@NgModule({
  declarations: [
    HintDialogComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    DrawingImageListModule,
    AoiTypeToolsModule,
    DrawingModule,
    DrawingRoutingModule
  ],
  exports: [
    MatDialogModule,
    HintDialogComponent,
  ]
})
export class AoiToolModule { }
