<div class="cards no-padding">
  <div class="cards-header card-header-padding">
    <strong>Your data</strong>
    <span class="cards-header-actions"><a [routerLink]="['/cases']">View more<span class="material-icons">
          keyboard_arrow_right </span></a></span>
  </div>

  <div
    id="transactionTable"
    class="table-wrapper no-shadow relative"
  >
    <ngx-spinner name="table">
      <p style="font-size: 20px; color: #000">Loading...</p>
    </ngx-spinner>

    <table
      mat-table
      [dataSource]="dataSource"
      matSort
    >
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          <!-- (change)="$event ? masterToggle() : null" -->
          <!-- [indeterminate]="selection.hasValue() && !isAllSelected()" -->
          <!-- <mat-checkbox [checked]="selection.hasValue()">
                                      </mat-checkbox> -->
          <button
            [disabled]="isDeleteHidden()"
            matTooltip="Delete All Selected"
            matTooltipPosition="above"
            (click)="onDeleteAll()"
            type="button"
            rel="tooltip"
            mat-icon-button
            color="warn"
          >
            <i class="material-icons">delete_sweep</i>
          </button>
        </th>
        <td
          mat-cell
          *matCellDef="let row"
        >
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th
          mat-header-cell
          *matHeaderCellDef
        >ID</th>
        <td
          mat-cell
          *matCellDef="let row"
        >{{ row?.id }}</td>
      </ng-container>

      <!-- User Column -->
      <ng-container
        matColumnDef="user"
        *ngIf="loggedRole === 'admin' || loggedRole === 'clinic_admin'"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
        >User</th>
        <td
          mat-cell
          *matCellDef="let row"
        >
          {{ row?.user?.name }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="client">
        <th
          mat-header-cell
          *matHeaderCellDef
        >Owner / Patient Ref.</th>
        <td
          mat-cell
          *matCellDef="let row"
          [matTooltip]="formatClientName(row.client)"
        >
          <span
            [ngClass]="{ 'empty-cell': !row.patient_refid }"
            [matTooltip]="'Patient ref ID for Quick Process case'"
            *ngIf="row.case_type.id === CaseTypeId.QUICK"
          >
            {{ row.patient_refid ? row.patient_refid : "no patient ref" }}
          </span>
          <span
            *ngIf="row.case_type.id !== CaseTypeId.QUICK"
            [matTooltip]="row?.client.name"
            [ngClass]="{ 'empty-cell': !row.client }"
          >{{
            row.client
            ? formatClientName(row.client).length > 15
            ? (formatClientName(row.client) | slice: 0:15) + "..."
            : formatClientName(row.client)
            : "no owner"
            }}
          </span>
        </td>
      </ng-container>

      <!-- Case Type Column -->
      <ng-container matColumnDef="aoi_type">
        <th
          mat-header-cell
          *matHeaderCellDef
        >Case Type</th>
        <td
          mat-cell
          *matCellDef="let row"
          [matTooltip]="utilService.getCaseTypeName(row.case_type)"
        >
          {{
          utilService.getCaseTypeName(row.case_type).length > 15
          ? (utilService.getCaseTypeName(row.case_type) | slice: 0:15) +
          "..."
          : utilService.getCaseTypeName(row.case_type)
          }}
        </td>
      </ng-container>

      <!-- created Column -->
      <ng-container matColumnDef="created">
        <th
          mat-header-cell
          *matHeaderCellDef
        >Created</th>
        <td
          mat-cell
          *matCellDef="let row"
        >
          {{ row.created_at | date: "mediumDate" }}
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let row">
          <mat-slide-toggle (change)="onChangeStatus(row.id, $event)" [checked]="row.status == 1">
          </mat-slide-toggle>
        </td>
      </ng-container> -->

      <!-- Algorithms Column -->
      <ng-container matColumnDef="algorithm">
        <th
          mat-header-cell
          *matHeaderCellDef
        >Use Case</th>
        <td
          mat-cell
          *matCellDef="let row"
          [matTooltip]="
            formatArrayToString(
              utilService.multipleValuesCell(
                row,
                'algorithm',
                'name',
                row.case_type_id,
                'algorithm'
              )
            ) | stripehtml
          "
        >
          <span
            [ngClass]="{
              'empty-cell': !utilService.multipleValuesCell(
                row,
                'algorithm',
                'name',
                row.case_type_id,
                'algorithm'
              ).length
            }"
            [innerHTML]="
              utilService.multipleValuesCell(
                row,
                'algorithm',
                'name',
                row.case_type_id,
                'algorithm'
              ).length
                ? formatArrayToString(
                    utilService.multipleValuesCell(
                      row,
                      'algorithm',
                      'name',
                      row.case_type_id,
                      'algorithm'
                    )
                  )
                : 'no use case'
            "
          >
          </span>
        </td>
      </ng-container>

      <!-- Status -->
      <ng-container matColumnDef="status">
        <th
          mat-header-cell
          *matHeaderCellDef
        >Status</th>
        <td
          mat-cell
          *matCellDef="let row"
        >
          <!-- waiting for processing -->
          <ng-container *ngIf="row.processing">
            <span class="other-values">
              <span class="status-grayed name">waiting for processing</span>
            </span>
          </ng-container>

          <ng-container *ngIf="!row.processing">
            <app-graph-stats
              *ngIf="row.stdev"
              [data]="parsingGraphStats(row.stdev)"
              [indicatorSection]="true"
              [useCaseSuffix]="row.algorithm?.suffix"
            >
            </app-graph-stats>
            <span
              [ngStyle]="{
                color: utilService.getColorForMLEval(row.ml.eval).color
              }"
              class="other-values"
              *ngIf="!row.stdev"
            ><span
                [ngStyle]="{
                  backgroundColor: utilService.getColorForMLEval(row.ml.eval)
                    .background
                }"
                [ngClass]="{
                  'gray-bg': utilService.getEvalValue2(row.ml) === 'N/A'
                }"
                class="name"
              >
                <li>
                  {{
                  row.ml.length !== 0
                  ? row.ml.eval
                  ? (row.ml.eval | lowercase)
                  : "undefined"
                  : "undefined"
                  }}
                </li>
              </span>
              <span [ngStyle]="{
                  backgroundColor: utilService.getColorForMLEval(row.ml.eval)
                    .background
                }">{{ utilService.getEvalValue2(row.ml, '', row.algorithm?.suffix)}}</span></span>
          </ng-container>
        </td>
        <!-- <td mat-cell *matCellDef="let row"></td> -->
      </ng-container>

      <ng-container matColumnDef="actions">
        <th
          mat-header-cell
          *matHeaderCellDef
        >Actions</th>
        <td
          mat-cell
          class="actions-cell"
          *matCellDef="let row"
        >
          <!-- Preview -->
          <button
            [disabled]="row.processing == 1"
            matTooltip="View"
            matTooltipPosition="above"
            (click)="onView(row.id)"
            type="button"
            rel="tooltip"
            mat-icon-button
            color="primary"
          >
            <!-- <mat-icon class="material-icons-outlined">search</mat-icon> -->
            <mat-icon class="material-icons-outlined">search</mat-icon>
          </button>
          <!-- Edit no processing -->
          <!-- routerLink="/cases/edit/no-processing/{{row.id}}"  -->

          <button
            [disabled]="
              row.processing == 1 ||
              !(row.case_type_id == 1 || row.case_type_id == 2) ||
              true
            "
            matTooltip="Edit"
            matTooltipPosition="above"
            (click)="onEdit(row.id, row.case_type_id)"
            type="button"
            rel="tooltip"
            mat-icon-button
            color="primary"
          >
            <mat-icon class="material-icons-outlined">edit</mat-icon>
          </button>

          <!-- edit Normal / Quick View -->
          <!-- routerLink="/cases/edit/{{row.id}}" -->
          <!-- <button [disabled]="row.processing == 1 || row.case_type_id == 1" matTooltip="Edit" matTooltipPosition="above"
            type="button" rel="tooltip" mat-icon-button color="primary"><mat-icon class="material-icons-outlined">edit</mat-icon></button> -->
          <!-- Delete -->
          <button
            [disabled]="row.processing == 1"
            matTooltip="Delete"
            matTooltipPosition="above"
            (click)="OnDeleteCase(row.id)"
            type="button"
            rel="tooltip"
            mat-icon-button
            color="primary"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="Delete"
            >delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr
        mat-row
        [ngClass]="{
          'red-text': row.ml.eval === 'abnormal',
          'closed-case': row.status == 1
        }"
        *matRowDef="let row; columns: displayedColumns"
      ></tr>

      <!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
      <tr
        class="mat-row"
        *matNoDataRow
      >
        <td
          class="mat-cell no-data-cell"
          colspan="4"
        >No data</td>
      </tr>
    </table>

    <mat-paginator
      [length]="total"
      [pageSize]="pageSize"
    ></mat-paginator>
  </div>
</div>
