import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AoitypeService {

  aoiLabels = {
    label1: 'FOV',
    label2: 'Spleen',
    label3: 'Lesion',
    label4: 'ROI'
  };

  aoitypeData = [
    {
      id: 1,
      label: this.aoiLabels.label1,
      data: null,
      isActive: false,
      hidden: false,
      valid: false,
      thumbnail: null,
      description: 'The field of view is the extent of the observable world that is seen at any given moment. In the case of optical instruments or sensors it is a solid angle through which a detector is sensitive to electromagnetic radiation.'
    },
    {
      id: 2,
      label: this.aoiLabels.label2,
      data: null,
      isActive: false,
      hidden: false,
      valid: false,
      thumbnail: null,
      description: 'The field of view is the extent of the observable world that is seen at any given moment. In the case of optical instruments or sensors it is a solid angle through which a detector is sensitive to electromagnetic radiation.'
    },
    {
      id: 3,
      label: this.aoiLabels.label3,
      data: null,
      isActive: false,
      hidden: false,
      valid: false,
      thumbnail: null,
      description: 'The field of view is the extent of the observable world that is seen at any given moment. In the case of optical instruments or sensors it is a solid angle through which a detector is sensitive to electromagnetic radiation.'
    },
    {
      id: 4,
      label: this.aoiLabels.label4,
      data: null,
      isActive: false,
      hidden: false,
      valid: false,
      thumbnail: null,
      description: '<p>up to 4 markers</p> The field of view is the extent of the observable world that is seen at any given moment. In the case of optical instruments or sensors it is a solid angle through which a detector is sensitive to electromagnetic radiation.'
    }
  ];

  aoitypeDataClear = [
    {
      id: 1,
      label: this.aoiLabels.label1,
      data: null,
      isActive: false,
      hidden: false,
      valid: false,
      thumbnail: null,
      description: 'The field of view is the extent of the observable world that is seen at any given moment. In the case of optical instruments or sensors it is a solid angle through which a detector is sensitive to electromagnetic radiation.'
    },
    {
      id: 2,
      label: this.aoiLabels.label2,
      data: null,
      isActive: false,
      hidden: false,
      valid: false,
      thumbnail: null,
      description: 'The field of view is the extent of the observable world that is seen at any given moment. In the case of optical instruments or sensors it is a solid angle through which a detector is sensitive to electromagnetic radiation.'
    },
    {
      id: 3,
      label: this.aoiLabels.label3,
      data: null,
      isActive: false,
      hidden: false,
      valid: false,
      thumbnail: null,
      description: 'The field of view is the extent of the observable world that is seen at any given moment. In the case of optical instruments or sensors it is a solid angle through which a detector is sensitive to electromagnetic radiation.'
    },
    {
      id: 4,
      label: this.aoiLabels.label4,
      data: null,
      isActive: false,
      hidden: false,
      valid: false,
      thumbnail: null,
      description: 'The field of view is the extent of the observable world that is seen at any given moment. In the case of optical instruments or sensors it is a solid angle through which a detector is sensitive to electromagnetic radiation.'
    }
  ];

  constructor() {
  }

  getAllLabels(): any {
    return Object.keys(this.aoiLabels).map(key => this.aoiLabels[key]);
  }

  getDataByIndex(index: number): any {
    return this.aoitypeData.filter((val, i) => i === index)[0];
  }

  setAll(data: any): void {
    // let aoiClone = new Array();
    // aoiClone = data;
    this.aoitypeData = [...data];
  }

  getAll(): any {
    let aoiClone = new Array();
    aoiClone = this.aoitypeData;
    return aoiClone;
  }

  clearAll(): void {
    let aoiClone = new Array();
    aoiClone = this.aoitypeData;

    aoiClone.forEach(aoi => {
      aoi.data = null;
    });
  }

  clearThumbnailsAndData(): void {
    let aoiClone = new Array();
    aoiClone = this.aoitypeData;

    aoiClone.forEach(aoi => {
      aoi.data = null;
      aoi.thumbnail = null;
    });
  }

  clearAllForced(): void {
    this.aoitypeData = [...this.aoitypeDataClear];
  }

  setData(index: number, data: any): boolean {
    Object.keys(data).forEach((key) => {
      this.aoitypeData[index][key] = data[key];
    });
    return true;
  }

  setDataAll(index: number, data: any): boolean {
    Object.keys(data).forEach((key) => {
      this.aoitypeDataClear[index][key] = data[key];
    });
    return true;
  }
}
