import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SpinnerService } from '@services/spinner.service';
import { AuditsService } from '@services/audits.service';
import { AuditsDataSource } from '@datasources/audits.datasource';
import { AuditLogsDetailsComponent } from './audit-logs-details/audit-logs-details.component';

@Component({
  selector: 'app-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.scss'],
})
export class AuditLogsComponent implements OnInit, AfterViewInit, OnDestroy {
  // datasource
  dataSource: any;
  displayedColumns: any[] = [
    'id',
    'timestamp',
    'event_type',
    'subject',
    'url',
    'user',
    'user_email',
    'browser',
    'ip',
    'actions',
  ];
  pageSize: number;
  total: number;

  // Filters
  filterSearch: string = '';

  subscription: Subscription[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatExpansionPanel, { static: true }) accordion: MatExpansionPanel;

  constructor(
    private dialog: MatDialog,
    private auditsService: AuditsService,
    private spinner: SpinnerService
  ) {}

  ngOnInit(): void {
    this.dataSource = new AuditsDataSource(this.auditsService);
    this.loadDataSource();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.paginator.page.pipe(tap(() => this.loadAuditsPage())).subscribe();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }

  loadAuditsPage(): void {
    this.dataSource.loadAudits(this.paginator.pageIndex + 1, this.filterSearch);
  }

  loadDataSource(): void {
    this.dataSource.loadAudits(1, this.filterSearch);
    this.paginator.firstPage();
    this.loadPaginationInfo();

    this.subscription.push(
      this.dataSource.loadingSubject.subscribe((res) => {
        if (!res) {
          this.spinner.hideSpinner('table');
        } else {
          this.spinner.showSpinner('table');
        }
      })
    );
  }

  loadPaginationInfo(): void {
    this.auditsService
      .getAllAudits(1, this.filterSearch)
      .subscribe((res: any) => {
        this.total = res.total;
        this.pageSize = res.per_page;
      });
  }

  /** Helpers: */
  resetFilters(): void {}
  onSearch(): void {}

  onDetailsView(row): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = row;
    dialogConfig.panelClass = 'modal-primary';
    this.dialog.open(AuditLogsDetailsComponent, dialogConfig);
  }
}
