import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { HelpersService } from '@services/helpers.service';
import { UserService } from '@services/user.service';
import { ToastService } from '@services/toast.service';

@Component({
  selector: 'app-register-trial',
  templateUrl: './register-trial.component.html',
  styleUrls: ['./register-trial.component.scss'],
})
export class RegisterTrialComponent implements OnInit {
  trialForm: FormGroup;

  passwordState: boolean = false;

  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private helperService: HelpersService,
    private userService: UserService,
    private toast: ToastService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.trialForm = this.fb.group(
      {
        first_name: ['', [Validators.required, Validators.maxLength(190)]],
        last_name: ['', [Validators.required, Validators.maxLength(190)]],
        email: [
          '',
          [Validators.required, Validators.email, Validators.maxLength(190)],
        ],
        subscribe: [''],
        subscriptionAgreement: [
          '',
          [Validators.required, Validators.pattern('true')],
        ],
        termsOfUse: ['', [Validators.required, Validators.pattern('true')]],
        privacyPolicy: ['', [Validators.required, Validators.pattern('true')]],
        password: ['', [Validators.required, Validators.maxLength(190)]],
        password_confirmation: [
          '',
          [Validators.required, Validators.maxLength(190)],
        ],
        trial: [true],
      },
      { validator: this.helperService.checkPasswords }
    );
  }

  onSubmit() {
    this.loading = true;
    this.userService.register(this.trialForm.getRawValue()).subscribe(
      (resp) => {
        this.loading = false;
        this.toast.showSuccess(
          'Account is registered, please check your email',
          'Registration'
        );

        if (!resp || !resp.user?.verify_token) {
          this.router.navigate([`/login`]);
          return;
        }

        this.router.navigate([`/verify/${ resp.user.verify_token }`]);
      },
      (error) => {
        this.loading = false;
        this.toast.showError(this.helperService.errorResponse(error), '');
      }
    );
  }

  nextStep(state) {
    if (state) this.passwordState = state;
  }
}
