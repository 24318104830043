import { FloatingPopupService } from './../../_services/floating-popup.service';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, Subscription } from 'rxjs';
import { tap, first } from 'rxjs/operators';
import { CasesDataSource } from '@datasources/cases.datasource';
import { CaseFilters } from '@models/filters';
import { ServerResponse } from '@models/serverResponse';
import { PercentageFormatPipe } from '@pipes/percentage-format.pipe';
import { AuthenticationService } from '@services/authentication.service';
import { CaseService } from '@services/case.service';
import { GraphStatsService } from '@services/graph-stats.service';
import { NotificationsService } from '@services/notifications.service';
import { SpinnerService } from '@services/spinner.service';
import { ToastService } from '@services/toast.service';
import { UtilService } from '@services/util.service';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { ViewComponent } from 'app/cases/view/view.component';
import { CaseTypeId } from '@enums/CaseTypeId';
import { useCaseNameEnum } from '@enums/useCaseNameEnum';

@Component({
  selector: 'app-card-transtactions-history',
  templateUrl: './card-transtactions-history.component.html',
  styleUrls: ['./card-transtactions-history.component.scss'],
})
export class CardTranstactionsHistoryComponent
  implements OnInit, OnDestroy, AfterViewInit {
  caseList: any[] = [];
  filteredCaseList: any[] = [];
  filterStatus = '';
  filterType = '';
  deleteCases: any[] = [];
  hideDeleteAllBtn = true;
  filterArrayUseCase: any[] = [];
  messageCaseId: any = 0;

  message: BehaviorSubject<any>;
  subscription: Subscription[] = [];

  total: any;
  pageSize: any;
  dataSource: any;
  displayedColumns;
  filter = '';
  selection = new SelectionModel<any>(true, []);
  loggedRole: any;
  loadingTable: boolean;

  CaseTypeId = CaseTypeId;

  filters = new CaseFilters();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // @Output() change: EventEmitter<MatSlideToggleChange>

  constructor(
    // private dashboardService: DashboardService,
    private httpCase: CaseService,
    private toast: ToastService,
    private dialog: MatDialog,
    private router: Router,
    private notificationService: NotificationsService,
    private gsService: GraphStatsService,
    private spinner: SpinnerService,
    public utilService: UtilService,
    private floatingPopupService: FloatingPopupService
  ) {
    const ls = JSON.parse(localStorage.getItem('currentUser'));
    if (ls) {
      this.loggedRole = ls.user.role;
    }
  }

  ngOnInit(): void {
    this.loadingTable = true;

    /** define table columns */
    this.displayedColumns =
      this.loggedRole === 'admin' || this.loggedRole === 'clinic_admin'
        ? [
          'id',
          'user',
          'client',
          'aoi_type',
          'created',
          'algorithm',
          'status',
          'actions',
        ]
        : [
          'id',
          'client',
          'aoi_type',
          'created',
          'algorithm',
          'status',
          'actions',
        ];

    this.dataSource = new CasesDataSource(this.httpCase);
    this.loadDataSource();

    this.subscription.push(
      this.selection.changed.subscribe((event) => {
        if (event.added.length !== 0) {
          this.addToDeleteArray(event.added);
        } else {
          this.removeFromDeleteArray(event.removed);
        }
        if (this.deleteCases.length !== 0) {
          this.hideDeleteAllBtn = false;
        } else {
          this.hideDeleteAllBtn = true;
        }
      })
    );

    this.subscription.push(
      this.notificationService.currentMessageTrigger.subscribe((state) => {
        if (state) {
          if (this.messageCaseId !== state.data?.case_id) {
            this.loadDataSource();
          }
          this.messageCaseId = state.data?.case_id;
        }
      })
    );
  }

  onView(id: number): void {
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.data = {
    //   id,
    // };
    // dialogConfig.maxWidth = '90vw';
    // dialogConfig.width = '90%';
    // dialogConfig.height = '90%';
    // dialogConfig.panelClass = 'viewDialogContainer';

    // const viewDialog = this.dialog.open(ViewComponent, dialogConfig);
    this.router.navigate([`view/${ id }`]);
  }

  onEdit(id: number, caseTypeId: number) {
    if (caseTypeId == 1) {
      this.router.navigate([`/cases/edit/${ id }`]);
    } else if (caseTypeId == 2) {
      this.router.navigate([`/cases/edit/no-processing/${ id }`]);
    }
  }

  ngAfterViewInit(): void {
    this.paginator.page.pipe(tap(() => this.loadCasePage())).subscribe();
  }

  loadCasePage(): void {
    this.clearDelete();
    this.dataSource.loadCases(this.paginator.pageIndex + 1, {
      ...this.filters,
    });
  }

  loadPaginationData(): void {
    this.subscription.push(
      this.httpCase.getCases({ ...this.filters }, 1).subscribe(
        (res: any) => {
          this.total = res.total;
          this.pageSize = res.per_page;
        },
        (err) => {
          this.toast.showError(err, 'Server Error!');
        }
      )
    );
  }

  loadDataSource(): void {
    this.dataSource.loadCases(this.paginator.pageIndex + 1, {
      ...this.filters,
    });
    this.paginator.firstPage();
    this.loadPaginationData();

    this.subscription.push(
      this.dataSource.loadingSubject.subscribe((res) => {
        if (!res) {
          this.loadingTable = false;
          this.spinner.hideSpinner('table');
        } else {
          this.loadingTable = true;
          this.spinner.showSpinner('table');
        }
      })
    );
  }

  addToDeleteArray(checkedRows): void {
    checkedRows.forEach((item) => {
      this.deleteCases.push(item);
    });
  }

  removeFromDeleteArray(checkedRow): void {
    this.deleteCases = this.deleteCases.filter(
      (item) => item !== checkedRow[0]
    );
  }

  parsingGraphStats(stdev): string {
    return this.gsService.getGraphData(stdev);
  }

  OnEditCase(id: number): void {
    this.router.navigate(['cases/edit' + id]);
  }

  clearDelete(): void {
    this.deleteCases = [];
    this.hideDeleteAllBtn = true;
  }

  isUseCasePresent(usecase, value): boolean {
    const array = this.filterUseCases(value);
    if (array.includes('ACL')) {
      return true;
    }
    return false;
  }

  filterUseCases(value): any {
    const array: any[] = [];
    value.forEach((item) => {
      array.push(item.algorithm_name);
    });
    const xnew = array.join(',');
    return Array.from(new Set(xnew.split(','))).toString();
  }

  isDeleteHidden(): any {
    return this.hideDeleteAllBtn;
  }

  onDeleteAll(): void {
    const ids = this.deleteCases.map((item) => item.id);
    this.OnDeleteCase(ids);
  }

  onChangeStatus(id: number, event): void {
    if (event.checked) {
      this.subscription.push(
        this.httpCase.deactivate(id).subscribe(
          (res: ServerResponse) => {
            this.toast.showSuccess(res.message, 'Case is completed!');
          },
          (err) => {
            this.toast.showError(err, 'Server Error!');
          }
        )
      );
    } else {
      this.subscription.push(
        this.httpCase.activate(id).subscribe(
          (res: ServerResponse) => {
            this.toast.showWarrning(res.message, 'Case is in progress!');
          },
          (err) => {
            this.toast.showError(err, 'Server Error!');
          }
        )
      );
    }
  }

  openTrainingPage(): void {
    this.router.navigate(['training']);
  }

  OnDeleteCase(id: number[]): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Are you sure you want to delete this case(s) ?',
      content: `
      <div class="delete-modal">
      <div><strong>Case ID:</strong> ${ id }</div>
      </div>`,
    };
    dialogConfig.width = '40%';
    dialogConfig.panelClass = 'modal-warn';

    const confirmDialog = this.dialog.open(
      ConfirmDialogComponent,
      dialogConfig
    );

    this.subscription.push(
      confirmDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.subscription.push(
            this.httpCase.delete(id).subscribe(
              (res: ServerResponse) => {
                if (res) {
                  this.toast.showSuccess(res.message, 'Success Deleted!');
                  this.loadDataSource();
                  this.deleteCases = [];
                  this.hideDeleteAllBtn = true;
                }
              },
              (err) => {
                this.toast.showError(err, 'Server Error!');
              }
            )
          );
        } else {
          // if canceled
        }
      })
    );
  }

  formatClientName(client): string {
    if (!client) {
      return '';
    }

    return `${ client.first_name } ${ client.middle_name } ${ client.last_name }`;
  }

  formatArrayToString(value): string {
    if (!Array.isArray(value)) {
      return value;
    }
    return value.join(',');
  }

  ngOnDestroy(): void {
    // subscriptions
    this.subscription.forEach((subscription) => subscription.unsubscribe());
  }
}
