<div class="dashboard-cards">
  <app-card-general class="card-a"></app-card-general>

  <app-card-personal-data
    [data]="statisticsData"
    [loading]="loading"
    class="card-b"
    *ngIf="user.role !== role.WOODY_BREAST_USER"
  ></app-card-personal-data>

  <app-card-statistics
    [data]="statisticsData?.caseStatistics"
    class="card-c"
    *ngIf="user.role !== role.WOODY_BREAST_USER"
  ></app-card-statistics>

  <app-card-transtactions-history class="card-d">
  </app-card-transtactions-history>

  <app-card-clinic-performance
    [data]="statisticsData?.clinicPerformanse"
    [loading]="loading"
    class="card-e"
    *ngIf="user.role !== role.WOODY_BREAST_USER && user.role === role.ADMIN"
  >
  </app-card-clinic-performance>

  <app-card-doctor-performance
    [data]="statisticsData?.doctorPerformanse"
    [loading]="loading"
    class="card-e"
    *ngIf="
      user.role !== role.WOODY_BREAST_USER && user.role === role.CLINIC_ADMIN
    "
  >
  </app-card-doctor-performance>

  <app-card-newsfeed
    class="card-e"
    *ngIf="user.role !== role.WOODY_BREAST_USER && user.role === role.USER"
  >
  </app-card-newsfeed>
</div>
