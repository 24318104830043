<div id="personal-data-usecase">
  <div class="inner-section-header justify-content-between">
    <div class="d-flex">
      <div class="inner-section-icon">
        <span class="material-icons-outlined"> donut_small </span>
      </div>

      <div class="inner-section-title">Use case data</div>
    </div>

    <div class="inner-header-buttons information-search">
      <mat-form-field appearance="outline" class="input-full-width">
        <mat-label>Search Use Case Name</mat-label>
        <span matPrefix>
          <mat-icon>search</mat-icon>
        </span>
        <input matInput type="text" (keyup)="applyFilter($event)" #input />
      </mat-form-field>
      <!-- <button
        mat-button
        color="primary"
        placeholder="Search patients name or ID..."
        mat-raised-button
        type="button"
      >
        Search
      </button> -->
    </div>
  </div>

  <mat-divider></mat-divider>

  <div
    class="no-data add-padding"
    *ngIf="usecaseDataFiltered && usecaseDataFiltered.length === 0"
  >
    No data for use cases
  </div>

  <div
    class="personal-usecase-content d-flex flex-column"
    *ngIf="usecaseDataFiltered && usecaseDataFiltered.length !== 0"
  >
    <div class="d-flex justify-content-between">
      <h3 class="personal-usecase-title">Use case name</h3>
      <h3 class="personal-usecase-title">Number of cases</h3>
    </div>

    <div class="d-flex flex-column">
      <div
        *ngFor="let item of usecaseDataFiltered"
        class="d-flex justify-content-between"
      >
        <div class="personal-usecase-item">
          {{ item.usecaseName | titlecase }}
        </div>

        <div class="personal-usecase-values">
          {{ item.numberOfCases }}
        </div>
      </div>
    </div>

    <div class="personal-usecase-values"></div>
  </div>
</div>
