import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';


export interface IDeactivateComponent {
  userActivityRecorded: (component: IDeactivateComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot) => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})

export class DeactivateActivityGuardService implements CanDeactivate<IDeactivateComponent>{

  constructor() { }

  public canDeactivate(
    component: IDeactivateComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return component.userActivityRecorded(component, currentRoute, currentState, nextState) ? component.userActivityRecorded(component, currentRoute, currentState, nextState) : true;
  }
}
