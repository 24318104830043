<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="text-center"
  ><span [innerHTML]="content"></span
></mat-dialog-content>
<mat-dialog-actions align="center">
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button
    mat-button
    mat-raised-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    class="my-button"
    color="primary"
  >
    Ok
  </button>
</mat-dialog-actions>
