<h1 mat-dialog-title>Send to mail</h1>

<div class="send-mail-dialog" mat-dialog-content>
  <form novalidate [formGroup]="emailGroup">
    <div class="d-flex add-new-email-input">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Enter new email</mat-label>
        <input matInput placeholder="Email" formControlName="newEmail" />
        <mat-error>Email is not valid.</mat-error>
      </mat-form-field>

      <button
        [disabled]="
          emailGroup.invalid || emailGroup.get('newEmail').value == ''
        "
        mat-button
        color="primary"
        class="my-button my-link my-button-small add-new"
        (click)="addNewEmail()"
      >
        Add
      </button>
    </div>

    <p>Recipient list:</p>

    <ng-container *ngFor="let adEmail of emailList; let i = index">
      <div class="d-flex justify-content-between align-items-center max-w-60">
        <mat-checkbox
          [checked]="true"
          class="example-margin"
          color="primary"
          [value]="adEmail"
          (change)="onCheckEmail($event)"
        >
          {{ adEmail }}
        </mat-checkbox>
        <!-- delete icon -->
        <button
          *ngIf="i !== 0"
          (click)="onRemoveEmail(adEmail)"
          type="button"
          mat-icon-button
          color="primary"
        >
          <i class="material-icons material-icons-outlined cursor-pointer"
            >delete</i
          >
        </button>
      </div>
    </ng-container>
  </form>
</div>

<mat-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    class="my-button"
    type="button"
    (click)="onSubmit()"
    [disabled]="emailGroup.invalid || !additionalEmails.length"
  >
    Send
  </button>
  <button mat-button class="my-button" (click)="closeDialog()">Cancel</button>
</mat-dialog-actions>
