<!-- Main Layout -->

<div class="header">
  <app-header></app-header>
</div>

<div id="main">
  <div class="navigation-aside">
    <app-navbar></app-navbar>
  </div>

  <div id="main-content" class="main-content">
    <app-card-availability-case></app-card-availability-case>
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
