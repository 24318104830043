import { Statistics } from '@models/Statistics';
import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from '@services/util.service';
import { SpinnerService } from '@services/spinner.service';

@Component({
  selector: 'app-card-personal-data',
  templateUrl: './card-personal-data.component.html',
  styleUrls: ['./card-personal-data.component.scss'],
})
export class CardPersonalDataComponent implements OnInit {
  @Input() data: Statistics;
  @Input() set loading(value) {
    if (value) {
      this.spinner.showSpinner('personalDataLoading');
    } else {
      this.spinner.hideSpinner('personalDataLoading');
    }
  }

  constructor(
    public utilService: UtilService,
    private spinner: SpinnerService
  ) {}

  ngOnInit(): void {}
}
