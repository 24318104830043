import { Cases } from './../models/cases';
import { CaseService } from './../_services/case.service';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';



export class CasesDataSource implements DataSource<Cases> {

  private caseSubject = new BehaviorSubject<Cases[]>([]);

  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private caseService: CaseService) {

  }

  loadCases(
    pageIndex,
    filters
  ): void {

    this.loadingSubject.next(true);

    this.caseService.findCasesTable(
      filters,
      pageIndex,
    ).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(cases => this.caseSubject.next(cases));

  }

  connect(collectionViewer: CollectionViewer): Observable<Cases[]> {
    return this.caseSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.caseSubject.complete();
    this.loadingSubject.complete();
  }

}
