<div id="personal-data-common">
  <div class="inner-section-header">
    <div class="inner-section-icon">
      <span class="material-icons-outlined"> donut_small </span>
    </div>

    <div class="inner-section-title">Most common cases</div>
  </div>

  <mat-divider></mat-divider>

  <div class="no-data add-padding" *ngIf="pieData && pieData.length === 0">
    No Data for common cases
  </div>

  <div class="chart" *ngIf="pieData && pieData.length !== 0">
    <ngx-charts-pie-chart [results]="pieData" [legend]="true">
    </ngx-charts-pie-chart>
  </div>
</div>
