import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldErrorsModule } from '@shared/form-elements/field-errors/field-errors.module';
import { MaterialModule } from '@modules/material/material.module';
import { BillingFormComponent } from './billing-form.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [BillingFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FieldErrorsModule,
    NgxMaskModule,
  ],
  exports: [BillingFormComponent, MatDialogModule]
})
export class BillingFormModule { }
