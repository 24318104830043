import { HttpService } from './http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Statistics } from './../models/Statistics';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DashboardStatisticsService {
  statisticsData = new BehaviorSubject<Statistics>(null);
  statisticsDataEmitter = this.statisticsData.asObservable();

  // * Selected Clinic Id
  clinicIdSubject = new BehaviorSubject<Number>(null);
  clinicId$ = this.clinicIdSubject.asObservable();

  // * Selected User Id
  userIdSubject = new BehaviorSubject<Number>(null);
  userId$ = this.userIdSubject.asObservable();

  // * Loading
  loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  constructor(private http: HttpService) {}

  storeStatistics(data) {
    this.statisticsData.next(data);
  }

  setClinicId(clinicId: number) {
    this.clinicIdSubject.next(clinicId);
  }

  startLoading() {
    this.loadingSubject.next(true);
  }

  stopLoading() {
    this.loadingSubject.next(false);
  }

  destroyLoading() {
    this.loadingSubject.complete();
  }

  setUserId(userId: number) {
    this.userIdSubject.next(userId);
  }

  readStatistics() {
    return this.statisticsDataEmitter as Observable<Statistics>;
  }

  getStatistics(filters?: any) {
    return this.http.get('/statistics', filters);
  }
}
