import { debounceTime } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelpersService } from '@services/helpers.service';
import { ToastService } from '@services/toast.service';
import { UserService } from '@services/user.service';
import { RegisterService } from '@services/register.service';
import { pipe, Subject } from 'rxjs';
import { IDeactivateComponent } from '@app/guards/deactivate-activity-guard.service';
import { AuditsService } from '@services/audits.service';

@Component({
  selector: 'app-register-account',
  templateUrl: './register-account.component.html',
  styleUrls: ['./register-account.component.scss'],
})
export class RegisterAccountComponent implements OnInit {
  registerForm: FormGroup;

  emitNewState = new Subject();

  passwordState: boolean = false;

  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private helperService: HelpersService,
    private userService: UserService,
    private toast: ToastService,
    private router: Router,
    private auditsService: AuditsService,
    private registerService: RegisterService,
  ) { }

  ngOnInit(): void {
    this.registerForm = this.fb.group(
      {
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        subscribe: [''],
        subscriptionAgreement: [
          '',
          [Validators.required, Validators.requiredTrue],
        ],
        privacyPolicy: ['', [Validators.required, Validators.requiredTrue]],
        termsOfUse: ['', [Validators.required, Validators.requiredTrue]],
        password: ['', Validators.required],
        password_confirmation: ['', Validators.required],
      },
      { validator: this.helperService.checkPasswords }
    );

    this.registerService.registerFormGroup = this.registerForm;

    this.emitNewState.pipe(
      debounceTime(100)
    ).subscribe(() => {
      const data = this.registerForm.value;
      this.auditsService.addUserActivityLog({
        email: data.email,
        route: 'register',
        form_fields: data,
      })
    })
  }

  public userActivityRecorded(
    component: IDeactivateComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): boolean {
    this.emitNewState.next();
    return true;
  };

  onSubmit() {
    this.loading = true;
    this.userService.register(this.registerForm.getRawValue()).subscribe(
      (resp) => {
        this.loading = false;
        this.toast.showSuccess(
          'Account is registered, please check your email',
          'Registration'
        );

        if (!resp?.user?.verify_token && resp?.user?.verify_token === '') {
          this.router.navigate([`/login`]);
          return;
        }

        this.router.navigate([`/verify/${ resp?.user?.verify_token }`]);
      },
      (error) => {
        this.loading = false;
        this.toast.showError(error.message, '');
      }
    );
  }

  nextStep(state) {
    if (state) this.passwordState = state;
  }
}
