import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agreement-terms-of-use',
  templateUrl: './agreement-terms-of-use.component.html',
  styleUrls: ['./agreement-terms-of-use.component.scss'],
})
export class AgreementTermsOfUseComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<AgreementTermsOfUseComponent>) {}

  ngOnInit(): void {}

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
