import { DeactivateActivityGuardService } from './guards/deactivate-activity-guard.service';
import { CreatedByAdminGuard } from './guards/created-by-admin.guard';
import { CardPersonalDataExtendedComponent } from './dashboard/card-personal-data/card-personal-data-extended/card-personal-data-extended.component';
import { RequestResetPasswordComponent } from './forgot-password/request-reset-password/request-reset-password.component';
import { Role } from './enums/role';
import { RegisterAccountTypeStepperComponent } from './register/register-account-type/register-account-type-stepper/register-account-type-stepper.component';
import { RegisterAccountComponent } from './register/register-account/register-account.component';
import { RegisterComponent } from './register/register.component';
import { DrawingComponent } from './aoi-tool/drawing/drawing.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuardService } from './guards/role-guard.service';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuditLogsComponent } from './audit-logs/audit-logs.component';
import { RegisterLayoutComponent } from './layouts/register-layout/register-layout.component';
import { RegisterTrialComponent } from './register/register-trial/register-trial/register-trial.component';
import { RegisterMailValidationComponent } from './register/register-mail-validation/register-mail-validation.component';
import { RegisterInvitedUserComponent } from './register/register-invited-user/register-invited-user.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password/forgot-password.component';
import { RegisterClinicAccountTypeStepperComponent } from './register/register-clinic-account-type-stepper/register-clinic-account-type-stepper.component';
import { CasesPopupViewComponent } from './cases/cases-popup-view/cases-popup-view.component';
import { UserActivityComponent } from './user-activity/user-activity.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [RoleGuardService],
    children: [
      { path: '', component: DashboardComponent },
      { path: 'dashboard', component: DashboardComponent },
      // * View Cases in popup routing
      {
        path: 'view/:id',
        canActivate: [RoleGuardService],
        component: CasesPopupViewComponent,
      },
      // * cases
      {
        path: 'cases',
        loadChildren: () =>
          import('./cases/cases.module').then((m) => m.CasesModule),
      },
      // * patients
      {
        path: 'patients',
        loadChildren: () =>
          import('./patients/patients.module').then((m) => m.PatientsModule),
      },
      // * clients
      {
        path: 'clients',
        loadChildren: () =>
          import('./clients-patients/clients-patients.module').then(
            (m) => m.ClientsPatientsModule
          ),
      },
      // * clinics
      {
        path: 'clinics',
        loadChildren: () =>
          import('./clinics/clinics.module').then((m) => m.ClinicsModule),
      },
      // * clinic users - users
      {
        path: 'clinic-users',
        loadChildren: () =>
          import('./clinic-users/clinic-users.module').then(
            (m) => m.ClinicUsersModule
          ),
      },
      // * user profile
      {
        path: 'profile',
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      // * dashboard
      {
        path: 'dashboard/yourdata',
        component: CardPersonalDataExtendedComponent,
      },
      // * others
      {
        path: 'references', loadChildren: () =>
          import('./references/references.module').then((m) => m.ReferencesModule),
      },
      { path: 'videos', component: DashboardComponent },
      { path: 'training', component: DashboardComponent },
      { path: 'audits', component: AuditLogsComponent },
      { path: 'user-activity', component: UserActivityComponent },
    ],
  },
  // * register layout
  {
    path: '',
    component: RegisterLayoutComponent,
    children: [
      // account type
      {
        path: 'account-type',
        canActivate: [RoleGuardService, CreatedByAdminGuard],
        canDeactivate: [DeactivateActivityGuardService],
        component: RegisterAccountTypeStepperComponent,
        data: {
          createdByAdmin: false,
          // roles: [Role.IN_PROCESS],
        },
      },
      {
        path: 'clinic-account-type',
        canActivate: [RoleGuardService, CreatedByAdminGuard],
        component: RegisterClinicAccountTypeStepperComponent,
        data: {
          createdByAdmin: true,
          roles: [Role.IN_PROCESS],
        },
      },
      {
        path: 'demo',
        component: RegisterTrialComponent,
      },
      {
        path: 'verify/:token',
        component: RegisterMailValidationComponent,
      },
      {
        path: 'register',
        component: RegisterAccountComponent,
        canDeactivate: [DeactivateActivityGuardService],
      },
      {
        path: 'invitation/:token',
        component: RegisterInvitedUserComponent,
      },
      {
        path: 'forgot-password/:token',
        component: ForgotPasswordComponent,
      },
      {
        path: 'request-reset',
        component: RequestResetPasswordComponent,
      },
    ],
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'login',
    component: AuthLayoutComponent,
    children: [{ path: '', component: LoginComponent }],
  },
  {
    path: 'aoi-marker',
    outlet: 'toolWorkspace',
    component: DrawingComponent,
    pathMatch: 'full',
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
