import { ProcessedLabels } from './../enums/ProcessedLabels';
import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

@Pipe({
  name: 'processedImageLabel',
})
export class ProcessedImageLabelPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    if (!value) {
      return 'undefined';
    }

    const label = value.toLowerCase();

    if (
      args.includes('detailed') &&
      (label === ProcessedLabels.BENIGN ||
        label === ProcessedLabels.MALIGNANT ||
        label === ProcessedLabels.ABNORMAL)
    ) {
      return 'Likely ' + label;
    }

    return label;
  }
}
