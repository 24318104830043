import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CompletedData } from '@models/completedData';


@Injectable({
  providedIn: 'root'
})

export class CroppingDataService {


  private croppedBase64 = new BehaviorSubject<any>(null);
  private cropperImageDataSource = new BehaviorSubject<any>(null);
  private changeTools = new BehaviorSubject<any>(null);
  private addAOIMarkerEvent = new BehaviorSubject<any>(null);
  private removeAOIMarkerEvent = new BehaviorSubject<any>(null);
  private rotateImageEvent = new BehaviorSubject<any>(null);
  private AOIDataEvent = new BehaviorSubject<any>(null);
  private onFinishCroppingTriggerEvent = new BehaviorSubject<any>(null);
  private onFinishAllTriggerEvent = new BehaviorSubject<any>(null);
  private saveAOIasImageEvent = new BehaviorSubject<any>(null);
  private continueWithoutSavingAOIEvent = new BehaviorSubject<any>(null);
  private selectionShapeEvent = new BehaviorSubject<any>(null);
  private questionModalEvent = new BehaviorSubject<any>(null);
  private aoiImageStatus = new BehaviorSubject<any>(null);
  private saveBtnStateEvent = new Subject; // true - button is enabled init

  isHintOpen = new Subject<any>();

  cropperImageData = this.cropperImageDataSource.asObservable();
  croppingData = this.croppedBase64.asObservable();
  stateChangeTools = this.changeTools.asObservable();
  addAOIMarkerState = this.addAOIMarkerEvent.asObservable();
  removeAOIMarkerState = this.removeAOIMarkerEvent.asObservable();
  rotateImageState = this.rotateImageEvent.asObservable();
  AOIData = this.AOIDataEvent.asObservable();
  onFinishCroppingTriggerState = this.onFinishCroppingTriggerEvent.asObservable();
  onFinishAllTriggerState = this.onFinishAllTriggerEvent.asObservable();
  saveAOIasImageState = this.saveAOIasImageEvent.asObservable();
  continueWithoutSavingAOIState = this.continueWithoutSavingAOIEvent.asObservable();
  selectionShapeState = this.selectionShapeEvent.asObservable();
  questionData = this.questionModalEvent.asObservable();
  imageStatus = this.aoiImageStatus.asObservable();
  saveBtnState = this.saveBtnStateEvent.asObservable();

  outline: any;
  imageIndex = 0;
  tempShapeStorage: any;
  private completeData: CompletedData[] = [];

  constructor() { }

  /**
   *
   * @param value question values selected by user
   */

  setQuestionData(value): void {
    this.questionModalEvent.next(value);
    this.questionModalEvent.next(false);
  }

  /***
   *
   */


  /**
   * @param value set outline value
   */
  set setOutline(value: any) {
    this.completeData[this.imageIndex].outline = value;
  }

  /**
   *
   * @param data Podaci iz kropa
   */

  changeImageData(data: any): void {
    this.cropperImageDataSource.next(data);
  }
  /**
   *
   * @param state boolean vrednost koja trigeruje setovanje alata
   */

  emitChangeTools(state: boolean): void {
    this.changeTools.next(state);
  }

  /**
   *
   * @param state boolean vrednost koja trigeruje setovanje AOI markera // trenutno ne radi
   */
  addAOIMarker(state: boolean): void {
    this.addAOIMarkerEvent.next(state);
  }

  /**
   *
   * @param state $boolean
   * trenutno ne radi
   */
  removeAOIMarker(state: boolean): void {
    this.removeAOIMarkerEvent.next(state);
  }

  /**
   *
   * @param state $boolean
   * Rotacija slike kod kropa trigger
   */
  rotateImageCropper(state: boolean): void {
    this.rotateImageEvent.next(state);
  }

  /**
   *
   * @param shape $string
   * vrednost koji je krop izabran "rect" / "circle"
   */
  setSelectedShape(shape: string): void {
    this.selectionShapeEvent.next(shape);
  }

  /**
   *
   * @param data $object
   * Vraca vrednosti iz AOI editora
   */
  setAOIData(data: any, index: number, setIndex?: any): void {
    this.imageIndex = index;
    this.completeData[index] = { aoiData: data, outline: '' };
  }

  /**
   * Clear completeData data
   */
  clearAOIData() {
    this.completeData = [];
  }

  /**
   *
   * @param state $boolean
   * Trigger za odradjen krop
   */
  onFinishCroppingTrigger(state: boolean): void {
    this.onFinishCroppingTriggerEvent.next(state);
  }

  /**
   *
   * @param state $boolean
   * Trigger kada se zavrsi editovanje slike Crop/AOI
   */
  onFinishAllTrigger(state: boolean): void {
    this.onFinishAllTriggerEvent.next(state);
    this.onFinishAllTriggerEvent.next(false);
  }

  /**
   *
   * @param state $boolean
   * Trigger kada se klikne na dugme da je AOI editovanje zavrseno
   */
  onSaveAOIasImageTrigger(state: boolean): void {
    this.saveAOIasImageEvent.next(state);
    this.saveAOIasImageEvent.next(false);
  }

  onContinueWithoutSavingAOITrigger(state: boolean): void {
    this.continueWithoutSavingAOIEvent.next(state);
  }

  /**
   * @returns $Promise > $Object
   * Vraca vrednosti i za Crop i AOI koje su trenutno setovane tokom editovanja slike.
   *
   */
  getAllDataFromCropperAOI(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (this.completeData) {
          resolve(this.completeData);
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  /** Storage Temp Shape */
  setTempShapes(temp: any): void {
    this.tempShapeStorage = temp;
  }

  getTempShapes(): any {
    return this.tempShapeStorage;
  }

  /** Open hint modal service */
  openHint(): void {
    this.isHintOpen.next(true);
  }

  /** aoi tool image status */
  setImageStatus(obj: any): void {
    this.aoiImageStatus.next(obj);
  }

  enableSaveBtn(state: boolean): void {
    this.saveBtnStateEvent.next(state);
  }
}
