import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-audit-logs-details',
  templateUrl: './audit-logs-details.component.html',
  styleUrls: ['./audit-logs-details.component.scss']
})
export class AuditLogsDetailsComponent implements OnInit {

  oldValue: any[] = [];
  newValue: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public audit: any
  ) { }

  ngOnInit(): void {
    if (this.audit.old_values) {
      const parsedJSON = JSON.parse(this.audit.old_values);

      for (let key in parsedJSON) {
        this.oldValue.push({ key, value: parsedJSON[key] });
      }
    }

    if (this.audit.new_values) {
      const parsedJSON = JSON.parse(this.audit.new_values);

      for (let key in parsedJSON) {
        this.newValue.push({ key, value: parsedJSON[key] });
      }
    }
  }

  parsed(value) {
    return JSON.stringify(value);
  }

}
