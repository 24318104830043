<div class="cards">
  <ngx-spinner name="doctorPerformance">
    <p style="font-size: 20px; color: #000">Loading Performance...</p>
  </ngx-spinner>

  <div class="cards-header d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center w-100">
      <strong>Doctor Performanse</strong>
      <span class="cards-header-actions"
        ><button mat-button disabled="true">
          View more<span class="material-icons"> keyboard_arrow_right </span>
        </button></span
      >
    </div>
  </div>
  <mat-divider></mat-divider>

  <div class="cards-body">
    <div class="no-data add-padding">No Data for Performance</div>

    <div
      *ngFor="let doctor of data; let i = index"
      class="cards-body-news-item d-flex align-items-center justify-content-between"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="doc-thumbnail-img mr-1">
          <img width="50" height="50" src="https://i.pravatar.cc/50" alt="" />
        </div>
        <strong>{{ doctor.name }}</strong>
      </div>
      <div class="color-primary text-center">
        <div>
          <strong>{{ doctor.casesCount }}</strong>
        </div>
        <strong>Cases</strong>
      </div>
      <div class="color-primary text-center">
        <div>
          <strong class="block"
            >{{
              utilService.formatNumberWithPercentage(
                doctor.weeklyData.numberOfCasesIncrease
              )
            }}
          </strong>
        </div>
        <strong>Weekly patients</strong>
      </div>
    </div>
  </div>
</div>
