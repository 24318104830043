export enum useCaseNameEnum {
  SpleenMass = 'SPLENIC_MASS_1',
  LiverMass = 'LIVER_MASS',
  LymphNode = 'LYMPH_NODE',
  DogDV3 = 'DV',
  ACL = 'ACL',
  CCL = 'CCL',
  AbdomenContrast = 'Abdomen Contrast',
  LungMass = 'Lung Mass',
  Bone = 'BONE',
}
