import { UserActivityLog } from './../models/UserActivityLog';
import { Injectable } from '@angular/core';
import { Audits } from '@models/audits';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AuditsService {

  constructor(
    private http: HttpService
  ) { }

  getAllAudits(
    pageNumber = 1,
    filterSearch): any {
    return this.http.getAuditTable(
      '/audits',
      pageNumber,
      filterSearch);
  }

  public findAudits(
    filterSearch,
    pageNumber = 1): Observable<Audits[]> {
    return this.http.getAuditTable(
      '/audits',
      pageNumber,
      filterSearch).pipe(
        map(res => res['data'])
      );
  }

  public findUserActivity(
    filterSearch,
    pageNumber = 1): Observable<Audits[]> {
    return this.http.getUserActivityTable(
      '/useractivity',
      pageNumber,
      filterSearch).pipe(
        map(res => res['data'])
      );
  }

  getAllUserActivity(
    pageNumber = 1,
    filterSearch): any {
    return this.http.getUserActivityTable(
      '/useractivity',
      pageNumber,
      filterSearch);
  }

  addUserActivityLog(logData: UserActivityLog) {
    return this.http.post('/useractivity', logData)
  }

  export() {
    return this.http.get('/useractivity/export');
  }
}
