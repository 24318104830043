import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { AuthenticationService } from '@services/authentication.service';
import { NotificationsService } from '@services/notifications.service';
import { NotificationListGroup } from '@models/notifications';
import { Subscription } from 'rxjs';
import { ViewComponent } from '@app/cases/view/view.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  loggedRole: any;
  userName: string;
  subscription: Subscription[] = [];
  messageList: any[] = [];
  notificationList: any[] = [];
  numberOfUnseen = 0;

  @ViewChild('menu') public userProfileMenu: MatMenuTrigger;

  constructor(
    private authenticaitonService: AuthenticationService,
    private notificationsService: NotificationsService,
    private dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getAllMessages();

    this.subscription.push(
      this.authenticaitonService.currentUser.subscribe({
        next: (resp) => {
          this.loggedRole = resp?.user?.role;
          this.userName = resp?.user?.name;
        },
      })
    );

    // this.subscription.push(this.notificationsService.currentMessage.subscribe((msg: any) => {
    //   if (msg) {
    //     this.getAllMessages();
    //   }
    // }));

    this.subscription.push(
      this.notificationsService.currentMessageTrigger.subscribe((state) => {
        if (state) {
          this.getAllMessages();
        }
      })
    );
  }

  onView(id: number): void {
    if (!id) {
      return;
    }

    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.data = {
    //   id,
    // };
    // dialogConfig.maxWidth = '95vw';
    // dialogConfig.width = '95%';
    // dialogConfig.height = '94%';
    // dialogConfig.panelClass = 'viewDialogContainer';

    // const viewDialog = this.dialog.open(ViewComponent, dialogConfig);
    this.router.navigate([`view/${ id }`]);
  }

  // get all messages for current logged user
  getAllMessages() {
    this.subscription.push(
      this.notificationsService.getAllNotifications().subscribe(
        (res: any) => {
          let notificationListWithGroup: NotificationListGroup[] = [
            { name: '', list: [] },
            { name: '', list: [] },
            { name: '', list: [] },
          ];

          /** today formating */
          const dateToday = new Date();
          dateToday.setHours(0, 0, 0, 0);
          /** yesterday formating */
          const dateYesterday = new Date();
          dateYesterday.setDate(dateYesterday.getDate() - 1);
          dateYesterday.setHours(0, 0, 0, 0);

          /** Sorting and Prepare Group of notifications */
          res
            .sort((a, b) => {
              if (a.created_at < b.created_at) {
                return 1;
              }
              if (a.created_at > b.created_at) {
                return -1;
              }

              return 0;
            })
            .forEach((n) => {
              const notificationCreatedAt = new Date(n.created_at).getTime();

              /** Compare dates to create groups for notify messages */
              if (notificationCreatedAt > dateToday.getTime()) {
                notificationListWithGroup[0].name = 'Today';
                notificationListWithGroup[0].list.push(n);
              } else if (
                notificationCreatedAt > dateYesterday.getTime() &&
                notificationCreatedAt < dateToday.getTime()
              ) {
                notificationListWithGroup[1].name = 'Yesterday';
                notificationListWithGroup[1].list.push(n);
              } else if (notificationCreatedAt < dateYesterday.getTime()) {
                notificationListWithGroup[2].name = 'Older';
                notificationListWithGroup[2].list.push(n);
              }
            });

          const notifyGroupIsEmpty = notificationListWithGroup.filter(
            (n) => n.list.length
          );
          this.notificationList = notifyGroupIsEmpty.length
            ? notifyGroupIsEmpty
            : [];
          this.numberOfUnseen = this.countUnseen(this.notificationList);
        },
        (err) => {
          // this.toast.showError(err, "Server Error");
          /**
           * * Handle Error
           */
        }
      )
    );
  }

  deleteNotifications() {
    let notificationIds = this.notificationList
      .map((value) => value.list)
      .reduce((prev, curr) => {
        return prev.concat(curr);
      })
      .map((notify) => notify.id);

    this.subscription.push(
      this.notificationsService.delete(notificationIds).subscribe(
        (res: any) => {
          // this.toast.showSuccess(res.message, 'Success cleared');

          this.getAllMessages();
        },
        (err) => {
          // this.toast.showError(err, 'Server Error');
          /**
           * * Handle Error
           */
        }
      )
    );
  }

  countUnseen(data): number {
    const list = data.map((value) => value.list);

    if (list.length) {
      return list
        .reduce((prev, curr) => {
          return prev.concat(curr);
        })
        .filter((count) => count.status === 0).length;
    }
    return 0;
  }

  OnViewAllNotification() {
    this.subscription.push(
      this.notificationsService.changeNotificationStatus().subscribe(
        (res: any) => {
          this.getAllMessages();
        },
        (err) => {
          /**
           * * Handle Error
           */
        }
      )
    );
  }

  logout(): void {
    this.authenticaitonService.logout();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.forEach((subscription) => subscription.unsubscribe());
  }
}
