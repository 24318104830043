import { ProcessedScorePositions } from './../../../enums/ProcessedScorePositions';
import { ProcessedLabels } from '@enums/ProcessedLabels';
import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from '@services/util.service';

@Component({
  selector: 'app-processed-results',
  templateUrl: './processed-results.component.html',
  styleUrls: ['./processed-results.component.scss'],
})
export class ProcessedResultsComponent implements OnInit {
  ProcessedLabels = ProcessedLabels;
  ProcessedScorePositions = ProcessedScorePositions;

  @Input() index: number;
  @Input() imgIndex: number;
  @Input() data: any;
  @Input() usecaseType: any;
  @Input() position: string = 'table';

  constructor(public utilService: UtilService) {

  }

  ngOnInit(): void {
  }
}
