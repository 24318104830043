<div class="card">
  <div class="inner-header p-2">
    <div class="inner-header-actions">
      <div class="inner-header-title d-flex align-items-center mb-1">
        <div class="page-icon"><span class="petview-folder"></span></div>
        <h3>Clinic cases</h3>
      </div>
      <div class="inner-header-buttons"></div>
    </div>

    <mat-divider></mat-divider>
  </div>

  <div class="personal-data-extended-wrapper p-2 pt-0">
    <div class="grid grid-col-2 gap-5">
      <div class="col">
        <app-card-personal-data-circles
          [personalData]="data"
        ></app-card-personal-data-circles>

        <app-card-personal-data-common-cases
          [data]="data"
        ></app-card-personal-data-common-cases>

        <app-card-personal-data-usecase-data
          [data]="data"
        ></app-card-personal-data-usecase-data>
      </div>

      <div class="col">
        <app-card-personal-data-weekly-monthly
          [data]="data"
        ></app-card-personal-data-weekly-monthly>
      </div>
    </div>
  </div>
</div>
