import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GraphStatsService {


  private data = {
    info: {
      current: 55,
      color: 'red',
      positive: {
        min: 13.19,
        max: 39.17,
      },
      negative: {
        min: 51.95,
        max: 70.68
      },
    },
    number_indicator: false
  };

  constructor() { }

  // async setGraphData(gData, gDisplay) {
  //   this.data.info = gData;
  //   this.data.number_indicator = gDisplay;
  //   await this.calculateRange(gData);
  // }

  getGraphData(current): any {
    this.data.info.current = current;
    return this.data;
  }



}
