import { Router } from '@angular/router';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { MyErrorStateMatcher } from './../register.component';
import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-register-password-form',
  templateUrl: './register-password-form.component.html',
  styleUrls: ['./register-password-form.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class RegisterPasswordFormComponent implements OnInit {

  matcher = new MyErrorStateMatcher();

  hide = true;

  hideRe = true;

  @Input() form: FormGroup;

  @Input() loading: boolean;

  @Output() onFinish = new EventEmitter();

  constructor(
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

  }

  passwordState(states) {
    const isCorrect = states.every(state => state);
    if (!isCorrect) {
      this.form.get('password').setErrors({ passwordRule: true })
    } else {
      this.form.get('password').setErrors(null)
    }
    this.changeDetector.detectChanges();
  }

  finish() {
    this.onFinish.emit();
  }

}
