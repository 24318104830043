import { ActivatedRoute, Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
  NgForm,
  FormControl,
} from '@angular/forms';
import { HelpersService } from '@services/helpers.service';
import { UserService } from '@services/user.service';
import { ToastService } from '@services/toast.service';
import { AuthenticationService } from '@services/authentication.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );

    return invalidCtrl || invalidParent;
  }
}

@Component({
  selector: 'app-register-invited-user',
  templateUrl: './register-invited-user.component.html',
  styleUrls: ['./register-invited-user.component.scss'],
})
export class RegisterInvitedUserComponent implements OnInit {
  invitedUserForm: FormGroup;

  matcher = new MyErrorStateMatcher();

  hide = true;

  hideRe = true;

  token: string = '';

  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private helperService: HelpersService,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private userService: UserService,
    private router: Router,
    private toast: ToastService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');

    /**
     * * Remove LocalStorage to prevent error while keep logged user.
     */
    this.authService.removeLocalStorage();

    this.invitedUserForm = this.fb.group(
      {
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        password: ['', Validators.required],
        password_confirmation: ['', Validators.required],
        subscribe: [''],
      },
      { validators: this.helperService.checkPasswords }
    );
  }

  passwordState(states) {
    const isCorrect = states.every((state) => state);
    if (!isCorrect) {
      this.invitedUserForm.get('password').setErrors({ passwordRule: true });
    } else {
      this.invitedUserForm.get('password').setErrors(null);
    }
    this.changeDetector.detectChanges();
  }

  onSubmit() {
    this.loading = true;
    this.userService
      .registerInvited(this.invitedUserForm.getRawValue(), this.token)
      .subscribe(
        (resp) => {
          this.loading = false;
          this.toast.showSuccess(
            'Your account has been successfully registered',
            'Email has been sent. Please verify your email'
          );
          this.router.navigate(['login']);
        },
        (error) => {
          this.loading = false;
          this.toast.showError(error.messaage, '');
        }
      );
  }
}
