import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Base64convertorService {


  constructor() { }

  parseBase64ToBlob(base64Data): Promise<any> {
    // canvas.renderAll();
    return new Promise((resolve, reject) => {
      const block = base64Data.split(';');
      // Get the content type
      const contentType = block[0].split(':')[1]; // In this case "image/gif"
      // get the real base64 content of the file
      const realData = block[1].split(',')[1]; // In this case "iVBORw0KGg...."

      let blobFile;
      this.b64toBlob(realData, contentType).then((blob) => {
        blobFile = blob;
        if (blobFile) {
          resolve(blobFile);
        }
      }, err => {
        reject(err);
        console.log(err);
      });
    });
    // Convert to blob
  }

  // parseBase64ToBlob = new Observable(observer => {
  //   observer.next(this.Base64ToBlob());
  // });

  generateName(): string {
    const randomNum = Math.floor(Date.now() / 1000) * Math.random();
    const randomName = randomNum.toString().substring(0, 5);

    const date = new Date();
    const currentDate = date.toISOString().split('T')[0];
    return `${randomName}${currentDate}1`;
  }

  b64toBlob(b64Data, contentType, sliceSize = 512): Promise<any> {

    return new Promise((resolve, reject) => {

      try {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);

          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });

        resolve(blob);
      } catch (err) {
        reject(err);
      }

    });
  }

  async blobFromURL(url: string, name: string): Promise<any> {
    return await fetch(url)
      .then(res => res.blob())
      .then(blobFile => new File([blobFile], name, { type: 'image/jpeg', lastModified: Date.now() }));
  }

  async toBase64(file: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
}
