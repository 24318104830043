import { Component, OnInit, OnDestroy } from '@angular/core';
import { SpinnerService } from '@services/spinner.service';

@Component({
  selector: 'app-basic-dialog',
  templateUrl: './basic-dialog.component.html',
  styleUrls: ['./basic-dialog.component.scss']
})
export class BasicDialogComponent implements OnInit {

  constructor(
    private spinner: SpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.showSpinner('basicDialogLoading');
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.spinner.hideSpinner('basicDialogLoading');
  }

}
