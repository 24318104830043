import { Component, OnInit } from '@angular/core';
import { pricingPlanEnum } from '@enums/pricingPlanEnum';
import { Role } from '@enums/role';
import { AuthenticationService } from '@services/authentication.service';
import { NotificationsService } from '@services/notifications.service';

@Component({
  selector: 'app-card-availability-case',
  templateUrl: './card-availability-case.component.html',
  styleUrls: ['./card-availability-case.component.scss'],
})
export class CardAvailabilityCaseComponent implements OnInit {
  pricingPlanEnum = pricingPlanEnum;
  role = Role;

  constructor(
    public notificationService: NotificationsService,
    public authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    const noCases =
      this.authService.currentUserValue?.user?.plan?.number_of_cases;

    if (!noCases) {
      this.notificationService.showCaseLeftNotification = true;
    }
  }

  /**
   * * Close notification about left cases
   */
  handleCloseNotification() {
    this.notificationService.showCaseLeftNotification = false;
  }
}
