import { MaterialModule } from './../modules/material/material.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserActivityComponent } from './user-activity.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { UserActivityDetailsComponent } from './user-activity-details/user-activity-details.component';



@NgModule({
  declarations: [
    UserActivityComponent,
    UserActivityDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxSpinnerModule,
    MatTooltipModule,
    MaterialModule,
    MatPaginatorModule,
  ],
  exports: [
    UserActivityComponent,
    UserActivityDetailsComponent
  ]
})
export class UserActivityModule { }
