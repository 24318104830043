import { from, Observable, of } from 'rxjs';
import { useCaseNameEnum } from './../enums/useCaseNameEnum';
import { PercentageFormatPipe } from './../pipes/percentage-format.pipe';
import { UtilClass } from './../models/UtilClass';
import { Injectable } from '@angular/core';
import { GraphStatsService } from './graph-stats.service';
import { CaseTypeId } from '@enums/CaseTypeId';
import { Role } from '@enums/role';
import { pricingPlanEnum } from '@enums/pricingPlanEnum';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(
    private percentageFormat: PercentageFormatPipe,
    private gsService: GraphStatsService
  ) { }

  getCaseTypeName(caseType): string {
    if (!UtilClass.isNullOrUndefined(caseType.id)) {
      if (caseType.id === 3) {
        return 'Quick Process';
      }

      if (caseType.id === 2) {
        return 'Share Research Images';
      }
      return caseType.name;
    }
  }

  // isNullOrUndefined(value: any): boolean {
  //   return value === null || value === undefined;
  // }

  getUseCaseName(useCase): string {
    if (!UtilClass.isNullOrUndefined(useCase)) {
      switch (useCase?.id) {
        case 1:
          return 'Cranial Cruciate Ligament Tear - <span class="text-red text-italic">Visual only Experimental</span>';
        case 2:
          return 'Radiograph Optimization - <span class="text-red text-italic">Visual only Experimental</span>';
        case 3:
          return 'Spleen - <span class="text-red text-italic">beta</span>';
        case 7:
          return 'Liver - <span class="text-red text-italic">beta</span>';
        case 9:
          return 'Lymph node - <span class="text-red text-italic">beta</span>';
        case 18:
          return 'Bone Visualization - <span class="text-red text-italic">Visual only Experimental</span>';
      }
      return useCase.name;
    }
  }

  multipleAOITypes(images) {
    const aoiImages = images.map((img) => img.aoi_image);
    const aoiTypes = [].concat.apply([], aoiImages).map((aoi) => aoi.aoi_type);
    return aoiTypes.filter((v, i) => aoiTypes.indexOf(v) == i); // reduce duplicated names
  }

  multipleValuesCell(row, parentKey, childrenKey, caseType, specialKey) {
    if (!caseType || !row.images.length) {
      return [];
    }

    let parent;
    let children;

    if (caseType == CaseTypeId.NORMAL || caseType == CaseTypeId.QUICK) {
      switch (specialKey) {
        case 'algorithm':
          return this.multipleUseCases(row.algorithm);
        case 'species':
          return this.multipleSpecies(row.patient);
        case 'modality':
          return this.multipleModality(row.image_modality);
      }
    }

    if (caseType == 2 && specialKey == 'algorithm') {
      parent = row.images
        .map((p) => this.getUseCaseName(p[parentKey]))
        .filter((v) => v);
      return parent.filter((v, i) => parent.indexOf(v) == i);
    } else {
      parent = row.images.map((p) => p[parentKey]).filter((v) => v);
    }

    if (childrenKey) {
      children = [].concat
        .apply([], parent)
        .map((child) => child && child[childrenKey]);
    } else {
      children = parent;
    }

    return children.filter((v, i) => children.indexOf(v) == i); // reduce duplicated names
  }

  multipleModality(obj) {
    if (!UtilClass.isNullOrUndefined(obj)) {
      return obj?.name;
    }
    return [];
  }

  multipleSpecies(obj) {
    if (!UtilClass.isNullOrUndefined(obj)) {
      return obj?.patient_type?.name;
    }
    return [];
  }

  multipleUseCases(obj) {
    if (!UtilClass.isNullOrUndefined(obj)) {
      return this.getUseCaseName(obj);
    }
    return [];
  }

  getRefAlgo(suffix) {
    if (suffix) {
      switch (suffix) {
        case useCaseNameEnum.SpleenMass:
          return 'splenic_mass';
        case useCaseNameEnum.LiverMass:
          return 'liver_mass';
        // TODO need to add reference images for this types before uncomment
        // case useCaseNameEnum.ACL:
        //   return 'acl';
        // case useCaseNameEnum.Bone:
        //   return 'bone';
        // case useCaseNameEnum.DogDV3:
        //   return 'dv';
      }
    }
    return '';
  }
  getColorForMLEval(name: string): any {
    if (!name) {
      return { color: '#adadad', background: '#efefef' };
    }
    if (name.toLowerCase() === 'abnormal') {
      return { color: '#B80000', background: '#FFE5E5' };
    }
    if (name.toLowerCase() === 'malignant') {
      return { color: '#B80000', background: '#FFE5E5' };
    }
    if (name.toLowerCase() === 'normal') {
      return { color: '#00AC2E', background: '#E5F7E9' };
    }
    if (name.toLowerCase() === 'benign') {
      return { color: '#E24C14', background: '#FFEDE6' };
    }
  }

  getEvalValue2(ml, type?: string, algorithm?: any): string {
    return this.percentageFormat.transform(this.getEvalValue(ml, type, algorithm), '%');
  }

  findHightlightedResult(row?: any): any {
    return '/';
  }

  parsingGraphStats(stdev): any {
    return this.gsService.getGraphData(stdev);
  }

  getEvalValue(ml, type?: string, algorithm?: any): string {
    /**
     * TODO privremeno
     */
    // return '95';
    let score = '';

    if (typeof ml === 'object' && ml.eval) {
      if (algorithm === useCaseNameEnum.SpleenMass) {
        score = '95';
      };
      if (algorithm === useCaseNameEnum.LiverMass) {
        score = '90';
      };
      if (algorithm === useCaseNameEnum.LymphNode) {
        score = '90';
      };
    }

    if (typeof ml == 'string') {
      if (algorithm === useCaseNameEnum.SpleenMass) {
        score = '95';
      };
      if (algorithm === useCaseNameEnum.LiverMass) {
        score = '90';
      };
      if (algorithm === useCaseNameEnum.LymphNode) {
        score = '90';
      };
    }

    if (typeof ml == 'object') {

      if (ml.eval2?.eval === 'BENIGN' || ml.eval2?.eval === "MALIGNANT") {
        if (algorithm?.suffix === useCaseNameEnum.SpleenMass) {
          score = '80';
        };
        if (algorithm?.suffix === useCaseNameEnum.LiverMass) {
          score = '75';
        };
        if (algorithm?.suffix === useCaseNameEnum.LymphNode) {
          score = '75';
        };
      } else {
        if (algorithm?.suffix === useCaseNameEnum.SpleenMass) {
          score = '95';
        };
        if (algorithm?.suffix === useCaseNameEnum.LiverMass) {
          score = '90';
        };
        if (algorithm?.suffix === useCaseNameEnum.LymphNode) {
          score = '90';
        };
      }
    }

    if (score) {

      return score;
    }


    /**
     * TODO ============
     */

    if (ml?.eval2) {
      if (ml.eval2?.eval) {
        return Math.round(ml.eval2.confidence).toString();
      }
    }

    if (ml?.eval) {
      if (ml.eval?.confidence) {
        return Math.round(ml.eval.confidence).toString();
      }
      return Math.round(ml.confidence).toString();
    }

    return '/';
  }

  formatNumberWithPercentage(number: number) {
    return `${ number >= 0 ? '+' : '' }${ Math.round(number) }%`;
  }

  transformReferentAlgorithm(index: number) {
    switch (index) {

    }
  }

  getEvalType(result: any) {

    if (result.ml.eval2.eval) {
      return result.ml.eval2.eval;
    } else {
      return result.ml.eval.eval;
    }

    // return null;
  }

  getIconByUsecase(usecaseSuffix) {
    switch (usecaseSuffix) {
      case useCaseNameEnum.SpleenMass:
        return 'petview-spleen';
      case useCaseNameEnum.DogDV3:
        return 'petview-x-ray';
      case useCaseNameEnum.LiverMass:
        return 'petview-liver';
      case useCaseNameEnum.ACL:
        return 'petview-ligament-1';
      case useCaseNameEnum.CCL:
        return 'petview-ligament-1';
    }
  }

  /**
   * * Copy To Clipboard Helper
   */

  copyToClipboard(text: string): Observable<any> {
    return from(navigator.clipboard.writeText(text));
  }


  /**
   * * Helper: checking all use case that doesnt AOI markers skip step
   */
  skipAOIStateNormalCase(useCaseName): boolean {
    // * For skipping AOI on specific usecase return 'true'

    // ? Current AOI drawing tool is disabled.
    return true;

    // ? To activate uncomment
    // switch (useCaseName) {
    //   case useCaseNameEnum.DogDV3:
    //   case useCaseNameEnum.SpleenMass:
    //   case useCaseNameEnum.LiverMass:
    //     return true;
    //   default:
    //     return false;
    // }
  }

  skipAOIStateQuickView(useCaseName): boolean {
    // * For skipping AOI on specific usecase return 'true'

    // ? Current AOI drawing tool is disabled.
    return true;

    // ? To activate uncomment
    // switch (useCaseName) {
    //   case useCaseNameEnum.DogDV3:
    //   case useCaseNameEnum.SpleenMass:
    //   case useCaseNameEnum.LiverMass:
    //     return true;
    //   default:
    //     return false;
    // }
  }

  skipAOIStateNoProcessing(useCaseName): boolean {
    // * For skipping AOI on specific usecase return 'true'
    switch (useCaseName) {
      case useCaseNameEnum.DogDV3:
      case useCaseNameEnum.SpleenMass:
      case useCaseNameEnum.LiverMass:
        return true;
      default:
        return false;
    }
  }

  /** Helper: checking all use case that doesnt require AOI markers */
  requireAOIStateNormalCase(useCaseName): boolean {
    switch (useCaseName) {
      case useCaseNameEnum.DogDV3:
        return true;
      default:
        return false;
    }
  }

  /** Helper: checking all use case that doesnt require AOI markers */
  requireAOIStateQuickView(useCaseName): boolean {
    switch (useCaseName) {
      case useCaseNameEnum.DogDV3:
        return true;
      default:
        return false;
    }
  }

  /** Helper: checking all use case that doesnt require AOI markers */
  requireAOIStateNoProcessing(useCaseName): boolean {
    switch (useCaseName) {
      case useCaseNameEnum.DogDV3:
        return true;
      default:
        return false;
    }
  }

  transformImageTypeId(id: any) {
    switch (id.toString()) {
      case '1':
        return 'Normal';
      case '2':
        return 'Benign';
      case '3':
        return 'Malignant'
    }
  }

  preventDefaultFn(event) {
    event.preventDefault();
    event.stopPropagation();
    return;
  }

  pricingPlans({ plan }) {
    switch (+plan) {
      case pricingPlanEnum.PER_CASE_ID:
        return pricingPlanEnum.PER_CASE
      case pricingPlanEnum.BASE_ID:
        return pricingPlanEnum.BASE;
      case pricingPlanEnum.ENTERPRISE_ID:
        return pricingPlanEnum.ENTERPRISE;
      case pricingPlanEnum.POPULAR_ID:
        return pricingPlanEnum.POPULAR;
      case -1:
        return 'FREE TRAIL'
      default:
        return 'Undefined plan';
    }
  }

  roleName(role) {
    if (role === Role.TRIAL) {
      return 'Trial';
    }
    if (role === Role.ADMIN) {
      return 'Admin';
    }
    if (role === Role.CLINIC_ADMIN) {
      return 'Clinic Admin';
    }
    if (role === Role.IN_PROCESS) {
      return 'In Process';
    }
    if (role === Role.USER) {
      return 'User';
    }
    if (role === Role.WOODY_BREAST_USER) {
      return 'User WB';
    }
    return 'Undefined Account Type';
  }

  dateDiff(startDate, endDate) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

}
