import { PipeModule } from './../pipes/pipe.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { GraphStatsModule } from './../shared/components/graph-stats/graph-stats.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './../modules/material/material.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardGeneralComponent } from './card-general/card-general.component';
import { CardPersonalDataComponent } from './card-personal-data/card-personal-data.component';
import { CardStatisticsComponent } from './card-statistics/card-statistics.component';
import { CardTranstactionsHistoryComponent } from './card-transtactions-history/card-transtactions-history.component';
import { CardNewsfeedComponent } from './card-newsfeed/card-newsfeed.component';
import { CardPersonalDataExtendedComponent } from './card-personal-data/card-personal-data-extended/card-personal-data-extended.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CardDoctorPerformanceComponent } from './card-doctor-performance/card-doctor-performance.component';
import { CardClinicPerformanceComponent } from './card-clinic-performance/card-clinic-performance.component';
import { CardPersonalDataCirclesComponent } from './card-personal-data/card-personal-data-circles/card-personal-data-circles.component';
import { CardPersonalDataCommonCasesComponent } from './card-personal-data/card-personal-data-common-cases/card-personal-data-common-cases.component';
import { CardPersonalDataUsecaseDataComponent } from './card-personal-data/card-personal-data-usecase-data/card-personal-data-usecase-data.component';
import { CardPersonalDataWeeklyMonthlyComponent } from './card-personal-data/card-personal-data-weekly-monthly/card-personal-data-weekly-monthly.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    CardGeneralComponent,
    CardPersonalDataComponent,
    CardStatisticsComponent,
    CardTranstactionsHistoryComponent,
    CardNewsfeedComponent,
    CardDoctorPerformanceComponent,
    CardClinicPerformanceComponent,
    CardPersonalDataExtendedComponent,
    CardPersonalDataCirclesComponent,
    CardPersonalDataCommonCasesComponent,
    CardPersonalDataUsecaseDataComponent,
    CardPersonalDataWeeklyMonthlyComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    NgxChartsModule,
    NgxSpinnerModule,
    GraphStatsModule,
    SharedModule,
    PipeModule,
  ],
  exports: [
    CardGeneralComponent,
    CardPersonalDataComponent,
    CardStatisticsComponent,
    CardNewsfeedComponent,
    CardTranstactionsHistoryComponent,
    CardDoctorPerformanceComponent,
    CardClinicPerformanceComponent,
    CardPersonalDataExtendedComponent,
    CardPersonalDataWeeklyMonthlyComponent,
  ],
})
export class DashboardModule {}
