<div class="card m-auto p-2 demo-form">
  <h2 class="mb-0">Check your email</h2>
  <small
    >Press Submit to enter the code shown below. If code is not shown below,
    find it in the verify email address, email we sent to you, enter it here,
    and press Submit.</small
  >
  <hr class="mb-1" />

  <div class="code-input-wrapper">
    <code-input
      [isCodeHidden]="false"
      [isNonDigitsCode]="true"
      [codeLength]="codeLength"
      [code]="code"
      (codeChanged)="onCodeChanged($event)"
      (codeCompleted)="onCodeCompleted($event)"
    >
    </code-input>
  </div>

  <button
    mat-raised-button
    color="primary"
    type="button"
    class="my-button"
    (click)="onVerify()"
    [disabled]="!isValid"
  >
    Submit
  </button>
</div>
