import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  constructor(private http: HttpService) {}

  animalTypeDropdown(): any {
    return this.http.get('/animal_types/dropdown');
  }

  breedDropdown(id: number): any {
    return this.http.get(`/type/breed/${id}/dropdown`);
  }

  algorithmsDropdown(): any {
    return this.http.get('/algorithm/dropdown');
  }

  clientsDropdown(): any {
    return this.http.get('/clients/dropdown');
  }

  alteredStatusDropdown(): any {
    return this.http.get('/altered_status/dropdown');
  }

  patientsDropdown(): any {
    return this.http.get('/patients/dropdown');
  }

  bodyPartsDropdown(): any {
    return this.http.get('/body_part/dropdown');
  }

  bodyViewDropdown(): any {
    return this.http.get('/body_view/dropdown');
  }

  imageModalityDropdown(): any {
    return this.http.get('/image_modality/dropdown');
  }
  bodyPartsDropdownByAlgorithmId(id: number): any {
    return this.http.get(`/dropdown/algorithms/bodypart/${id}`);
  }

  algorithmsDropdownByBodyPartsId(id: number): any {
    return this.http.get(`/dropdown/bodypart/algorithms/${id}`);
  }

  algorithmsNoProcessingDropdownByBodyPartId(id: number): any {
    return this.http.get(
      `/dropdown/case_noprocessing/bodypart/algorithms/${id}`
    );
  }

  imageModalityByAlgorithmIds(ids: number[]): any {
    return this.http.get(`/dropdown/algorithms/image_modality?ids=${ids}`);
  }

  imageTypeByAlgorithmId(id: number[]): any {
    return this.http.get(`/dropdown/algorithms/image_type/${id}`);
  }

  bodyViewDropdownByAlgorithmIds(ids: number[]): any {
    return this.http.get(`/dropdown/algorithms/body_view?ids=${ids}`);
  }

  imageTypeDropdown(): any {
    return this.http.get('/image_type/dropdown');
  }

  getAllDropdown(): Observable<any> {
    return this.http.get('/case/filter/dropdown');
  }

  getAllClinics(): any {
    return this.http.get('/clinics/dropdown');
  }

  getUsersByClinicId(clinicId: number) {
    return this.http.get(`/users/dropdown?clinic_id=${clinicId}`);
  }
}
