import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-personal-data-usecase-data',
  templateUrl: './card-personal-data-usecase-data.component.html',
  styleUrls: ['./card-personal-data-usecase-data.component.scss'],
})
export class CardPersonalDataUsecaseDataComponent implements OnInit {
  usecaseData: any;
  usecaseDataFiltered: any[];

  @Input() set data(value) {
    if (value) {
      this.usecaseData = value?.usecaseData;
      this.usecaseDataFiltered = value?.usecaseData;
    }
  }
  constructor() {}

  ngOnInit(): void {}

  applyFilter(event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.usecaseDataFiltered = this.usecaseData.filter((usecase) => {
      return (
        usecase.usecaseName
          .trim()
          .toLowerCase()
          .includes(filterValue.trim().toLowerCase()) ||
        usecase.numberOfCases
          .toString()
          .trim()
          .includes(filterValue.toString().trim())
      );
    });
  }
}
