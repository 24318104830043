import { PasswordStrengthComponent } from './password-strength.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [PasswordStrengthComponent],
  imports: [
    CommonModule
  ],
  exports: [PasswordStrengthComponent]
})
export class PasswordStrengthModule { }
