import { RegisterPasswordFormModule } from './register-password-form/register-password-form.module';
import { BillingFormModule } from './../billing/billing-form/billing-form.module';
import { PasswordStrengthModule } from './../shared/form-elements/password-strength/password-strength.module';
import { FieldErrorsModule } from './../shared/form-elements/field-errors/field-errors.module';
import { RegisterTrialFormComponent } from './register-trial/register-trial-form/register-trial-form.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'app/modules/material/material.module';
import { ClinicComponent } from './clinic/clinic.component';
import { RegisterComponent } from './register.component';
import { RegisterAccountComponent } from './register-account/register-account.component';
import { RegisterTrialComponent } from './register-trial/register-trial/register-trial.component';
import { RegisterInvitedUserComponent } from './register-invited-user/register-invited-user.component';
import { RegisterPasswordFormComponent } from './register-password-form/register-password-form.component';
import { RegisterMailValidationComponent } from './register-mail-validation/register-mail-validation.component';
import { RegisterAccountTypeStepperComponent } from './register-account-type/register-account-type-stepper/register-account-type-stepper.component';
import { RegisterAccountTypeInvitesComponent } from './register-account-type/register-account-type-invites/register-account-type-invites.component';
import { RegisterAccountTypeFormComponent } from './register-account-type/register-account-type-form/register-account-type-form.component';
import { CodeInputModule } from 'angular-code-input';
import { RegisterAccountFormComponent } from './register-account-form/register-account-form.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMaskModule } from 'ngx-mask';
import { RegisterPricingPlanComponent } from './register-pricing-plan/register-pricing-plan.component';
import { RegisterClinicAccountTypeStepperComponent } from './register-clinic-account-type-stepper/register-clinic-account-type-stepper.component';

@NgModule({
  declarations: [
    RegisterComponent,
    ClinicComponent,
    RegisterAccountComponent,
    RegisterInvitedUserComponent,
    RegisterMailValidationComponent,
    RegisterAccountTypeFormComponent,
    RegisterAccountTypeStepperComponent,
    RegisterAccountTypeInvitesComponent,
    RegisterTrialComponent,
    RegisterTrialFormComponent,
    RegisterAccountFormComponent,
    RegisterPricingPlanComponent,
    RegisterClinicAccountTypeStepperComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxSpinnerModule,
    MaterialModule,
    FieldErrorsModule,
    CodeInputModule,
    NgxMatSelectSearchModule,
    PasswordStrengthModule,
    NgxMaskModule,
    BillingFormModule,
    RegisterPasswordFormModule,
  ],
  exports: [RegisterComponent, ClinicComponent, RegisterPricingPlanComponent],
})
export class RegisterModule {}
