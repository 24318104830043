import { ToastService } from './../../_services/toast.service';
import { UserService } from './../../_services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-mail-validation',
  templateUrl: './register-mail-validation.component.html',
  styleUrls: ['./register-mail-validation.component.scss'],
})
export class RegisterMailValidationComponent implements OnInit {
  token: string;

  code: string;

  isValid: boolean = false;

  codeLength = 6;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');
    this.code = this.route.snapshot.queryParamMap.get('code');
    this.onCodeCompleted(this.code);
  }

  onCodeCompleted(code: string) {
    this.isValid = true;
    this.code = code;
  }

  onCodeChanged(code: string) {
    if (code.length < this.codeLength) this.isValid = false;
  }

  onVerify() {
    // this.code - value
    const data = {
      token: this.token,
      code: this.code,
    };

    this.userService.verifyAccount(data).subscribe(
      (resp) => {
        this.toast.showSuccess(resp.message, 'Success');
        this.router.navigate(['/login']);
      },
      (error) => {
        this.toast.showError(error, '');
      }
    );
  }
}
