<div
  id="case-details"
  class="card"
  *ngIf="!loading"
  (click)="$event.stopPropagation()"
>
  <table>
    <tr>
      <th>Body view:</th>
      <td>{{ uploadDataCaseData.body_view?.name }}</td>
    </tr>
    <tr>
      <th>Species:</th>
      <td>{{ uploadDataCaseData.patient_type?.name }}</td>
    </tr>
    <tr>
      <th>Breed:</th>
      <td>{{ uploadDataCaseData.breed?.name }}</td>
    </tr>
    <tr>
      <th>Gender:</th>
      <td>{{ uploadDataCaseData.gender }}</td>
    </tr>
    <tr>
      <th>Reproductive status:</th>
      <td>{{ uploadDataCaseData.altered_status?.name }}</td>
    </tr>
    <tr>
      <th>Age:</th>
      <td>{{ uploadDataCaseData.age }}</td>
    </tr>
    <tr>
      <th>Image type:</th>
      <td>{{ uploadDataCaseData.image_type?.name }}</td>
    </tr>
    <tr>
      <th>Specific disease:</th>
      <td>{{ uploadDataCaseData.other_image_type }}</td>
    </tr>
    <tr>
      <th>Confirmed by Pathology:</th>
      <td>{{ uploadDataCaseData.pathology }}</td>
    </tr>
    <tr>
      <th>Diagnosis type:</th>
      <td>
        {{
        uploadDataCaseData.show_diagnosis === "true"
        ? "Diagnoiss"
        : "Differential Diagnosis"
        }}
      </td>
    </tr>
    <tr>
      <th>Diagnosis:</th>
      <td>
        {{
        uploadDataCaseData.show_diagnosis === "true"
        ? uploadDataCaseData.diagnosis
        : uploadDataCaseData.differential_diagnosis
        }}
      </td>
    </tr>
  </table>
</div>
