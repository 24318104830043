<div
  class="main-img-placeholder"
  *ngIf="originalArray.length === 0 && !loading"
>
  <span class="material-icons-outlined"> image_not_supported </span>
  <p><strong>Original image doesn't exist</strong></p>
</div>

<app-image-viewer
  *ngIf="originalArray.length !== 0 && !loading"
  [images]="[originalArray[imageIndex]?.original_image]"
  [idContainer]="'first'"
  [loadOnInit]="true"
  [imageTypeName]="'Original image'"
  (handleInfoModal)="openInformation($event)"
  [infoBtn]="true"
  (viewerInstance)="handleActive($event)"
></app-image-viewer>
