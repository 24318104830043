<div
  id="case-details"
  class="card"
  *ngIf="!loading"
  (click)="$event.stopPropagation()"
>
  <table>
    <tr>
      <th>Created at:</th>
      <td>{{ patientClientData?.created_at }}</td>
    </tr>
    <tr>
      <th>Case ID:</th>
      <td>{{ patientClientData?.id }}</td>
    </tr>

    <tr>
      <th>Transaction ID:</th>
      <td>{{ patientClientData?.images[index]?.transaction_id }}</td>
    </tr>

    <tr>
      <th>Image name:</th>
      <td>
        {{ patientClientData?.images[index]?.name }}
      </td>
    </tr>

    <tr>
      <th>Original image name:</th>
      <td>
        {{ patientClientData?.images[index]?.original_name }}
      </td>
    </tr>
    <tr>
      <th>Machine manufacturer:</th>
      <td>
        {{ patientClientData?.machine_manufacturer }}
      </td>
    </tr>
    <tr>
      <th>Machine model:</th>
      <td>
        {{ patientClientData?.machine_model }}
      </td>
    </tr>
    <tr>
      <th>Machine note:</th>
      <td>
        {{ patientClientData?.machine_note }}
      </td>
    </tr>

    <ng-container *ngIf="caseTypeId === 1">
      <tr>
        <th>Client:</th>
        <td>
          {{ patientClientData?.client?.first_name }}
          {{ patientClientData?.client?.middle_name }}
          {{ patientClientData?.client?.last_name }}
        </td>
      </tr>
      <tr>
        <th>Client ID:</th>
        <td>{{ patientClientData?.client?.id }}</td>
      </tr>
      <tr>
        <th>Patient name:</th>
        <td>{{ patientClientData?.patient?.name }}</td>
      </tr>
      <tr>
        <th>Patient ID:</th>
        <td>{{ patientClientData?.patient?.id }}</td>
      </tr>
      <tr>
        <th>Species:</th>
        <td>{{ patientClientData?.patient?.patient_type?.name }}</td>
      </tr>
      <tr>
        <th>Gender:</th>
        <td>{{ patientClientData?.patient?.gender }}</td>
      </tr>
      <tr>
        <th>Breed:</th>
        <td>{{ patientClientData?.patient?.breed?.name }}</td>
      </tr>
      <tr>
        <th>Age:</th>
        <td>{{ patientClientData?.patient?.age }}</td>
      </tr>
      <tr>
        <th>Reproductive status:</th>
        <td>{{ patientClientData?.patient?.altered_status?.name }}</td>
      </tr>
      <tr class="d-flex flex-column notes">
        <th>Notes:</th>
        <td>
          <p>{{ patientClientData?.patient?.note }}</p>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="currentUser?.user?.role === Role.ADMIN">
      <hr />
      <tr>
        <th>ALG version:</th>
        <td>
          <p>{{ imageData?.algversion }}</p>
        </td>
      </tr>
      <tr>
        <th>MDL version:</th>
        <td>
          <p>{{ imageData?.mdlversion }}</p>
        </td>
      </tr>
      <tr>
        <th>N brightest pix:</th>
        <td>
          <p>{{ imageData?.nbrightestpixpc }}</p>
        </td>
      </tr>
      <tr>
        <th>Mean:</th>
        <td>
          <p>{{ imageData?.mean }}</p>
        </td>
      </tr>
      <tr>
        <th>STDev:</th>
        <td>
          <p>{{ imageData?.stdev }}</p>
        </td>
      </tr>
    </ng-container>
  </table>
</div>
