<div
  class="dialog full-width-dialog aoi-main-tool-container"
  mat-dialog-content
>
  <!-- List of Images -->

  <app-drawing-image-list
    (selectedImageEvent)="selectedImageEvent($event)"
    [images]="emitAllImages"
    [selectedIndex]="imageListSelectedIndex"
  >
  </app-drawing-image-list>

  <!-- List of Images END -->

  <mat-divider></mat-divider>

  <div class="main-container">
    <div class="main">
      <div class="left-sidemenu open" #tools>
        <!-- AOI TYPES -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            aoitype-group
          "
        >
          <h3 class="title text-center">AOI Type:</h3>

          <mat-tab-group
            (click)="clickOnTab($event)"
            (selectedTabChange)="tabChanged($event)"
            [selectedIndex]="tabIndex"
            animationDuration="0ms"
          >
            <!-- *ngIf="displayTabsFor.includes(currentUseCase.id)" -->
            <!-- *ngIf="imageData?.caseType === 2 && displayTabsFor.includes(currentUseCase.id)" -->
            <ng-container *ngFor="let aoiType of aoitypeData">
              <mat-tab disabled *ngIf="!aoiType.hidden" [label]="aoiType.label">
                <ng-template mat-tab-label>
                  <div class="name">{{ aoiType.label }}</div>
                  <span
                    [ngClass]="{ completed: aoiType.data }"
                    class="d-flex justify-content-between"
                  >
                    <div class="d-flex">
                      <span
                        class="custom-icon"
                        [mdePopoverTriggerFor]="appPopover"
                        mdePopoverTriggerOn="hover"
                        #popoverTrigger="mdePopoverTrigger"
                        >?
                        <mde-popover
                          #appPopover="mdePopover"
                          [mdePopoverOverlapTrigger]="false"
                        >
                          <div class="popover box-orange card">
                            <p>{{ aoiType.label }}</p>
                            {{ aoiType.description }}
                          </div>
                        </mde-popover>
                      </span>
                      <!-- <mat-icon style="color: #303030;">check_box</mat-icon> -->
                      <mat-icon *ngIf="!aoiType.data" style="color: #303030"
                        >check_box_outline_blank</mat-icon
                      >
                      <span
                        *ngIf="aoiType.data"
                        class="petview-check_1 ml-1"
                      ></span>
                      <span
                        style="color: #303030"
                        *ngIf=""
                        class="petview-check_1"
                      ></span>
                    </div>
                  </span>
                </ng-template>
              </mat-tab>
            </ng-container>
          </mat-tab-group>

          <span></span>
        </div>

        <!-- Aoi Type Tools -->
        <app-aoi-type-tools></app-aoi-type-tools>
      </div>

      <div class="main-canvas">
        <!-- MAIN CANVAS -->

        <div
          class="wrapper"
          [ngClass]="{ 'invalid-canvas': shapeOverlapBorders }"
          (dblclick)="
            this.drawingHelper.selectedShapeCopy == 'polygon'
              ? this.drawingHelper.doubleClick()
              : null
          "
          #dialogElement
        >
          <canvas width="" height="" id="drawCanvas"></canvas>
        </div>
      </div>
    </div>

    <!-- END MAIN CANVAS -->

    <!-- Reference images -->
    <div class="list-reference-images">
      <div class="header">Reference images</div>
      <div class="reference-image-card">
        <img
          *ngIf="aoiTypeSelectedData.label === 'FOV'"
          [src]="refImagesList.good[refImageIndex.good]"
          alt="good example"
        />
        <ng-container *ngIf="aoiTypeSelectedData.label !== 'FOV'">
          <div
            class="d-flex justify-content-center align-items-center relative"
          >
            <img src="./../../../assets/images/blank.jpg" alt="good example" />
            <h5 class="absolute mb-0">NO IMAGE</h5>
          </div>
        </ng-container>
        <div class="footer">
          <div class="status green">Good input</div>
          <div class="commands">
            <div class="left-arrow" (click)="prevRef('good')">
              <div class="petview-left_arrow"></div>
              Prev
            </div>
            <div class="right-arrow" (click)="nextRef('good')">
              Next
              <div class="petview-right_arrow"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="reference-image-card">
        <img
          *ngIf="aoiTypeSelectedData.label === 'FOV'"
          [src]="refImagesList.bad[refImageIndex.bad]"
          alt="bad example"
        />
        <ng-container *ngIf="aoiTypeSelectedData.label !== 'FOV'">
          <div
            class="d-flex justify-content-center align-items-center relative"
          >
            <img src="./../../../assets/images/blank.jpg" alt="good example" />
            <h5 class="absolute mb-0">NO IMAGE</h5>
          </div>
        </ng-container>
        <div class="footer">
          <div class="status red">Bad input</div>
          <div class="commands">
            <div class="left-arrow" (click)="prevRef('bad')">
              <div class="petview-left_arrow"></div>
              Prev
            </div>
            <div class="right-arrow" (click)="nextRef('bad')">
              Next
              <div class="petview-right_arrow"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
