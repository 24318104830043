<div class="register-layout-grid">
  <div class="relative side" id="sign-in-wrapper">
    <ng-container *ngIf="!tryForFreeInfo">
      <div class="overlay-blue"></div>
      <div class="login-background"></div>
    </ng-container>

    <ng-container *ngIf="tryForFreeInfo">
      <div class="h-100 relative d-flex flex-center">
        <div class="overlay-blue"></div>
        <div class="login-background"></div>

        <div class="content">
          <div class="content-header"></div>

          <div class="content-main">
            <strong class="content-title">Re-inventing Image Analysis</strong>

            <div>
              <strong>Current Use Cases:</strong>
              <ul>
                <li>Liver - beta</li>
                <li>Lymph Node - beta</li>
                <li>Spleen - beta</li>
                <li>Bone Visualization - Visual only Experimental</li>
              </ul>

              <strong>Coming Soon</strong>
              <ul>
                <li>
                  Cranial Cruciate Ligament Tear - Visual only Experimental
                </li>
              </ul>
            </div>

            <button
              (click)="demo()"
              mat-stroked-button
              type="button"
              class="my-button my-outline my-outline-white mt-2 my-button-scaleup"
            >
              Try for free
            </button>
          </div>

          <div class="content-footer">
            Trusted by the the world's most notable companies
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="main">
    <img
      src="../../../assets/images/petview_logo_svg.svg"
      id="logo"
      alt="logo"
    />

    <router-outlet></router-outlet>
  </div>
</div>
