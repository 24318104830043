import { Component, OnInit, Input } from '@angular/core';
import { HelpersService } from '@services/helpers.service';

@Component({
  selector: 'app-card-statistics',
  templateUrl: './card-statistics.component.html',
  styleUrls: ['./card-statistics.component.scss'],
})
export class CardStatisticsComponent implements OnInit {
  multi: any;
  dataset: any;
  view: any[] = [];

  // * Active state for filter statistics
  activeFilter: string = 'weekly';

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  showYAxisLabel: boolean = false;
  legend: boolean = false;
  yAxisTicks: number[] = [0, 1, 2, 3, 4, 5, 10, 15, 20, 30];
  showYAxisTicks: [] = [];

  // * Selected dataset
  selectedDataset: string = 'weekly';
  // selectedDataset: string = 'monthly';

  colorScheme = {
    domain: ['#93d0e1', '#f07c50', '#fff'],
  };

  @Input() set data(value) {
    if (value) {
      this.dataset = value;
      this.multi = value[this.selectedDataset];

      this.setYAxis();
    }
  }

  constructor(private helperService: HelpersService) {}

  /**
   * * Lifehooks
   */
  ngOnInit(): void {}

  /**
   * * Events
   */
  onSelectDataset(datasetName) {
    this.multi = this.dataset[datasetName];
    this.activeFilter = datasetName;

    this.setYAxis();
  }

  onSelect(data): void {}

  onActivate(data): void {}

  onDeactivate(data): void {}

  /**
   * * Helpers
   */

  setYAxis() {
    const isActive = this.helperService.isYAxisTickActive(this.multi);

    if (isActive) {
      this.yAxisTicks = [0, 1, 2, 3, 4, 5, 10, 15, 20, 30];
    } else {
      this.yAxisTicks = [];
    }
  }
}
