<h2 mat-dialog-title>Subscription Agreement</h2>
<mat-dialog-content class="mat-typography">
  <h3 class="text-center">
    PETVIEWDX IMAGING ANALYSIS SERVICES SUBSCRIPTION AGREEMENT
  </h3>
  <ol>
    <li>
      <strong> INTERPRETATION. </strong>The definitions and rules of
      interpretation in this Clause apply in this Agreement.
    </li>
  </ol>
  <p>
    &rdquo;<strong>Access Credentials</strong>&rdquo; means any user name,
    identification number, password, license or security key, security token,
    PIN or other security code, method, technology or device used, alone or in
    combination, to verify an individual&rsquo;s identity and authorization to
    access and use the Imaging Analysis Services.
  </p>
  <p>
    <strong>Agreement:</strong> means this Imaging Analysis Services
    Subscription Agreement.
  </p>
  <p>
    <strong>Approved Image Format</strong>: means the image format specified on
    the PetView Site for a Use Case.
  </p>
  <p>
    <strong>Authorized Users</strong>: means Primary Account Holder or a user
    authorized by the Primary Account Holder and to whom Access Credentials are
    provided by PetView, subject to compliance with the General Terms and
    Conditions of Use [insert link].
  </p>
  <p>
    <strong>Credit Card</strong>: means the Primary Account Holder&rsquo;s
    credit card and associated account information provided by Primary Account
    Holder on the PetView Site.
  </p>
  <p>
    <strong>Fee:</strong> means the fee to be paid to Petview by Primary Account
    Holder as specified on the PetView Site for the provision by PetView of
    Imaging Analysis Services for each respective Use Case.&nbsp;
  </p>
  <p>
    <strong>Imaging Analysis Services</strong>: means (i) the processing by
    PetView of Use Case Images in connection with a Use Case and (ii) delivery
    via email or download to an Authorized User by PetView of the associated
    Report.
  </p>
  <p><strong>Parties:</strong> Primary Account Holder and PetView.</p>
  <p>
    <strong>PetView</strong>: means PetViewDX, LLC, a Delaware limited liability
    with a principal place of business at [].
  </p>
  <p>
    <strong>Petview Intellectual Property Rights</strong>: Any and all
    intellectual property rights of any nature, whether registered, registerable
    or otherwise, including patents, utility models, trademarks, registered
    designs and domain names, applications for any of the foregoing, trade or
    business names, goodwill, copyright and rights in the nature of copyright,
    design rights, rights in databases, moral rights, know-how and any other
    intellectual property rights that subsist in computer software, computer
    programs, websites, documents, information, techniques, business methods,
    drawings, logos, instruction manuals, marketing methods and procedures and
    advertising literature. Intellectual Property Rights shall also include all
    rights and forms of intellectual property protection of a similar nature or
    having equivalent or similar effect to any of the foregoing that may subsist
    anywhere in the world, in each case for their full term, together with any
    future rights and renewals or extensions. Intellectual Property Rights shall
    also include copyrights associated with the &ldquo;look and feel&rdquo; of
    the PetView Site, design parameters, view lists, procedures and particulars
    of Imaging Analysis Services.
  </p>
  <p>
    <strong>PetView Site: </strong>means the internet website portal established
    by PetView which enables Authorized Users to upload Use Case Images and
    associated user data to enable PetView to perform Imaging Analysis Services,
    and from which Reports may be downloaded by or emailed to Authorized Users.
  </p>
  <p>
    <strong>PetView</strong><strong>System: </strong>&nbsp;means the PetView
    Site and associated servers and other hardware, databases and software,
    including the Software
  </p>
  <p>
    <strong>Primary Account Holder</strong>: means the person or entity which
    enters into this Agreement with Petview.
  </p>
  <p>
    <strong>Report</strong>: means the report which provides the results of
    Imaging Analysis Services applicable to a given Use Case as set forth in the
    PetView Site.
  </p>
  <p>
    <strong>Software:</strong> The PetView Software used by PetView to provide
    Imaging Analysis Services.&nbsp;
  </p>
  <p>
    <strong>Term: </strong>means the period starting on the date Primary Account
    Holder agrees to this Agreement and continuing until either Party terminates
    this Agreement in writing.
  </p>
  <p>
    <strong>Third Party</strong>: means a person or entity other than a Party
    and other than a PetView Affiliate.
  </p>
  <p>
    <strong>Use Case</strong>: means. for a given animal species, the type of
    tissue for which Imaging Analysis Services are provided by Petview, such as,
    for example, a dog spleen or a cat kidney.
  </p>
  <p>
    <strong>Use Case Fee</strong>: means the fee charged by PetView for
    providing Imaging Analysis Services for a Use Case as set forth on the
    PetView Site at the time Imaging Analysis Services are requested by an
    Authorized User based upon the billing structure selected by the Primary
    Account Holder.&nbsp;
  </p>
  <ol start="2">
    <li>
      <strong> OBLIGATION OF PRIMARY ACCOUNT HOLDER. </strong>Primary Account
      Holder does and shall:
    </li>
  </ol>
  <ul>
    <li>
      ensure that each Authorized User will abide by the PetViewDX General Terms
      of Use [Insert link]
    </li>
  </ul>
  <ul>
    <li>
      be responsible for keeping its Authorized User information and other
      account information up to date on the PetView Site;
    </li>
  </ul>
  <ul>
    <li>
      provide PetView with such information as may be required by PetView in
      order to render the Imaging Processing Services;
    </li>
  </ul>
  <ul>
    <li>
      ensure that Use Case Images provided by Authorized Users are consistent
      with the applicable Approved Image Format;
    </li>
  </ul>
  <ul>
    <li>
      not directly or indirectly resell or serve as a service bureau with
      respect any Imaging Processing Services or Reports to any Third Party;
    </li>
  </ul>
  <ul>
    <li>
      employ all physical, administrative and technical controls, screening and
      security procedures and other safeguards necessary to: (a) securely
      administer the distribution and use of all access credentials and protect
      against any unauthorized access to or use of the Imaging Processing
      Services; and (b) control the content and use of Reports so that they are
      not made available to Third Parties;
    </li>
  </ul>
  <ul>
    <li>
      maintain regular data backups or redundant data archives with respect to
      Use Case Images and Reports, it being understood that PETVIEW HAS NO
      OBLIGATION OR LIABILITY FOR ANY LOSS, ALTERATION, DESTRUCTION, DAMAGE,
      CORRUPTION OR RECOVERY OF USE CASE IMAGES, ASSOCIATED DATA OR REPORTS;
    </li>
  </ul>
  <ul>
    <li>
      be responsible for paying for the Use Case Fee and hereby authorizes
      PetView to charge Primary Account Holder&rsquo;s Credit Card;
    </li>
  </ul>
  <ul>
    <li>
      use reasonable efforts so as not to cause any faults or malfunction in the
      Software or any related software or system of PetView; and,
    </li>
  </ul>
  <ul>
    <li>
      warrant that it has the full capacity and authority to enter into and
      perform this Agreement, and that it has all rights in Use Case Images
      uploaded by Authorized Users for use by PetView consistent with this
      Agreement.
    </li>
  </ul>
  <p>.</p>
  <ol start="3">
    <li><strong> PETVIEW&rsquo;S OBLIGATIONS.</strong></li>
  </ol>
  <ul>
    <li>
      Subject to the terms and conditions of this Agreement, PetView will
      provide Authorized Users access to the PetView Site for the purposes of
      allowing Authorized Users to call upon and utilize PetView&rsquo;s Imaging
      Analysis Services as provided hereunder. PetView reserves the right to
      terminate the provision of Imaging Analysis Services at any time. PetView
      does not warrant that use of Imaging Analysis Services by Authorized Users
      will be uninterrupted or error-free.
    </li>
  </ul>
  <ul>
    <li>
      PetView may suspend Imaging Analysis Services without liability if: (i)
      there is an attack on PetView Site, (ii) PetView is required by law, or a
      regulatory or government body to suspend Imaging Analysis Services, (iii)
      there is another event for which PetView reasonably believes that the
      suspension of Imaging Analysis Services is necessary to protect the
      PetView computer network, (iv) Primary Account Holder is in breach of any
      provision of Section 2 hereunder; and (v) any Authorized User is not in
      compliance with the General Terms of Use.
    </li>
  </ul>
  <ol start="4">
    <li>
      <strong> PROPRIETARY RIGHTS</strong>. PetView shall own all PetView
      Intellectual Property Rights including but not limited that related to the
      PetView Site. PetView retains the copyright on all Reports provided that
      Primary Account Holder shall have the right to use the Report for its own
      purposes. PetView shall have the right to use all Use Case Images provided
      by Authorized Users for purposes of research and develop and machine
      learning purposes. Neither Primary Account Holder nor any Authorized User
      shall acquire any license, other ownership interest, or other Right in the
      PetView Site, in the PetView System or the Software.&nbsp;
    </li>
  </ol>
  <ol start="5">
    <li>
      <strong
        >DISCLAIMER OF WARRANTIES, LIMITATION OF LIABILITY PROVISIONS, AND
        LIMITATION ON TIME TO FILES CLAIMS</strong
      >. The Disclaimer Of Warranties, Limitation Of Liability Provisions, And
      Limitation On Time To Files Claims of the General Terms And Conditions Of
      Use are incorporated by reference herein. [Insert Link to General Terms of
      Use.]
    </li>
  </ol>
  <ol start="6">
    <li><strong> TERM AND TERMINATION</strong></li>
  </ol>
  <ul>
    <li>
      This Agreement shall commence on the Effective Dates and shall, unless
      terminated earlier in accordance with this Clause, continue in force for
      the full duration of the Term.
    </li>
  </ul>
  <ul>
    <li>
      Subject to the survival provisions as set forth in Section 6.4 below,
      Primary Account Holder may terminate this Agreement by electing that
      option as set forth on the PetView Site.
    </li>
  </ul>
  <ul>
    <li>
      Subject to the survival provisions of 6.4 below, PetView may terminate
      this Agreement by pro providing email notice of the same to the email
      address provided by Primary Account Holder on the PetView Site;
    </li>
  </ul>
  <p>
    (d) The following provisions shall survive termination of this Agreement:
    Sections 2 (a), (e), (f), (g), (h), (i), 4, 6.4, 7, 8, 9, 10, 11, 12, 13 and
    14.&nbsp;
  </p>
  <ol start="7">
    <li>
      <strong> FORCE MAJEURE</strong>. PetView shall not be liable to the other
      under this Agreement if it is prevented from, or delayed in, performing
      its obligations under this Agreement, or from carrying on its business, by
      acts, events, omissions or accidents due to Force Majeure. Force Majeure:
      means a factor beyond the reasonable control of PetView, such as including
      without limitation acts of God, flood, fire, earthquake or explosion, war,
      terrorism, invasion, riot or other civil unrest, embargoes or blockades in
      effect on or after the date of this Agreement, national or regional
      emergency, strikes, epidemics or pandemics, labor stoppages or slowdowns
      or other industrial disturbances, passage of Law or any action taken by a
      governmental or public authority, including imposing an embargo, export or
      import restriction, quota or other restriction or prohibition or any
      complete or partial government shutdown, national or regional or local
      shortage of adequate power or telecommunications or transportation, or
      service outages of PetView&rsquo;s internet service providers, or other
      outages of internet infrastructure providers affecting service
      availability of PetView and its underlying data suppliers.
    </li>
  </ol>
  <ol start="8">
    <li>
      <strong> WAIVER. </strong>No failure or delay by a party to exercise any
      right or remedy provided under this Agreement or by law shall constitute a
      waiver of that or any other right or remedy, nor shall it preclude or
      restrict the further exercise of that or any other right or remedy. No
      single or partial exercise of such right or remedy shall preclude or
      restrict the further exercise of that or any other right or remedy.
    </li>
  </ol>
  <ol start="9">
    <li>
      <strong> SEVERANCE. </strong>If any court or competent authority finds
      that any provision of this Agreement (or part of any provision) is
      invalid, illegal or unenforceable, that provision or part-provision shall,
      to the extent required, be deemed to be deleted, and the validity and
      enforceability of the other provisions of this Agreement shall not be
      affected. If any invalid, unenforceable or illegal provision of this
      Agreement would be valid, enforceable and legal if some part of it were
      deleted, the Parties shall negotiate in good faith to amend such provision
      such that, as amended, it is legal, valid and enforceable, and, to the
      greatest extent possible, achieves the Parties&rsquo; original commercial
      intention.
    </li>
  </ol>
  <ol start="10">
    <li>
      <strong> ENTIRE AGREEMENT AND AMENDMENT. </strong>This Agreement
      constitutes the entire Agreement between the Parties and supersedes all
      previous discussions, correspondence, negotiations, arrangements,
      understandings and agreements between them relating to its subject matter.
    </li>
  </ol>
  <ol start="11">
    <li>
      <strong> ASSIGNMENT. </strong>Primary Account Holder may not assign its
      rights and obligations under this Agreement.
    </li>
  </ol>
  <ol start="12">
    <li>
      <strong> NO PARTNERSHIP OR AGENCY. </strong>Nothing in this Agreement is
      intended to, or shall be deemed to, establish any partnership or joint
      venture between any of the Parties, constitute any party the agent of
      another party, nor authorize any party to make or enter into any
      commitments for or on behalf of any other party.
    </li>
  </ol>
  <ol start="13">
    <li>
      <strong> THIRD-PARTY RIGHTS. </strong>This Agreement is made for the
      benefit of the Parties to it and (where applicable) their successors and
      permitted assigns, and is not <u>i</u>ntended to benefit or be enforceable
      by anyone else.
    </li>
  </ol>
  <ol start="14">
    <li>
      <strong> GOVERNING LAW AND JURISDICTION. </strong>This Agreement and any
      dispute or claim arising out of or in connection with it or its subject
      matter or formation (including non-contractual disputes or claims) shall
      be governed by and construed in accordance with the laws of North
      Carolina. Primary Account Holder consents to the sole and exclusive
      jurisdiction of the North Carolina Courts or the United States District
      Court for the Eastern District of North Carolina, as well as the
      jurisdiction of all courts from which appeal may be taken therefrom, for
      the purpose of hearing any suit, action, or other proceeding relating to
      this Agreement.
    </li>
  </ol>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button
    color="primary"
    mat-raised-button
    class="my-button"
    (click)="onConfirm()"
    cdkFocusInitial
  >
    I agree
  </button>
</mat-dialog-actions>
