import { config } from '@helpers/Config';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationsService } from './notifications.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private notificationService: NotificationsService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public set currentUserToLocalStorage(user) {
    /** parse data before save to localStorage */
    const localStorageStage = JSON.parse(localStorage.getItem('currentUser'));
    const newLocalStorage = Object.assign(localStorageStage, { user });

    this.currentUserSubject.next(newLocalStorage);

    localStorage.setItem('currentUser', JSON.stringify(newLocalStorage));
  }

  removeLocalStorage() {
    localStorage.removeItem('currentUser');

  }

  updateRememberMeStatus(state: boolean): void {

    localStorage.setItem('rememberMe', `${ state }`);
  }

  login(email: string, password: string, remember: number): Observable<any> {
    return this.http
      .post<any>(config.endpoint + '/login', { email, password, remember })
      .pipe(
        map((user) => {
          if (user && user.access_token) {
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  logout(): void {
    this.notificationService.deleteToken();
    localStorage.removeItem('currentUser');
    this.router.navigate(['/login']);
    this.currentUserSubject.next(null);
  }
}
