<div class="card m-auto p-2 demo-form">
  <h2 class="mb-0">Set your password</h2>
  <small>Create your new password.</small>
  <hr class="mb-1" />

  <div class="demo-fields">
    <!-- Additional Fields -->

    <ng-content #name></ng-content>

    <!-- End Additional Fields -->

    <div class="d-flex demo-name-group">
      <!-- Password -->

      <div class="d-flex flex-column flex-1">
        <mat-form-field
          appearance="outline"
          class="input-full-width"
        >
          <mat-label>Password</mat-label>
          <input
            matInput
            name="password"
            placeholder="Password"
            formControlName="password"
            maxlength="60"
            [type]="hide ? 'password' : 'text'"
          />

          <mat-error *ngIf="form.controls['password'].hasError('required')">
            Password is a <strong>required.</strong>
          </mat-error>

          <button
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
            type="button"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>

          <mat-error>
            <app-field-errors [formField]="form.get('password')">
            </app-field-errors>
          </mat-error>
        </mat-form-field>

        <app-password-strength
          (passwordState)="passwordState($event)"
          [password]="form.get('password').value"
        >
        </app-password-strength>
      </div>

      <!-- Repeat password -->
      <mat-form-field
        appearance="outline"
        class="input-full-width"
      >
        <mat-label>Repeat password</mat-label>
        <input
          matInput
          name="password-confirmation"
          placeholder="Repeat password"
          formControlName="password_confirmation"
          maxlength="60"
          [errorStateMatcher]="matcher"
          [type]="hideRe ? 'password' : 'text'"
        />

        <button
          mat-icon-button
          matSuffix
          (click)="hideRe = !hideRe"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hideRe"
          type="button"
        >
          <mat-icon>{{ hideRe ? "visibility_off" : "visibility" }}</mat-icon>
        </button>

        <mat-error>
          <app-field-errors [formField]="form"> </app-field-errors>
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="d-flex justify-content-between">
    <button
      mat-raised-button
      color="primary"
      type="button"
      class="my-button"
      (click)="finish()"
      [disabled]="form.invalid || loading"
    >
      Finish
    </button>

    <a
      mat-button
      [routerLink]="['login']"
      class="my-link my-button"
    >Back to login</a>
  </div>
</div>
