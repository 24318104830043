<div class="inner-header card p-2 mb-2">
  <div class="inner-header-actions">
    <div class="inner-header-title d-flex align-items-center mb-1">
      <div class="page-icon"><span class="petview-training"></span></div>
      <h3>User Activity</h3>
    </div>
    <div class="inner-header-buttons">
      <a
        mat-button
        (click)="export()"
        class="my-link my-button"
      >Export All (.xlsx)</a>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="inner-header-filters">
    <div class="group mt-2">
      <div class="flex-1">
        <mat-form-field
          appearance="outline"
          class="full-width mt-0 pb-0"
        >
          <mat-label>Search by Email</mat-label>
          <span matPrefix>
            <mat-icon>search</mat-icon>
          </span>
          <input
            matInput
            placeholder="Search by Email"
            (keydown.enter)="loadDataSource()"
            [(ngModel)]="filters.filterSearch"
          />
          <button
            name="filterSearch"
            mat-button
            *ngIf="filters.filterSearch"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="onClear($event, 'filterSearch')"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="d-flex align-items-center min-width-auto">
        <button
          mat-button
          (click)="accordion.toggle()"
          class="my-outline my-button ml-1"
        >
          Advanced Search <span class="petview-filter"></span>
        </button>

        <button
          mat-raised-button
          type="submit"
          color="primary"
          (click)="loadDataSource()"
          class="my-button ml-1"
        >
          Search <span class="petview-search"></span>
        </button>
      </div>
    </div>

    <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0">
        <div class="group align-items-start">
          <div class="mw-75">
            <div class="group_row">
              <mat-form-field appearance="outline">
                <mat-label>User Type</mat-label>
                <mat-select
                  disableOptionCentering
                  placeholder="User type"
                  name="User type"
                  [(ngModel)]="filters.filterUserType"
                  (selectionChange)="loadDataSource()"
                >
                  <mat-option
                    *ngFor="let userType of dropdownUserTypes"
                    [value]="userType.value"
                  >
                    {{ userType.label }}
                  </mat-option>
                </mat-select>

                <button
                  name="filterUserType"
                  mat-button
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  *ngIf="filters.filterUserType"
                  (click)="onClear($event, 'filterUserType')"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Form Step</mat-label>
                <mat-select
                  disableOptionCentering
                  placeholder="Form Step"
                  name="formStep"
                  [(ngModel)]="filters.filterFormStep"
                  (selectionChange)="loadDataSource()"
                >
                  <mat-option
                    *ngFor="let formStep of dropdownFormSteps"
                    [value]="formStep.value"
                  >
                    {{ formStep.label }}
                  </mat-option>
                </mat-select>

                <button
                  name="filterFormStep"
                  mat-button
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  *ngIf="filters.filterFormStep"
                  (click)="onClear($event, 'filterFormStep')"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

            </div>

            <div class="group_row">
              <mat-form-field appearance="outline">
                <mat-label>Detection time from</mat-label>

                <input
                  matInput
                  [matDatepicker]="picker"
                  [(ngModel)]="filters.filterDateFrom"
                  (dateChange)="loadDataSource()"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                >
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <button
                  name="filterDateFrom"
                  mat-button
                  *ngIf="filters.filterDateFrom"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="onClear($event, 'filterDateFrom')"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Detection time to</mat-label>

                <input
                  matInput
                  [matDatepicker]="picker1"
                  [(ngModel)]="filters.filterDateTo"
                  (dateChange)="loadDataSource()"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker1"
                >
                </mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>

                <button
                  name="filterDateTo"
                  mat-button
                  *ngIf="filters.filterDateTo"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="onClear($event, 'filterDateTo')"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

            </div>
          </div>
          <div class="col-5">
            <button
              mat-button
              (click)="resetFilters()"
              class="my-link my-button ml-1"
            >
              Reset advanced search
            </button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div
  id="audit-logs"
  class="relative"
>
  <ngx-spinner
    bgColor="red"
    name="table"
  >
    <p style="font-size: 20px; color: #000">Loading...</p>
  </ngx-spinner>

  <table
    mat-table
    [dataSource]="dataSource"
    matSort
  >
    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th
        mat-header-cell
        *matHeaderCellDef
      >ID</th>
      <td
        mat-cell
        *matCellDef="let row"
      >{{ row.id }}</td>
    </ng-container>

    <!-- Session Id Column -->
    <!-- <ng-container matColumnDef="session_id">
      <th
        mat-header-cell
        *matHeaderCellDef
      >Session ID</th>
      <td
        mat-cell
        *matCellDef="let row"
      >
        <span matTooltip="{{ row.session_id }}">
          {{
          row.session_id.length > 30
          ? (row.session_id | slice: 0:15) + "..." + (row.session_id | slice: 27)
          : row.session_id
          }}</span>
    </ng-container> -->

    <!-- Detection Ip Column -->
    <!-- <ng-container matColumnDef="ip_address">
      <th
        mat-header-cell
        *matHeaderCellDef
      >Detection IP</th>
      <td
        mat-cell
        *matCellDef="let row"
      >
        {{ row.ip_address }}
      </td>
    </ng-container> -->

    <!-- User Type Column -->
    <ng-container matColumnDef="type">
      <th
        mat-header-cell
        *matHeaderCellDef
      >User Type</th>
      <td
        mat-cell
        *matCellDef="let row"
      >
        {{ utilService.roleName(row.type) }}
      </td>
    </ng-container>

    <!-- Clinic Name Column -->
    <ng-container matColumnDef="clinic">
      <th
        mat-header-cell
        *matHeaderCellDef
      >Clinic</th>
      <td
        mat-cell
        *matCellDef="let row"
      >

        <span [ngClass]="{ 'empty-cell': !row.clinic }">{{
          row.clinic
          ? row.clinic
          : "no clinic"
          }}
        </span>

      </td>
    </ng-container>

    <!-- Detection Time Column -->
    <ng-container matColumnDef="created_at">
      <th
        mat-header-cell
        *matHeaderCellDef
      >Detection Time</th>
      <td
        mat-cell
        *matCellDef="let row"
      >
        {{ row.created_at | date:'medium' }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="email">
      <th
        mat-header-cell
        *matHeaderCellDef
      >Email</th>
      <td
        mat-cell
        *matCellDef="let row"
      >{{ row.email }}</td>
    </ng-container>

    <!-- Pricing Plan Column -->
    <ng-container matColumnDef="plan">
      <th
        mat-header-cell
        *matHeaderCellDef
      >Pricing Plan</th>
      <td
        mat-cell
        *matCellDef="let row"
      >{{ row.plan }}</td>
    </ng-container>

    <!-- Form Step Column -->
    <ng-container matColumnDef="form_step">
      <th
        mat-header-cell
        *matHeaderCellDef
      >Form Step</th>
      <td
        mat-cell
        *matCellDef="let row"
      >{{ row.form_step }}</td>
    </ng-container>

    <!-- URL Column -->
    <ng-container matColumnDef="form_fields">
      <th
        mat-header-cell
        *matHeaderCellDef
      >Form Fields</th>
      <td
        mat-cell
        *matCellDef="let row"
      >
        <!-- <span matTooltip="{{ row.form_fields }}">
          {{
          row.form_fields.length > 30
          ? (row.form_fields | slice: 0:15) + "..."
          : row.form_fields
          }}</span> -->

        <button
          matTooltip="Show more"
          mat-button
          (click)="showFormFields(row)"
        >
          <span class="material-icons material-outlined">
            more_horiz
          </span>
        </button>
      </td>
    </ng-container>

    <!-- Browser Column -->
    <ng-container matColumnDef="route">
      <th
        mat-header-cell
        *matHeaderCellDef
      >Route</th>
      <td
        mat-cell
        *matCellDef="let row"
      >
        {{ row.route }}
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="actions">
      <th
        mat-header-cell
        *matHeaderCellDef
      >Action</th>
      <td
        mat-cell
        class="actions-cell-audits"
        *matCellDef="let row"
      >
        <button
          matTooltip="View details"
          matTooltipPosition="above"
          (click)="onDetailsView(row)"
          type="button"
          rel="tooltip"
          mat-icon-button
          color="primary"
        >
          <span class="petview-search"></span>
        </button>
      </td>
    </ng-container> -->

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
  </table>

  <mat-paginator
    [length]="total"
    [pageSize]="pageSize"
  ></mat-paginator>
</div>
