import { AOILabels } from '@enums/AOILabels';

export const aoiTypeInitalData = [
    {
        id: 1,
        label: AOILabels.FOV,
        data: null,
        isActive: false,
        hidden: false,
        valid: false,
        thumbnail: null,
        description: 'The field of view is the extent of the observable world that is seen at any given moment. In the case of optical instruments or sensors it is a solid angle through which a detector is sensitive to electromagnetic radiation.'
      },
      {
        id: 2,
        label: AOILabels.SPLEEN,
        data: null,
        isActive: false,
        hidden: false,
        valid: false,
        thumbnail: null,
        description: 'The spleen of view is the extent of the observable world that is seen at any given moment. In the case of optical instruments or sensors it is a solid angle through which a detector is sensitive to electromagnetic radiation.'
      },
      {
        id: 3,
        label: AOILabels.LESION,
        data: null,
        isActive: false,
        hidden: false,
        valid: false,
        thumbnail: null,
        description: 'The Lesion is the extent of the observable world that is seen at any given moment. In the case of optical instruments or sensors it is a solid angle through which a detector is sensitive to electromagnetic radiation.'
      },
      {
        id: 4,
        label: AOILabels.ROI,
        data: null,
        isActive: false,
        hidden: false,
        valid: false,
        thumbnail: null,
        description: 'The other region is the extent of the observable world that is seen at any given moment. In the case of optical instruments or sensors it is a solid angle through which a detector is sensitive to electromagnetic radiation.'
      }
]