import { Resourses } from '@shared/resources/add-resourses';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Role } from '@enums/role';
import { Component, OnInit } from '@angular/core';
import { InformationOfTypeDialogComponent } from '@shared/components/information-of-type-dialog/information-of-type-dialog.component';
import { pricingPlanEnum } from '@enums/pricingPlanEnum';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'app-card-general',
  templateUrl: './card-general.component.html',
  styleUrls: ['./card-general.component.scss'],
})
export class CardGeneralComponent implements OnInit {
  date: any;
  user: any;

  role = Role;
  pricingPlanEnum = pricingPlanEnum;

  constructor(
    private dialog: MatDialog,
    private resources: Resourses,
    private authService: AuthenticationService
  ) {
    this.date = new Date();
  }

  ngOnInit(): void {
    this.authService.currentUser.subscribe({
      next: (resp) => {
        if (resp) {
          this.user = resp?.user;
        }
      },
    });
  }

  openMoreDetails(type, name, event): void {
    // open info dialog about use case
    event.stopPropagation();
    event.preventDefault();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      data: {
        name,
        content: this.resources.createCaseTypeDescription[type],
      },
    };
    dialogConfig.width = '90%';
    dialogConfig.panelClass = 'modal-primary';

    this.dialog.open(InformationOfTypeDialogComponent, dialogConfig);
  }
}
