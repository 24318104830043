import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CroppingDataService } from '@services/cropping-data.service';

@Component({
  selector: 'app-drawing-image-list',
  templateUrl: './drawing-image-list.component.html',
  styleUrls: ['./drawing-image-list.component.scss']
})
export class DrawingImageListComponent implements OnInit, OnDestroy {

  subscription: Subscription[] = [];
  imagesStatus: any[] = [];

  @Input() images: any;
  @Input() selectedIndex;
  @Output() selectedImageEvent = new EventEmitter();
  constructor(
    private croppingService: CroppingDataService
  ) { }

  ngOnInit(): void {
    // init create empty array
    // this.images.allImages.map(obj => this.imagesStatus.splice(this.selectedIndex, 0, false));

    this.images.allImages.forEach((value) => {
      const exist = value.aoiParameters ? value.aoiParameters.filter(aoitype => aoitype.AOISegment.length !== 0) : [];
      if (exist.length !== 0) {
        this.imagesStatus[value.index] = true;
      } else {
        this.imagesStatus[value.index] = false;
      }
    });

    this.subscription.push(this.croppingService.imageStatus.subscribe((response: any) => {
      if (response) {
        const exist = response.state.filter(aoitype => aoitype.data);
        if (exist.length !== 0) {
          this.imagesStatus[response.index] = true;
        } else {
          this.imagesStatus[response.index] = false;
        }
      }
    }));
  }

  onSelectImage(selectedImageData, index: number): void {
    this.selectedImageEvent.emit({ data: selectedImageData, savingOrigin: true, index });
  }

  // returns true if at least 1 AOI is plotted
  isImageMarked(data: any): any {
    if (data.aoiTabsData) {
      return data.aoiTabsData.some(aoiTab => aoiTab.data);
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.subscription.forEach(subscription => subscription.unsubscribe());
  }

}
