export class CanvasObject {
    backgroundImage: any;
    img: CanvasImg = new CanvasImg();
    container: CanvasContainer = new CanvasContainer();
    dimensions: CanvasDimensions = new CanvasDimensions();
}

export class CanvasDimensions {
    width: number = 0;
    height: number = 0;
}

export class CanvasContainer {
    elementWidth: number = 0;
    elementHeight: number = 0;
}

export class CanvasImg {
    width: number = 0;
    height: number = 0;
}