import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';
import { ClinicUsers } from '@models/clinicUsers';

@Injectable({
  providedIn: 'root',
})
export class ClinicUsersService {
  localUserData = new BehaviorSubject(null);
  localUserDataEmitter = this.localUserData.asObservable();

  // * Saved search & filters
  savedSearch = new BehaviorSubject(null);

  constructor(private http: HttpService) { }

  // store data for clinic on edit
  storeClinicUser(user: any): void {
    this.localUserData.next(user);
  }
  // get stored data for clinic
  readClinicUser(): Observable<any> {
    return this.localUserDataEmitter as Observable<any>;
  }

  createClinicUser(data: any): any {
    return this.http.post('/users', data);
  }

  registerClinicUser(data: any): any {
    return this.http.post('/register', data);
  }

  registerClinic(data: any): any {
    return this.http.post('/clinics/newClinicRequest', data);
  }

  updateClinicUser(id: number, data: any) {
    return this.http.post(`/users/${ id }`, data);
  }

  updateClinic(id: number, data: any) {
    return this.http.post(`/clinics/${ id }`, data);
  }

  getClinicUserById(id: number) {
    return this.http.get(`/users/${ id }`);
  }

  deleteClinicUser(id: number[]): any {
    return this.http.delete(`/users?ids=${ id }`);
  }

  getClinicUsers(filters, pageNumber = 1): any {
    return this.http.getClinicUsersTable('/users', pageNumber, filters);
  }

  public findClinicUsersTable(
    filters,
    pageNumber = 1
  ): Observable<ClinicUsers[]> {
    return this.http
      .getClinicUsersTable('/users', pageNumber, filters)
      .pipe(map((res) => res['data']));
  }

  saveSearch(params) {
    this.savedSearch.next(params);
  }

  getSavedSearch() {
    return this.savedSearch.asObservable();
  }
}
