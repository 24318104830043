import { UtilClass } from './UtilClass';

export class SCInstance {
  active: boolean = false;
  data: any = null;
  dimensions: any[] = [];
  shape: string = '';
  
  setActive(isActive: boolean) {
    this.active = isActive;
  }
  setData(data: any) {
    this.data = data;
  }
  setDimensions(dimensions: any) {
    this.dimensions = dimensions;
  }
  setShape(shape: string) {
    this.shape = shape;
  }


  constructor(obj?: any) {
    if(!UtilClass.isNullOrUndefined(obj)) {
      Object.assign(this, obj);
    }
  }
}
