import { MaterialModule } from '@modules/material/material.module';
import { FieldErrorsComponent } from './field-errors.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [FieldErrorsComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [FieldErrorsComponent]
})
export class FieldErrorsModule { }
