<mat-dialog-content class="text-center">

  <div class="relative height-40">
    <ngx-spinner name="basicDialogLoading">
    </ngx-spinner>
  </div>

  <h3>Your request is still being
    processed, please wait.</h3>

</mat-dialog-content>
