<div class="card m-auto p-2 demo-form">
  <h2 class="mb-0">Complete your registration</h2>
  <small>Create an account and continue using our customer experience.</small>
  <hr class="mb-1" />

  <div
    class="demo-fields"
    [formGroup]="invitedUserForm"
  >
    <div class="d-flex demo-name-group">
      <!-- FistName -->
      <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input
          matInput
          value=""
          formControlName="first_name"
          name="first_name"
          maxlength="60"
          type="text"
        />
        <mat-error>
          <app-field-errors [formField]="invitedUserForm.get('first_name')">
          </app-field-errors>
        </mat-error>
      </mat-form-field>

      <!-- Last Name -->
      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input
          matInput
          value=""
          formControlName="last_name"
          name="last_name"
          maxlength="60"
          type="text"
        />
        <mat-error>
          <app-field-errors [formField]="invitedUserForm.get('last_name')">
          </app-field-errors>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="d-flex demo-name-group">
      <!-- Password -->

      <div class="d-flex flex-column flex-1">
        <mat-form-field
          appearance="outline"
          class="input-full-width"
        >
          <mat-label>Password</mat-label>
          <input
            matInput
            placeholder="Password"
            formControlName="password"
            maxlength="60"
            [type]="hide ? 'password' : 'text'"
          />

          <mat-error *ngIf="invitedUserForm.controls['password'].hasError('required')">
            Password is a <strong>required.</strong>
          </mat-error>

          <button
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
            type="button"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>

          <mat-error>
            <app-field-errors [formField]="invitedUserForm.get('password')">
            </app-field-errors>
          </mat-error>
        </mat-form-field>

        <app-password-strength
          (passwordState)="passwordState($event)"
          [password]="invitedUserForm.get('password').value"
        >
        </app-password-strength>
      </div>

      <!-- Repeat password -->
      <mat-form-field
        appearance="outline"
        class="input-full-width"
      >
        <mat-label>Repeat password</mat-label>
        <input
          matInput
          placeholder="Repeat password"
          formControlName="password_confirmation"
          maxlength="60"
          [errorStateMatcher]="matcher"
          [type]="hideRe ? 'password' : 'text'"
        />

        <button
          mat-icon-button
          matSuffix
          (click)="hideRe = !hideRe"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hideRe"
          type="button"
        >
          <mat-icon>{{ hideRe ? "visibility_off" : "visibility" }}</mat-icon>
        </button>

        <mat-error>
          <app-field-errors [formField]="invitedUserForm"> </app-field-errors>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Checkbox -->
    <mat-checkbox
      formControlName="subscribe"
      color="primary"
    >Subscribe to our newsletter</mat-checkbox>
  </div>

  <button
    mat-raised-button
    color="primary"
    type="button"
    class="my-button"
    [disabled]="invitedUserForm.invalid || loading"
    (click)="onSubmit()"
  >
    Submit
  </button>
</div>
