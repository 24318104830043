<div class="cards card-min-h">
  <div class="cards-header d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center w-100">
      <strong>Your statistics</strong>
      <span class="cards-header-actions"
        ><button mat-button disabled>
          Show by Cases
          <span class="material-icons"> keyboard_arrow_down </span>
        </button></span
      >
    </div>
  </div>

  <mat-divider class=""></mat-divider>

  <p class="d-flex justify-content-between align-items-center mb-1 mt-1 w-100">
    <span>Case statistics</span
    ><span
      ><button
        mat-button
        class="my-button my-button-small mr-1"
        color="primary"
        [ngClass]="{ active: activeFilter === 'weekly' }"
        (click)="onSelectDataset('weekly')"
      >
        Weekly
      </button>
      <button
        mat-button
        class="my-button my-button-small"
        [ngClass]="{ active: activeFilter === 'monthly' }"
        (click)="onSelectDataset('monthly')"
      >
        Monthly
      </button>
      <!-- <button mat-button>Yearly</button> -->
    </span>
  </p>

  <div class="chart overflow-hidden">
    <ngx-charts-bar-vertical-2d
      [scheme]="colorScheme"
      [results]="multi"
      autoScale="true"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [legend]="legend"
      [groupPadding]="20"
      [barPadding]="4"
      (select)="onSelect($event)"
      (activate)="onActivate($event)"
      [yAxisTicks]="yAxisTicks"
      (deactivate)="onDeactivate($event)"
    >
    </ngx-charts-bar-vertical-2d>
  </div>
</div>
