import { AuthenticationService } from '@services/authentication.service';
import { NotificationsService } from '@services/notifications.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { Role } from '@enums/role';
import { RouteInfo } from '@models/RouteInfo';
import { ClinicRequestsService } from '@services/clinic-requests.service';
import { ClinicUsersRequestsService } from '@services/clinic-users-requests.service';
import { ToastService } from '@services/toast.service';
import { ROUTES } from './navbar-routes.config';
import { config } from '@helpers/Config';
import { UserService } from '@services/user.service';
import { RequestsStatsService } from '@services/requests-stats.service';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  menuItems: RouteInfo[] = [];
  loggedRole: string;
  message: BehaviorSubject<any>;
  messageList: any[] = [];
  notificationList: any[] = [];
  numberOfUnseen: any[] = [];
  currentMessageCaseId: any;
  subscription: Subscription[] = [];
  activeNav: string;
  appVersion: string;
  role = Role;

  // // active users requests
  // userRequestsCount: number;
  // // active clinics requests
  // clinicRequestsCount: number;

  openSubMenu: boolean = false;

  constructor(
    private router: Router,
    public notificationsService: NotificationsService,
    private toast: ToastService,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private userService: UserService,
    public location: Location,
    private rsService: RequestsStatsService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private sw: SwUpdate,
  ) {
    this.subscription.push(
      this.authService.currentUser.subscribe({
        next: (resp) => {
          if (resp) {
            this.loggedRole = resp?.user?.role;
          }
        },
      })
    );
  }

  ngOnInit(): void {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);

    // ! service worker
    // console.log(this.sw);

    // if (this.sw.isEnabled) {
    //   interval(5000).subscribe(() => {
    //     console.log('enabled interval');

    //     this.sw.checkForUpdate().then((res) => console.log(res)
    //     )
    //   });

    //   this.sw.available.subscribe(() => {
    //     console.log('has update! = SW');
    //   });
    // }

    // ! end

    this.userService.me().subscribe(
      (resp) => {
        this.authService.currentUserToLocalStorage = resp;

        if (resp.subscribe && resp.subscribe?.renewal_date && this.calculateDate(resp.subscribe?.renewal_date) <= 7) {
          // compare dates and show notification for day lefts before next renewal
          const dialogConfig = new MatDialogConfig();
          let renewalTxt = 'Renewal subscription';

          if (this.calculateDate(resp.subscribe?.renewal_date) === 0) {
            renewalTxt = `Renewal subscription`;
          } else {
            renewalTxt = `${ this.calculateDate(resp.subscribe?.renewal_date) } days left until renewal`;
          }


          dialogConfig.data = {
            title: renewalTxt,
            content: `Your renewal date is on ${ this.datePipe.transform(resp.subscribe?.renewal_date, 'MMM d, y') }`,
            confirmTxt: 'Close',
            onlyConfirmation: true,
          };
          dialogConfig.panelClass = 'modal-primary';
          this.dialog.open(ConfirmDialogComponent, dialogConfig);
        }

      },
      (error) => {
        this.authService.logout();
      }
    );

    this.notificationsService.requestPermission();
    this.notificationsService.receiveMessage();

    // this.message = this.notificationsService.currentMessage;

    this.menuItems = ROUTES.filter((menuItem) => menuItem);

    this.message = this.notificationsService.currentMessage;
    this.subscription.push(
      this.message.subscribe((msg: any) => {
        if (msg) {
          if (this.currentMessageCaseId !== msg.data.case_id) {
            this.messageList.push(msg);
            this.toast.showNotification(
              msg.notification.title,
              msg.notification.body,
              msg
            );
            this.notificationsService.playSound();
          } else if (!msg.data) {
            this.messageList.push(msg);
            this.toast.showNotification(
              msg.notification.title,
              msg.notification.body,
              msg
            );
            this.notificationsService.playSound();
          }

          if (msg.data?.case_id) {
            this.currentMessageCaseId = msg.data?.case_id;
          }
        }
      })
    );

    /** set app version */
    this.appVersion = config.appVersion;

    /** Load requests notifications */
    this.rsService.loadRequestsNotification();
  }

  calculateDate(discountExpDate): any {
    if (!discountExpDate) {
      return 0;
    }
    const diffDate = new Date(discountExpDate).valueOf() - new Date().valueOf();
    return Math.ceil(diffDate / 8.64e7);
  }

  setActiveNavRoute(path: string): void {
    this.activeNav = path;
  }

  navRoute(submenus: any[]): any {
    try {
      return submenus.filter((submenus) =>
        this.location.path().includes(submenus.path)
      )[0];
    } catch (err) {
      return false;
    }
  }

  openNotification(data) {
    this.router.navigate(['/cases/view/' + data.case_id], {
      relativeTo: this.route.parent,
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.forEach((subscription) => subscription.unsubscribe());
  }
}
