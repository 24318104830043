<!-- <div class="logo">
  <a routerLink="/">
    <img
      class="logo-img"
      src="../../../assets/images/petview_logo_svg.svg"
      alt=""
    />
  </a>
</div> -->

<mat-nav-list class="navigation-list">
  <!-- Dashboard -->
  <li>
    <a
      mat-list-item
      [routerLinkActive]="'is-active'"
      [routerLink]="[menuItems[0].path]"
      class="color-secondary"
    >
      <span class="petview-dashboard mr-1"></span>{{ menuItems[0].title }}</a>
  </li>

  <!-- Woody Breast -->
  <li *ngIf="loggedRole === role.USER || loggedRole === role.WOODY_BREAST_USER">
    <a
      mat-list-item
      [routerLinkActive]="'is-active'"
      [routerLink]="[menuItems[1].path]"
      class="color-secondary"
    >
      <span class="petview-folder mr-1"></span>{{ menuItems[1].title }}</a>
  </li>

  <!-- Cases -->
  <li *ngIf="loggedRole === role.ADMIN || loggedRole === role.CLINIC_ADMIN">
    <a
      mat-list-item
      class="color-secondary"
      (click)="setActiveNavRoute(menuItems[1].path)"
      [routerLink]="[navRoute(menuItems[1].submenu) || menuItems[1].path]"
      [routerLinkActive]="'is-active'"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      <span class="petview-folder mr-1"></span>
      {{ menuItems[1].title }}
    </a>

    <mat-nav-list
      class="navigation-list submenu"
      [ngClass]="{ open: navRoute(menuItems[1].submenu) }"
    >
      <!-- Cases list -->
  <li>
    <a
      mat-list-item
      (click)="setActiveNavRoute(menuItems[1].submenu[0].path)"
      [routerLinkActiveOptions]="{ exact: false }"
      [routerLinkActive]="'is-active'"
      [routerLink]="[menuItems[1].submenu[0].path]"
      class="color-secondary"
    >
      <!-- <span class="petview-folder mr-1"></span> -->
      {{ menuItems[1].submenu[0].title }}
    </a>
  </li>

  <!-- References -->
  <li *ngIf="loggedRole === role.ADMIN">
    <a
      mat-list-item
      (click)="setActiveNavRoute(menuItems[1].submenu[1].path)"
      [routerLinkActiveOptions]="{ exact: false }"
      [routerLinkActive]="'is-active'"
      [routerLink]="[menuItems[1].submenu[1].path]"
      class="color-secondary"
    >
      {{ menuItems[1].submenu[1].title }}
    </a>
  </li>
</mat-nav-list>
</li>

<!-- Clinics -->
<li *ngIf="loggedRole === role.ADMIN">
  <a
    mat-list-item
    [routerLinkActive]="'is-active'"
    (click)="setActiveNavRoute(menuItems[2].path)"
    [routerLink]="[navRoute(menuItems[2].submenu) || menuItems[2].path]"
    class="color-secondary"
  >
    <span class="petview-plus mr-1"></span> {{ menuItems[2].title }}</a>

  <mat-nav-list
    class="navigation-list submenu"
    [ngClass]="{ open: navRoute(menuItems[2].submenu) }"
  >
    <a
      mat-list-item
      (click)="setActiveNavRoute(menuItems[2].submenu[0].path)"
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLinkActive]="'is-active'"
      [routerLink]="[menuItems[2].submenu[0].path]"
      class="color-secondary"
    >
      {{ menuItems[2].submenu[0].title }}</a>

    <a
      mat-list-item
      (click)="setActiveNavRoute(menuItems[2].submenu[1].path)"
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLinkActive]="'is-active'"
      [routerLink]="[menuItems[2].submenu[1].path]"
      class="color-secondary"
    >
      <span
        matBadgeColor="warn"
        [matBadgeHidden]="
            notificationsService.requestsNotification.clinicRequestCount === 0
          "
        [matBadge]="
            notificationsService.requestsNotification.clinicRequestCount
          "
        matBadgeOverlap="false"
      >{{ menuItems[2].submenu[1].title }}</span></a>
  </mat-nav-list>
</li>

<li *ngIf="loggedRole === role.ADMIN || loggedRole === role.CLINIC_ADMIN">
  <a
    mat-list-item
    [routerLinkActive]="'is-active'"
    (click)="setActiveNavRoute(menuItems[3].path)"
    [routerLink]="[navRoute(menuItems[3].submenu) || menuItems[3].path]"
    class="color-secondary"
  >
    <span class="petview-user_circle mr-1"></span> {{ menuItems[3].title }}</a>

  <mat-nav-list
    class="navigation-list submenu"
    [ngClass]="{ open: navRoute(menuItems[3].submenu) }"
  >
    <a
      mat-list-item
      (click)="setActiveNavRoute(menuItems[3].submenu[0]?.path)"
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLinkActive]="'is-active'"
      [routerLink]="[menuItems[3].submenu[0].path]"
      class="color-secondary"
    >
      {{ menuItems[3].submenu[0].title }}</a>

    <a
      mat-list-item
      (click)="setActiveNavRoute(menuItems[3].submenu[1]?.path)"
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLinkActive]="'is-active'"
      [routerLink]="[menuItems[3].submenu[1].path]"
      class="color-secondary"
    >
      <span
        matBadgeColor="warn"
        [matBadgeHidden]="
            notificationsService.requestsNotification.userRequestCount === 0
          "
        [matBadge]="
            notificationsService.requestsNotification.userRequestCount
          "
        matBadgeOverlap="false"
      >{{ menuItems[3].submenu[1].title }}</span></a>
  </mat-nav-list>
</li>

<li *ngIf="loggedRole !== role.WOODY_BREAST_USER">
  <a
    mat-list-item
    [routerLinkActive]="'is-active'"
    [routerLink]="[menuItems[4].path]"
    class="color-secondary"
  >
    <span class="petview-clients mr-1"></span> {{ menuItems[4].title }}</a>
</li>

<li *ngIf="loggedRole !== role.WOODY_BREAST_USER">
  <a
    mat-list-item
    [routerLinkActive]="'is-active'"
    [routerLink]="[menuItems[5].path]"
    class="color-secondary"
  >
    <span class="petview-patients mr-1"></span> {{ menuItems[5].title }}</a>
</li>

<li *ngIf="loggedRole !== role.WOODY_BREAST_USER">
  <a
    [disabled]="true"
    mat-list-item
    [routerLinkActive]="'is-active'"
    [routerLink]="[menuItems[6].path]"
    class="color-secondary is-disabled"
  >
    <span class="petview-video mr-1"></span> {{ menuItems[6].title }}</a>
</li>

<li *ngIf="loggedRole !== role.WOODY_BREAST_USER">
  <a
    [disabled]="true"
    mat-list-item
    [routerLinkActive]="'is-active'"
    [routerLink]="[menuItems[7].path]"
    class="color-secondary is-disabled"
  >
    <span class="petview-training mr-1"></span>{{ menuItems[7].title }}</a>
</li>

<li *ngIf="loggedRole === role.ADMIN">
  <a
    mat-list-item
    [routerLinkActive]="'is-active'"
    [routerLink]="[menuItems[8].path]"
    class="color-secondary"
  >
    <span class="petview-training mr-1"></span>{{ menuItems[8].title }}</a>
</li>

<li *ngIf="loggedRole === role.ADMIN">
  <a
    mat-list-item
    [routerLinkActive]="'is-active'"
    [routerLink]="[menuItems[9].path]"
    class="color-secondary"
  >
    <span class="petview-training mr-1"></span>{{ menuItems[9].title }}</a>
</li>
</mat-nav-list>

<span></span>
