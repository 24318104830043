<div id="register-account-type-stepper" class="card m-auto p-2 demo-form">
  <mat-horizontal-stepper #stepper [linear]="true" [@.disabled]="true">
    <!-- Pricing Plan step -->
    <ng-container *ngIf="!hideBilling">
      <mat-step [stepControl]="pricingForm" [optional]="hideBilling">
        <form novalidate [formGroup]="pricingForm" class="pricing-plan-form">
          <ng-template matStepLabel>Pricing Plan</ng-template>

          <h1>Select pricing plan</h1>

          <app-register-pricing-plan
            [form]="pricingForm"
          ></app-register-pricing-plan>

          <div class="stepper-action">
            <button
              mat-raised-button
              color="primary"
              type="button"
              class="my-button"
              [disabled]="pricingForm.invalid || loading"
              matStepperNext
            >
              Continue
            </button>
          </div>
        </form>
      </mat-step>
    </ng-container>

    <!-- 2 stepper -->
    <mat-step [stepControl]="billingForm">
      <form novalidate [formGroup]="billingForm" class="account-type-form">
        <ng-template matStepLabel>Billing</ng-template>

        <h1>Add new billing info</h1>

        <app-billing-form [form]="billingForm"></app-billing-form>

        <div class="stepper-action">
          <button
            mat-raised-button
            color="primary"
            type="button"
            class="my-button"
            [disabled]="billingForm.invalid || loading"
            (click)="submitBilling()"
          >
            <!-- <div
                  *ngIf="loading"
                  class="spinner-border text-success"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div> -->
            Continue
          </button>
        </div>
      </form>
    </mat-step>

    <!-- 3 stepper -->
    <mat-step [stepControl]="invitesForm">
      <form novalidate [formGroup]="invitesForm" class="account-type-form">
        <ng-template matStepLabel>Finish</ng-template>

        <ng-container *ngIf="!hideBilling">
          <h1>You have successfully added a billing account.</h1>

          <app-register-account-type-invites
            [form]="invitesForm"
            [formArray]="invitesArray"
            (onAddEmail)="onAddEmail()"
            (onRemoveEmail)="onRemoveEmail($event)"
          >
          </app-register-account-type-invites>

          <div class="stepper-action">
            <button
              mat-raised-button
              color="primary"
              type="button"
              class="my-button mr-1"
              [disabled]="invitesForm.invalid || loading"
              (click)="submitWithInvites()"
            >
              Invite All
            </button>

            <a mat-button [routerLink]="['login']" class="my-link my-button"
              >Skip</a
            >
          </div>
        </ng-container>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
  <!-- </form> -->
</div>
