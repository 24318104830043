<ng-container>
  <ul id="password-strength">
    <li *ngFor="let flag of flags; let i = index">
      <small>
        <i *ngIf="flag" class="petview-check_1 success"></i>
        <i *ngIf="!flag" class="petview-x error"></i>
        {{ labels[i] }}
      </small>
    </li>
  </ul>
</ng-container>
