import { AuthenticationService } from './authentication.service';
import { Role } from '@enums/role';
import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { ToastService } from './toast.service';
import { UserService } from './user.service';
import { HttpService } from './http.service';
import { RequestsNotification } from '@models/notifications';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  currentMessage = new BehaviorSubject(null);
  currentMessageTrigger = this.currentMessage.asObservable();
  // messaging = firebase.messaging(); // firebase comp
  audio = new Audio('../../assets/sound/light.mp3');

  // notification_messages: AngularFireList<any[]>; // firebase comp
  requestsNotification = new RequestsNotification();

  /**
   * * Case left notification
   */
  $casesLeftSubject = new BehaviorSubject(null);
  casesLeftObservable = this.$casesLeftSubject.asObservable();

  showCaseLeftNotification: boolean = false;

  // notifyObj: ;
  loggedInfo: any;
  messageID: number;
  fcmNewToken: string;
  subscription: Subscription[] = [];

  role = Role;
  loggedRole: string;

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private http: HttpService,
    private userService: UserService,
    private toast: ToastService
  ) {
    if (
      localStorage.getItem('currentUser') !== '' &&
      localStorage.getItem('currentUser')
    ) {
      this.loggedInfo = JSON.parse(localStorage.getItem('currentUser'));
    }
  }

  updateToken(token): any {
    // we can change this function to request our backend service

    if (!this.loggedInfo) {
      return;
    }

    this.userService.updateFCMToken({ token }).subscribe(
      (res: any) => {
        // to do after success update token
        if (this.fcmNewToken !== token) {
          this.fcmNewToken = token;
          return;
        }
        return;
      },
      (err) => {
        /**
         * * Handle Error
         */
      }
    );
  }

  requestPermission(): void {
    this.angularFireMessaging.requestPermission.subscribe(
      () => {
        this.getNewToken();
      },
      (error) => {
        this.toast.showError(
          'Petview would like to send you notifications please allow them in your Settings',
          ''
        );
      }
    );
  }

  receiveMessage(): void {
    // firebase comp

    this.angularFireMessaging.messages.subscribe(
      // firebase comp
      (payload: any) => {
        this.playSound();
        this.currentMessage.next(payload);
      },
      (err) => {
        /**
         * * Handle Error
         */
      }
    );
  }

  deleteToken() {
    this.angularFireMessaging.getToken.subscribe((t) =>
      this.angularFireMessaging.deleteToken(t)
    );
  }

  getNewToken(): void {
    this.subscription.push(
      this.angularFireMessaging.requestToken.subscribe(
        (token) => {
          this.updateToken(token);
        },
        (err) => {
          console.error('request token error: ', err);
        }
      )
    );
  }

  playSound(): void {
    this.audio.play();
  }

  getAllNotifications(): Observable<any> {
    return this.http.get('/allnotifications');
  }

  changeNotificationStatus(): any {
    return this.http.get('/notifications/changestatus');
  }

  delete(ids: number[]): any {
    return this.http.delete('/notifications/delete?ids=' + ids);
  }

  getRequestsNumberStatus() {
    return this.http.get('/request-stats');
  }
}
