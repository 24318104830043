import { UtilClass } from './UtilClass';

export interface AOI {
  imageData?: any;
  originalFile?: any;
  index?: any;
  AOI?: any[];
  AOIObjects?: AOIGroup[];
}

export class AOIGroup {
  aoiTypeId: number;
  aoiTypeName: string;
  aoiImageName?: any;
  AOIBlobFile: any;
  AOIImagePreview: string;
  aoi_coords: string;
  thumbnail: string;
  AOISegment: AOISegment[];
  canvasObjects: any;

  constructor(obj?: any) {
    if(!UtilClass.isNullOrUndefined(obj)) {
      Object.assign(this, obj);
    }
  }
}

export interface AOISegment {
  data?: null;
  dimensions: any[];
  shape: string;
}

export class Point {
  x = null;
  y = null;

  constructor(x, y) {
    this.x = x;
    this.y = y
  }
}