import { StripHtmlPipe } from './stripehtml.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RoundNumberPipe } from './round-number.pipe';
import { ProcessedImageLabelPipe } from './processed-image-label.pipe';
import { CreditCardHiddenPipe } from './credit-card-hidden.pipe';
import { PluralsPipe } from './plurals.pipe';
import { FilterReferenceImagePipe } from './filter-reference-image.pipe';

@NgModule({
  declarations: [
    StripHtmlPipe,
    RoundNumberPipe,
    ProcessedImageLabelPipe,
    CreditCardHiddenPipe,
    PluralsPipe,
    FilterReferenceImagePipe,
  ],
  imports: [CommonModule],
  exports: [
    StripHtmlPipe,
    RoundNumberPipe,
    ProcessedImageLabelPipe,
    CreditCardHiddenPipe,
    PluralsPipe,
    FilterReferenceImagePipe,
  ],
})
export class PipeModule { }
