import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CreatedByAdminGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const localStorageData = JSON.parse(localStorage.getItem('currentUser'));

    if (
      next.data.createdByAdmin ===
      Boolean(localStorageData.user.created_by_admin)
    ) {
      return true;
    }

    return false;
  }
}
