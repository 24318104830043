import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  ControlContainer,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UserService } from '@services/user.service';
import { ToastService } from '@services/toast.service';
import { AgreementSubscriptionTrialComponent } from '@app/agreements/agreement-subscription-trial/agreement-subscription-trial.component';
import { AgreementSubscriptionComponent } from '@app/agreements/agreement-subscription/agreement-subscription.component';
import { AgreementTermsOfUseComponent } from '@app/agreements/agreement-terms-of-use/agreement-terms-of-use.component';
import { AgreementPrivacyPolicyComponent } from '@app/agreements/agreement-privacy-policy/agreement-privacy-policy.component';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'app-register-trial-form',
  templateUrl: './register-trial-form.component.html',
  styleUrls: ['./register-trial-form.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class RegisterTrialFormComponent implements OnInit {
  subscription: Subscription[] = [];

  dialogConfig = new MatDialogConfig();

  @Output() nextStep = new EventEmitter<any>();

  @Input() form: FormGroup;

  @Input() loading: boolean;

  constructor(
    private userService: UserService,
    private toast: ToastService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.dialogConfig.data = {};
    this.dialogConfig.width = '80vw';
    this.dialogConfig.panelClass = 'modal-primary';

    /**
     * * Set init values from signup form
     */
    this.form.patchValue({
      first_name: this.route.snapshot.paramMap.get('first_name'),
      last_name: this.route.snapshot.paramMap.get('last_name'),
      email: this.route.snapshot.paramMap.get('email'),
      subscribe: this.route.snapshot.paramMap.get('subscribe'),
      subscriptionAgreement: this.route.snapshot.paramMap.get('subscriptionAgreement'),
      termsOfUse: this.route.snapshot.paramMap.get('termsOfUse'),
      privacyPolicy: this.route.snapshot.paramMap.get('privacyPolicy'),
    });

    // this.form.updateValueAndValidity();

    if (this.route.snapshot.paramMap.get('demoRedirection')) {
      this.passwordStep();
    }
  }

  openSubscriptionAgreement(event) {
    event.preventDefault();

    const confirmDialog = this.dialog.open(
      AgreementSubscriptionTrialComponent,
      this.dialogConfig
    );

    this.subscription.push(
      confirmDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.form.get('subscriptionAgreement').setValue(true);
        }
      })
    );
  }

  openTermsOfUse(event) {
    event.preventDefault();

    const confirmDialog = this.dialog.open(
      AgreementTermsOfUseComponent,
      this.dialogConfig
    );

    this.subscription.push(
      confirmDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.form.get('termsOfUse').setValue(true);
        }
      })
    );
  }

  openPrivacyPolicy(event) {
    event.preventDefault();

    const confirmDialog = this.dialog.open(
      AgreementPrivacyPolicyComponent,
      this.dialogConfig
    );

    this.subscription.push(
      confirmDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.form.get('privacyPolicy').setValue(true);
        }
      })
    );
  }

  passwordStep() {
    if (
      this.form.get('first_name').valid &&
      this.form.get('last_name').valid &&
      this.form.get('email').valid
    ) {
      this.userService
        .checkEmailTrial({ email: this.form.get('email').value })
        .subscribe(
          (resp) => {

            if (resp.redirectUrl) {
              this.router.navigate([resp.redirectUrl]);
              this.authenticationService.currentUserToLocalStorage = resp.user;
              return;
            }

            this.nextStep.next(true);
          },
          (error) => {
            this.toast.showError(error, '');
            this.form.get('email').setErrors({ emailTaken: true });
            this.form.get('email').markAsPristine();
          }
        );
    }
  }
}
