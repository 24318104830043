<div class="cards relative card-min-h">
  <ngx-spinner name="personalDataLoading">
    <p style="font-size: 20px; color: #000">Loading Personal Data...</p>
  </ngx-spinner>

  <div class="cards-header">
    <strong>Your data</strong>
    <span class="cards-header-actions"
      ><a [routerLink]="'dashboard/yourdata'"
        >View more<span class="material-icons"> keyboard_arrow_right </span></a
      ></span
    >
  </div>
  <mat-divider></mat-divider>

  <div class="cards-body">
    <app-card-personal-data-circles
      [personalData]="data"
    ></app-card-personal-data-circles>

    <div class="d-flex align-items-start justify-content-between mt-2">
      <div class="col flex-1">
        <h4>Most common cases</h4>

        <div class="no-data text-left" *ngIf="data?.usecaseData.length === 0">
          Currently you don't have cases.
        </div>

        <div
          *ngFor="let usecase of data?.usecaseData?.slice(0, 3)"
          class="item d-flex align-items-center"
        >
          <span
            [ngClass]="utilService.getIconByUsecase(usecase?.usecaseSuffix)"
          ></span>
          <p class="m-0">{{ usecase?.usecaseName | titlecase }}</p>
        </div>
      </div>
      <div class="col flex-1">
        <h4>Cases report</h4>

        <div class="card-item d-flex">
          <p class="green m-0">Weekly cases</p>
          <p class="green m-0">
            {{
              utilService.formatNumberWithPercentage(
                data?.weeklyData?.numberOfCasesIncrease
              )
            }}
          </p>
        </div>
        <div class="card-item d-flex">
          <p class="green m-0">Monthly cases</p>
          <p class="green m-0">
            {{
              utilService.formatNumberWithPercentage(
                data?.monthlyData?.numberOfCasesIncrease
              )
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
