import { RouterModule } from '@angular/router';
import { BasicDialogComponent } from './basic-dialog.component';
import { MaterialModule } from './../../../modules/material/material.module';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';



@NgModule({
  declarations: [BasicDialogComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    MaterialModule,
    NgxSpinnerModule,
  ],
  exports: [BasicDialogComponent]
})
export class BasicDialogModule { }
