<div class="d-flex align-items-center justify-content-center flex-column h-100">
  <img
    class="mb-2"
    src="../../../assets/images/petview_logo_svg.svg"
    id="logo"
    alt="logo"
  />
  <div class="card m-auto p-2 text-center">
    <h2>Register user</h2>
    <hr class="mb-1" />
    <form class="register-form" [formGroup]="registerForm">
      <!-- Type -->
      <mat-form-field appearance="outline" class="input-full-width">
        <mat-label>Role</mat-label>
        <mat-select
          disableOptionCentering
          placeholder="Select role"
          name="Role"
          formControlName="role"
        >
          <mat-option value="user">Doctor</mat-option>
          <mat-option value="clinic_admin">Clinic Admin</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Clinics -->
      <mat-form-field appearance="outline" class="input-full-width">
        <mat-label>Clinic</mat-label>
        <mat-select
          disableOptionCentering
          placeholder="Select clinic"
          name="clinic"
          formControlName="clinic_id"
        >
          <mat-option>
            <ngx-mat-select-search
              [formControl]="clinicFilterCtrl"
              placeholderLabel="Select clinic"
              noEntriesFoundLabel="no matching found"
            >
            </ngx-mat-select-search>
          </mat-option>

          <mat-option
            *ngFor="let clinic of filteredClinic | async"
            [value]="clinic.id"
          >
            {{ clinic?.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div>
        <!-- First name -->
        <mat-form-field appearance="outline" class="input-full-width">
          <mat-label>First name</mat-label>
          <input
            matInput
            placeholder="First name"
            formControlName="first_name"
          />
          <mat-error
            *ngIf="registerForm.controls['first_name'].hasError('required')"
          >
            First name is a <strong>required.</strong>
          </mat-error>
        </mat-form-field>

        <!-- Middle name -->
        <mat-form-field appearance="outline" class="input-full-width">
          <mat-label>Middle name</mat-label>
          <input
            matInput
            placeholder="Middle name"
            formControlName="middle_name"
          />
        </mat-form-field>

        <!-- Last name -->
        <mat-form-field appearance="outline" class="input-full-width">
          <mat-label>Last name</mat-label>
          <input matInput placeholder="Last name" formControlName="last_name" />
          <mat-error
            *ngIf="registerForm.controls['last_name'].hasError('required')"
          >
            Last name is a <strong>required.</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <!-- Title -->
        <mat-form-field appearance="outline" class="input-full-width">
          <mat-label>Title</mat-label>
          <mat-select
            disableOptionCentering
            placeholder="Select title"
            name="Title"
            formControlName="title"
          >
            <mat-option value="Mr.">Mr.</mat-option>
            <mat-option value="Mrs.">Mrs.</mat-option>
            <mat-option value="Ms.">Ms.</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Phone -->
        <mat-form-field appearance="outline" class="input-full-width">
          <mat-label>Phone</mat-label>
          <input matInput placeholder="Phone" formControlName="phone" />
        </mat-form-field>
      </div>

      <div>
        <!-- Email -->
        <mat-form-field appearance="outline" class="input-full-width">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email" formControlName="email" />

          <mat-error
            *ngIf="registerForm.controls['email'].hasError('required')"
          >
            Email is a <strong>required.</strong>
          </mat-error>

          <mat-error *ngIf="registerForm.controls['email'].hasError('used')">
            {{ registerForm.get("email").errors.message }}
          </mat-error>
        </mat-form-field>

        <!-- Address -->
        <mat-form-field appearance="outline" class="input-full-width">
          <mat-label>Address</mat-label>
          <input matInput placeholder="Address" formControlName="address" />
        </mat-form-field>
      </div>

      <!-- Password -->
      <mat-form-field appearance="outline" class="input-full-width">
        <mat-label>Password</mat-label>
        <input
          matInput
          placeholder="Password"
          formControlName="password"
          [type]="hide ? 'password' : 'text'"
        />

        <mat-error
          *ngIf="registerForm.controls['password'].hasError('required')"
        >
          Password is a <strong>required.</strong>
        </mat-error>

        <button
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>

        <mat-error *ngIf="registerForm.controls['password'].hasError('used')">
          {{ registerForm.get("password").errors.message }}
        </mat-error>
      </mat-form-field>

      <!-- Repeat password -->
      <mat-form-field appearance="outline" class="input-full-width">
        <mat-label>Repeat password</mat-label>
        <input
          matInput
          placeholder="Repeat password"
          formControlName="password_confirmation"
          [errorStateMatcher]="matcher"
          [type]="hideRe ? 'password' : 'text'"
        />

        <mat-error
          *ngIf="
            registerForm.controls['password_confirmation'].hasError('required')
          "
        >
          Password confirmation is a <strong>required.</strong>
        </mat-error>

        <button
          mat-icon-button
          matSuffix
          (click)="hideRe = !hideRe"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hideRe"
        >
          <mat-icon>{{ hideRe ? "visibility_off" : "visibility" }}</mat-icon>
        </button>

        <mat-error *ngIf="registerForm.hasError('notSame')">
          Passwords do not match
        </mat-error>
      </mat-form-field>

      <!-- Terms of Service and PrivacyPolicy -->
      <div class="d-flex align-items-center justify-content-between mt-2">
        <mat-checkbox
          class="example-margin"
          formControlName="termsOfService"
          color="primary"
          >I agree to the
          <a (click)="termsOfService()"
            ><strong>Terms of Service</strong></a
          ></mat-checkbox
        >
        <mat-checkbox
          class="example-margin"
          formControlName="privacyPolicy"
          color="primary"
          >I agree to the
          <a (click)="privacyPolicy()"><strong>Privacy Policy</strong></a>
        </mat-checkbox>
      </div>

      <button
        mat-raised-button
        color="primary"
        type="submit"
        class="my-button"
        [disabled]="registerForm.invalid"
        (click)="onSubmit()"
      >
        Create account
      </button>
    </form>
  </div>
</div>
