<form [formGroup]="trialForm" (ngSubmit)="onSubmit()">
  <ng-container *ngIf="!passwordState">
    <app-register-trial-form
      [form]="trialForm"
      [loading]="loading"
      (nextStep)="nextStep($event)"
    ></app-register-trial-form>
  </ng-container>

  <ng-container *ngIf="passwordState">
    <app-register-password-form
      [form]="trialForm"
      (onFinish)="onSubmit()"
      [loading]="loading"
    >
    </app-register-password-form>
  </ng-container>
</form>
