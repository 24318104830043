import { Router } from '@angular/router';
import { AgreementPrivacyPolicyComponent } from '@app/agreements/agreement-privacy-policy/agreement-privacy-policy.component';
import { AgreementSubscriptionComponent } from './../../agreements/agreement-subscription/agreement-subscription.component';
import { Subscription } from 'rxjs';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  ControlContainer,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastService } from '@services/toast.service';
import { UserService } from '@services/user.service';
import { AgreementTermsOfUseComponent } from '@app/agreements/agreement-terms-of-use/agreement-terms-of-use.component';

@Component({
  selector: 'app-register-account-form',
  templateUrl: './register-account-form.component.html',
  styleUrls: ['./register-account-form.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class RegisterAccountFormComponent implements OnInit {
  loading: boolean = false;
  subscription: Subscription[] = [];
  dialogConfig = new MatDialogConfig();

  @Input() form: FormGroup;

  @Output() nextStep = new EventEmitter<any>();

  constructor(
    private userService: UserService,
    private toast: ToastService,
    private dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.dialogConfig.data = {};
    this.dialogConfig.width = '80vw';
    this.dialogConfig.panelClass = 'modal-primary';
  }

  openSubscriptionAgreement(event) {
    event.preventDefault();

    const confirmDialog = this.dialog.open(
      AgreementSubscriptionComponent,
      this.dialogConfig
    );

    this.subscription.push(
      confirmDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.form.get('subscriptionAgreement').setValue(true);
        }
      })
    );
  }

  tryForFree() {
    this.router.navigate(['demo', {
      first_name: this.form.get('first_name').value,
      last_name: this.form.get('last_name').value,
      email: this.form.get('email').value,
      subscribe: this.form.get('subscribe').value ? true : false,
      subscriptionAgreement: this.form.get('subscriptionAgreement').value ? true : false,
      termsOfUse: this.form.get('termsOfUse').value ? true : false,
      privacyPolicy: this.form.get('privacyPolicy').value ? true : false,
      demoRedirection: true,
    }]);
  }

  openTermsOfUse(event) {
    event.preventDefault();

    const confirmDialog = this.dialog.open(
      AgreementTermsOfUseComponent,
      this.dialogConfig
    );

    this.subscription.push(
      confirmDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.form.get('termsOfUse').setValue(true);
        }
      })
    );
  }

  openPrivacyPolicy(event) {
    event.preventDefault();

    const confirmDialog = this.dialog.open(
      AgreementPrivacyPolicyComponent,
      this.dialogConfig
    );

    this.subscription.push(
      confirmDialog.afterClosed().subscribe((result) => {
        if (result) {
          this.form.get('privacyPolicy').setValue(true);
        }
      })
    );
  }

  passwordStepDemoAccount() {
    if (
      this.form.get('first_name').valid &&
      this.form.get('last_name').valid &&
      this.form.get('email').valid
    ) {
      this.loading = true;

      this.userService
        .checkEmail({ email: this.form.get('email').value })
        .subscribe(
          (resp) => {
            this.nextStep.next(true);
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            this.toast.showError(error, '');
            this.form.get('email').setErrors({ emailTaken: true });
            this.form.get('email').markAsPristine();
          }
        );
    }
  }

  passwordStep() {
    if (
      this.form.get('first_name').valid &&
      this.form.get('last_name').valid &&
      this.form.get('email').valid
    ) {
      this.loading = true;

      this.userService
        .checkEmail({ email: this.form.get('email').value })
        .subscribe(
          (resp) => {
            this.nextStep.next(true);
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            this.toast.showError(error, '');
            this.form.get('email').setErrors({ emailTaken: true });
            this.form.get('email').markAsPristine();
          }
        );
    }
  }
}
