import { environment } from './../../environments/environment';

import {
  Injectable,
  Inject,
  InjectionToken,
  ErrorHandler,
} from '@angular/core';

import * as Rollbar from 'rollbar';

// import * as Rollbar from 'rollbar'; // When using Typescript < 3.6.0.
// import Rollbar from 'rollbar';// is the required syntax for Typescript 3.6.x.
// However, it will only work when setting either `allowSyntheticDefaultImports`
// or `esModuleInterop` in your Typescript options.


const rollbarConfig = {
  accessToken: 'c85664804ec64abcb19067bbb770b9aa',
  captureUncaught: true,
  captureUnhandledRejections: true,
  autoInstrument: {
    network: true,
    log: true,
    dom: true,
    connectivity: true,
  },
  enabled: environment.production
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {

  constructor(@Inject(RollbarService) private rollbar: Rollbar) { }

  handleError(err: any): void {
    this.rollbar.error(err.originalError || err);
    throw new Error(err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
