import { Role } from './../../../enums/role';
import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'app-card-personal-data-circles',
  templateUrl: './card-personal-data-circles.component.html',
  styleUrls: ['./card-personal-data-circles.component.scss'],
})
export class CardPersonalDataCirclesComponent implements OnInit {
  role = Role;
  data: any = {};

  @Input() set personalData(value) {
    this.data = value;
  }

  constructor(public authService: AuthenticationService) {}

  ngOnInit(): void {}
}
