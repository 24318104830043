import { AOIGroup } from './../../models/aoi';
import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
  Inject,
} from '@angular/core';
import { MatTabGroup, MatTab, MatTabHeader } from '@angular/material/tabs';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogConfig,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { fabric } from 'fabric';
import { AoiToolComponent } from '../aoi-tool.component';
import { refImagesList } from './dummy-ref-images';
import { aoiTypeInitalData } from './drawing-aoi-type-initial-data';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { DrawingShapes } from '@enums/DrawingShapes';
import { AoiType } from '@models/AoiType';
import { AOI, AOISegment } from '@models/aoi';
import { CanvasObject } from '@models/CanvasHelperClass';
import { AoitypeService } from '@services/aoitype.service';
import { Base64convertorService } from '@services/base64convertor.service';
import { CroppingDataService } from '@services/cropping-data.service';
import { ToastService } from '@services/toast.service';
import { HelpersService } from '@services/helpers.service';
import { CanvasHelperService } from '@services/canvas-helper.service';
import { DrawingHelpersService } from '@services/drawing-helpers.service';
import { SCInstance } from '@models/shapeObject';

@Component({
  selector: 'app-drawing',
  templateUrl: './drawing.component.html',
  styleUrls: ['./drawing.component.scss'],
})
export class DrawingComponent implements OnInit, OnDestroy {
  imageIndex = 0;
  // Originalblob: any;
  currentUseCase: any;
  showApprovedTools = true;
  aoitypeSelectedIndex = 0;
  tabIndex = 0;
  aoiTypeSelectedData: any;
  // invalid states
  shapeOverlapBorders: boolean = false;
  // ref images
  refImagesList = refImagesList;

  refImageIndex = {
    good: 0,
    bad: 0,
  };

  AOIData: AOI = {
    AOI: [null, null, null, null],
    imageData: null,
    // index: null,
    originalFile: null,
    AOIObjects: [],
  };

  // initial values
  initActiveTab: string;

  /** Aoi Types */
  aoiTypes: AoiType[] = [];

  /**
   * 3 - Splenic Mass
   * 8 - Liver
   * 10 - Lymph Node
   */
  displayTabsFor: any[] = [3, 8, 10]; // usecase ID's
  displayTabsQuickView: any[] = [3]; // usecase ID's
  displayTabsNormal: any[] = [3]; // usecase ID's

  emitAllImages: any;
  aoitypeData: any[] = [];
  // imageData: any;
  savingOrigin: boolean;
  selectedImageEventData: any;
  tempData: any[] = [];
  imageListSelectedIndex: number;
  ls: any;
  subscription: Subscription[] = [];
  AOIGroupObject = new AOIGroup();

  @ViewChild('dialogElement', { static: true }) dialogElement: ElementRef;
  @ViewChild('finishbutton', { static: true }) finishbutton: ElementRef;
  @ViewChild('tabs') tabs: MatTabGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public imageDataAOIToolSource: any,
    private dialogRef: MatDialogRef<AoiToolComponent>,
    private dialog: MatDialog,
    private croppingService: CroppingDataService,
    private helper: HelpersService,
    private toast: ToastService,
    private aoiTypeService: AoitypeService,
    private convertor: Base64convertorService,
    public drawingHelper: DrawingHelpersService,
    private canvasHelperService: CanvasHelperService
  ) {}

  ngOnInit(): void {
    /**
     * @description set aoi type inital data
     */
    aoiTypeInitalData.forEach((singleAOIType: any) => {
      this.aoitypeData.push(new AoiType(singleAOIType));
    });

    /**
     * @argument canvas
     */
    // spawn polygon area
    this.drawingHelper.canvas = new fabric.Canvas('drawCanvas', {
      preserveObjectStacking: true,
    });
    this.drawingHelper.canvas.on('object:modified', (e: any) => {
      this.canvasHelperService.canvasModified(e);
    });
    this.drawingHelper.canvas.on('object:added', (e: any) => {
      this.canvasHelperService.objectCreated(e);
    });
    this.drawingHelper.canvas.backgroundColor = '#000';
    this.drawingHelper.shapeObject = new SCInstance();

    /**
     * New Instanace for canvasObject helper data
     */
    this.canvasHelperService.canvasObject = new CanvasObject();
    this.canvasHelperService.canvasObject.container.elementWidth =
      this.dialogElement.nativeElement.clientWidth;
    this.canvasHelperService.canvasObject.container.elementHeight =
      this.dialogElement.nativeElement.clientHeight;
    this.emitAllImages = this.imageDataAOIToolSource;
    this.imageIndex = this.imageDataAOIToolSource.index;
    this.imageDataAOIToolSource.allImages.map((img) =>
      this.tempData.push(null)
    );

    /**
     * @description Subscribe on canvas image data changes
     */
    this.subscription.push(
      this.croppingService.cropperImageData.subscribe((data: any) => {
        this.showApprovedTools = true;
        this.resetAOI();
        this.resetAllData();
        this.AOIData.imageData = data;

        if (data) {
          /**
           * Check is Base64
           */
          this.canvasHelperService.imageChangedEvent = data.preview;

          this.initActiveTab = data.selectedAOI;
          /*** ispitivanje zbog edit-a - no processing dela */
          this.currentUseCase = data.useCase.id
            ? data.useCase
            : { id: data.useCase };
          this.imageIndex = data.index;
          this.imageListSelectedIndex = data.index;
          this.setupTabs(data.caseType);

          if (
            this.currentUseCase.id === 3 ||
            this.currentUseCase.id === 1 ||
            this.currentUseCase.id === 2
          ) {
            this.showApprovedTools = true;
          } else {
            this.showApprovedTools = false;
          }

          if (this.helper.getTabIndex(this.initActiveTab) === 2) {
            this.showApprovedTools = false;
          }

          /**
           * Set values and Shapes
           */
          if (!this.initActiveTab) {
            this.aoiTypeService.clearAll();
          }

          if (this.initActiveTab !== '' && this.initActiveTab) {
            this.aoiTypeService.clearAllForced();
            this.aoitypeSelectedIndex = this.helper.getTabIndex(
              this.initActiveTab
            );

            if (data.aoiTabsData) {
              this.aoiTypeService.setAll(data.aoiTabsData);
            }

            if (data.isEditing) {
              if (data.aoiParameters) {
                data.aoiParameters.forEach((param) => {
                  /**
                   * @description Proveriti podatke koji se smestaju
                   * u AOIGroupObject nakon dodavanja EDIT opcije za case
                   *
                   * @EDIT_OPTION_CASE
                   *
                   */
                  const AOIGroupObject = new AOIGroup({
                    ...param,
                    AOISegment: JSON.parse(param.data.aoi_coords),
                    canvasObjects: param.data.aoi_type_object_json,
                    thumbnail:
                      param.thumbnail &&
                      this.drawingHelper.canvas.toDataURL('image/jpeg'),
                    aoiImageName: param.name,
                  });

                  if (param.aoiObjects) {
                    this.aoitypeData =
                      this.drawingHelper.convertToAOITypeDataObject(
                        param.aoiObjects
                      );
                    // this.aoitypeData = [...param.aoiObjects];
                  } else {
                    if (param.aoi_type_object_json) {
                      this.setData(
                        this.helper.getTabIndex(AOIGroupObject.aoiTypeName),
                        {
                          data: param.aoi_type_object_json,
                        }
                      );
                    }

                    this.setData(
                      this.helper.getTabIndex(AOIGroupObject.aoiTypeName),
                      { thumbnail: param.thumbnail }
                    );
                  }
                  // this.setData(this.getTabIndex(aoiType), { data: param.data.aoi_type_object_json });
                  this.AOIData.AOI.splice(
                    this.helper.getTabIndex(AOIGroupObject.aoiTypeName),
                    1,
                    AOIGroupObject
                  );
                });
              }
            } else {
              /**
               * @DEFAULT
               * @description U koliko nije edit mod
               */

              if (data.aoiParameters) {
                data.aoiParameters.forEach((param) => {
                  const AOIGroupObject = new AOIGroup({
                    ...param,
                    AOIImagePreview:
                      this.drawingHelper.canvas.toDataURL('image/jpeg'),
                    thumbnail:
                      this.drawingHelper.canvas.toDataURL('image/jpeg'),
                    AOISegment: param.aoi,
                  });

                  if (param.aoiObjects) {
                    this.aoitypeData =
                      this.drawingHelper.convertToAOITypeDataObject(
                        param.aoiObjects
                      );
                    // this.aoitypeData = [...param.aoiObjects];
                  } else {
                    if (param.aoiTypeObjectJSON) {
                      this.setData(
                        this.helper.getTabIndex(AOIGroupObject.aoiTypeName),
                        {
                          data: param.aoiTypeObjectJSON,
                        }
                      );
                    }
                    this.setData(
                      this.helper.getTabIndex(AOIGroupObject.aoiTypeName),
                      {
                        thumbnail: param.data.thumbnail,
                      }
                    );
                  }
                  this.AOIData.AOI.splice(
                    this.helper.getTabIndex(AOIGroupObject.aoiTypeName),
                    1,
                    AOIGroupObject
                  );
                });
              } else {
                this.objectInitialize();
              }
            }
          }

          this.aoiTypes = this.drawingHelper.convertToAOITypeDataObject(
            this.aoitypeData
          );
          this.aoiTypeSelectedData = this.getDataByIndex(
            this.aoitypeSelectedIndex
          );
          this.setMaxNumberOfShapes(this.aoitypeSelectedIndex);
          this.loadAOIShape(this.aoitypeSelectedIndex, 'subscribe');
          this.setInitAOITypeActiveTab(this.initActiveTab);
          this.croppingService.enableSaveBtn(true);

          /**
           * Load
           */
          try {
            const blockOriginal =
              this.canvasHelperService.imageChangedEvent.split(';');
            // Get the content type
            const contentTypeOrginal = blockOriginal[0].split(':')[1]; // In this case "image/gif"
            // get the real base64 content of the file
            const realDataOriginal = blockOriginal[1].split(',')[1]; // In this case "iVBORw0KGg...."

            // Convert to blob
            this.convertor
              .b64toBlob(realDataOriginal, contentTypeOrginal)
              .then((result) => {
                // this.Originalblob = result;
                this.AOIData.originalFile = result;
              });
          } catch (err) {
            /**
             * * Handle Error
             */
          }
        }
      })
    );
    // subscription for selected shape
    this.subscription.push(
      this.croppingService.selectionShapeState.subscribe((shape: string) => {
        if (shape) {
          this.drawingHelper.selectedShape = shape;
        }
      })
    );

    /**
     * @description subscribe for anytime when SAVE for AOI is triggered
     */
    this.subscription.push(
      this.croppingService.saveAOIasImageState.subscribe((state: boolean) => {
        if (state) {
          // this.croppingService.onFinishAllTrigger(true);
          this.saveAOIasImage(true, 0, this.savingOrigin);
        }
      })
    );

    /**
     * @description on skip saving
     */
    this.subscription.push(
      this.croppingService.continueWithoutSavingAOIState.subscribe(
        (state: boolean) => {
          if (state) {
            // this.croppingService.onFinishAllTrigger(true);
            this.saveAOIasImage(false);
          }
        }
      )
    );

    /**
     * @description Border limit subscription warning
     */
    this.subscription.push(
      this.drawingHelper.borderLimitState.subscribe((state) => {
        // if border is passed!
        if (state) {
          this.croppingService.enableSaveBtn(false);

          // open dialog
          const dialogConfig = new MatDialogConfig();

          // message
          dialogConfig.data = {
            title: 'Warning',
            content: 'The shape is out of bounds!',
            confirmTxt: 'Close',
            onlyConfirmation: true,
          };
          dialogConfig.panelClass = 'modal-warn';
          this.dialog.open(ConfirmDialogComponent, dialogConfig);
        } else {
          this.croppingService.enableSaveBtn(true);
        }
        this.shapeOverlapBorders = state;
      })
    );
  }

  /**
   * @param refType string
   * @returns null
   * @description previous reference image
   */
  prevRef(refType): void {
    if (this.refImageIndex[refType] === 0) {
      return;
    }
    this.refImageIndex[refType]--;
  }

  /**
   *
   * @param refType string
   * @returns null
   * @description next reference image
   */
  nextRef(refType): void {
    if (
      this.refImageIndex[refType] ===
      this.refImagesList[refType].length - 1
    ) {
      return;
    }
    this.refImageIndex[refType]++;
  }

  /**
   * @description initialize store objects from temporary data
   */
  objectInitialize(): void {
    const dataByIndex = this.tempData[this.imageIndex];
    if (dataByIndex) {
      dataByIndex.aoi.AOI.filter((aoi) => aoi).forEach((param) => {
        const AOIGroupObject = new AOIGroup({
          ...param,
        });

        if (dataByIndex.aoiTypes) {
          this.aoitypeData = [...dataByIndex.aoiTypes];
        } else {
          if (param.canvasObjects) {
            this.setData(this.helper.getTabIndex(AOIGroupObject.aoiTypeName), {
              data: param.canvasObjects,
            });
          }
          this.setData(this.helper.getTabIndex(AOIGroupObject.aoiTypeName), {
            thumbnail: param.thumbnail,
          });
        }
        this.AOIData.AOI.splice(
          this.helper.getTabIndex(AOIGroupObject.aoiTypeName),
          1,
          AOIGroupObject
        );
      });
    }
  }

  /**
   *
   * @param caseType casetypeid
   * * 1 = normal
   * * 2 = no processing
   * * 3 = quick view
   * @description show or hide tabs for specific case
   */
  setupTabs(caseType: number): void {
    if (caseType === 3) {
      this.setData(0, { hidden: true });
      this.setData(1, { hidden: true });
      this.setData(2, { hidden: true });
    } else if (caseType === 1) {
      this.setData(0, { hidden: true });
      this.setData(1, { hidden: true });
      this.setData(2, { hidden: true });
    } else if (caseType === 2) {
      // set tabs labels
      if (this.currentUseCase.id === 7) {
        this.setData(1, { label: 'Liver' });
        this.setData(3, { hidden: true });
      } else if (this.currentUseCase.id === 9) {
        this.setData(1, { label: 'Lymph Node' });
        this.setData(2, { hidden: true });
        this.setData(3, { hidden: true });
      } else {
        this.setData(1, { label: 'Spleen' });
      }
    }
  }

  /**
   * @description Reset all AOIData data
   */
  resetAllData(): void {
    this.AOIData = {
      AOI: [null, null, null, null],
      imageData: null,
      index: null,
      originalFile: null,
      AOIObjects: [],
    };
  }

  /**
   * @description Reset aoiTypeData object
   */
  resetAOI(): void {
    this.aoitypeData =
      this.drawingHelper.convertToAOITypeDataObject(aoiTypeInitalData);
  }

  /**
   *
   * @param index number
   * @returns AoiType data
   */
  getDataByIndex(index: number): any {
    return this.aoiTypes.filter((val, i) => i === index)[0];
  }

  /**
   * @description set data for aoiTypeData instance class
   */
  setData(index: number, data: any): void {
    Object.keys(data).forEach((key) => {
      this.aoitypeData[index][key] = data[key];
    });
  }

  setDataForAllObjects(objects): void {
    objects.forEach((object, index) => {
      this.setData(index, { data: object.data });
    });
  }

  loadAOIShape(index: number, src?: any): void {
    const json = this.aoitypeData[index].data;
    this.drawingHelper.removeAllAOIMarker(false); // false ignore and prevent conflict with setAsDirty state
    this.drawingHelper.canvas.loadFromJSON(json);
    this.drawingHelper.setAsDirty = false;
    this.canvasHelperService.renderImageInCanvas();
  }

  /**
   *
   * @returns confirmation Promise<any>
   * @description modal for confirmation on change image, aoi type if we have unsaved changes
   */
  confirmToSave(): Promise<any> {
    return new Promise((resolve, reject) => {
      const dialogConfig = new MatDialogConfig();

      // default message
      dialogConfig.data = {
        title: 'Confirmation',
        content: 'You have unsaved changes. Do you want to save before leave?',
        confirmTxt: 'Save',
      };

      /** if all markers is removed discard all and prevent user to save image without any marker */
      if (this.drawingHelper.canvas.getObjects().length === 0) {
        dialogConfig.data = {
          title: 'Warning',
          content: 'Current changes will not be saved.',
          confirmTxt: 'Ok',
          onlyCancelation: true,
          cancelationTxt: 'Ok',
        };
      }

      const confirmDialog = this.dialog.open(
        ConfirmDialogComponent,
        dialogConfig
      );

      this.subscription.push(
        confirmDialog.afterClosed().subscribe((result) => {
          if (result) {
            this.print().then((res) => {
              resolve('true');
              this.drawingHelper.setAsDirty = false;
            });
          } else {
            this.drawingHelper.setAsDirty = false;
            reject('false');
          }
        })
      );
    }).catch((err) => err);
  }

  setInitAOITypeActiveTab(typeName: string) {
    const clickedTabIndex = this.helper.getTabIndex(typeName);
    const isTabValid = this.getDataByIndex(clickedTabIndex);

    /** hardcoded tab index for init */
    const tabIndex = typeName === 'FOV' ? 0 : 1; // 0 for first FOV and 1 for ROI as second

    /** -------------- */
    if (isTabValid) {
      if (isTabValid.valid || this.drawingHelper.setAsDirty) {
        this.confirmToSave().then((res) => {
          // state in service
          this.setData(clickedTabIndex, {
            valid: this.drawingHelper.setAsDirty,
          });
          this.aoitypeSelectedIndex = clickedTabIndex;
          this.tabIndex = tabIndex;
        });
      } else {
        // this.aoiTypeService.setData(this.aoitypeSelectedIndex, { valid: setAsDirty });
        this.aoitypeSelectedIndex = clickedTabIndex;
        this.tabIndex = tabIndex;
      }
    } else {
      this.tabIndex = tabIndex;
      this.aoitypeSelectedIndex = clickedTabIndex;
    }
  }

  setMaxNumberOfShapes(index): void {
    switch (index) {
      case 0:
      case 1:
      case 3:
        this.drawingHelper.maxNumberOfShapes = 1;
        break;
      case 2:
        this.drawingHelper.maxNumberOfShapes = 4;
        break;
      default:
        this.drawingHelper.maxNumberOfShapes = 1;
    }
  }

  clickOnTab(event: any): any {
    // onclick
    const parentElement = event.target.classList.contains('mat-tab-label')
      ? event.target
      : event.target.closest('div[role="tab"]');
    const tabElement = parentElement.querySelector('.name');
    const elementText = tabElement.innerText;
    const clickedTabIndex = this.helper.getTabIndex(elementText);
    const isTabValid = this.getDataByIndex(clickedTabIndex);
    let tabIndex;

    if (
      tabElement.classList.contains('name') ||
      tabElement.classList.contains('mat-tab-label-content')
    ) {
      const element = tabElement.closest('div[role="tab"]');
      tabIndex = Number(element.id[element.id.length - 1]);
    }

    if (
      clickedTabIndex === -1 ||
      tabIndex === this.tabIndex ||
      this.shapeOverlapBorders
    ) {
      return;
    }

    if (isTabValid) {
      if (isTabValid.valid || this.drawingHelper.setAsDirty) {
        this.confirmToSave().then((res) => {
          // state in service

          this.setData(clickedTabIndex, {
            valid: this.drawingHelper.setAsDirty,
          });
          this.aoitypeSelectedIndex = clickedTabIndex;
          this.tabIndex = tabIndex;
        });
      } else {
        // this.aoiTypeService.setData(this.aoitypeSelectedIndex, { valid: setAsDirty });
        this.aoitypeSelectedIndex = clickedTabIndex;
        this.tabIndex = tabIndex;
      }
    } else {
      this.tabIndex = tabIndex;
      this.aoitypeSelectedIndex = clickedTabIndex;
    }
  }

  tabChanged(event: any): void {
    this.drawingHelper.clearCoords();
    this.setMaxNumberOfShapes(this.aoitypeSelectedIndex);

    if (this.aoitypeSelectedIndex === 2) {
      // lesion type at 3 place in array
      this.showApprovedTools = false;
    } else {
      this.showApprovedTools = true;
    }

    this.aoiTypeSelectedData = this.getDataByIndex(this.aoitypeSelectedIndex);
    this.loadAOIShape(this.aoitypeSelectedIndex, 'tabchange');
    this.drawingHelper.setSelectTool(DrawingShapes.POLYGON);
  }

  getMoreInformation(): string {
    return '- Choose tool \n - Capture area \n - Double-click to finish';
  }

  onChangeBorderWidth(event): void {
    this.drawingHelper.borderValue = event.value;
  }

  changeEmitData(event): void {
    // this.imageData = event;
    this.AOIData.imageData = event;
  }

  selectedImageEvent(event: any): void {
    // check if canvas is invalid
    if (this.shapeOverlapBorders) {
      return;
    }

    // set active image
    if (event && typeof event.index !== undefined) {
      this.imageListSelectedIndex = event.index;
    }

    this.customValidation().then(() => {
      // objects data
      const allAOIObjects = [...this.aoitypeData];
      const index = this.imageIndex;

      this.AOIData.AOIObjects = allAOIObjects;
      this.tabIndex = 0;

      // setters
      this.croppingService.setAOIData(this.AOIData, this.imageIndex); // set data in service
      this.tempData[this.imageIndex] = {
        aoi: this.AOIData,
        aoiTypes: this.aoitypeData,
      }; // set temporary data
      // events
      this.croppingService.changeImageData(event.data); // Trigger event cropperImageData
      this.croppingService.setImageStatus({ index, state: allAOIObjects }); // trigger event to change image checkmark status
      this.drawingHelper.setSelectTool(DrawingShapes.POLYGON);
    });
  }

  customValidation(): Promise<any> {
    return new Promise((resolve, reject) => {
      const isTabValid = this.getDataByIndex(this.aoitypeSelectedIndex);
      if (isTabValid) {
        if (isTabValid.valid || this.drawingHelper.setAsDirty) {
          this.confirmToSave().then((res) => {
            // state in service
            this.setData(this.aoitypeSelectedIndex, {
              valid: this.drawingHelper.setAsDirty,
            });
            this.aoitypeSelectedIndex = 0;
            resolve(true);
          });
        } else {
          resolve(true);
        }
      }
    });
  }

  print(): Promise<any> {
    return new Promise((resolve, reject) => {
      const objects: AOISegment[] = [];
      const canvasAspect =
        this.canvasHelperService.canvasObject.container.elementWidth /
        this.canvasHelperService.canvasObject.container.elementHeight;
      const imageAspect =
        this.canvasHelperService.canvasObject.img.width /
        this.canvasHelperService.canvasObject.img.height;
      let zoomRatio = 1;

      if (canvasAspect > imageAspect) {
        zoomRatio =
          this.canvasHelperService.canvasObject.img.height /
          this.canvasHelperService.canvasObject.dimensions.height;
      } else {
        zoomRatio =
          this.canvasHelperService.canvasObject.img.width /
          this.canvasHelperService.canvasObject.dimensions.width;
      }

      this.drawingHelper.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      this.canvasHelperService.GetCanvasAtResoution(
        this.canvasHelperService.canvasObject.img.width,
        this.canvasHelperService.canvasObject.img.height
      );

      this.drawingHelper.canvas.getObjects().forEach((element, objectIndex) => {
        const shapeName = element.get('type');
        if (shapeName === 'polyline') {
          objects[objectIndex] =
            this.drawingHelper.polygonShapeCalcNew(element);
        } else if (shapeName === 'circle') {
          objects[objectIndex] = this.drawingHelper.circleShapeCalcNew(element);
        } else if (shapeName === 'rect') {
          objects[objectIndex] =
            this.drawingHelper.rectangleShapeCalcNew(element);
        }
      });

      if (objects.length === 0) {
        this.setData(this.aoitypeSelectedIndex, {
          data: null,
          thumbnail: null,
        });
        this.AOIData.AOI.splice(this.aoitypeSelectedIndex, 1, null);
        resolve(false);
        return;
      }

      /** Pocetak originalni kod */
      const block = this.drawingHelper.canvas
        .toDataURL('image/jpeg')
        .split(';');
      // Get the content type
      const contentType = block[0].split(':')[1]; // In this case "image/gif"
      // get the real base64 content of the file
      const realData = block[1].split(',')[1]; // In this case "iVBORw0KGg...."

      this.convertor
        .b64toBlob(realData, contentType)
        .then((result) => {
          let aoiImageName = '';
          if (this.AOIData.AOI[this.aoitypeSelectedIndex]) {
            aoiImageName =
              this.AOIData.AOI[this.aoitypeSelectedIndex].aoiImageName;
          }

          const obj: any = {
            aoiTypeId: this.aoiTypeSelectedData.id,
            aoiTypeName: this.aoiTypeSelectedData.label,
            AOIBlobFile: result,
            AOIImagePreview: this.drawingHelper.canvas.toDataURL('image/jpeg'),
            thumbnail: this.drawingHelper.canvas.toDataURL('image/jpeg'),
            aoi_coords: JSON.stringify(objects),
            AOISegment: objects,
            aoiImageName, // <--- UPISATI IME
            canvasObjects: null,
          };

          this.drawingHelper.canvas.backgroundImage = null;
          obj.canvasObjects = JSON.stringify(
            this.drawingHelper.canvas.toObject()
          );
          this.setData(this.aoitypeSelectedIndex, {
            data: JSON.stringify(this.drawingHelper.canvas.toObject()),
            thumbnail: obj.AOIImagePreview,
          });

          this.AOIData.AOI.splice(this.aoitypeSelectedIndex, 1, obj);

          this.drawingHelper.canvas.requestRenderAll();
          resolve(true);
        })
        .catch((err) => {
          // tslint:disable-next-line:quotemark
          this.toast.showError(
            "We couldn't save your AOI.",
            'Please try again.'
          );
          reject();
        });
    });
  }

  /**
   * @description metoda sa submit izmena u AOI editoru
   */
  saveAOIasImage(requiredShape, index = 0, savingSourceOrigin = false): void {
    this.drawingHelper.clearCoords();
    const allAOIObjects = [...this.aoitypeData];

    if (requiredShape) {
      if (!this.drawingHelper.canvas.item(0)) {
        this.toast.showWarrning('Missing or Invalid Shape!', 'Shape Warning');
        this.croppingService.enableSaveBtn(true);
        return;
      }
    }

    this.print().then((res) => {
      this.AOIData.AOIObjects = allAOIObjects;
      this.croppingService.setAOIData(this.AOIData, this.imageIndex);
      this.croppingService.onFinishAllTrigger(true);
      this.tempData = [];

      if (!savingSourceOrigin) {
        this.tempData = [];
        this.dialogRef.close(true);
        return;
      }

      this.setData(this.aoitypeSelectedIndex, {
        valid: this.drawingHelper.setAsDirty,
      });
      this.croppingService.changeImageData(this.selectedImageEventData);

      this.toast.showInfo('Saved!', this.aoiTypeSelectedData.label);
    });
  }

  /**
   * @description Metoda koja se poziva ukoliko se komponenta unisti (zatvori)
   * @example Uglavnom se ovde smestaju unsubscribe() metode za Subscribe u okviru klase
   */
  ngOnDestroy(): void {
    // this.aoiTypeService.clearAllForced();
    this.aoitypeData = [];
    this.subscription.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.croppingService.clearAOIData();
    this.croppingService.setImageStatus(null);
  }
}
