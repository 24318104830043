<div
  id="case-alert"
  [ngClass]="{
    warn:
      authService.currentUserValue?.user?.plan?.number_of_cases > 1 &&
      authService.currentUserValue?.user?.plan?.number_of_cases <= 3,
    alert:
      authService.currentUserValue?.user?.plan?.number_of_cases === 0 &&
      authService.currentUserValue?.user?.plan?.name !==
        pricingPlanEnum.PER_CASE
  }"
  *ngIf="
    authService.currentUserValue?.user?.plan &&
    notificationService.showCaseLeftNotification &&
    authService.currentUserValue?.user.role === role.ADMIN
  "
>
  <div
    class="cards-header d-flex justify-content-between"
    *ngIf="
      authService.currentUserValue?.user?.plan.name === pricingPlanEnum.PER_CASE
    "
  >
    <div class="label">
      You are currently on <strong>percase</strong> plan. You can
      <a class="text-underline" [routerLink]="['/profile/plan/edit']"
        >upgrade</a
      >
      your plan.
    </div>
    <span
      class="material-icons-outlined cursor-pointer"
      (click)="handleCloseNotification()"
    >
      close
    </span>
  </div>

  <div
    class="cards-header d-flex justify-content-between"
    *ngIf="
      authService.currentUserValue?.user?.plan.name !== pricingPlanEnum.PER_CASE
    "
  >
    <div class="label">
      You reach your limit and used all available cases. You can upgrade your
      <a [routerLink]="['/profile/plan/edit']"><strong>plan</strong></a> for
      more.
    </div>
    <span
      class="material-icons-outlined cursor-pointer"
      (click)="handleCloseNotification()"
    >
      close
    </span>
  </div>
</div>
