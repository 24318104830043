<div class="actions d-flex justify-content-center align-items-center">
  <button
    mat-raised-button
    color="primary"
    class="my-button"
    (click)="onChangeState()"
  >
    Close comparison
  </button>
</div>

<div
  id="view-reference-wrapper"
  class="main"
>
  <div class="ref-side">
    <p><strong>Reference cases</strong></p>
    <div class="ref-side-image">
      <img
        [src]="getRefImageSrc()"
        alt="Reference Image"
      />
    </div>
    <div class="ref-side-footer">
      <mat-form-field>
        <!-- <mat-label></mat-label> -->
        <mat-select
          [(ngModel)]="refType"
          name="ReferenceType"
          placeholder="Image type"
        >
          <mat-option value="normal">Normal image</mat-option>
          <mat-option value="malignant">Malignant image</mat-option>
          <mat-option value="benign">Benign image</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="nav">
        <div class="prev">
          <a
            [disabled]="this.refIndex === 0"
            (click)="prevRef(this.refIndex === 0)"
            mat-button
            class="my-link my-nav"
          ><span class="petview-left_arrow mr-1"></span>Prev</a>
        </div>
        <span class="text-primary">
          <strong>{{ refType }}_reference_image_{{ refIndex + 1 }}</strong>
        </span>
        <div class="next">
          <a
            [disabled]="
              this.refImages[this.activeAlgorithmReference][this.refType]
                .length -
                1 ===
              this.refIndex
            "
            (click)="
              nextRef(
                this.refImages[this.activeAlgorithmReference][this.refType]
                  .length -
                  1 ===
                  this.refIndex
              )
            "
            mat-button
            class="my-link my-nav"
          >Next<span class="petview-right_arrow ml-1"></span></a>
        </div>
      </div>
    </div>
  </div>

  <div
    class="your-side"
    *ngIf="outputImageArray.length > 0"
  >
    <p class="text-align-right"><strong>Your images</strong></p>
    <div class="your-side-image">
      <img
        [src]="outputImageArray[activeAOIType]?.processed_image"
        alt="Output image"
      />
    </div>

    <div class="your-side-footer">
      <div class="nav">
        <div class="prev">
          <a
            [disabled]="
              !(outputImageArray.length > 1 && this.activeAOIType > 0)
            "
            (click)="prevAOIType($event)"
            mat-button
            class="my-link my-nav"
          ><span class="petview-left_arrow mr-1"></span>Prev</a>
        </div>
        <span class="text-primary">
          <strong>{{
            data.original_aoi_images[activeAOIType]?.aoiType == "AOI"
            ? "ROI"
            : data.original_aoi_images[activeAOIType]?.aoiType
            }}</strong>
        </span>
        <div class="next">
          <a
            [disabled]="!(outputImageArray.length - 1 > this.activeAOIType)"
            (click)="nextAOIType($event)"
            mat-button
            class="my-link my-nav"
          >Next<span class="petview-right_arrow ml-1"></span></a>
        </div>
      </div>
    </div>

    <div class="your-side-list">
      <div
        class="your-side-list-image"
        *ngFor="let aoi of aoiImageArray; let i = index"
        [ngClass]="{ active: i === activeAOIType }"
      >
        <img
          [src]="aoi.aoi_image"
          alt="Aoi image"
        />
        <!-- status info  -->
        <div class="info">
          <app-graph-stats
            *ngIf="aoi.stdev"
            [data]="utilService.parsingGraphStats(aoi.stdev)"
            [indicatorSection]="true"
            [triggerChange]="trigger"
          >
          </app-graph-stats>
          <span
            [ngStyle]="{
              color: utilService.getColorForMLEval(
                aoi.ml.eval.eval === 'NORMAL'
                  ? aoi.ml.eval.eval
                  : aoi.ml.eval2.eval
              ).color
            }"
            class="other-values"
            *ngIf="!aoi.stdev"
          ><span
              [ngClass]="{
                'gray-bg': utilService.getEvalValue2(aoi.ml) === 'N/A'
              }"
              class="name"
            >
              <li>
                <span *ngIf="aoi.ml.eval.eval === 'NORMAL'">
                  {{
                  aoi.ml.eval.eval
                  ? (aoi.ml.eval.eval | lowercase)
                  : "undefined"
                  }}
                </span>
                <span *ngIf="aoi.ml.eval.eval !== 'NORMAL'">
                  {{
                  aoi.ml.eval2.eval
                  ? (aoi.ml.eval2.eval | lowercase)
                  : "undefined"
                  }}
                </span>
              </li>
            </span>
            <span
              class="ml-1 other-values-number"
              [ngStyle]="{
                backgroundColor: utilService.getColorForMLEval(
                  aoi.ml.eval.eval === 'NORMAL'
                    ? aoi.ml.eval.eval
                    : aoi.ml.eval2.eval
                ).background
              }"
            >{{ utilService.getEvalValue2(aoi.ml, '', aoi.algorithm.suffix) }}</span></span>

          {{ aoi | json}}
        </div>
      </div>
    </div>
  </div>
</div>
