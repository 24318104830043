import { Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise, takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ViewComponent } from '../view/view.component';

@Component({
  selector: 'app-cases-popup-view',
  template: '',
})
export class CasesPopupViewComponent implements OnInit {
  destroy = new Subject<any>();
  currentDialog = null;
  redirectionUrl: any = '/';

  constructor(
    route: ActivatedRoute,
    router: Router,
    private dialog: MatDialog
  ) {
    /**
     * * Params handling
     */
    route.params.pipe(takeUntil(this.destroy)).subscribe((params) => {
      const id = params.id;
      this.redirectionUrl = params?.redirectionUrl;

      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        id,
      };
      dialogConfig.maxWidth = '100vw';
      dialogConfig.width = '100%';
      dialogConfig.height = '100vh';
      dialogConfig.panelClass = 'viewDialogContainer';

      const viewDialog = this.dialog.open(ViewComponent, dialogConfig);
      // When router navigates on this component is takes the params and opens up the photo detail modal

      // Go back to home page after the modal is closed
      viewDialog.afterClosed().subscribe(
        (result) => {
          router.navigateByUrl(this.redirectionUrl);
        },
        (reason) => {
          router.navigateByUrl(this.redirectionUrl);
        }
      );
    });
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.destroy.next();
  }
}
