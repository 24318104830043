<div class="header-body">
  <img
    class="logo-img"
    src="../../../assets/images/petview_logo_svg.svg"
    alt="logo"
  />
  <div class="header-body-content">
    <div
      *ngIf="notificationList.length > 0"
      class="notification-bell d-flex align-items-center color-primary"
      [matMenuTriggerFor]="notificationListMenu"
      (click)="OnViewAllNotification()"
    >
      <mat-icon
        *ngIf="numberOfUnseen > 0"
        [matBadge]="numberOfUnseen"
        matBadgeColor="warn"
      >notifications_outline
      </mat-icon>
      <mat-icon
        *ngIf="numberOfUnseen == 0"
        class="material-icons-outlined"
      >
        notifications
      </mat-icon>
    </div>

    <div
      *ngIf="notificationList.length == 0"
      class="notification-bell d-flex align-items-center color-primary"
      [matMenuTriggerFor]="notificationListMenuEmpty"
    >
      <mat-icon
        *ngIf="numberOfUnseen > 0"
        [matBadge]="numberOfUnseen"
        matBadgeColor="warn"
      >notifications_outline
      </mat-icon>
      <mat-icon
        *ngIf="numberOfUnseen == 0"
        class="material-icons-outlined"
      >notifications</mat-icon>
      <!-- Include text description of the icon's meaning for screen-readers -->
    </div>

    <mat-menu
      #notificationListMenuEmpty="matMenu"
      xPosition="before"
      class="notificationListMenuEmpty"
    >
      <div
        mat-menu-item
        class="notificationItem"
        [disabled]="true"
      >
        You have no notifications right now.
      </div>
    </mat-menu>

    <mat-menu
      #notificationListMenu="matMenu"
      xPosition="before"
      class="notificationListMenu"
    >
      <ng-container *ngFor="let notification of notificationList">
        <div
          mat-menu-item
          class="notificationHeader"
          [disabled]="true"
        >
          {{ notification.name }}
        </div>

        <div
          mat-menu-item
          *ngFor="let notiItem of notification.list"
          class="notificationItem"
        >
          <div
            class="notificationContent"
            (click)="onView(notiItem.case_id)"
          >
            <div class="icon">
              <span class="petview-folder"></span>
            </div>
            <div class="content">
              <div *ngIf="notiItem?.case_id">
                <span>ID:{{ notiItem.case_id }}</span>
              </div>
              <div *ngIf="!notiItem?.case_id">
                {{ notiItem?.message ? notiItem?.message : "no message" }}
              </div>
              <small>{{ notiItem.created_at | date: "short" }} ({{
                notiItem.zone
                }})</small>
            </div>
          </div>
        </div>
      </ng-container>

      <div
        mat-menu-item
        class="notificationActions"
        (click)="deleteNotifications()"
      >
        Clear All
      </div>
    </mat-menu>

    <button
      class="client-name"
      mat-button
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
    >
      {{ userName }}
      <span class="material-icons">
        {{ t.menuOpen ? "keyboard_arrow_up" : "keyboard_arrow_down" }}
      </span>
    </button>

    <mat-menu
      #menu="matMenu"
      class="profileMenu"
    >
      <button
        mat-menu-item
        [disabled]="true"
      >Contact</button>
      <button
        mat-menu-item
        [routerLink]="['profile/edit']"
      >
        Edit profile
      </button>
      <button
        mat-menu-item
        [disabled]="true"
      >
        Dark mode: <strong>OFF</strong>
      </button>
      <button
        mat-menu-item
        (click)="logout()"
      >Sign out</button>
    </mat-menu>
  </div>
</div>
