<h2 mat-dialog-title>Audit log #{{ audit.id }}</h2>
<mat-dialog-content class="mat-typography">
  <div class="audit-details">
    <ul>
      <li class="item">
        <span>Auditable ID:</span>
        <span>{{ audit.id }}</span>
      </li>

      <li class="item">
        <span>Timestamp:</span>
        <span>{{ audit.created_at | date: 'medium' }}</span>
      </li>

      <li class="item">
        <span>Event type:</span>
        <span>{{ audit.event }}</span>
      </li>

      <li class="item">
        <span>Subject:</span>
        <span>{{ audit.auditable_type }}</span>
      </li>

      <li class="item">
        <span>URL:</span>
        <span>{{ audit.url }}</span>
      </li>

      <li class="item">
        <span>Browser:</span>
        <span>{{ audit.user_agent }}</span>
      </li>

      <li class="item">
        <span>User: </span>
        <span>{{ audit.user.name }} - {{ audit.user.email }}</span>
      </li>

      <li class="item">
        <span>IP Address: </span>
        <span>{{ audit.ip_address }}</span>
      </li>

      <li>
        <span>Current / Old Value</span>
        <span>
          <code>
            <span *ngFor="let entry of oldValue">

              <div>{{ entry.key }}</div>
              <div [ngClass]="{'null-value': entry.value == null || entry.value == ''}">{{ entry.value !== null ? entry.value !== '' ? (entry.value | json) : 'empty string' : 'null' }}</div>



            </span>
            <span *ngIf="oldValue.length === 0">
              empty
            </span>

          </code>
        </span>
      </li>

      <li>
        <span>New Value</span>
        <span>
          <code>
            <span *ngFor="let entry of newValue">

              <div>{{ entry.key }}</div>
              <div [ngClass]="{'null-value': entry.value == null || entry.value == ''}">{{ entry.value !== null ? entry.value !== '' ? parsed(entry.value) : 'empty string' : 'null' }}</div>
            </span>
            <span *ngIf="newValue.length === 0">
              empty
            </span>
          </code>
        </span>
      </li>


    </ul>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button
    color="primary"
    mat-button
    class="my-button my-outline mr-1"
    mat-dialog-close
  >Close</button>
</mat-dialog-actions>
