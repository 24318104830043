<form [formGroup]="registerForm">

  <ng-container *ngIf="!passwordState">
    <app-register-account-form
      [form]="registerForm"
      (nextStep)="nextStep($event)"
    ></app-register-account-form>
  </ng-container>

  <ng-container *ngIf="passwordState">
    <app-register-password-form
      [form]="registerForm"
      (onFinish)="onSubmit()"
      [loading]="loading"
    >

    </app-register-password-form>
  </ng-container>

</form>
