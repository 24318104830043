import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Cases } from '@models/cases';
import { Search } from '@models/search';
import { HttpService } from './http.service';
import { config } from '@helpers/Config';

@Injectable({
  providedIn: 'root',
})
export class CaseService {
  private isClientPatientCompActive = new BehaviorSubject({
    state: false,
    operation: 'add',
    clientId: null,
    patientId: null,
    caseIndex: 0,
  });
  private savedSearch = new BehaviorSubject(null);
  private parentId = new BehaviorSubject(null);
  private clientId = new BehaviorSubject(null);
  clientPatientCompEmit = this.isClientPatientCompActive.asObservable();

  constructor(private http: HttpService) { }

  resetState() {
    this.isClientPatientCompActive.next({
      state: false,
      operation: 'add',
      clientId: null,
      patientId: null,
      caseIndex: 0,
    });
  }

  triggerClientPatientComp(state: any): void {
    this.isClientPatientCompActive.next(state);
  }

  download(caseId, imageId): any {
    return this.http.get('/download/' + caseId + '/' + imageId);
  }

  downloadSelected(data): any {
    return this.http.post('/download', data);
  }

  downloadSelectedSearch(rows: any): any {
    return this.http.post('/download_search', rows);
  }

  createCase(data): any {
    return this.http.postFile('/case', data);
  }

  createQuickCase(data): any {
    return this.http.postFile('/quick_case', data);
  }

  createCaseWithoutProcessing(data): any {
    return this.http.postFile('/image_upload_noprocessing', data);
  }

  editCase(id, data): any {
    return this.http.post(`/case/${ id }`, data);
  }

  editCaseNoProcessing(id, data): any {
    return this.http.post(`/image_upload_noprocessing/${ id }`, data);
  }

  getCases(filters, pageNumber = 1): any {
    return this.http.getCaseTable('/case', pageNumber, filters);
  }

  getSearch(
    search,
    library,
    dateFrom,
    dateTo,
    biopsy,
    aoiCaptured,
    species,
    body_part,
    algorithm,
    diagnosis,
    gender,
    altered_status,
    image_type,
    breed,
    age,
    image_modality,
    image_view,
    aoi_type,
    machineManufacturer,
    machineModel,
  ): any {
    return this.http.getSearchTable(
      '/search-capability',
      1,
      search,
      library,
      dateFrom,
      dateTo,
      biopsy,
      aoiCaptured,
      species,
      body_part,
      algorithm,
      diagnosis,
      gender,
      altered_status,
      image_type,
      breed,
      age,
      image_modality,
      image_view,
      aoi_type,
      machineManufacturer,
      machineModel,
    );
  }

  getClients(): any {
    return this.http.get('/clients/dropdown');
  }

  getCaseById(id): any {
    return this.http.get('/case/' + id);
  }

  getAllCasesByPatientId(patientId): any {
    return this.http.get('/case/patient/' + patientId);
  }

  setParentId(id): any {
    this.parentId.next(id);
  }

  setClientId(id): any {
    this.clientId.next(id);
  }

  getClientId(): any {
    return this.clientId;
  }

  getParentId(): any {
    return this.parentId;
  }

  delete(ids: number[]): any {
    return this.http.delete('/cases?ids=' + ids);
  }

  activate(id: number): any {
    return this.http.get('/case/activate/' + id);
  }

  deactivate(id: number): any {
    return this.http.get('/case/deactivate/' + id);
  }

  public findCases(filter, caseType, status, pageNumber = 1): Observable<Cases[]> {
    return this.http.getTable('/case', filter, pageNumber, status, caseType).pipe(map((res) => res['data']));
  }

  public findCasesTable(filters, pageNumber = 1): Observable<Cases[]> {
    return this.http.getCaseTable('/case', pageNumber, filters).pipe(map((res) => res['data']));
  }

  public findSearch(
    search,
    library,
    dateFrom,
    dateTo,
    biopsy,
    aoiCaptured,
    species,
    bodyPart,
    algorithm,
    diagnosis,
    gender,
    alteredStatus,
    imageType,
    breed,
    age,
    modality,
    bodyView,
    aoiType,
    machineManufacturer,
    machineModel,
    pageIndex: number = 1
  ): Observable<Search[]> {
    return this.http
      .getSearchTable(
        '/search-capability',
        pageIndex,
        search,
        library,
        dateFrom,
        dateTo,
        biopsy,
        aoiCaptured,
        species,
        bodyPart,
        algorithm,
        diagnosis,
        gender,
        alteredStatus,
        imageType,
        breed,
        age,
        modality,
        bodyView,
        aoiType,
        machineManufacturer,
        machineModel
      )
      .pipe(map((res) => res['data']));
  }

  saveSearch(params) {
    this.savedSearch.next(params);
  }

  getSavedSearch() {
    return this.savedSearch.asObservable();
  }
}
