import { AuditsService } from './../_services/audits.service';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Audits } from '../models/audits';



export class AuditsDataSource implements DataSource<Audits> {

  private auditSubject = new BehaviorSubject<Audits[]>([]);

  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private auditsService: AuditsService) {

  }

  loadAudits(
    pageIndex,
    filterSearch
  ): void {

    this.loadingSubject.next(true);

    this.auditsService.findAudits(
      filterSearch,
      pageIndex
    ).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(audit => this.auditSubject.next(audit));

  }

  connect(collectionViewer: CollectionViewer): Observable<Audits[]> {
    return this.auditSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.auditSubject.complete();
    this.loadingSubject.complete();
  }

}
