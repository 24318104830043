<div
  id="download-details-dialog"
  class="card download-content"
  *ngIf="!loading"
  (click)="$event.stopPropagation();"
>
  <div class="files">
    <section
      class="section mb-1"
      *ngIf="caseTypeId !== 4"
    >
      <label>Select files:</label>
      <mat-checkbox
        [disabled]="true"
        [checked]="true"
        class="example-margin"
        color="primary"
      >
        Processed output</mat-checkbox>
      <mat-checkbox
        *ngIf="loggedRole === 'admin'"
        class="example-margin"
        color="primary"
        [(ngModel)]="original"
      >
        Original</mat-checkbox>
      <mat-checkbox
        *ngIf="loggedRole === 'admin'"
        class="example-margin"
        color="primary"
        [(ngModel)]="xml"
      >Image data file <small>(.xml)</small>
      </mat-checkbox>
    </section>

    <div
      class="downloading-progress mb-1"
      *ngIf="downloadingProgress"
    >
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <button
      [disabled]="downloadingProgress"
      mat-raised-button
      color="primary"
      class="my-button"
      (click)="download('regular', false)"
    >
      Download selected
    </button>
    <button
      *ngIf="caseTypeId !== 4"
      [disabled]="downloadingProgress"
      mat-raised-button
      color="primary"
      class="my-button"
      (click)="download('email', false)"
    >
      Send selected to mail
    </button>

    <hr />

    <button
      [disabled]="downloadingProgress"
      mat-raised-button
      color="primary"
      class="my-button"
      (click)="download('regular', true)"
    >
      Download all
    </button>
    <button
      *ngIf="caseTypeId !== 4"
      [disabled]="downloadingProgress"
      mat-raised-button
      color="primary"
      class="my-button"
      (click)="download('email', true)"
    >
      Send all to mail
    </button>
  </div>
</div>
