import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClinicUsersService } from '@services/clinic-users.service';
import { DropdownService } from '@services/dropdown.service';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );

    return invalidCtrl || invalidParent;
  }
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  subscription: Subscription[] = [];

  clinicFilterCtrl: FormControl = new FormControl();
  filteredClinic: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected onDestroy = new Subject<void>();

  matcher = new MyErrorStateMatcher();

  hide = true;
  hideRe = true;

  constructor(
    private dropdownService: DropdownService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private clinicUsersService: ClinicUsersService
  ) {}

  ngOnInit(): void {
    this.registerForm = this.fb.group(
      {
        first_name: ['', Validators.required],
        middle_name: [''],
        last_name: ['', Validators.required],
        role: ['user'],
        clinic_id: [''],
        clinic: [''],
        clinicList: [''],
        title: [''],
        phone: [''],
        email: ['', [Validators.required, Validators.email]],
        address: [''],
        password: ['', Validators.required],
        password_confirmation: ['', Validators.required],
        termsOfService: ['', Validators.requiredTrue],
        privacyPolicy: ['', Validators.requiredTrue],
      },
      { validator: this.checkPasswords }
    );

    // this.subscription.push(this.dropdownService.getAllClinics().subscribe(response => {
    //   this.registerForm.controls.clinicList.setValue(response);
    //   this.filteredClinic.next(this.registerForm.controls.clinicList.value.slice());
    // }, err => {
    // }))
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.forEach((subscription) => subscription.unsubscribe());
  }

  /** Helpers */

  // Clinic filter logic
  protected filterClient(): any {
    if (!this.registerForm.controls.clinicList.value) {
      return;
    }
    // get the search keyword
    let search = this.clinicFilterCtrl.value;
    if (!search) {
      this.filteredClinic.next(
        this.registerForm.controls.clinicList.value.slice()
      );
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the clinic
    this.filteredClinic.next(
      this.registerForm.controls.clinicList.value.filter(
        (clinic) => clinic.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  // Check password
  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.password_confirmation.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  termsOfService(): void {}

  // PrivacyPolicy modal
  privacyPolicy(): void {}

  /** Helpers */
  onSubmit(): void {
    // submit
    // submit
    const newUser = this.registerForm.getRawValue();
    this.subscription.push(
      this.clinicUsersService.registerClinicUser(newUser).subscribe(
        (response) => {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.width = '40%';
          dialogConfig.panelClass = 'modal-success';
          dialogConfig.data = {
            title: 'User successfully created',
            content: `
          <table class="modal-table">
            <tr>
                <th>User name</th>
                <th>User email</th>
            </tr>

            <tr>
                <td>${response.name}</td>
                <td>${response.email}</td>
            </tr>
        </table>
        `,
            onlyConfirmation: true,
            confirmTxt: 'Ok',
          };

          const confirmDialog = this.dialog.open(
            ConfirmDialogComponent,
            dialogConfig
          );

          this.subscription.push(
            confirmDialog.afterClosed().subscribe((result) => {
              // this.router.navigate(['clinic-users']);
            })
          );
        },
        (err) => {
          for (let key in err.error) {
            this.registerForm
              .get(key)
              .setErrors({ used: true, message: err.error[key].join(', ') });
          }
        }
      )
    );
  }
}
