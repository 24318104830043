import { Role } from '@enums/role';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Resourses {
  role = Role;

  trialUserExpiresInDays = 120;

  machineTypeManufacturerOptions = [
    {
      label: 'Non specified / Other',
      value: 'non_specified',
      children: [
        {
          label: 'Non specified / Other',
          value: 'non_specified',
        },
      ]
    },
    {
      label: 'Butterfly Network',
      value: 'Butterfly Network',
      children: [
        {
          label: 'Non specified / Other',
          value: 'non_specified',
        },
        {
          label: 'IQ+ Vet',
          value: 'IQ+ Vet',
        },
      ]
    },
    {
      label: 'Canon Medical Systems',
      value: 'Canon Medical Systems',
      children: [
        {
          label: 'Non specified / Other',
          value: 'non_specified',
        },
        {
          label: 'Aplio i800',
          value: 'Aplio i800',
        },
        {
          label: 'Aplio i700',
          value: 'Aplio i700',
        },
        {
          label: 'Aplio a550',
          value: 'Aplio a550',
        },
        {
          label: 'Aplio a450',
          value: 'Aplio a450',
        },
        {
          label: 'Xario 200G',
          value: 'Xario 200G',
        },
        {
          label: 'Xario 100G',
          value: 'Xario 100G',
        },
      ]
    },
    {
      label: 'Esaote North America',
      value: 'Esaote North America',
      children: [
        {
          label: 'Non specified / Other',
          value: 'non_specified',
        },
        {
          label: 'MyLab X8',
          value: 'MyLab X8',
        },
        {
          label: 'MyLab Omega',
          value: 'MyLab Omega',
        },
        {
          label: 'MyLab X5',
          value: 'MyLab X5',
        },
      ]
    },
    {
      label: 'Fujifilm Healthcare Americas',
      value: 'Fujifilm Healthcare Americas',
      children: [
        {
          label: 'Non specified / Other',
          value: 'non_specified',
        },
        {
          label: 'Arietta 750SE',
          value: 'Arietta 750SE',
        },
        {
          label: 'Arietta 65',
          value: 'Arietta 65',
        },
      ]
    },
    {
      label: 'GE Healthcare',
      value: 'GE Healthcare',
      children: [
        {
          label: 'Non specified / Other',
          value: 'non_specified',
        },
        {
          label: 'Venue Ultrasound System',
          value: 'Venue Ultrasound System',
        },
        {
          label: 'Voluson E10 BT21',
          value: 'Voluson E10 BT21',
        },
        {
          label: 'LOGIQ E10 R3',
          value: 'LOGIQ E10 R3',
        },
        {
          label: 'Vivid E95 Ultra Edition (v204)',
          value: 'Vivid E95 Ultra Edition (v204)',
        },
        {
          label: 'Vivid S70N v204',
          value: 'Vivid S70N v204',
        },
        {
          label: 'Vivid iq v204',
          value: 'Vivid iq v204',
        },
        {
          label: 'Vivid iq 4D v204',
          value: 'Vivid iq 4D v204',
        },
        {
          label: 'Vivid iq Premium v204',
          value: 'Vivid iq Premium v204',
        },
        {
          label: 'Vivid T9 v204',
          value: 'Vivid T9 v204',
        },
        {
          label: 'Versana Premier vV2',
          value: 'Versana Premier vV2',
        },
        {
          label: 'Versana Active V1.5',
          value: 'Versana Active V1.5',
        },
        {
          label: 'Vscan Air',
          value: 'Vscan Air',
        },
        {
          label: 'Vscan Extend',
          value: 'Vscan Extend',
        },
        {
          label: 'LOGIQ E10s R3',
          value: 'LOGIQ E10s R3',
        },
        {
          label: 'Voluson E10 BT21',
          value: 'Voluson E10 BT21',
        },
        {
          label: 'Voluson E8 BT21',
          value: 'Voluson E8 BT21',
        },
        {
          label: 'Voluson E6 BT21',
          value: 'Voluson E6 BT21',
        },
        {
          label: 'Voluson S10 Expert BT22',
          value: 'Voluson S10 Expert BT22',
        },
        {
          label: 'Voluson S8 w/ Touch Panel BT22',
          value: 'Voluson S8 w/ Touch Panel BT22',
        },
        {
          label: 'Voluson SWIFT',
          value: 'Voluson SWIFT',
        },
        {
          label: 'Voluson P8 BT22',
          value: 'Voluson P8 BT22',
        },
      ]
    },
    {
      label: 'Hologic',
      value: 'Hologic',
      children: [
        {
          label: 'Non specified / Other',
          value: 'non_specified',
        },
        {
          label: 'SuperSonic MACH 40',
          value: 'SuperSonic MACH 40',
        },
        {
          label: 'SuperSonic MACH 20',
          value: 'SuperSonic MACH 20',
        },
        {
          label: 'SuperSonic MACH 30',
          value: 'SuperSonic MACH 30',
        },
      ]
    },
    {
      label: 'Konica Minolta Healthcare Americas, Inc.',
      value: 'Konica Minolta Healthcare Americas, Inc.',
      children: [
        {
          label: 'Non specified / Other',
          value: 'non_specified',
        },
        {
          label: 'Sonimage HS2',
          value: 'Sonimage HS2',
        },
        {
          label: 'Sonimage MX1',
          value: 'Sonimage MX1',
        },
      ]
    },
    {
      label: 'Mindray',
      value: 'Mindray',
      children: [
        {
          label: 'Non specified / Other',
          value: 'non_specified',
        },
        {
          label: 'ZS3',
          value: 'ZS3',
        },
        {
          label: 'Z.One PRO',
          value: 'Z.One PRO',
        },
        {
          label: 'Resona 7',
          value: 'Resona 7',
        },
        {
          label: 'M9',
          value: 'M9',
        },
        {
          label: 'ME8',
          value: 'ME8',
        },
        {
          label: 'MX7',
          value: 'MX7',
        },
        {
          label: 'TE7',
          value: 'TE7',
        },
        {
          label: 'TE7 Max',
          value: 'TE7 Max',
        },
        {
          label: 'DC-90',
          value: 'DC-90',
        },
      ]
    },
    {
      label: 'Philips',
      value: 'Philips',
      children: [
        {
          label: 'Non specified / Other',
          value: 'non_specified',
        },
        {
          label: 'EPIQ Elite',
          value: 'EPIQ Elite',
        },
        {
          label: 'Affiniti70/50',
          value: 'Affiniti70/50',
        },
        {
          label: 'Affiniti 30',
          value: 'Affiniti 30',
        },
        {
          label: 'Lumify',
          value: 'Lumify',
        },
        {
          label: 'Sparq',
          value: 'Sparq',
        },
        {
          label: 'Xperius',
          value: 'Xperius',
        },
      ]
    },
    {
      label: 'Samsung',
      value: 'Samsung',
      children: [
        {
          label: 'Non specified / Other',
          value: 'non_specified',
        },
        {
          label: 'RS85 Prestige',
          value: 'RS85 Prestige',
        },
        {
          label: 'RS85 Ver 1',
          value: 'RS85 Ver 1',
        },
        {
          label: 'Hera I10',
          value: 'Hera I10',
        },
        {
          label: 'Hera W10',
          value: 'Hera W10',
        },
        {
          label: 'Hera W9',
          value: 'Hera W9',
        },
        {
          label: 'V8',
          value: 'V8',
        },
        {
          label: 'HS60',
          value: 'HS60',
        },
        {
          label: 'HS50',
          value: 'HS50',
        },
        {
          label: 'HS40',
          value: 'HS40',
        },
        {
          label: 'HM70 EVO',
          value: 'HM70 EVO',
        },
      ]
    },
    {
      label: 'Siemens Healthineers',
      value: 'Siemens Healthineers',
      children: [
        {
          label: 'Non specified / Other',
          value: 'non_specified',
        },
        {
          label: 'Acuson Sequoia',
          value: 'Acuson Sequoia',
        },
        {
          label: 'Acuson Redwood',
          value: 'Acuson Redwood',
        },
        {
          label: 'Acuson Juniper',
          value: 'Acuson Juniper',
        },
      ]
    },
  ]

  machineTypeOptions = [
    {
      label: 'Machine Type 1',
      value: 'machine_type_1'
    },
    {
      label: 'Machine Type 2',
      value: 'machine_type_2'
    },
    {
      label: 'Machine Type 3',
      value: 'machine_type_3'
    },
    {
      label: 'Machine Type 4',
      value: 'machine_type_4'
    },
  ]

  bodyPartsList = [
    {
      name: 'Abdomen',
    },
    {
      name: 'Skull',
    },
    {
      name: 'Torax',
    },
    {
      name: 'Pelvis and pelvic limb',
    },
    {
      name: 'Forelimb',
    },
    {
      name: 'Vertebrae',
    },
  ];

  algorithms = [
    {
      name: 'Algorithm 1 2',
    },
    {
      name: 'Algorithm 2',
    },
    {
      name: 'Algorithm 3',
    },
    {
      name: 'Algorithm 4',
    },
  ];

  imageModality = [
    {
      name: 'X-Ray',
    },
    {
      name: 'Ultrasound',
    },
    {
      name: 'CT',
    },
    {
      name: 'MRI',
    },
  ];

  bodyView = [
    {
      name: 'Right Lateral',
    },
    {
      name: 'Left Lateral',
    },
    {
      name: 'Ventrodorsal',
    },
    {
      name: 'Dorsoventral',
    },
  ];

  alteredList = [
    {
      id: 1,
      name: 'Spayed',
    },
    {
      id: 2,
      name: 'Neutered',
    },
    {
      id: 4,
      name: 'Unaltered',
    },
    {
      id: 3,
      name: 'Unknown',
    },
  ];

  genderList = [
    {
      id: 1,
      name: 'Male',
      altered_ids: [2, 3, 4],
    },
    {
      id: 2,
      name: 'Female',
      altered_ids: [1, 3, 4],
    },
    {
      id: 3,
      name: 'Unknown',
      altered_ids: [3],
    },
  ];

  imageTypeList = [
    {
      id: 1,
      name: 'Normal',
    },
    {
      id: 2,
      name: 'Benign',
    },
    {
      id: 3,
      name: 'Malignancy',
    },
    {
      id: 4,
      name: 'Other condition of interest',
    },
  ];

  dropdownUserTypes = [
    {
      value: this.role.CLINIC_ADMIN,
      label: 'Clinic Admin',
    },
    {
      value: this.role.USER,
      label: 'User',
    },
    {
      value: this.role.TRIAL,
      label: 'Trial User',
    },
    {
      value: this.role.IN_PROCESS,
      label: '(In Process)',
    }
  ];

  dropdownFormSteps = [
    {
      value: 'account_type',
      label: 'Account Type Step',
    },
    {
      value: 'pricing_plan',
      label: 'Pricing plan Step',
    },
    {
      value: 'billing',
      label: 'Billing Step',
    },
    {
      value: 'finish',
      label: 'Finish/Invite other Step',
    },
  ];

  imageTypeListSearch = [
    {
      name: 'Normal',
      group: [
        {
          id: 1,
          name: 'Normal',
        },
      ],
    },
    {
      name: 'Abnormal',
      group: [
        {
          id: 4,
          name: 'All', // abnormal
        },
        {
          id: 2,
          name: 'Benign',
        },
        {
          id: 3,
          name: 'Malignancy',
        },
      ],
    },
  ];

  accountTypes = [{
    value: '',
    name: 'All',
  },
  {
    value: 'trial_user',
    name: 'Free trial',
  }];

  createCaseTypeDescription = {
    3: 'This transaction allows the user to specify up to 6 images per case to be uploaded to the portal for processing.  Only minimal information will be prompted for association with the case.  If you would like to input pet and/or pet owner information with the case please choose the "Process Image - with Client and Patient Info" link.',
    1: 'This transaction allows the user to specify up to 6 images per case to be uploaded to the portal for processing.  User may enter information about the client and patient that will be documented in the case details.',
    2: 'This  transaction allows the user to upload images to the portal that can be used by PetView for research and development purposes to develop new use cases and improve existing ones.  It is most helpful if the images uploaded have known truth.  That is, that they have been verified by pathology regarding a specific disease or condition.  Otherwise, "normal" images are helpful as well, and do not have to be verified by pathology.',
  };
}
