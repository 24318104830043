import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-information-of-type-dialog',
  templateUrl: './information-of-type-dialog.component.html',
  styleUrls: ['./information-of-type-dialog.component.scss'],
})
export class InformationOfTypeDialogComponent implements OnInit {
  content = `Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Eveniet a nemo perspiciatis officia assumenda est voluptas quaerat sint fuga officiis,
            explicabo quasi quas repellat voluptate, aspernatur sit doloribus dicta molestiae!`;
  title = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public type: any,
    private dialogRef: MatDialogRef<InformationOfTypeDialogComponent>
  ) {}

  ngOnInit(): void {
    this.title = this.type.data ? this.type.data.name : '';
    this.content = this.type.data.content ? this.type.data.content : '';
  }
}
