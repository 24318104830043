import { DrawingShapes } from './../../enums/DrawingShapes';
import { Subscription } from 'rxjs';
import { Component, Input, OnInit, QueryList, ViewChild } from '@angular/core';
import { fabric } from 'fabric';
import { DrawingHelpersService } from '@services/drawing-helpers.service';
import {
  FabricCircleConfig,
  FabricPolygonConfig,
  FabricRectConfig,
} from '@models/fabricConfig';
import { Point } from '@models/aoi';
import { ViewChildren } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { ElementRef } from '@angular/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-aoi-type-tools',
  templateUrl: './aoi-type-tools.component.html',
  styleUrls: ['./aoi-type-tools.component.scss'],
})
export class AoiTypeToolsComponent implements OnInit, AfterViewInit {
  subscription: Subscription[] = [];

  @ViewChild('modalLimit', { static: true }) modalLimit: ElementRef;
  @ViewChildren(MatButton, { read: ElementRef })
  toolButtons: QueryList<ElementRef>;

  constructor(public drawingHelper: DrawingHelpersService) {}

  ngOnInit(): void {
    this.subscription.push(
      this.drawingHelper.onSelectTool.subscribe(
        (shape: any) => {
          switch (shape) {
            case shape === DrawingShapes.POLYGON:
              this.onSelectPolygon(DrawingShapes.POLYGON);
              break;
            case shape === DrawingShapes.CIRCLE:
              this.onSelectCircleCrop(DrawingShapes.CIRCLE);
              break;
            case shape === DrawingShapes.RECTANGLE:
              this.onSelectRectangleCrop(DrawingShapes.RECTANGLE);
              break;
            default:
              this.onSelectPolygon(DrawingShapes.POLYGON);
          }
        },
        (err: any) => {
          /**
           * * Handle Error
           */
        }
      )
    );

    /**
     * @description set inital value for selected tool
     */
    this.drawingHelper.setSelectTool(DrawingShapes.POLYGON);
  }

  ngAfterViewInit(): void {
    this.drawingHelper.toolButtonsSelection = this.toolButtons;
  }

  /**
   *
   * @param shape String
   * @returns null
   */
  onSelectRectangleCrop(shape: string): any {
    if (
      this.drawingHelper.shapeObject.active &&
      this.drawingHelper.canvas.getObjects().length >=
        this.drawingHelper.maxNumberOfShapes
    ) {
      this.modalLimit.nativeElement.style.display = 'block';
      return;
    }
    this.drawingHelper.setHighlightSelection(shape);
    this.drawingHelper.selectedShape = shape;
    this.drawingHelper.selectedShapeCopy = shape;
    this.draw();
    this.changeSelectableStatus(false);
  }

  /**
   *
   * @param shape string
   * @param element HTMLElement
   * @description if select polygon tool
   */
  onSelectPolygon(shape: string): any {
    if (
      this.drawingHelper.shapeObject.active &&
      this.drawingHelper.canvas.getObjects().length >=
        this.drawingHelper.maxNumberOfShapes
    ) {
      this.modalLimit.nativeElement.style.display = 'block';
      return;
    }

    this.drawingHelper.setHighlightSelection(shape);
    this.drawingHelper.selectedShape = shape;
    this.drawingHelper.selectedShapeCopy = shape;
    this.draw();
    this.changeSelectableStatus(false);
  }

  /**
   *
   * @param shape String
   * @returns null
   * @description on select Cricle tool
   */
  onSelectCircleCrop(shape: string): any {
    if (
      this.drawingHelper.shapeObject.active &&
      this.drawingHelper.canvas.getObjects().length >=
        this.drawingHelper.maxNumberOfShapes
    ) {
      this.modalLimit.nativeElement.style.display = 'block';
      return;
    }
    this.drawingHelper.setHighlightSelection(shape);
    this.drawingHelper.selectedShape = shape;
    this.drawingHelper.selectedShapeCopy = shape;
    this.draw();
    this.changeSelectableStatus(false);
  }

  /**
   *
   * @param preventConflitSetAsDitry boolean flag
   */
  // removeAllAOIMarker(preventConflitSetAsDitry = true): void {
  //   this.clearCoords();
  //   this.deleteSelection('all', preventConflitSetAsDitry);
  //   this.drawingHelper.removeHighlightSelection();
  // }

  /**
   * @description remove all AOI markers on canvas
   */
  removeAOIMarker(): void {
    this.clearCoords();
    this.deleteSelection();
    this.drawingHelper.removeHighlightSelection();
  }

  /**
   * Clear coordinates on canvas
   */
  clearCoords(): void {
    this.drawingHelper.lines.forEach((value, index, ar) => {
      this.drawingHelper.canvas.remove(value);
    });

    // clear arrays
    this.drawingHelper.polygonPoints = [];
    this.drawingHelper.lines = [];
    this.drawingHelper.lineCounter = 0;

    this.drawingHelper.selectedShape = '';
    this.drawingHelper.selectedShapeCopy = '';

    this.drawingHelper.canvas.off('mouse:down');
    this.drawingHelper.canvas.off('mouse:move');
    this.drawingHelper.canvas.off('mouse:up');
    this.drawingHelper.canvas.off('mouse:wheel');
  }

  /**
   *
   * @param range if parse "all" delete all objects on canvas
   * @param preventConflict prevent conflict with setAsDirty state
   */
  deleteSelection(range: string = '', preventConflict = true): void {
    if (range === 'all') {
      this.drawingHelper.canvas.remove(
        ...this.drawingHelper.canvas.getObjects()
      );
    } else {
      this.drawingHelper.canvas.remove(
        this.drawingHelper.canvas.getActiveObject()
      );
    }

    if (preventConflict) {
      this.drawingHelper.setAsDirty = true;
    }
  }

  /**
   * @description set events
   * @event mouse:down poziva metodu @method onMouseDown
   * @event mouse:move poziva metodu @method onMouseMove
   * @event mouse:up poziva metodu @method onMouseUp
   */
  draw(): void {
    this.drawingHelper.canvas.on('mouse:down', (e: any) => {
      this.onMouseDown(e);
    });
    this.drawingHelper.canvas.on('mouse:move', (e: any) => {
      this.onMouseMove(e);
    });
    this.drawingHelper.canvas.on('mouse:up', (e: any) => {
      this.onMouseUp(e);
    });
  }

  /**
   *
   * @param val parsed value
   * @description Izmena statusa da li je omoguceno selektovane objekta(shape) ili ne
   */
  changeSelectableStatus(val): void {
    this.drawingHelper.canvas.forEachObject((obj) => {
      obj.selectable = val;
    });
    this.drawingHelper.canvas.requestRenderAll();
  }

  /**
   *
   * @param o object event
   * @description Fn koja se trigeruje kada se cursorom klikne i zapocinje isctavanje shape-a
   */
  onMouseDown(o): void {
    const event = o.e;

    if (event.altKey === true) {
      this.drawingHelper.isDragging = true;
      this.drawingHelper.canvas.selection = false;
      this.drawingHelper.canvas.lastPosX = event.clientX;
      this.drawingHelper.canvas.lastPosY = event.clientY;
    } else {
      this.drawingHelper.isDragging = false;
      this.drawingHelper.canvas.selection = true;
      this.drawingHelper.isZoomActive = false;
    }

    const pointer = this.drawingHelper.canvas.getPointer(o.e);
    this.drawingHelper.isDown = true;
    this.drawingHelper.origX = pointer.x;
    this.drawingHelper.origY = pointer.y;

    if (this.drawingHelper.selectedShape === 'rect') {
      this.drawingHelper.rectangle = new fabric.Rect(
        new FabricRectConfig(this.drawingHelper.origX, this.drawingHelper.origY)
      );

      this.drawingHelper.canvas.add(this.drawingHelper.rectangle);
    } else if (this.drawingHelper.selectedShape === 'circle') {
      this.drawingHelper.rectangle = new fabric.Circle(
        new FabricCircleConfig(
          this.drawingHelper.origX,
          this.drawingHelper.origY
        )
      );
      this.drawingHelper.canvas.add(this.drawingHelper.rectangle);
    } else if (this.drawingHelper.selectedShape === 'polygon') {
      this.drawingHelper.canvas.selection = false;
      this.setStartingPoint(o.e); // set x,y
      this.drawingHelper.polygonPoints.push(
        new Point(this.drawingHelper.polyVarX, this.drawingHelper.polyVarY)
      );
      const points = [
        this.drawingHelper.polyVarX,
        this.drawingHelper.polyVarY,
        this.drawingHelper.polyVarX,
        this.drawingHelper.polyVarY,
      ];
      this.drawingHelper.lines.push(
        new fabric.Line(points, new FabricPolygonConfig())
      );
      this.drawingHelper.canvas.add(
        this.drawingHelper.lines[this.drawingHelper.lineCounter]
      );
      this.drawingHelper.lineCounter++;
    }
  }

  /**
   *
   * @param o event
   * @description Fn koja se trigeruje kada se cursorom pomera i iscrtava se shape
   */
  onMouseMove(o): void {
    if (this.drawingHelper.isDragging) {
      const e = o.e;
      const vpt = this.drawingHelper.canvas.viewportTransform;
      vpt[4] += e.clientX - this.drawingHelper.canvas.lastPosX;
      vpt[5] += e.clientY - this.drawingHelper.canvas.lastPosY;
      this.drawingHelper.canvas.requestRenderAll();
      this.drawingHelper.canvas.lastPosX = e.clientX;
      this.drawingHelper.canvas.lastPosY = e.clientY;
    }

    if (!this.drawingHelper.isDown) {
      return;
    }
    const pointer = this.drawingHelper.canvas.getPointer(o.e);

    if (this.drawingHelper.selectedShape === 'rect') {
      try {
        if (this.drawingHelper.origX > pointer.x) {
          this.drawingHelper.rectangle.set({
            left: Math.abs(pointer.x),
          });
        }
        if (this.drawingHelper.origY > pointer.y) {
          this.drawingHelper.rectangle.set({
            top: Math.abs(pointer.y),
          });
        }

        this.drawingHelper.rectangle.set({
          width: Math.abs(this.drawingHelper.origX - pointer.x),
        });
        this.drawingHelper.rectangle.set({
          height: Math.abs(this.drawingHelper.origY - pointer.y),
        });
      } catch (e) {
        // Do something on error....
      }
    } else if (this.drawingHelper.selectedShape === 'circle') {
      try {
        this.drawingHelper.rectangle.set({
          radius: Math.abs(this.drawingHelper.origX - pointer.x),
        });
      } catch (e) {
        // Do something on error....
      }
    } else if (
      this.drawingHelper.lines[0] !== null &&
      this.drawingHelper.lines[0] !== undefined &&
      this.drawingHelper.selectedShape === 'polygon'
    ) {
      this.setStartingPoint(o.e);
      this.drawingHelper.lines[this.drawingHelper.lineCounter - 1].set({
        x2: this.drawingHelper.polyVarX,
        y2: this.drawingHelper.polyVarY,
      });
    }

    this.drawingHelper.canvas.requestRenderAll();
  }
  /**
   *
   * @param o event
   * @description Fn koja se trigeruje kada je klik u gornjem polozaju
   */
  onMouseUp(o): void {
    if (this.drawingHelper.selectedShape === 'polygon') {
      this.drawingHelper.canvas.selection = true;
      return;
    } else if (this.drawingHelper.selectedShape !== 'zoom') {
      this.drawingHelper.rectangle.setCoords();
      this.drawingHelper.isDown = false;

      this.drawingHelper.canvas.off('mouse:down');
      this.drawingHelper.canvas.off('mouse:move');
      this.drawingHelper.canvas.off('mouse:up');
    } else {
      this.drawingHelper.canvas.setViewportTransform(
        this.drawingHelper.canvas.viewportTransform
      );
      this.drawingHelper.isDragging = false;
    }

    this.drawingHelper.canvas.forEachObject((obj) => {
      obj.selectable = true;
    });

    this.drawingHelper.canvas.selection = true;
    this.drawingHelper.canvas.requestRenderAll();
  }

  /**
   *
   * @param o Event
   * @description Mouse Scroll Event for 'canvas'
   */
  onMouseWheelScroll(o): void {
    const delta = o.e.deltaY;
    let zoom = this.drawingHelper.canvas.getZoom();
    zoom *= 0.999 ** delta;
    if (zoom > 20) {
      zoom = 20;
    }
    if (zoom < 0.01) {
      zoom = 0.01;
    }
    this.drawingHelper.canvas.setZoom(zoom);
    o.e.preventDefault();
    o.e.stopPropagation();
  }

  // function for startPoint
  setStartingPoint(options): void {
    const pointer = this.drawingHelper.canvas.getPointer(options);
    this.drawingHelper.polyVarX = pointer.x;
    this.drawingHelper.polyVarY = pointer.y;
  }

  /**
   * zoom canvas
   */
  onZoom(): void {
    this.drawingHelper.canvas.on('mouse:wheel', (e) => {
      this.onMouseWheelScroll(e);
    });
    // on zoom function
    this.drawingHelper.selectedShape = 'zoom';
    this.drawingHelper.selectedShapeCopy = 'zoom';
    // clear arrays
    this.drawingHelper.polygonPoints = [];
    this.drawingHelper.lines = [];
    this.drawingHelper.lineCounter = 0;

    this.resetZoom();
    this.draw();
    this.changeSelectableStatus(false);
  }

  /**
   * @description Reset Zoom
   */
  resetZoom(): void {
    // this.renderImageInCanvas();
    this.drawingHelper.canvas.requestRenderAll();
  }

  /**
   * Close modal for Warning info messages
   */
  closeModalLimit(): void {
    this.modalLimit.nativeElement.style.display = 'none';
  }
}
