<form [formGroup]="forgotPasswordForm">
  <div class="card m-auto p-2 demo-form">
    <h2 class="mb-0">Reset password</h2>
    <small>Send email with instructions to reset password.</small>
    <hr class="mb-1" />
    <!-- Email -->
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Email</mat-label>

      <input matInput value="" formControlName="email" name="email" />

      <mat-error>
        <app-field-errors [formField]="forgotPasswordForm.get('email')">
        </app-field-errors>
      </mat-error>
    </mat-form-field>

    <div class="d-flex justify-content-between">
      <button
        mat-raised-button
        color="primary"
        type="button"
        class="my-button"
        [disabled]="forgotPasswordForm.invalid || loading"
        (click)="onSubmit()"
      >
        <!-- <div
        *ngIf="loading"
        class="spinner-border text-success"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div> -->
        Send
      </button>

      <a mat-button [routerLink]="['login']" class="my-link my-button"
        >Back to login</a
      >
    </div>
  </div>
</form>
