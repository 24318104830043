import { RouteInfo } from '@models/RouteInfo';

export const ROUTES: RouteInfo[] = [
  { path: 'dashboard', title: 'Dashboard' },
  {
    path: 'cases',
    title: 'Cases',
    submenu: [
      { path: 'cases', title: 'Cases list' },
      { path: 'references', title: 'References' },
    ],
  },
  {
    path: 'clinics',
    title: 'Clinics',
    submenu: [
      { path: 'clinics', title: 'Clinic list' },
      { path: 'clinics/requests', title: 'Clinic requests' },
    ],
  },
  {
    path: 'clinic-users',
    title: 'Clinic users',
    submenu: [
      { path: 'clinic-users', title: 'User list' },
      { path: 'clinic-users/requests', title: 'User requests' },
    ],
  },
  { path: 'clients', title: 'Owners' },
  { path: 'patients', title: 'Patients' },
  { path: 'videos', title: 'Videos' },
  { path: 'training', title: 'Training' },
  { path: 'audits', title: 'Audit logs' },
  { path: 'user-activity', title: 'User Activity' },
];
