import { Subscription } from 'rxjs';
import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy, ComponentFactoryResolver } from '@angular/core';
import { FilterReferenceImagePipe } from '@pipes/filter-reference-image.pipe';
import { UtilService } from '@services/util.service';
import { HelpersService } from '@services/helpers.service';

@Component({
  selector: 'app-view-reference-image',
  templateUrl: './view-reference-image.component.html',
  styleUrls: ['./view-reference-image.component.scss'],
  providers: [FilterReferenceImagePipe]
})
export class ViewReferenceImageComponent implements OnInit, OnChanges, OnDestroy {
  referenceImagesArray: any[] = [];
  refAllImages: any[] = [];
  referenceCaseName: string = '';
  subscription: Subscription[] = [];
  outputIndex: number = 0;

  @Input() refCaseIndex: number;
  @Input() activeAlgorithmReference: string = '';
  @Input() refCaseSelected: any = '1';
  @Input() set referenceImages(value) {
    this.refAllImages = value;
    this.referenceImagesArray = this.filterPipe.transform(this.refAllImages, this.refCaseSelected, this.activeAlgorithmReference) || [];
    this.referenceCaseName = this.utilService.transformImageTypeId(this.refCaseSelected);
  };

  @Output() toggleFullScreen = new EventEmitter();
  @Output() isActive = new EventEmitter();

  constructor(
    public filterPipe: FilterReferenceImagePipe,
    private utilService: UtilService,
    public helperService: HelpersService,
  ) { }

  ngOnInit(): void {
    this.referenceImagesArray = this.filterPipe.transform(this.refAllImages, this.refCaseSelected, this.activeAlgorithmReference) || [];
    this.referenceCaseName = this.utilService.transformImageTypeId(this.refCaseSelected);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.refCaseSelected) {
      this.referenceImagesArray = this.filterPipe.transform(this.refAllImages, changes.refCaseSelected?.currentValue, this.activeAlgorithmReference) || [];
      this.referenceCaseName = this.utilService.transformImageTypeId(changes.refCaseSelected?.currentValue);
    }
  }

  onFullScreen() {
    this.toggleFullScreen.emit();
  }

  handleActive(event): void {
    this.isActive.emit(event);
  }
}
