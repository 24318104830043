export const refImagesListView = {
  liver_mass: {
    normal: [
      './../../../assets/images/ref/liver_mass/normal/Liver-normal.png',
    ],
    malignant: [
      './../../../assets/images/ref/liver_mass/malignant/Liver-malignat.png',
    ],
    benign: [
      './../../../assets/images/ref/liver_mass/benign/Liver-benign.png',
    ]
  },
  splenic_mass: {
    normal: [
      './../../../assets/images/ref/splenic_mass/normal/Spleen-normal.png',
    ],
    malignant: [
      './../../../assets/images/ref/splenic_mass/malignant/Spleen-Malignat.png',
    ],
    benign: [
      './../../../assets/images/ref/splenic_mass/benign/Spleen-Benign.png',
    ]
  }


};
