<app-graph-stats
  *ngIf="data?.result?.stdev"
  [data]="utilService.parsingGraphStats(data?.result.stdev)"
  [indicatorSection]="true"
>
</app-graph-stats>
<span
  [ngStyle]="{
    color: utilService.getColorForMLEval(
      data?.result.ml.eval.eval === 'NORMAL'
        ? data?.result.ml.eval.eval
        : data?.result.ml.eval2.eval
    ).color
  }"
  class="other-values"
  [ngClass]="{ detailed: position === ProcessedScorePositions.DETAILED }"
  *ngIf="!data?.result?.stdev"
>
  <span class="title">State</span>
  <span
    [ngStyle]="{
      backgroundColor: utilService.getColorForMLEval(
        data?.result.ml.eval.eval === 'NORMAL'
          ? data?.result.ml.eval.eval
          : data?.result.ml.eval2.eval
      ).background
    }"
    [ngClass]="{
      'gray-bg': utilService.getEvalValue2(data?.result.ml) === 'N/A'
    }"
    class="name"
  >
    <span *ngIf="data?.result.ml.eval.eval === 'NORMAL'">
      <span *ngIf="position === ProcessedScorePositions.DETAILED">{{
        data?.result.ml.eval.eval
        | processedImageLabel: ProcessedScorePositions.DETAILED
        }}</span>
    </span>
    <span *ngIf="data?.result.ml.eval.eval !== 'NORMAL'">
      <span *ngIf="position === ProcessedScorePositions.DETAILED">
        {{
        data?.result.ml.eval2.eval
        | processedImageLabel: ProcessedScorePositions.DETAILED
        }}</span>
    </span>
  </span>
  <span class="title">Score</span>
  <span [ngStyle]="{
      backgroundColor: utilService.getColorForMLEval(
        data?.result.ml.eval.eval === 'NORMAL'
          ? data?.result.ml.eval.eval
          : data?.result.ml.eval2.eval
      ).background
    }">{{ utilService.getEvalValue2(data?.result.ml, '', data?.algorithm.suffix) }}</span></span>
