import { environment } from './../environments/environment';
import { UserActivityModule } from './user-activity/user-activity.module';
import { SharedModule } from './shared/shared.module';
import { RollbarErrorHandler } from './_helpers/rollbarErrorHandler';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { RequestResetPasswordModule } from './forgot-password/request-reset-password/request-reset-password.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password/forgot-password.module';
import { BasicDialogModule } from './shared/components/basic-dialog/basic-dialog.module';
import { DicomInformationDialogModule } from './shared/components/dicom-information-dialog/dicom-information-dialog.module';
import { PatientsDiagnosisDialogComponent } from './patients/patients-diagnosis-dialog/patients-diagnosis-dialog.component';
import { HeaderModule } from './shared/header/header.module';
import { AuditLogsModule } from './audit-logs/audit-logs.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { RegisterModule } from './register/register.module';
import { StripHtmlPipe } from './pipes/stripehtml.pipe';
import { GraphStatsModule } from './shared/components/graph-stats/graph-stats.module';
import { LoginModule } from './login/login.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { PercentageFormatPipe } from './pipes/percentage-format.pipe';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FeatureComponentsModule } from './modules/feature-components/feature-components.module';
import { MaterialModule } from './modules/material/material.module';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AoiToolComponent } from './aoi-tool/aoi-tool.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { ClientsPatientsAddComponent } from './clients-patients/clients-patients-add/clients-patients-add.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { RollbarService, rollbarFactory } from '@helpers/rollbarErrorHandler';
import { FullSizeContainerComponent } from '@shared/components/full-size-container/full-size-container.component';
import { RegisterLayoutComponent } from './layouts/register-layout/register-layout.component';
import { NgxMaskModule } from 'ngx-mask';
import { AgreementSubscriptionComponent } from './agreements/agreement-subscription/agreement-subscription.component';
import { AgreementPrivacyPolicyComponent } from './agreements/agreement-privacy-policy/agreement-privacy-policy.component';
import { AgreementTermsOfUseComponent } from './agreements/agreement-terms-of-use/agreement-terms-of-use.component';
import { AgreementSubscriptionTrialComponent } from './agreements/agreement-subscription-trial/agreement-subscription-trial.component';
import { CardAvailabilityCaseComponent } from '@shared/components/card-availability-case/card-availability-case.component';

// * Services
import { UserService } from '@services/user.service';

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    DashboardComponent,
    AoiToolComponent,
    FullSizeContainerComponent,
    RegisterLayoutComponent,
    AgreementSubscriptionComponent,
    AgreementPrivacyPolicyComponent,
    AgreementTermsOfUseComponent,
    AgreementSubscriptionTrialComponent,
    CardAvailabilityCaseComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FeatureComponentsModule,
    HttpClientModule,
    LoginModule,
    DashboardModule,
    GraphStatsModule,
    NgxSpinnerModule,
    RouterModule,
    MaterialModule,
    HeaderModule,
    AuditLogsModule,
    UserActivityModule,
    DicomInformationDialogModule,
    BasicDialogModule,
    ForgotPasswordModule,
    RequestResetPasswordModule,
    NavbarModule,
    RegisterModule,
    SharedModule,
    ServiceWorkerModule.register('./../../../ngsw-worker.js', {
      enabled: environment.production,
    }),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp({
      appId: '1:447186792415:web:fd01133f934ecea9',
      apiKey: 'AIzaSyDOdduKXzQ4Ig1WSfIqSJfGCDcdQs9v9QA',
      authDomain: 'petview-19320.firebaseapp.com',
      databaseURL: 'https://petview-19320.firebaseio.com',
      projectId: 'petview-19320',
      storageBucket: 'petview-19320.appspot.com',
      messagingSenderId: '447186792415',
    }),
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    // { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    PercentageFormatPipe,
    StripHtmlPipe,
    UserService,
    DatePipe,
  ],
  entryComponents: [
    ClientsPatientsAddComponent,
    PatientsDiagnosisDialogComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
