<div class="processed_results_wrapper">
  <!-- [ngStyle]="{
    backgroundColor:
      index === imgIndex
        ? utilService.getColorForMLEval(data?.ml_eval?.eval).background
        : ''
  }" -->
  <app-graph-stats
    *ngIf="data?.stdev"
    [data]="utilService.parsingGraphStats(data?.stdev)"
    [indicatorSection]="true"
    [position]="position"
  >
  </app-graph-stats>

  <div *ngIf="position === ProcessedScorePositions.DETAILED && !data?.stdev">
    <app-processed-results-subcategory
      [data]="data?.processed_images[0]"
      [position]="position"
    ></app-processed-results-subcategory>
  </div>

  <span
    [ngStyle]="{
        color: utilService.getColorForMLEval(data?.ml_eval.eval).color
      }"
    class="other-values"
    [ngClass]="{ detailed: position === ProcessedScorePositions.DETAILED }"
    *ngIf="!data?.stdev && position !== ProcessedScorePositions.DETAILED"
  >
    <span
      class="title"
      *ngIf="position === ProcessedScorePositions.DETAILED"
    >State</span>
    <span
      class="name"
      [ngStyle]="{
            backgroundColor: utilService.getColorForMLEval(
              data?.processed_images[0]?.result.ml.eval.eval === 'NORMAL'
                ? data?.processed_images[0]?.result.ml.eval.eval
                : data?.processed_images[0]?.result.ml.eval2.eval
            ).background
          }"
    >
      <li>
        <span>
          <span *ngIf="
              position === ProcessedScorePositions.TABLE ||
              position === ProcessedScorePositions.IMAGE
            ">{{ (data?.processed_images[0]?.result.ml.eval.eval === 'NORMAL'
            ? data?.processed_images[0]?.result.ml.eval.eval
            : data?.processed_images[0]?.result.ml.eval2.eval) | processedImageLabel }}</span>
        </span>
      </li>
    </span>
    <span
      class="title"
      *ngIf="position === ProcessedScorePositions.DETAILED"
    >Score</span>
    <span
      class="score"
      [ngStyle]="{
        backgroundColor:
          position === ProcessedScorePositions.DETAILED &&
          utilService.getColorForMLEval(data?.processed_images[0]?.result.ml.eval.eval === 'NORMAL'
            ? data?.processed_images[0]?.result.ml.eval.eval
            : data?.processed_images[0]?.result.ml.eval2.eval).background
      }"
    >

      {{ utilService.getEvalValue2(data?.processed_images[0]?.result.ml, '',
      usecaseType)}}
    </span>
  </span>
</div>
