import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-activity-details',
  templateUrl: './user-activity-details.component.html',
  styleUrls: ['./user-activity-details.component.scss']
})
export class UserActivityDetailsComponent implements OnInit {

  allFields: any[] = [];
  otherFields: any;
  formFields: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public fields: any
  ) { }

  ngOnInit(): void {

    this.otherFields = this.fields;
    this.formFields = JSON.parse(this.otherFields.form_fields);

    for (let key in this.formFields) {
      this.allFields.push({
        label: key,
        value: this.formFields[key]
      })
    }
  }



}
