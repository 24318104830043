import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentageFormat'
})
export class PercentageFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const regEx = /\d+/g;
    if (!value) { return value; }
    if (value === 'N/A') {
      return value;
    }
    if (args === '%' && value.includes('%')) {
      return Math.round(Number(value.match(regEx).join('.'))) + args;
    } else {
      return value;
    }
  }

}
