<div *ngIf="role.ADMIN === authService.currentUserValue?.user.role">
  <div class="circles d-flex align-items-center justify-content-between mt-2">
    <div
      class="circles-item"
      [ngClass]="{ disabled: !data?.clinics }"
    >
      <p class="circles-item-number">{{ data?.clinics }}</p>
      <p class="circles-item-desc">{{ "CLINIC" | plurals: data?.clinics }}</p>
    </div>
    <div
      class="circles-item"
      [ngClass]="{ disabled: !data?.doctors }"
    >
      <p class="circles-item-number">{{ data?.doctors }}</p>
      <p class="circles-item-desc">{{ "USER" | plurals: data?.doctors }}</p>
    </div>
    <div class="circles-item">
      <p class="circles-item-number">{{ data?.normalCase }}</p>
      <p class="circles-item-desc">
        {{ "NORMAL CASE" | plurals: data?.normalCase }}
      </p>
    </div>
    <div class="circles-item">
      <p class="circles-item-number">{{ data?.abnormalCase }}</p>
      <p class="circles-item-desc">
        {{ "ABNORMAL CASE" | plurals: data?.abnormalCase }}
      </p>
    </div>
  </div>
</div>

<ng-container *ngIf="role.CLINIC_ADMIN === authService.currentUserValue?.user.role">
  <div class="circles d-flex align-items-center justify-content-between mt-2">
    <div
      class="circles-item"
      [ngClass]="{ disabled: !data?.doctors }"
    >
      <p class="circles-item-number">{{ data?.doctors }}</p>
      <p class="circles-item-desc">{{ "USER" | plurals: data?.doctors }}</p>
    </div>
    <div class="circles-item">
      <p class="circles-item-number">{{ data?.cases }}</p>
      <p class="circles-item-desc">{{ "CASE" | plurals: data?.cases }}</p>
    </div>
    <div class="circles-item">
      <p class="circles-item-number">{{ data?.clients }}</p>
      <p class="circles-item-desc">{{ "CLIENT" | plurals: data?.clients }}</p>
    </div>
    <div class="circles-item">
      <p class="circles-item-number">{{ data?.patients }}</p>
      <p class="circles-item-desc">{{ "PATIENT" | plurals: data?.patients }}</p>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="
    role.USER === authService.currentUserValue?.user.role ||
    role.TRIAL === authService.currentUserValue?.user.role
  ">
  <div class="circles d-flex align-items-center justify-content-between mt-2">
    <div class="circles-item">
      <p class="circles-item-number">{{ data?.cases }}</p>
      <p class="circles-item-desc">
        {{ "INSERTED CASE" | plurals: data?.cases }}
      </p>
    </div>
    <div class="circles-item">
      <p class="circles-item-number">{{ data?.abnormalCase }}</p>
      <p class="circles-item-desc">
        {{ "ABNORMAL CASE" | plurals: data?.abnormalCase }}
      </p>
    </div>
    <div class="circles-item">
      <p class="circles-item-number">{{ data?.clients }}</p>
      <p class="circles-item-desc">{{ "client" | plurals: data?.clients }}</p>
    </div>
    <div class="circles-item">
      <p class="circles-item-number">{{ data?.patients }}</p>
      <p class="circles-item-desc">{{ "patient" | plurals: data?.patients }}</p>
    </div>
  </div>
</ng-container>
