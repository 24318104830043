export class FabricCircleConfig {
    left: any = 0;
    top: any = 0;
    radius: any = 1;
    strokeWidth:any = 3;
    stroke: string = 'red';
    fill: string = 'transparent';
    selectable: boolean = false;
    originX: string = 'center';
    originY: string = 'center';
    lockScalingX: boolean = true;
    lockScalingY: boolean = true;

    constructor(left?: any, top?: any, strokeWidth?: any) {
        this.left = left;
        this.top = top;
        this.strokeWidth = strokeWidth;
    }
}

export class FabricRectConfig {
    left: any = 0;
    top: any = 0;
    fill: string = 'transparent';
    stroke: string = 'red';
    strokeWidth: any = 3;
    selectable: boolean = false;
    lockScalingX: boolean = true;
    lockScalingY: boolean = true;

    constructor(left?: any, top?: any, strokeWidth?: any) {
        this.left = left;
        this.top = top;
        this.strokeWidth = strokeWidth;
    }
}

export class FabricPolygonConfig {
    strokeWidth: any = 3;
    selectable: boolean = false;
    stroke: string = 'red';

    constructor() {
       
    }
}

export class FabricPolylineConfig {
    fill: string = 'rgba(0,0,0,0)';
    stroke: string = 'red';
    strokeWidth: any = 3;
    objectCaching: boolean = false;
    lockScalingX: boolean = true;
    lockScalingY: boolean = true;

    constructor(strokeWidth?: any) {
        this.strokeWidth = strokeWidth;
    }
}