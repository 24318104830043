import { FieldErrorsModule } from '@shared/form-elements/field-errors/field-errors.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestResetPasswordComponent } from './request-reset-password.component';
import { MaterialModule } from '@modules/material/material.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [RequestResetPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FieldErrorsModule,
  ],
  exports: [RequestResetPasswordComponent],
})
export class RequestResetPasswordModule {}
