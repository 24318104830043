<h2 mat-dialog-title>Privacy Policy</h2>
<mat-dialog-content>
  <p><strong>PetviewDX, LLC</strong></p>
  <h3 class="text-center">Privacy Policy</h3>
  <small>Last Updated December 12, 2021</small>
  <p>
    PetviewDX, LLC, a Delaware limited liability company, whose address is
    _____________________________________, ("<strong>Petview</strong>") is
    deeply committed to your right to privacy and takes your privacy seriously.
    Petview has prepared this privacy policy statement ("<strong
      >Privacy Policy</strong
    >") to describe its collection, use, and disclosure of your personal
    information through the [Insert Link to the PetView website]
    ("<strong>Website</strong>") and the Imaging Analysis Service
    (&ldquo;Service&rdquo;) provided by Petview on the the Website. This Privacy
    Policy is incorporated into the General Terms of Use Agreement [Insert
    Link]. Definitions used in this Terms of Use shall also apply to this
    Privacy Policy. PetView will collect, use, and disclose your personal
    information as described in this Privacy Policy. By accessing the Website
    you agree to this Privacy Policy and also the use of cookies as noted below.
  </p>
  <p><strong>What Information About You Does Petview Collect?</strong></p>
  <p>
    Petview collects the following information provided for each Authorized
    User: First Name, Last Name, email address. In addition, Petview collects
    the address of the Primary Account Holder and type of subscription, as well
    as transaction history with respect the Primary Account.&nbsp;
  </p>
  <p>
    <strong
      >What Categories of Sources of Information About You are Utilized</strong
    >?
  </p>
  <p>
    All information Petview collects about you is derived from information
    provided by you upon registration and your cookies as described below.
  </p>
  <p><strong>How Do Use Cookies and Tracking Codes?</strong></p>
  <p>
    Cookies are small pieces of text sent to your web browser by a website you
    visit. When you visit the Website a cookie file is stored in your web
    browser and allows the Service or a third-party to recognize you and make
    your next visit easier and the Service more useful to you.
  </p>
  <p>Cookies can be "persistent" or "session" cookies.</p>
  <p>
    Through the combination of both internal and third party tracking code as
    well as cookies, Petview tracks the following categories of information when
    a visitor enters the Website: (1) IP address, (2) domain servers, (3) type
    of computer used to access the Website, (4) types of web browsers
    (collectively "<strong>Anonymous Data</strong>"). Such data does not collect
    information that identifies you as an individual. Petview uses Anonymous
    Data for general marketing purposes and to help enhance customer experience
    on the Website.
  </p>
  <p>
    When you use and access the WebSite, Petview may place a number of cookies
    files in your web browser.
  </p>
  <p>
    Petview useses cookies for the following purposes: to enable certain
    functions of the Imaging Analysis Service
    (&ldquo;<strong>Service</strong>&rdquo;), to provide analytics, to store
    your preferences, to enable advertisements delivery, including behavioral
    advertising.
  </p>
  <p>
    Petview uses both session and persistent cookies on the Service and uses
    different types of cookies to run the Service.
  </p>
  <p>
    Petview may use &ldquo;essential cookies&rdquo; to authenticate users and
    prevent fraudulent use of user accounts.
  </p>
  <p>
    In addition to its own cookies, Petview may also use various third-parties
    cookies to report usage statistics of the Service, deliver advertisements on
    and through the Service, and so on.
  </p>
  <p>
    If you'd like to delete cookies or instruct your web browser to delete or
    refuse cookies, please visit the help pages of your web browser.
  </p>
  <p>
    Please note, however, that if you delete cookies or refuse to accept them,
    you might not be able to use all of the features Petview offers, you may not
    be able to store your preferences, and some of the Website pages might not
    display properly.
  </p>
  <p>
    You can learn more about cookies and the following third-party websites:
  </p>
  <ul>
    <li>
      AllAboutCookies:
      <a href="http://www.allaboutcookies.org/"
        >http://www.allaboutcookies.org/</a
      >
    </li>
    <li>
      Network Advertising Initiative:
      <a href="http://www.networkadvertising.org/"
        >http://www.networkadvertising.org/</a
      >
    </li>
  </ul>
  <p>
    <strong
      >How Does Petview Use The Information About You That Petview
      Collects?</strong
    >
  </p>
  <p>
    Personally Identifiable Information and Anonymous Data are used to gather
    general statistics regarding Petview&rsquo;s customers and visitors in order
    to enhance the consumer experience on the Website and to facilitate use of
    the Service. Petview may also use demographic data in a manner that does not
    identify you specifically or allow you to be contacted but does identify
    certain criteria about Petview&rsquo;s users in general. For example,
    Petview may inform third parties about the number of registered users,
    number of unique visitors, and the pages most frequently browsed.
  </p>
  <p>
    Petview may use your Personally Identifiable Data to provide you with
    special information and promotions regarding Petview&rsquo;s Services or in
    the performance of the Service to Authorized Users pursuant to the General
    Terms of Use and agreements reference therein.&nbsp;
  </p>
  <p>
    Petview does not and will not sell your personally identifiable information
    to third parties for direct marketing purposes.
  </p>
  <p>
    <strong
      >With Whom Does Petview Share Your Information That Petview
      Collects?</strong
    >
  </p>
  <p>
    Except as described in this Privacy Policy, Petview will not share your
    Personally Identifiable Information with third parties, unless such
    disclosure is necessary (as determined in Petview&rsquo;s sole discretion)
    to: (a) comply with a court order or other legal process; (b) protect the
    rights, property, and/or safety of Petview or any third party; (c) enforce
    the General Terms of Use or to Provide the Services or otherwise to perform
    the obligations of Petview pursuant to the Subscription Agreement.
  </p>
  <p>
    Petview does not retain any payment information. Payment information with
    respect to the Website is handled by Authorize.net (<a
      href="http://www.authorize.net"
      >www.authorize.net</a
    >). Please also see
    <a href="https://usa.visa.com/legal/privacy-policy.html"
      >https://usa.visa.com/legal/privacy-policy.html</a
    >
    for the privacy policy link for the Authorize.net site.&nbsp;
  </p>
  <p>
    Petview uses third-party advertising companies such as Google or Facebook to
    serve ads on Petview&rsquo;s behalf as a part of a remarketing or
    retargeting marketing campaign. These companies may employ cookies and
    action tags also known as single pixel gifs or web beacons to measure
    advertising effectiveness by showing ads across other web sites or apps that
    you have visited based on your past visits to the Website. Any information
    that these third parties collect via cookies and action tags is completely
    anonymous. Please see the following links for information about how Google
    and Facebook may use this information:
  </p>
  <p>&nbsp;Google</p>
  <p>https://policies.google.com/technologies/types?hl=en-US</p>
  <p>https://policies.google.com/technologies/cookies?hl=en-US</p>
  <p>Facebook</p>
  <p>https://www.facebook.com/policy/cookies/</p>
  <p>
    https://www.facebook.com/business/help/471978536642445?id=1205376682832142
  </p>
  <p>
    Petview may provide your personal information to third party service
    providers who work on behalf of or with Petview to provide some of the
    Service and features of the Website and to help us communicate with the
    users of the Website. However, these service providers do not have any
    independent right to share this information (expect pursuant to a legal
    requirement such as a subpoena or warrant).
  </p>
  <p>
    Petview may share some or all of your personal information with
    Petview&rsquo;s parent company, subsidiaries, joint ventures, or other
    companies under a common control ("<strong>Affiliates</strong>"), in which
    case Petview will require its Affiliates to honor this Privacy Policy.
  </p>
  <p>
    If the assets related to the Website are acquired by another company, that
    company will possess the personal information collected by Petview and it
    will assume the rights and obligations regarding your personal information
    as described in this Privacy Policy.
  </p>
  <p>
    Our Website may contains links to other third-party websites ("<strong
      >Linked Websites</strong
    >"). Petview is not responsible for the privacy practices or the content of
    the Linked Websites. If you choose to visit other websites, Petview is not
    responsible for the Privacy Practices or content of those other websites,
    and it is your responsibility to review the Privacy Policies at those
    websites to confirm that you understand and agree with their policies.
  </p>
  <p><strong>How Does Petview Maintain Security?</strong>&nbsp;</p>
  <p>
    Petview uses reasonable measures to maintain the security of your Personally
    Identifiable Information. However, no company, organization, or online
    community, including Petview, can fully eliminate security risks associated
    with personal information.
  </p>
  <p>
    Petview has security measures in place to minimize the loss, misuse,
    destruction and alteration of the information that you provide us or that
    Petview obtains from you. Petview will have no liability, however, to you or
    to any third-party arising out of any such loss, misuse, destruction, or
    alternation.
  </p>
  <p><strong>How Does Petview Make Changes in Privacy Policy?</strong>&nbsp;</p>
  <p>
    Petview reserves the right, at any time and without notice, to add to,
    change, update or modify this Privacy Policy, by posting such change, update
    or modification on the Website. Any such change, update or modification will
    be effective immediately upon posting.
  </p>
  <p><strong>Private Information Contact. </strong>&nbsp;</p>
  <p>
    If you have any questions about this Privacy Policy, or your dealings with
    the Petview Website, you can contact us info@Petview.com.
  </p>
  <p><strong>Your Privacy Rights.</strong></p>
  <p>
    Petview does not believe that it is subject to Section 1798.83 of the
    California Civil Code Section 1798.83 because Petview does not share
    personally identifiable information for direct marketing purposes but
    Petview does nonetheless try to comply with the spirit of the law.
    Accordingly, Petview will honor the following requests to the extent they
    are applicable.
  </p>
  <p>
    <strong
      >How May I Find Out What Personally Identifiable Information Petview has
      about me?
    </strong>
  </p>
  <p>
    You may send an email to info@Petview.com to request personal information
    collected by Petview about you and third parties that received the
    information during the preceding calendar year.
  </p>
  <p>
    <strong>What Rights Do I Have to Delete Any Personal Information?</strong
    >&nbsp;
  </p>
  <p>
    By communicating with
    <a href="mailto:info@Petview.com">info@Petview.com</a> you may request the
    deletion of personal information that Petview holds except where Petview
    needs to retain the personal information in order to do any of the
    following:
  </p>
  <p>Provide Services to you</p>
  <p>Detect or resolve issues security or functionality-related issues</p>
  <p>Comply with the law</p>
  <p>Conduct research in the public interest</p>
  <p>Safeguard the right to free speech</p>
  <p>Carry out any actions for internal purposes that you reasonably expect</p>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button
    color="primary"
    mat-raised-button
    class="my-button"
    (click)="onConfirm()"
    cdkFocusInitial
  >
    I agree
  </button>
</mat-dialog-actions>
