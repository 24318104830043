import { Subscription } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { HelpersService } from '@services/helpers.service';

@Component({
  selector: 'app-billing-form',
  templateUrl: './billing-form.component.html',
  styleUrls: ['./billing-form.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class BillingFormComponent implements OnInit {
  activeMask: string;
  activeCCVMask: string;

  @Input() form: FormGroup;
  subscription: Subscription[] = [];

  constructor(public helperService: HelpersService) {}

  ngOnInit(): void {
    this.subscription.push(
      this.form
        .get('billingCardNumber')
        .valueChanges.subscribe((value: string) => {
          this.activeMask = this.helperService.convertMask(value.charAt(0));
          this.activeCCVMask = this.helperService.convertCCV(value.charAt(0));

          this.form
            .get('creditCardType')
            .setValue(
              this.helperService.getCreditCardTypeName(value.charAt(0))
            );
        })
    );
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.forEach((subscription) => subscription.unsubscribe());
  }
}
