import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agreement-privacy-policy',
  templateUrl: './agreement-privacy-policy.component.html',
  styleUrls: ['./agreement-privacy-policy.component.scss'],
})
export class AgreementPrivacyPolicyComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<AgreementPrivacyPolicyComponent>
  ) {}

  ngOnInit(): void {}
  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
