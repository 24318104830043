<h2 mat-dialog-title>Terms of Use</h2>
<mat-dialog-content>
  <p>PetViewDX, LLC</p>
  <h3 class="text-center">Website General Terms of Use</h3>
  <small>Last Modified: [DATE]</small>
  <p>
    <strong><u>Acceptance of the General Terms of Use</u></strong>
  </p>
  <p>
    These General Terms of Use of use are entered into by and between You and
    PetViewDX, LLC, a Delaware limited liability
    (&ldquo;<strong>PetView</strong>&rdquo;, "<strong>Company</strong>,"
    "<strong>we</strong>," or "<strong>us</strong>"). The following terms and
    conditions, together with any documents they expressly incorporate by
    reference (collectively, "<strong>Terms of Use</strong>"), govern your
    access to and use of [WEBSITE DOMAIN NAME], including any content,
    functionality, and services offered on or through [WEBSITE DOMAIN NAME] (the
    "<strong>Website</strong>"), whether as a guest or a registered user.
  </p>
  <p>
    Please read the Terms of Use carefully before you start to use the Website.
    <strong
      >By using the Website you accept and agree to be bound and abide by these
      Terms of Use and our Privacy Policy, found at [PRIVACY POLICY URL],
      incorporated herein by reference.</strong
    >
    If you do not want to agree to these Terms of Use or the Privacy Policy, you
    must not access or use the Website.
  </p>
  <p>
    This Website is offered and available to users who 18 years of age or older
    and reside in the United States or any of its territories or possessions. By
    using this Website, you represent and warrant that you are of legal age to
    form a binding contract with the Company and meet all of the foregoing
    eligibility requirements. If you do not meet all of these requirements, you
    must not access or use the Website.
  </p>
  <p>
    If you print, copy, modify, download, or otherwise use or provide any other
    person with access to any part of the Website in breach of the Terms of Use,
    your right to use the Website will stop immediately and you must, at our
    option, return or destroy any copies of the materials you have made. No
    right, title, or interest in or to the Website or any content on the Website
    is transferred to you, and all rights not expressly granted are reserved by
    the Company. Any use of the Website not expressly permitted by these Terms
    of Use is a breach of these Terms of Use and may violate copyright,
    trademark, and other laws.
  </p>
  <p>
    <strong><u>Changes to the Terms of Use</u></strong>
  </p>
  <p>
    We may revise and update these Terms of Use from time to time in our sole
    discretion. All changes are effective immediately when we post them, and
    apply to all access to and use of the Website thereafter.
  </p>
  <p>
    Your continued use of the Website following the posting of revised Terms of
    Use means that you accept and agree to the changes. You are expected to
    check this page each time you access this Website so you are aware of any
    changes, as they are binding on you.
  </p>
  <p>
    <strong><u>Accessing the Website and Account Security</u></strong>
  </p>
  <p>
    We reserve the right to withdraw or amend this Website, and any service or
    material we provide on the Website, in our sole discretion without notice.
    We will not be liable if for any reason all or any part of the Website is
    unavailable at any time or for any period. From time to time, we may
    restrict access to some parts of the Website, or the entire Website, to
    users, including registered users.
  </p>
  <p>You are responsible for both:</p>
  <ul>
    <li>
      Making all arrangements necessary for you to have access to the Website.
    </li>
    <li>
      Ensuring that all persons who access the Website through your internet
      connection are aware of these Terms of Use and comply with them.
    </li>
  </ul>
  <p>
    To access the Website or some of the resources it offers, you may be asked
    to provide certain registration details or other information. It is a
    condition of your use of the Website that all the information you provide on
    the Website is correct, current, and complete. You agree that all
    information you provide to register with this Website or otherwise,
    including, but not limited to, through the use of any interactive features
    on the Website, is governed by our <em>Privacy Policy</em> [LINK TO PRIVACY
    POLICY], and you consent to all actions we take with respect to your
    information consistent with our Privacy Policy.
  </p>
  <p>
    If you choose, or are provided with, a user name, password, or any other
    piece of information as part of our security procedures, you must treat such
    information as confidential, and you must not disclose it to any other
    person or entity. You also acknowledge that your account is personal to you
    and, except as may otherwise be provided pursuant to a Subscription
    Agreement, agree not to provide any other person with access to this Website
    or portions of it using your user name, password, or other security
    information. You agree to notify us immediately of any unauthorized access
    to or use of your user name or password or any other breach of security. You
    also agree to ensure that you exit from your account at the end of each
    session. You should use particular caution when accessing your account from
    a public or shared computer so that others are not able to view or record
    your password or other personal information.
  </p>
  <p>
    We have the right to disable any user name, password, or other identifier,
    whether chosen by you or provided by us, at any time in our sole discretion
    for any or no reason, including if, in our opinion, you have violated any
    provision of these Terms of Use.
  </p>
  <p>
    <strong
      ><u>PetViewDX Imaging Analysis Services Beta Use Agreement</u></strong
    >. These Terms of Use also apply to You if you have entered into a Beta
    Partner Letter Agreement or similar agreement.&nbsp;
  </p>
  <p>
    <strong><u>Trial Use Agreement</u></strong
    >. The Website provides for a click through Trial Use Agreement which
    enables potential subscribers to test the software for a limited period of
    time. These Terms of Use also apply to those users who agree to the Trial
    Use Agreement in addition to the terms of the Trial Use Agreement.
  </p>
  <p>
    <strong><u>Subscription Agreement. </u></strong> The Website provides for a
    click through agreement by which Primary Account Holders and their users can
    agree to the terms and conditions of the PetViewDX Imaging Analysis Services
    Subscription Agreement (&ldquo;<strong>Subscription Agreement</strong
    >&rdquo;). These Terms of Use also apply to those users who subscribe to the
    Subscription Agreement.&nbsp;
  </p>
  <p>
    <strong><u>Intellectual Property Rights</u></strong>
  </p>
  <p>
    The Website and its entire contents, features, and functionality (including
    but not limited to all information, software, text, displays, images, video,
    and audio, and the design, selection, and arrangement thereof) are owned by
    the Company, its licensors, or other providers of such material and are
    protected by United States and international copyright, trademark, patent,
    trade secret, and other intellectual property or proprietary rights laws.
  </p>
  <p>
    Except as may otherwise apply to Subscribers under the Subscription
    Agreement, these Terms of Use permit you to use the Website for your
    personal, non-commercial use only. You must not reproduce, distribute,
    modify, create derivative works of, publicly display, publicly perform,
    republish, download, store, or transmit any of the material on our Website,
    except as follows:
  </p>
  <ul>
    <li>
      Your computer may temporarily store copies of such materials in RAM
      incidental to your accessing and viewing those materials.
    </li>
    <li>
      You may store files that are automatically cached by your Web browser for
      display enhancement purposes.
    </li>
    <li>
      You may print or download one copy of a reasonable number of pages of the
      Website for your own personal, non-commercial use and not for further
      reproduction, publication, or distribution.
    </li>
    <li>
      If we provide desktop, mobile, or other applications for download, you may
      download a single copy to your computer or mobile device solely for your
      own personal, non-commercial use, provided you agree to be bound by our
      end user license agreement for such applications.
    </li>
  </ul>
  <p>
    <strong><u>Definitions</u></strong>
  </p>
  <p>As used herein the following definitions shall apply:</p>
  <p>
    &nbsp;&rdquo;<strong>Customer Data</strong>&rdquo; means, other than
    Reports, information, data and other content, in any form or medium, that is
    collected, downloaded or otherwise received, directly or indirectly from You
    by or through the Imaging Analysis Services or that incorporates or is
    derived from the processing of such information, data or content by or
    through the Imaging Analysis Services. Customer Data includes information
    reflecting the access or use of the Services by or on behalf of Customer or
    any Authorized User other than Reports.
  </p>
  <p>
    &ldquo;<strong>Documentation</strong>&rdquo; means any manuals, instructions
    or other documents or materials that the PetView provides or makes available
    to You in any form or medium and which describe the functionality,
    components, features or requirements of the Imaging Analysis Services or
    PetView Materials, including any aspect of the installation, configuration,
    integration, operation, use, support or maintenance thereof.
  </p>
  <p>
    &ldquo;<strong>Imaging Analysis Services</strong>&rdquo; means (i) the
    processing by PetView of Use Case Images in connection with a Use Case and
    (ii) delivery via email or by download to an Authorized User by PetView of
    the associated Report pursuant to a Subscription Agreement or Trial Use
    Agreement.
  </p>
  <p>
    &nbsp;&rdquo;<strong>PetView Materials</strong>&rdquo; means the Service
    Software, Documentation and PetView Systems and any and all other
    information, data, documents, materials, works and other content, devices,
    methods, processes, hardware, software and other technologies and
    inventions, including any deliverables, technical or functional
    descriptions, requirements, plans or reports, that are provided or used by
    PetView or any Subcontractor in connection with the Services or otherwise
    comprise or relate to the Services or PetView Systems. For the avoidance of
    doubt, PetView Materials include resultant data and any information, data or
    other content derived from PetView&rsquo;s monitoring of Customer&rsquo;s
    access to or use of the Services, but do not include Customer Data.
  </p>
  <p>
    &ldquo;<strong>Privacy Laws</strong>&rdquo; shall mean, to the extent
    applicable to the use by Customer, any domestic or international laws
    related to protection of Personal Information, including but not limited to
    the Children's Online Privacy Protection Act of 1998, (15 U.S.C. 6501, et
    seq.,), the Gramm-Leach Bliley Act of 1999 (GLBA)(P.L. 106-102), the
    Driver&rsquo;s Privacy Protection Act of 199 (18 USC &sect;&sect;2721-2725),
    the Fair Credit Reporting Act (FCRA) (15 U.S.C. &sect; 1681, et seq.), the
    Health and Insurance Portability and Accountability Act of 1996,104 P.L.
    191,110 Stat. 1936, 2018 California Consumer Privacy Act (1798.140(c)), the
    EU Data Protection Directive (Directive 95/46/EEC) and the General Data
    Protection Regulation (GDPR) (EU) 2016/679 of the European Parliament and of
    the Council of 27 April 2016.
  </p>
  <p>
    &ldquo;<strong>Report</strong>&rdquo; means the report which provides the
    results of Imaging Analysis Services applicable to a given Use Case as set
    forth in the PetView Site.
  </p>
  <p>
    &nbsp;&rdquo;<strong>Service Software</strong>&rdquo; means the PetView
    software application or applications and any third-party or other software
    that provide the Imaging Analysis Services, and all new versions, updates,
    revisions, improvements and modifications of the foregoing, that PetView
    provides remote access to and use of as part of the Services.
  </p>
  <p>
    &ldquo;<strong>Use Case</strong>&rdquo; means. for a given animal specie,
    the type of organ for which Imaging Analysis Services are provided by
    Petview, such as, for example, a dog spleen or a dog lung.
  </p>
  <p>
    <strong><u>Trademarks</u></strong>
  </p>
  <p>
    The Company name, the terms PetViewDX, the Company logo, and all related
    names, logos, product and service names, designs, and slogans are trademarks
    of the Company or its affiliates or licensors. You must not use such marks
    without the prior written permission of the Company. All other names, logos,
    product and service names, designs, and slogans on this Website are the
    trademarks of their respective owners.
  </p>
  <p>
    <strong><u>Prohibited Uses</u></strong>
  </p>
  <p>
    You may use the Website only for lawful purposes and in accordance with
    these Terms of Use. You agree not to use the Website:
  </p>
  <ul>
    <li>
      In any way that violates any applicable federal, state, local, or
      international law or regulation (including, without limitation, any laws
      regarding the export of data or software to and from the US or other
      countries, and including any Privacy Law).
    </li>
    <li>
      For the purpose of exploiting, harming, or attempting to exploit or harm
      minors in any way by exposing them to inappropriate content, asking for
      personally identifiable information, or otherwise.
    </li>
    <li>
      To impersonate or attempt to impersonate the Company, a Company employee,
      another user, or any other person or entity (including, without
      limitation, by using email addresses or screen names associated with any
      of the foregoing).
    </li>
    <li>
      To engage in any other conduct that restricts or inhibits anyone's use or
      enjoyment of the Website, or which, as determined by us, may harm the
      Company or users of the Website, or expose them to liability.
    </li>
  </ul>
  <p>
    Except as may otherwise be set forth in a Subscription Agreement as it
    applies to You as a Primary Account Holders or as an Authorized User
    pursuant to the Subscription Agreement, You must not:
  </p>
  <ul>
    <li>Modify copies of any PetView Material.</li>
    <li>
      Use any illustrations, photographs, video or audio sequences, or any
      graphics separately from the accompanying text.
    </li>
    <li>
      Delete or alter any copyright, trademark, or other proprietary rights
      notices from copies of materials from this site.
    </li>
    <li>
      Access or use for any commercial purposes any part of the Website or any
      services or materials available through the Website.
    </li>
    <li>
      Rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer
      or otherwise make available any Imaging Analysis Services, PetView
      Materials, or Reports to any Person, including on or in connection with
      the internet or any time-sharing, service bureau, software as a service,
      cloud or other technology or service.
    </li>
    <li>
      Reverse engineer, disassemble, decompile, decode, adapt or otherwise
      attempt to derive or gain access to the source code of the Service
      Software, or PetView Materials, or reproduce the PetView Site or any
      content thereon, or any webpage thereon, or the look and feel or likeness
      thereof.
    </li>
    <li>
      Access or use the Imaging Analysis Services or PetView Materials for
      purposes of competitive analysis of the Imaging Analysis Services, PetView
      Materials, or Reports, the development, provision or use of a competing
      software service or product or any other purpose that is to the
      PetView&rsquo;s detriment or commercial disadvantage.
    </li>
    <li>
      Run or install any computer software or hardware on our products, services
      or network; use any technology to automatically download, mine, scrape or
      index our data, or in any way download the PetView data in bulk.
    </li>
    <li>
      Automatically connect (whether through APIs or otherwise) our data to
      other data, software, services or networks.
    </li>
    <li>
      Otherwise access or use the Imaging Analysis Services, PetView Materials
      and Petviw research data beyond the scope of the authorization granted in
      this Terms and Conditions of Use.
    </li>
  </ul>
  <p>Additionally, you agree not to:</p>
  <ul>
    <li>
      Use the Website in any manner that could disable, overburden, damage, or
      impair the site or interfere with any other party's use of the Website,
      including their ability to engage in real time activities through the
      Website.
    </li>
    <li>
      Use any robot, spider, or other automatic device, process, or means to
      access the Website for any purpose, including monitoring or copying any of
      the material on the Website.
    </li>
    <li>
      Use any manual process to monitor or copy any of the material on the
      Website, or for any other purpose not expressly authorized in these Terms
      of Use, without our prior written consent.
    </li>
    <li>
      Use any device, software, or routine that interferes with the proper
      working of the Website.
    </li>
    <li>
      Introduce any viruses, Trojan horses, worms, logic bombs, or other
      material that is malicious or technologically harmful.
    </li>
    <li>
      Attempt to gain unauthorized access to, interfere with, damage, or disrupt
      any parts of the Website, the server on which the Website is stored, or
      any server, computer, or database connected to the Website.
    </li>
    <li>
      Attack the Website via a denial-of-service attack or a distributed
      denial-of-service attack.
    </li>
    <li>
      Otherwise attempt to interfere with the proper working of the Website.
    </li>
  </ul>
  <p>
    <strong><u>Changes to the Website</u></strong>
  </p>
  <p>
    We may update the content on this Website from time to time, but its content
    is not necessarily complete or up-to-date. Any of the material on the
    Website may be out of date at any given time, and we are under no obligation
    to update such material.
  </p>
  <p>
    <strong><u>Information About You and Your Visits to the Website</u></strong>
  </p>
  <p>
    All information we collect on this Website is subject to our Privacy Policy
    [LINK TO PRIVACY POLICY]. By using the Website, you consent to all actions
    taken by us with respect to your information in compliance with the Privacy
    Policy.
  </p>
  <p>
    <strong><u>Linking to the Website and Social Media Features</u></strong>
  </p>
  <p>
    If the Website contains links to other sites and resources provided by third
    parties, these links are provided for your convenience only. This includes
    links contained in advertisements, including banner advertisements and
    sponsored links. We have no control over the contents of those sites or
    resources, and accept no responsibility for them or for any loss or damage
    that may arise from your use of them. If you decide to access any of the
    third-party websites linked to this Website, you do so entirely at your own
    risk and subject to the terms and conditions of use for such websites.
  </p>
  <p>
    <strong><u>Geographic Restrictions</u></strong>
  </p>
  <p>
    The owner of the Website is based in the State of North Carolina in the
    United States. We provide this Website for use only by persons located in
    the United States. We make no claims that the Website or any of its content
    is accessible or appropriate outside of the United States. Access to the
    Website may not be legal by certain persons or in certain countries. If you
    access the Website from outside the United States, you do so on your own
    initiative and are responsible for compliance with local laws.
  </p>
  <p>
    <strong><u>Disclaimer of Warranties</u></strong>
  </p>
  <p>
    You understand that we cannot and do not guarantee or warrant that files
    available for downloading from the internet or the Website will be free of
    viruses or other destructive code. You are responsible for implementing
    sufficient procedures and checkpoints to satisfy your particular
    requirements for anti-virus protection and accuracy of data input and
    output, and for maintaining a means external to our site for any
    reconstruction of any lost data.
    <strong
      >TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS
      OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR
      OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
      EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO
      YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
      WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY
      WEBSITE LINKED TO IT.</strong
    >
  </p>
  <p>
    <strong
      >YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED
      THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY
      SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS"
      AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER
      EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE
      COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
      COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF
      THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR
      ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE
      WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
      WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT
      DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT
      AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE
      WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL
      OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
    </strong>
  </p>
  <p>
    TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL
    WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE,
    INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
    NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
  </p>
  <p>
    THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR
    LIMITED UNDER APPLICABLE LAW.
  </p>
  <p>
    <strong><u>Limitation on Liability</u></strong>
  </p>
  <p>
    <strong
      >TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS
      AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS,
      OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL
      THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO
      USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR
      SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
      CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL
      INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF
      PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
      GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
      BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
    </strong>
  </p>
  <p>
    <strong
      >THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR
      LIMITED UNDER APPLICABLE LAW.</strong
    >
  </p>
  <p>
    <strong><u>Indemnification</u></strong>
  </p>
  <p>
    You agree to defend, indemnify, and hold harmless the Company, its
    affiliates, licensors, and service providers, and its and their respective
    officers, directors, employees, contractors, agents, licensors, suppliers,
    successors, and assigns from and against any claims, liabilities, damages,
    judgments, awards, losses, costs, expenses, or fees (including reasonable
    attorneys' fees) arising out of or relating to your violation of these Terms
    of Use, of the Beta Partner Letter Agreement, of the Trial Use Agreement, or
    of the Subscription Agreement or your use of the Website, including, but not
    limited to, your user contributions, any general use of the Website's
    content, services, and products other than as expressly authorized in these
    Terms of Use, or your use of any information obtained from the Website.
  </p>
  <p>
    <strong><u>Governing Law and Jurisdiction</u></strong>
  </p>
  <p>
    All matters relating to the Website and these Terms of Use, and any dispute
    or claim arising therefrom or related thereto (in each case, including
    non-contractual disputes or claims), shall be governed by and construed in
    accordance with the internal laws of the State of North Carolina without
    giving effect to any choice or conflict of law provision or rule (whether of
    the State of North Carolina or any other jurisdiction).
  </p>
  <p>
    Any legal suit, action, or proceeding arising out of, or related to, these
    Terms of Use or the Website shall be instituted exclusively in the federal
    courts of the United States or the courts of the State of North Carolina, in
    each case located in the City of Raleigh and County of Wake, although we
    retain the right to bring any suit, action, or proceeding against you for
    breach of these Terms of Use in your country of residence or any other
    relevant country. You waive any and all objections to the exercise of
    jurisdiction over you by such courts and to venue in such courts.
  </p>
  <p>
    <strong><u>Arbitration</u></strong>
  </p>
  <p>
    At Company's sole discretion, it may require You to submit any disputes
    arising from these Terms of Use or use of the Website, including disputes
    arising from or concerning their interpretation, violation, invalidity,
    non-performance, or termination, to final and binding arbitration under the
    Rules of Arbitration of the American Arbitration Association applying North
    Carolina law.
  </p>
  <p>
    <strong><u>Limitation on Time to File Claims</u></strong>
  </p>
  <p>
    <strong
      >ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO
      THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR
      AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR
      CLAIM IS PERMANENTLY BARRED.</strong
    >
  </p>
  <p>
    <strong><u>Waiver and Severability</u></strong>
  </p>
  <p>
    No waiver by the Company of any term or condition set out in these Terms of
    Use shall be deemed a further or continuing waiver of such term or condition
    or a waiver of any other term or condition, and any failure of the Company
    to assert a right or provision under these Terms of Use shall not constitute
    a waiver of such right or provision.
  </p>
  <p>
    If any provision of these Terms of Use is held by a court or other tribunal
    of competent jurisdiction to be invalid, illegal, or unenforceable for any
    reason, such provision shall be eliminated or limited to the minimum extent
    such that the remaining provisions of the Terms of Use will continue in full
    force and effect.
  </p>
  <p>
    <strong><u>Entire Agreement</u></strong>
  </p>
  <p>
    The Terms of Use, our Privacy Policy, and PetViewDX Imaging Analysis
    Services Subscription Agreement constitute the sole and entire agreement
    between you and PetView regarding the Website and supersede all prior and
    contemporaneous understandings, agreements, representations, and warranties,
    both written and oral, regarding the Website.
  </p>
  <p>
    <strong><u>Your Comments and Concerns</u></strong>
  </p>
  <p>This website is operated by PetViewDX, LLC [COMPANY ADDRESS].</p>
  <p>
    All other feedback, comments, requests for technical support, and other
    communications relating to the Website should be directed to: [EMAIL
    ADDRESS].
  </p>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button
    color="primary"
    mat-raised-button
    class="my-button"
    (click)="onConfirm()"
    cdkFocusInitial
  >
    I agree
  </button>
</mat-dialog-actions>
