<div
  id="register-account-type-stepper"
  class="card m-auto p-2 demo-form"
>

  <p
    class="msg-expired-trial"
    #msg
  >Your trial period has expired, if you want to continue using our portal, please go
    through the procedure in the
    next
    steps. If you need help, please feel free to contact us.
    <span
      (click)="dismiss(msg)"
      class="cursor-pointer close-btn"
    >
      <i class="material-icons">close</i>
    </span>
  </p>

  <mat-horizontal-stepper
    #stepper
    [linear]="true"
    [@.disabled]="true"
    (selectionChange)="onChangeStep($event)"
  >
    <!-- 1 stepper -->
    <mat-step [stepControl]="accountType">
      <form
        novalidate
        [formGroup]="accountType"
        class="account-type-form"
      >
        <ng-template matStepLabel>Account Type</ng-template>

        <h1 class="text-center">Are you a primary account owner?</h1>

        <app-register-account-type-form
          [form]="accountType"
          (hideBilling)="setBillingStepper($event)"
          [clinicsList]="clinicsList"
          [AccOwner]="AccOwner"
        >
        </app-register-account-type-form>

        <div class="stepper-action">
          <button
            mat-raised-button
            color="primary"
            type="button"
            class="my-button"
            matStepperNext
            [disabled]="accountType.get('type').invalid || fieldsInvalid"
          >
            Continue
          </button>
        </div>
      </form>
    </mat-step>

    <!-- Pricing Plan step -->
    <ng-container *ngIf="!hideBilling">
      <mat-step
        [stepControl]="pricingForm"
        [optional]="hideBilling"
      >
        <form
          novalidate
          [formGroup]="pricingForm"
          class="pricing-plan-form"
        >
          <ng-template matStepLabel>Pricing Plan</ng-template>

          <h1>Select pricing plan</h1>

          <app-register-pricing-plan [form]="pricingForm"></app-register-pricing-plan>

          <div class="stepper-action">
            <button
              mat-raised-button
              color="primary"
              type="button"
              class="my-button"
              [disabled]="pricingForm.invalid || loading"
              matStepperNext
            >
              Continue
            </button>
          </div>
        </form>
      </mat-step>
    </ng-container>

    <!-- 2 stepper -->
    <ng-container *ngIf="!hideBilling && pricingForm.get('plan').value !== -1">
      <mat-step
        [stepControl]="billingForm"
        [optional]="hideBilling"
      >
        <form
          novalidate
          [formGroup]="billingForm"
          class="account-type-form"
        >
          <ng-template matStepLabel>Billing</ng-template>

          <h1>Add new billing info</h1>

          <app-billing-form [form]="billingForm"></app-billing-form>

          <div class="stepper-action">
            <button
              mat-raised-button
              color="primary"
              type="button"
              class="my-button"
              [disabled]="billingForm.invalid || loading"
              (click)="submitBilling()"
            >
              <!-- <div
                *ngIf="loading"
                class="spinner-border text-success"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div> -->
              Continue
            </button>
          </div>
        </form>
      </mat-step>
    </ng-container>
    <!-- 3 stepper -->
    <mat-step [stepControl]="accountType">
      <form
        novalidate
        [formGroup]="accountType"
        class="account-type-form"
      >
        <ng-template matStepLabel>Finish</ng-template>

        <ng-container *ngIf="!hideBilling && pricingForm.get('plan').value !== -1">
          <h2>You have successfully added a billing account.</h2>

          <app-register-account-type-invites
            [form]="invitesForm"
            [formArray]="invitesArray"
            (onAddEmail)="onAddEmail()"
            (onRemoveEmail)="onRemoveEmail($event)"
          >
          </app-register-account-type-invites>

          <div class="stepper-action">
            <button
              mat-raised-button
              color="primary"
              type="button"
              class="my-button mr-1"
              [disabled]="accountType.get('type').invalid || loading || invitesForm.invalid"
              (click)="submitWithInvites()"
            >
              Invite All
            </button>

            <a
              mat-button
              [routerLink]="['login']"
              class="my-link my-button"
            >Skip</a>
          </div>
        </ng-container>

        <ng-container *ngIf="pricingForm.get('plan').value === -1">
          <h2>You are about to create a demo trial account!</h2>

          <div class="stepper-action">
            <button
              mat-raised-button
              color="primary"
              type="button"
              class="my-button"
              [disabled]="accountType.get('type').invalid || loading"
              (click)="onSubmitRequest()"
            >
              Submit
            </button>
          </div>
        </ng-container>


        <ng-container *ngIf="hideBilling && pricingForm.get('plan').value !== -1">

          <div class="d-flex justify-content-center align-items-center flex-column">
            <h2>
              Your request will be send to the clinic
            </h2>

            <span class="clinic-name-block">
              {{ clinicName(accountType.get("clinic_id").value) }}
            </span>
          </div>

          <div class="stepper-action">
            <button
              mat-raised-button
              color="primary"
              type="button"
              class="my-button"
              [disabled]="accountType.get('type').invalid || loading"
              (click)="onSubmitRequest()"
            >
              Submit
            </button>
          </div>
        </ng-container>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
  <!-- </form> -->
</div>
