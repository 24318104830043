import { UtilService } from '@services/util.service';
import { Resourses } from '@shared/resources/add-resourses';
import { UserActivityDataSource } from './../_datasources/user-activity.datasource';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuditLogsDetailsComponent } from '@app/audit-logs/audit-logs-details/audit-logs-details.component';
import { AuditsService } from '@services/audits.service';
import { SpinnerService } from '@services/spinner.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserActivityDetailsComponent } from './user-activity-details/user-activity-details.component';
import { UserActivityFilters } from '@models/filters';

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.scss']
})
export class UserActivityComponent implements OnInit {

  // datasource
  dataSource: any;
  displayedColumns: any[] = [
    'id',
    'type',
    'clinic',
    'created_at',
    'email',
    'plan',
    'form_step',
    'form_fields',
    'route',
  ];
  pageSize: number;
  total: number;

  filters = new UserActivityFilters();

  // * Dropdowns
  dropdownUserTypes: any[] = [];
  dropdownFormSteps: any[] = [];

  subscription: Subscription[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatExpansionPanel, { static: true }) accordion: MatExpansionPanel;

  constructor(
    private dialog: MatDialog,
    private auditsService: AuditsService,
    private spinner: SpinnerService,
    private resources: Resourses,
    public utilService: UtilService,
  ) { }

  ngOnInit(): void {
    this.dataSource = new UserActivityDataSource(this.auditsService);
    this.loadDataSource();

    this.dropdownUserTypes = this.resources.dropdownUserTypes;
    this.dropdownFormSteps = this.resources.dropdownFormSteps;
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.paginator.page.pipe(tap(() => this.loadUserActivityPage())).subscribe();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }

  loadUserActivityPage(): void {
    this.dataSource.loadUserActivity(this.paginator.pageIndex + 1, { ...this.filters });
  }

  loadDataSource(): void {
    this.dataSource.loadUserActivity(1, { ...this.filters });
    this.paginator.firstPage();
    this.loadPaginationInfo();

    this.subscription.push(
      this.dataSource.loadingSubject.subscribe((res) => {
        if (!res) {
          this.spinner.hideSpinner('table');
        } else {
          this.spinner.showSpinner('table');
        }
      })
    );
  }

  loadPaginationInfo(): void {
    this.auditsService
      .getAllUserActivity(1, { ...this.filters })
      .subscribe((res: any) => {
        this.total = res.total;
        this.pageSize = res.per_page;
      });
  }

  showFormFields(row: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = row;
    dialogConfig.panelClass = 'modal-primary';
    this.dialog.open(UserActivityDetailsComponent, dialogConfig);
  }

  /** Helpers: */
  resetFilters(): void {
    this.filters = new UserActivityFilters();
    this.loadDataSource();
  }

  onSearch(): void {
    this.loadDataSource();
  }

  onClear(event, field): void {
    event.stopPropagation();
    this.filters[field] = '';
    this.loadDataSource();
  }

  export() {
    console.log('export');
    this.auditsService.export().subscribe({
      next: (resp) => {
        window.open(resp.link, 'download_window');
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  onDetailsView(row: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = row;
    dialogConfig.panelClass = 'modal-primary';
    this.dialog.open(AuditLogsDetailsComponent, dialogConfig);
  }
}
