import { HttpService } from './http.service';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private configuration: any = {
    timeOut: 10000,
    disableTimeOut: false,
    autoDismiss: true,
    newestOnTop: true,
    preventDuplicates: true,
    closeButton: true,
    maxOpened: 5,
    positionClass: 'toast-top-right',
    messageClass: 'toast-message',
    titleClass: 'toast-title',
    easing: 'slideFromLeft',
    enableHtml: true,
    iconClasses: null,
    countDuplicates: true,
    resetTimeoutOnDuplicate: false,
  };

  constructor(private http: HttpService, private toastr: ToastrService) {
    /** Potrebno ispitivanje */
    // POTREBNO ISPITIVANJE
    // this.toastr.onTap().subscribe((event: any) => {
    //   if (event.data != null) {
    //     this.setAsSeen(event.data.data.case_id).subscribe(res => { });
    //     this.router.navigate(['/cases/view/', event.data.data.case_id]);
    //   }
    // });
  }

  setAsSeen(id: number): any {
    return this.http.get('/notifications/changestatus/' + id);
  }

  showSuccess(msg: string, title: string): void {
    this.toastr.success(msg, title, this.configuration);
  }

  showError(msg: string, title: string): void {
    this.toastr.error(msg, title, this.configuration);
  }

  showWarrning(msg: string, title: string): void {
    this.toastr.warning(msg, title, this.configuration);
  }

  showInfo(msg: string, title: string): void {
    // this.toast.infoToastr(msg, title, this.configuration);
    this.toastr.info(msg, title, this.configuration);
  }

  showNotification(msg: string, title: string, data: any): void {
    // this.toast.infoToastr(msg, title, this.configuration);
    this.toastr.info(msg, title, {
      timeOut: 10000,
      newestOnTop: true,
      closeButton: true,
      messageClass: null,
      titleClass: null,
      enableHtml: true,
    });
  }
}
