export const refImagesList = {
  good: [
    './../../../assets/images/good_example.jpg',
    './../../../assets/images/good_example2.jpg'
  ],
  bad: [
    './../../../assets/images/bad_example.jpg',
    './../../../assets/images/bad_example2.jpg'
  ]
};
