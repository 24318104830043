import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-upload-data-details-dialog',
  templateUrl: './case-upload-data-details-dialog.component.html',
  styleUrls: ['./case-upload-data-details-dialog.component.scss']
})
export class CaseUploadDataDetailsDialogComponent implements OnInit {

  @Input() uploadDataCaseData: any;
  @Input() loading: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
