<div class="tools-list">
  <h3 class="title text-center">AOI Tools:</h3>
  <div class="btn-group">
    <!-- zoom -->
    <div
      class="tool"
      (click)="onZoom()"
      [ngClass]="{ active: this.drawingHelper.selectedShapeCopy == 'zoom' }"
      [mdePopoverTriggerFor]="appPopover"
      mdePopoverTriggerOn="hover"
      #popoverTrigger="mdePopoverTrigger"
    >
      <!-- <mat-slider id="zoom" min="0.1" max="1" step="0.1" tickInterval="1" (input)="onChangeSliderValue($event)"
                  [value]="sliderValue"></mat-slider> -->
      <button matTooltipClass="tooltip_break_line" mat-icon-button mat-primary>
        <mat-icon class="material-icons-outlined">search</mat-icon>
      </button>
      <label color="primary" for="zoom">Zoom</label>
      <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
        <div class="popover box-orange bg-dark card">
          <div>hold <span class="text-orange">ALT</span> to move</div>
        </div>
      </mde-popover>
    </div>

    <!-- Draw rectangle button -->
    <div
      class="tool"
      *ngIf="!this.drawingHelper.showApprovedTools"
      [ngClass]="{ active: this.drawingHelper.selectedShapeCopy == 'rect' }"
      (click)="onSelectRectangleCrop('rect')"
    >
      <button #toolbutton mat-icon-button aria-label="Rectangle Shape">
        <i class="material-icons">crop_landscape</i>
      </button>
      <p>Rectangle</p>
    </div>
    <!-- Draw circle button -->
    <div
      class="tool"
      *ngIf="!this.drawingHelper.showApprovedTools"
      [ngClass]="{ active: this.drawingHelper.selectedShapeCopy == 'circle' }"
      (click)="onSelectCircleCrop('circle')"
    >
      <button #toolbutton mat-icon-button aria-label="Circle Shape">
        <i class="material-icons">panorama_fish_eye</i>
      </button>
      <p>Circle</p>
    </div>
    <!-- Draw polygon button -->
    <div
      class="tool"
      [ngClass]="{ active: this.drawingHelper.selectedShapeCopy == 'polygon' }"
      (click)="onSelectPolygon('polygon')"
    >
      <button mat-icon-button aria-label="Polygon Shape">
        <i class="material-icons">format_shapes</i>
      </button>
      <p>Polygon</p>
    </div>

    <!-- Remove All Markers button -->
    <div class="tool" (click)="this.drawingHelper.removeAllAOIMarker()">
      <button
        matTooltip="Remove ALL AOI markers"
        mat-icon-button
        aria-label="Remove All AOI Markers"
      >
        <i class="material-icons text-danger">delete_sweep</i>
      </button>
      <p>Delete all</p>
    </div>
    <!-- Remove Selected Marker button -->
    <div class="tool" (click)="removeAOIMarker()">
      <button
        matTooltip="Remove selected AOI marker"
        mat-icon-button
        aria-label="Remove selected AOI"
      >
        <i class="material-icons">delete</i>
      </button>
      <p>Delete</p>
    </div>
  </div>
</div>

<div #modalLimit id="modal-limit">
  <div class="content">
    <p>
      The maximum number of shapes in the image has been exceeded. The maximum
      number is {{ this.drawingHelper.maxNumberOfShapes }}.
    </p>
    <a mat-raised-button class="close-btn" (click)="closeModalLimit()">Close</a>
  </div>
</div>
