import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@modules/material/material.module';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { InformationOfTypeDialogComponent } from './components/information-of-type-dialog/information-of-type-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { UseCaseNameComponent } from './components/case/use-case-name/use-case-name.component';
import { ImageViewerStandaloneComponent } from './components/image-viewer-standalone/image-viewer-standalone.component';

@NgModule({
  declarations: [
    InformationOfTypeDialogComponent,
    ConfirmDialogComponent,
    UseCaseNameComponent,
    ImageViewerComponent,
    ImageViewerStandaloneComponent
  ],
  imports: [CommonModule, MaterialModule],
  exports: [
    InformationOfTypeDialogComponent,
    ConfirmDialogComponent,
    ImageViewerComponent,
    ImageViewerStandaloneComponent
  ],
})
export class SharedModule { }
