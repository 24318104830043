import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { config } from '@helpers/Config';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) { }

  public get(route, params?): Observable<any> {
    const url = config.url(route);

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('No-Auth', 'True');

    return this.http
      .get(url, { headers, params })
      .pipe(map((response) => response));
  }

  public getTable(
    route,
    search: string,
    page: number = 1,
    filter: string = '',
    caseType: string = ''
  ): Observable<any> {
    const url = config.url(route);

    const headers = new HttpHeaders().set('Accept', 'application/json');
    const params = new HttpParams()
      .set('search', search)
      .set('filter', filter)
      .set('type', caseType)
      .set('page', page.toString());

    return this.http.get(url, { headers, params });
  }

  public getClinicTable(route, page: number = 1, filters): Observable<any> {
    const url = config.url(route);

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('No-Auth', 'True');
    const params = new HttpParams()
      .set('search', filters.filterSearch)
      .set('name', filters.filterClinicName)
      .set('email', filters.filterClinicEmail)
      .set('created_from', filters.filterCreatedFrom)
      .set('created_to', filters.filterCreatedTo)
      .set('last_active_from', filters.filterLastActiveFrom)
      .set('last_active_to', filters.filterLastActiveTo)
      .set('page', page.toString());

    return this.http
      .get(url, { headers, params })
      .pipe(map((response) => response));
  }

  public getClinicUsersTable(
    route,
    page: number = 1,
    filters
  ): Observable<any> {
    const url = config.url(route);

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('No-Auth', 'True');
    const params = new HttpParams()
      .set('search', filters.filterSearch)
      .set('name', filters.filterUserName)
      .set('clinic_name', filters.filterClinicName)
      .set('created_from', filters.filterCreatedFrom)
      .set('created_to', filters.filterCreatedTo)
      .set('last_active_from', filters.filterLastActiveFrom)
      .set('last_active_to', filters.filterLastActiveTo)
      .set('account_type', filters.filterAccountType)
      .set('page', page.toString());

    return this.http
      .get(url, { headers, params })
      .pipe(map((response) => response));
  }

  public getClinicUsersRequestsTable(
    route,
    page: number = 1,
    filters
  ): Observable<any> {
    const url = config.url(route);

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('No-Auth', 'True');
    const params = new HttpParams()
      .set('search', filters.filterSearch)
      .set('name', filters.filterUserName)
      .set('clinic_name', filters.filterClinicName)
      .set('email', filters.filterEmail)
      .set('phone', filters.filterPhone)
      .set('address', filters.filterAddress)
      .set('created_from', filters.filterCreatedFrom)
      .set('created_to', filters.filterCreatedTo)
      .set('page', page.toString());

    return this.http
      .get(url, { headers, params })
      .pipe(map((response) => response));
  }

  public getClinicRequestsTable(
    route,
    page: number = 1,
    filters
  ): Observable<any> {
    const url = config.url(route);

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('No-Auth', 'True');
    const params = new HttpParams()
      .set('search', filters.filterSearch)
      .set('name', filters.filterClinicName)
      .set('email', filters.filterClinicEmail)
      .set('phone', filters.filterClinicPhone)
      .set('address', filters.filterClinicAddress)
      .set('created_from', filters.filterCreatedFrom)
      .set('created_to', filters.filterCreatedTo)
      .set('page', page.toString());

    return this.http
      .get(url, { headers, params })
      .pipe(map((response) => response));
  }

  public getCaseTable(route, page: number = 1, filters): Observable<any> {
    const url = config.url(route);

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('No-Auth', 'True');
    const params = new HttpParams()
      .set('search', filters.filterSearch)
      .set('library', filters.filterLibrary)
      .set('biopsy', filters.filterBiopsy)
      .set('aoi_captured', filters.filterAOICaptured)
      .set('species', filters.filterSpecies)
      .set('usecase', filters.filterUseCase)
      .set('diagnosis', filters.filterDiagnosis)
      .set('sex', filters.filterGender)
      .set('alteredStatus', filters.filterReproductiveStatus)
      .set('imageType', filters.filterImageType)
      .set('other_image_type', filters.filterOtherImageType)
      .set('aoi_type', filters.filterAOIType)
      .set('case_status', filters.filterCaseStatus)
      .set('breed', filters.filterBreed)
      .set('age', filters.filterAge)
      .set('imageModality', filters.filterImageModality)
      .set('caseType', filters.filterCaseType)
      .set('machine_manufacturer', filters.filterMachineManufacturer)
      .set('machine_model', filters.filterMachineModel)
      .set('bodyPart', filters.filterBodyPart)
      .set('client', filters.filterClient)
      .set('patient', filters.filterPatient)
      .set('dateFrom', filters.filterDateFrom)
      .set('dateTo', filters.filterDateTo)
      .set('page', page.toString());

    return this.http
      .get(url, { headers, params })
      .pipe(map((response) => response));
  }

  public getClientTable(route, page = 1, filters): Observable<any> {
    const url = config.url(route);
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const params = new HttpParams()
      .set('search', filters.filterSearch)
      .set('clientId', filters.filterClientId)
      .set('name', filters.filterName)
      .set('numberPatient', filters.filterNumberPatient)
      .set('patient', filters.filterPatientName)
      .set('dateFrom', filters.filterDateFrom)
      .set('dateTo', filters.filterDateTo)
      .set('created', filters.filterCreated)
      .set('updated', filters.filterUpdated)
      .set('patientId', filters.filterPatientId)
      .set('page', page.toString());

    return this.http
      .get(url, { headers, params })
      .pipe(map((response) => response));
  }

  public getPatientTable(route, page = 1, filters): Observable<any> {
    const url = config.url(route);
    const headers = new HttpHeaders().set('Accept', 'application/json');

    // {{endpoint}}/patients?search=&name=rex&patientId=&client=ime&clientId=&created=&updated=
    const params = new HttpParams()
      .set('search', filters.filterSearch)
      .set('clientId', filters.filterClientId)
      .set('client', filters.filterClientName)
      .set('patientId', filters.filterPatientId)
      .set('name', filters.filterPatientName)
      .set('dateFrom', filters.filterDateFrom)
      .set('dateTo', filters.filterDateTo)
      .set('created', filters.filterCreated)
      .set('updated', filters.filterUpdated)
      .set('page', page.toString());

    return this.http
      .get(url, { headers, params })
      .pipe(map((response) => response));
  }

  public getAuditTable(route, page = 1, filterSearch = ''): Observable<any> {
    const url = config.url(route);
    const headers = new HttpHeaders().set('Accept', 'application/json');

    const params = new HttpParams()
      .set('search', filterSearch)
      .set('page', page.toString());

    return this.http
      .get(url, { headers, params })
      .pipe(map((response) => response));
  }

  public getUserActivityTable(route, page = 1, filters): Observable<any> {
    const url = config.url(route);
    const headers = new HttpHeaders().set('Accept', 'application/json');

    const params = new HttpParams()
      .set('search', filters.filterSearch)
      .set('page', page.toString())
      .set('type', filters.filterUserType)
      .set('form_step', filters.filterFormStep)
      .set('create_from', filters.filterDateFrom)
      .set('create_to', filters.filterDateTo)

    return this.http
      .get(url, { headers, params })
      .pipe(map((response) => response));
  }

  public getSearchTable(
    route,
    page: number = 1,
    search: string = '',
    library: string = '',
    dateFrom = '',
    dateTo = '',
    biopsy = '',
    aoiCaptured = '',
    species: string = '',
    bodyPart: string = '',
    algorithm: string = '',
    diagnosis: string = '',
    gender: string = '',
    alteredStatus: string = '',
    imageType: string = '',
    breed: string = '',
    age: any = '',
    imageModality: any = '',
    bodyView: any = '',
    aoiType: any = '',
    machineManufacturer: any = '',
    machineModel: any = '',
  ): Observable<any> {
    const url = config.url(route);

    const headers = new HttpHeaders().set('Accept', 'application/json');
    const params = new HttpParams()
      .set('search', search)
      .set('case_type', library)
      .set('date_from', dateFrom)
      .set('date_to', dateTo)
      .set('pathology', biopsy)
      .set('aoi_captured', aoiCaptured)
      .set('patient', species)
      .set('body_part', bodyPart)
      .set('algorithm', algorithm)
      .set('diagnosis', diagnosis)
      .set('gender', gender)
      .set('altered_status', alteredStatus)
      .set('image_type', imageType)
      .set('breed', breed)
      .set('age', age)
      .set('modality', imageModality)
      .set('body_view', bodyView)
      .set('aoi_type', aoiType)
      .set('machine_manufacturer', machineManufacturer)
      .set('machine_model', machineModel)
      .set('page', page.toString());

    return this.http
      .get(url, { headers, params })
      .pipe(map((response) => response));
  }

  public post(route, data): Observable<any> {
    const url = config.url(route);

    const headers = new HttpHeaders().set('Accept', 'application/json');

    return this.http.post(url, data, { headers }).pipe(map((res) => res));
  }

  public put(route, data): Observable<any> {
    const url = config.url(route);

    const headers = new HttpHeaders().set('Accept', 'applicaiton/json');

    return this.http.put(url, data, { headers }).pipe(map((res) => res));
  }

  public delete(route): Observable<any> {
    const url = config.url(route);

    const headers = new HttpHeaders().set('Accept', 'application/json');

    return this.http.delete(url, { headers }).pipe(map((res) => res));
  }

  public postFile(route, data): Observable<any> {
    const url = config.url(route);

    const headers = new HttpHeaders().set('Accept', 'application/json');

    return this.http
      .post(url, data, {
        headers,
        reportProgress: true,
      })
      .pipe(map((res) => res));
  }

  public putFile(route, data): Observable<any> {
    const url = config.url(route);

    const headers = new HttpHeaders().set('Accept', 'application/json');

    return this.http
      .put(url, data, {
        headers,
        reportProgress: true,
      })
      .pipe(map((res) => res));
  }
}
